import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import ReviewerVerifiedSearchContainer from "containers/reviewerVerified/ReviewerVerifiedSearchContainer";
import Checkbox from "@material-ui/core/Checkbox";
import { TableContainer, Table, TableHead, TableRow, TableBody, Button } from "@material-ui/core";

import ListWrapper from "components/common/ListWrapper";
import CustomTableCell from "components/common/CustomTableCell";
import { callDirectApi } from "lib/common";
import { setVerifiedStatus as setVerifiedStatusAPI } from "lib/api/reviewerStatus";
import { Color } from "theme";

const tableHeaderList = [
    { label: "리뷰어 ID", align: "center" },
    { label: "닉네임", align: "center" },
    { label: "상태", align: "center" },
    { label: "포트폴리오", align: "center" },
    { label: "취소 횟수", align: "center" },
    { label: "프로젝트 미제출", align: "center" },
    { label: "제안 미제출", align: "center" }
];

const convertStatus = status => {
    switch (status) {
        case "Verified":
            return "인증 완료";

        default:
            return "인증 요청";
    }
};
const ReviewerVerifiedContainer = () => {
    const history = useHistory();
    const location = useLocation();

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState([]);

    const totalCount = useSelector(({ reviewerStatus }) => reviewerStatus.totalCount);
    const verifiedList = useSelector(({ reviewerStatus }) => reviewerStatus.list);

    const params = queryString.parse(location.search);

    const setPage = pageNum => {
        params.page = pageNum;
        const query = queryString.stringify(params);
        history.push(`${location.pathname}?${query}`);
    };

    const onHandleCheck = id => {
        if (!id) return;

        const _selectItem = [...selectedItem];
        const index = _selectItem.indexOf(id);

        if (index > -1) {
            _selectItem.splice(index, 1);
        } else {
            _selectItem.push(id);
        }

        setSelectedItem(_selectItem);
    };

    const setVerifiedStatus = async type => {
        try {
            await callDirectApi(setVerifiedStatusAPI, { reviewerIdList: selectedItem, type }, true);
            history.go(0);
        } catch (error) {
            window.alert(error.message);
        }
    };

    useEffect(() => {
        if (params.page) {
            setCurrentPage(Number(params.page));
        }
    }, [params.page]);

    useEffect(() => {
        setSelectedItem([]);
    }, [location.search]);
    return (
        <>
            <ReviewerVerifiedSearchContainer />
            <ListWrapper
                pageLimit={30}
                currentPage={currentPage}
                handlePage={(e, pageNum) => setPage(pageNum)}
                totalCount={totalCount}
                customButtons={
                    params.status !== "Verified" && (
                        <>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{
                                    backgroundColor: Color.greenDark
                                }}
                                onClick={() => setVerifiedStatus("confirm")}
                            >
                                인증 승인
                            </Button>

                            <Button
                                variant="contained"
                                color="secondary"
                                style={{
                                    backgroundColor: Color.tertiaryColor
                                }}
                                onClick={() => setVerifiedStatus("deny")}
                            >
                                인증 거절
                            </Button>
                        </>
                    )
                }
            >
                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>
                                    <Checkbox color="primary" />
                                </CustomTableCell>
                                {(params.status !== "Verified"
                                    ? [...tableHeaderList, { label: "신청 날짜", align: "center" }]
                                    : [...tableHeaderList, { label: "인증 날짜", align: "center" }]
                                ).map((headerItem, index) => (
                                    <CustomTableCell
                                        align={headerItem.align}
                                        key={index}
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        {headerItem.label}
                                    </CustomTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!verifiedList?.length &&
                                verifiedList.map((reviewerStatus, index) => {
                                    const isSelected =
                                        selectedItem.indexOf(reviewerStatus.owner.id) > -1;
                                    return (
                                        <TableRow key={index} hover>
                                            <CustomTableCell width={20}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={isSelected}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        onHandleCheck(reviewerStatus.owner.id);
                                                    }}
                                                />
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width="10%"
                                            >
                                                {reviewerStatus.owner.id}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width="10%"
                                            >
                                                {reviewerStatus.ownerInfo.nickname}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                className={
                                                    reviewerStatus.verifyStatus === "Request"
                                                        ? "red-font"
                                                        : ""
                                                }
                                                style={{ cursor: "pointer" }}
                                                width="10%"
                                            >
                                                {convertStatus(reviewerStatus.verifyStatus)}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width="30%"
                                            >
                                                <a
                                                    href={`${process.env.REACT_APP_MAIN_SERVICE_URL}/portfolio/${reviewerStatus.ownerInfo.portfolioUrl}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    @{reviewerStatus.ownerInfo.portfolioUrl}
                                                </a>
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width="10%"
                                            >
                                                {reviewerStatus.cancel_count}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width="10%"
                                            >
                                                {reviewerStatus.unsubmitCount.projectCounts}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width="10%"
                                            >
                                                {reviewerStatus.unsubmitCount.suggestionCounts}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width="10%"
                                            >
                                                {moment(
                                                    reviewerStatus.verifyStatusDate[
                                                        params.status !== "Verified"
                                                            ? "request"
                                                            : "verified"
                                                    ]
                                                ).format("YYYY.MM.DD HH:mm")}
                                            </CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ListWrapper>
        </>
    );
};

export default ReviewerVerifiedContainer;

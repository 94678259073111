import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { numberWithCommas } from "lib/common";
import { Color } from "theme";
import CreatePromotionModal from "components/promotion/CreatePromotionModal";

import "./PromotionActions.scss";

const counts = 0;
const PromotionActions = ({ idList }) => {
    const [actionState, setActionState] = useState(null);

    const removeCoupon = () => {
        console.log("remove", idList);
    };

    return (
        <div id="promotion-list-actions">
            {actionState === "create" && (
                <CreatePromotionModal closeModal={() => setActionState(null)} />
            )}

            <ul className="count-list">
                <li>
                    <p>전체: {numberWithCommas(counts)}건</p>
                </li>
                <li>
                    <p>선택: {numberWithCommas(idList?.length)}건</p>
                </li>
            </ul>
            <ul className="btn-list">
                <li>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ backgroundColor: Color.greenDark }}
                        onClick={() => setActionState("create")}
                    >
                        생성
                    </Button>
                </li>
                <li>
                    <Button variant="contained" color="secondary" onClick={removeCoupon}>
                        삭제
                    </Button>
                </li>
            </ul>
        </div>
    );
};

export default PromotionActions;

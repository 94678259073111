export const DIVIDED_DESCRIPTION_ITEMS = [
    {
        label: "어떤 분야 전문 리뷰어인가요?",
        value: "area",
        required: true,
        noImage: true,
        placeholder: "안녕하세요 저는 뷰티/코스메틱 제품을 전문으로 하는 리뷰어입니다."
    },
    {
        label: "리뷰어님의 장점을 어필해 주세요.",
        value: "appeal",
        placeholder:
            "저만의 감성으로 분위기를 담은 자연스러운 사진들로 담당자님 목적에 맞게 콘텐츠를 제공해 드립니다!"
    },
    {
        label: "어떤 콘텐츠를 얼마나 제공하시나요?",
        value: "numOfContents",
        required: true,
        placeholder:
            "기본적으로 10장의 사진 및 영상 콘텐츠를 제공해드립니다. \n- 제품 상세 사진 3~4장 원하시는 컨셉에 최대한 맞춰서 제공 \n- 제품과 함께 찍은 사진 3~4장 ( 얼굴 노출 가능 ) \n- 제품 사용하는 짧은 영상 1~2개"
    },
    {
        label: "작업 시간이 얼마나 소요 되시나요?",
        value: "schedule",
        required: true,
        noImage: true,
        placeholder:
            "평균적으로 사진/영상 촬영 작업 1~2일, 편집 기간 2일, 담당자님과의 피드백 기간 2일을 반영하여, \n완성까지 총 6일 소요 예상합니다!"
    },
    {
        label: "별도의 안내사항을 작성해 주세요.",
        value: "etc",
        noImage: true,
        placeholder:
            "추가 요청 사항 및 가격에 대한 문의는 쪽지로 상담 먼저 부탁드립니다. \n요청사항에 가이드라인 및 원하시는 컨셉을 구체적으로 적어주세요. \n그 외에 협의 사항도 전달 주시면 협의 가능합니다!"
    }
];

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { TableContainer, Table, TableHead, TableRow, TableBody } from "@material-ui/core";
import CustomTableCell from "components/common/CustomTableCell";
import { callDirectApi } from "lib/common";
import {
    getTemplateList as getTemplateListAPI,
    removeTemplates as removeTemplatesAPI
} from "lib/api/template";
import TemplateDetailModalContainer from "containers/template/TemplateDetailModalContainer";
import TemplateSearch from "components/template/TemplateSearch";

const tableHeaderList = [
    { label: "ID", align: "center" },
    { label: "템플릿명", align: "center" },
    { label: "작성일", align: "center" }
];

const TemplateListContainer = ({ location, history }) => {
    const { title } = queryString.parse(location.search);

    const [templateList, setTemplateList] = useState([]);
    const [checkedIdList, handleCheckedIdList] = useState([]);
    const [templateModal, handleTemplateModal] = useState({ status: false, templateObj: "" });

    const getTemplateList = async () => {
        try {
            const result = await callDirectApi(getTemplateListAPI, title, true);

            if (result.status === 200) {
                setTemplateList(result.data);
            }
        } catch (error) {
            console.log("Get template list error in list page.");
            console.log(error);
            window.alert("리스트를 가져오는 데 실패하였습니다.");
        }
    };

    const onCloseModal = () => {
        handleTemplateModal({ status: false, contents: "" });
        getTemplateList(title);
    };

    const selectTemplate = templateId => {
        if (checkedIdList.indexOf(templateId) < 0) {
            handleCheckedIdList([...checkedIdList, templateId]);
        } else {
            handleCheckedIdList(checkedIdList.filter(checkedId => checkedId !== templateId));
        }
    };

    const removeTemplates = async () => {
        if (checkedIdList.length === 0) {
            return;
        }
        try {
            await callDirectApi(removeTemplatesAPI, { idList: checkedIdList.join(",") }, true);
            history.go(0);
        } catch (error) {
            console.log("removeTemplates error", error);
            window.alert("삭제에 실패하였습니다.");
        }
    };

    useEffect(() => {
        getTemplateList();
    }, [title]);

    return (
        <>
            {templateModal.status && (
                <TemplateDetailModalContainer
                    modalStatus={templateModal.status}
                    onClose={onCloseModal}
                    templateId={templateModal.templateObj._id}
                    templateObj={templateModal.templateObj}
                />
            )}
            <TemplateSearch />
            <div>
                <Button variant="contained" color="secondary" onClick={removeTemplates}>
                    삭제
                </Button>
            </div>
            <TableContainer>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>
                                <Checkbox color="primary" />
                            </CustomTableCell>
                            {tableHeaderList.map((headerItem, index) => (
                                <CustomTableCell
                                    align={headerItem.align}
                                    key={index}
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    {headerItem.label}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {templateList.length > 0 &&
                            templateList.map((template, index) => (
                                <TableRow key={index} hover>
                                    <CustomTableCell width={20}>
                                        <Checkbox
                                            color="primary"
                                            onChange={() => selectTemplate(template._id)}
                                        />
                                    </CustomTableCell>
                                    <CustomTableCell
                                        align="center"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            handleTemplateModal({
                                                status: true,
                                                templateObj: template
                                            })
                                        }
                                        width={20}
                                    >
                                        {template._id}
                                    </CustomTableCell>
                                    <CustomTableCell
                                        align="left"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            handleTemplateModal({
                                                status: true,
                                                templateObj: template
                                            })
                                        }
                                    >
                                        {template.title}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" width={200}>
                                        {moment(template.createdAt).format("YYYY-MM-DD HH:mm")}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default withRouter(TemplateListContainer);

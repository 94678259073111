import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import auth, { authSaga } from "./modules/auth";
import wallet, { walletSaga } from "./modules/wallet";
import loading from "./modules/loading";
// import brand from "./modules/brand";
import common, { commonSaga } from "./modules/common";
import project, { projectSaga } from "./modules/project";
import user, { userSaga } from "./modules/user";
import scoreRecord, { scoreRecordSaga } from "./modules/scoreRecord";
import post, { postSaga } from "./modules/post";
import suggestion, { suggestionSaga } from "./modules/suggestion";
import pointGift, { pointGiftSaga } from "./modules/pointGift";
import payment, { paymentSaga } from "./modules/payment";
import banner, { bannerSaga } from "./modules/banner";
import comment, { commentSaga } from "./modules/comment";
import pointRequest, { pointRequestSaga } from "./modules/pointRequest";
import projectRequest, { projectRequestSaga } from "./modules/projectRequest";
import talent, { talentSaga } from "./modules/talent";
import order, { orderSaga } from "./modules/order";
import messenger, { messengerSaga } from "./modules/messenger";
import subs, { subsSaga } from "./modules/subscription";
import statistic, { statistcSaga } from "./modules/statistic";
import coupon, { couponSaga } from "./modules/coupon";
import feedback, { feedbackSaga } from "./modules/feedback";
import reviewerStatus, { reviewerStatusSaga } from "./modules/reviewerStatus";

const rootReducer = combineReducers({
    common,
    auth,
    loading,
    wallet,
    // brand,
    project,
    user,
    scoreRecord,
    suggestion,
    pointGift,
    payment,
    banner,
    post,
    comment,
    pointRequest,
    projectRequest,
    talent,
    order,
    messenger,
    subs,
    statistic,
    coupon,
    feedback,
    reviewerStatus
});

export function* rootSaga() {
    yield all([
        commonSaga(),
        authSaga(),
        walletSaga(),
        projectSaga(),
        userSaga(),
        scoreRecordSaga(),
        suggestionSaga(),
        pointGiftSaga(),
        paymentSaga(),
        bannerSaga(),
        postSaga(),
        commentSaga(),
        pointRequestSaga(),
        projectRequestSaga(),
        talentSaga(),
        orderSaga(),
        messengerSaga(),
        subsSaga(),
        statistcSaga(),
        couponSaga(),
        feedbackSaga(),
        reviewerStatusSaga()
    ]);
}

export default rootReducer;

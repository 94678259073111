import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Modal, Button, Select, MenuItem, Checkbox } from "@material-ui/core";
import { TreeView, TreeItem } from "@material-ui/lab";
import { ExpandMore, ChevronRight } from "@material-ui/icons";
import { Editor, Tag } from "reviewshare-common-component";
import { deleteTalent } from "stores/modules/talent";
import { DIVIDED_DESCRIPTION_ITEMS } from "scheme/talent";
import { numberWithCommas, returnTagColor, addHyphenToNumber } from "lib/common";
import { convertTalentStatus } from "lib/utils/talent";
import { convertTalentCategory } from "lib/utils/category";
import { useTalentState, useTalentAction } from "lib/customHooks/useTalent";
import AddTalentImage from "components/talent/AddTalentImage";

import "./TalentModal.scss";

const initialInfoState = {
    status: "",
    category: "",
    title: "",
    mainPhoto: null,
    subPhoto: [null, null, null, null, null, null],
    description: "",
    detailDescType: "integration",
    dividedDetailDescription: {},
    detailDescription: "",
    preventPopular: false,
    pick: false
};
const TalentModal = () => {
    const dispatch = useDispatch();

    const categoryList = useSelector(({ common }) => common.category.talent);
    const { resetTalentDetail, updateTalentDetail, setPickTalent } = useTalentAction();
    const data = useTalentState("detail");

    const [info, setInfo] = useState(initialInfoState);

    const onClose = () => {
        resetTalentDetail();
    };

    const onValidateValues = () => {
        const requiredItems = [
            "title",
            "mainPhoto",
            "description",
            info.detailDescType === "division" ? "dividedDetailDescription" : "detailDescription"
        ];

        const emptyItem = requiredItems.find(item => !info[item]);

        try {
            if (emptyItem) {
                switch (emptyItem) {
                    case "title":
                        throw new Error("서비스명을 입력해주세요.");
                    case "mainPhoto":
                        throw new Error("대표 이미지를 등록해주세요.");
                    case "price":
                        throw new Error("거래 가격을 입력해주세요.");
                    case "description":
                        throw new Error("간단 설명을 입력해주세요.");
                    case "detailDescription":
                        throw new Error("상세 설명을 입력해주세요.");
                    default:
                        break;
                }
            }

            return true;
        } catch (e) {
            window.alert(e.message);
            return false;
        }
    };

    const onReturnFormData = () => {
        const values = { ...info };
        const formData = new FormData();
        formData.append("status", values.status);
        formData.append("title", values.title);
        formData.append("categoryId", values.categoryId);
        formData.append("description", values.description);
        formData.append("detailDescType", values.detailDescType);
        formData.append("preventPopular", values.preventPopular);

        if (values.detailDescType === "division") {
            formData.append(
                "dividedDetailDescription",
                JSON.stringify(values.dividedDetailDescription)
            );
        } else if (values.detailDescType === "integration") {
            formData.append("detailDescription", values.detailDescription);
        }

        if (values.mainPhoto) {
            if (values.mainPhoto.file) {
                formData.append("mainPhoto", values.mainPhoto.file);
            } else if (values.mainPhoto._id) {
                formData.append("mainPhoto", values.mainPhoto.id);
            }
        }
        values.subPhoto.forEach(item => {
            if (item) {
                if (item.file) {
                    formData.append("subPhoto", item.file);
                    formData.append("orderOfSubPhoto", item.file.name);
                } else if (item._id) {
                    formData.append("orderOfSubPhoto", item.id);
                }
            }
        });

        return formData;
    };

    const onModify = () => {
        const isValidate = onValidateValues();
        if (!isValidate) return;

        const formData = onReturnFormData();
        updateTalentDetail({ talentId: data._id, obj: formData });
        if (data.pick && data.pick.active !== info.pick) {
            setPickTalent({
                idList: [data._id],
                process: info.pick ? "active" : "inactive"
            });
        }
    };

    const onRemove = () => {
        dispatch(deleteTalent(data._id));
    };

    const onChangeInputValue = e => {
        const { name, value } = e.target;
        setInfo(prev => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        if (data._id) {
            const newInfoItems = {};

            Object.keys(initialInfoState).forEach(key => {
                newInfoItems[key] = data[key] || initialInfoState[key];
            });

            setInfo({
                ...newInfoItems,
                mainPhoto: data.mainPhoto || null,
                subPhoto: initialInfoState.subPhoto.map((item, index) => {
                    return data.subPhoto[index] || null;
                }),
                pick: data.pick?.active,
                categoryId: data.category?._id,
                category: undefined
            });
        }
    }, [data]);

    return data._id ? (
        <Modal open onClose={onClose}>
            <div id="talent-detail-modal">
                <h2 className="modal-title">
                    서비스 ID {data._id}{" "}
                    <Tag className="size-l" color={returnTagColor(data.status)}>
                        {convertTalentStatus(data.status)}
                    </Tag>
                </h2>
                <div className="modal-content">
                    <fieldset>
                        <div className="field-box">
                            <div className="field">
                                <p className="field-title">조회수</p>
                                <div className="field-content">{numberWithCommas(data.views)}</div>
                            </div>
                            <div className="field">
                                <p className="field-title">리뷰어 ID</p>
                                <div className="field-content">{data.owner && data.owner._id}</div>
                            </div>
                            <div className="field">
                                <p className="field-title">닉네임</p>
                                <div className="field-content">
                                    {data.owner && data.owner.nickname}
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">휴대폰 번호</p>
                                <div className="field-content">
                                    {addHyphenToNumber(data.owner && data.owner.phoneNumber)}
                                </div>
                            </div>
                        </div>
                        <div className="field-box">
                            <div className="field row">
                                <p className="field-title">가격</p>
                                <div className="field-content">
                                    {numberWithCommas(data.price && data.price.real)}P
                                </div>
                            </div>
                            <div className="field row">
                                <p className="field-title">대표 서비스</p>
                                <div className="field-content">
                                    <Checkbox color="primary" checked={data.isMajor} disabled />
                                </div>
                            </div>
                            <div className="field row">
                                <p className="field-title">리뷰쉐어 픽</p>
                                <div className="field-content">
                                    <Checkbox
                                        color="primary"
                                        checked={info.pick}
                                        onClick={() =>
                                            setInfo(prev => ({
                                                ...prev,
                                                pick: !prev.pick
                                            }))
                                        }
                                    />
                                </div>
                            </div>
                            <div className="field row">
                                <p className="field-title">인기 서비스 제외</p>
                                <div className="field-content">
                                    <Checkbox
                                        color="primary"
                                        checked={info.preventPopular}
                                        onClick={() =>
                                            setInfo(prev => ({
                                                ...prev,
                                                preventPopular: !prev.preventPopular
                                            }))
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="field-items">
                                <div className="field">
                                    <p className="field-title">상태</p>
                                    <div className="field-content">
                                        <Select
                                            name="status"
                                            value={info.status}
                                            disabled={info.status === "ready"}
                                            onChange={onChangeInputValue}
                                        >
                                            <MenuItem value="running">거래 가능</MenuItem>
                                            <MenuItem value="pause">거래 중지</MenuItem>
                                            <MenuItem value="ready" disabled>
                                                임시저장
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="field-items">
                                <div className="field">
                                    <p className="field-title">카테고리</p>
                                    <div className="field-content">
                                        <Select
                                            value={info.categoryId}
                                            name="categoryId"
                                            onChange={onChangeInputValue}
                                        >
                                            {categoryList.map((item, index) => (
                                                <MenuItem value={item?._id} key={index}>
                                                    {convertTalentCategory(item?.name)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <AddTalentImage inputItems={info} setInputItems={setInfo} />
                            <div className="field">
                                <p className="field-title">서비스명</p>
                                <div className="field-content">
                                    <input
                                        type="text"
                                        className="size-l"
                                        name="title"
                                        value={info.title}
                                        onChange={onChangeInputValue}
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">간단 설명</p>
                                <div className="field-content">
                                    <input
                                        type="text"
                                        className="size-l"
                                        name="description"
                                        value={info.description}
                                        onChange={onChangeInputValue}
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">상세 설명</p>
                                {info.detailDescType === "division" ? (
                                    <div className="field-content">
                                        {DIVIDED_DESCRIPTION_ITEMS.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <p className="field-sub-title">{item.label}</p>
                                                <Editor
                                                    value={
                                                        info.dividedDetailDescription?.[
                                                            item.value
                                                        ] || ""
                                                    }
                                                    placeholder={item.placeholder}
                                                    noImage={item.noImage}
                                                    onChange={val =>
                                                        setInfo(prev => ({
                                                            ...prev,
                                                            dividedDetailDescription: {
                                                                ...prev.dividedDetailDescription,
                                                                [item.value]: val
                                                            }
                                                        }))
                                                    }
                                                />
                                            </React.Fragment>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="field-content">
                                        <Editor
                                            value={info.detailDescription}
                                            onChange={val =>
                                                setInfo(prev => ({
                                                    ...prev,
                                                    detailDescription: val
                                                }))
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="field">
                                <p className="field-title">생성 날짜</p>
                                <div className="field-content">
                                    {moment(data.createdAt).format("YYYY-MM-DD HH:mm")}
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">노출 시작 날짜</p>
                                <div className="field-content">
                                    {moment(data.startedAt).format("YYYY-MM-DD HH:mm")}
                                </div>
                            </div>
                            {data.talentHistory && data.talentHistory.length > 0 && (
                                <div className="field">
                                    <p className="field-title">히스토리</p>
                                    <div className="field-content">
                                        <TreeView
                                            defaultCollapseIcon={<ExpandMore />}
                                            defaultExpandIcon={<ChevronRight />}
                                        >
                                            {data.talentHistory.map((history, index) => (
                                                <TreeItem
                                                    key={index}
                                                    nodeId={`title${index}`}
                                                    label={`날짜:${
                                                        history.revisionDate
                                                            ? moment(history.revisionDate).format(
                                                                  "YYYY-MM-DD HH:mm"
                                                              )
                                                            : ""
                                                    }`}
                                                >
                                                    {Object.keys(history).map((value, i) => (
                                                        <TreeItem
                                                            key={i}
                                                            nodeId={`item${i}`}
                                                            label={`${value}:${history[value]}`}
                                                        />
                                                    ))}
                                                </TreeItem>
                                            ))}
                                        </TreeView>
                                    </div>
                                </div>
                            )}
                        </div>
                    </fieldset>
                </div>
                <div className="modal-footer">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onModify}
                        disabled={data.status === "ready" || data.isDeleted}
                    >
                        수정
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onRemove}
                        disabled={data.status === "ready" || data.isDeleted}
                    >
                        삭제
                    </Button>
                </div>
            </div>
        </Modal>
    ) : null;
};

export default TalentModal;

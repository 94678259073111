import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 9001;

    .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const Loading = () => {
    const { status } = useSelector(({ loading }) => loading);
    useEffect(() => {
        if (status) {
            document.querySelector("body").style.overflowY = "hidden";
        } else {
            document.querySelector("body").style.overflowY = "auto";
        }
    }, [status]);
    return (
        <>
            {status && (
                <Wrapper id="loading">
                    <CircularProgress />
                </Wrapper>
            )}
        </>
    );
};
export default Loading;

import React from "react";
import cx from "classnames";
import { useForm } from "react-hook-form";
import SelectBiz from "components/common/SelectBiz";

import "./SelectUser.scss";

const SelectUser = () => {
    const { register, watch } = useForm({
        defaultValues: {
            type: "user"
        }
    });
    const type = watch("type");

    return (
        <div id="promotion-select-user">
            <div className="field select-type-list">
                <p className="field-title">생성 방식</p>
                <div className="field-content">
                    <label>
                        <input type="radio" name="type" value="user" ref={register} />
                        유저 지정
                    </label>
                    <label>
                        <input type="radio" name="type" value="group" ref={register} />
                        그룹 지정
                    </label>
                </div>
            </div>
            <div className={cx("select-type group", { active: type === "group" })}>
                {/* select */}
            </div>
            <div className={cx("select-type user", { active: type === "user" })}>
                <SelectBiz />
            </div>
        </div>
    );
};

export default SelectUser;

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import queryString from "query-string";

import "./TemplateSearch.scss";

const TemplateSearch = ({ history, location }) => {
    const query = queryString.parse(location.search);

    const [searchOptions, setSearchOptions] = useState({
        title: query.title || ""
    });

    const onSearch = () => {
        const newQuery = queryString.stringify({ ...query, ...searchOptions });
        history.replace({
            pathname: location.pathname,
            search: newQuery
        });
    };

    return (
        <div className="template-search-area">
            <div className="search-items">
                <div className="search-item">
                    <h3>템플릿명</h3>
                    <input
                        type="text"
                        value={searchOptions.title}
                        placeholder="템플릿명을 입력해주세요."
                        name="templateTitle"
                        onChange={e => {
                            const { value } = e.target;
                            setSearchOptions(prev => ({ ...prev, title: value }));
                        }}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onSearch();
                            }
                        }}
                    />
                </div>
            </div>
            <div className="btn-area">
                <Button
                    variant="contained"
                    color="primary"
                    className="submit-btn"
                    type="button"
                    onClick={onSearch}
                >
                    검색
                </Button>
            </div>
        </div>
    );
};

export default withRouter(TemplateSearch);

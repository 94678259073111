import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { USER_FILTER_INPUT_ITEMS, CODE_FILTER_INPUT_ITEMS } from "scheme/coupon";
import { getAllCouponList, getAllCouponCodeList } from "stores/modules/coupon";
import SearchFilterItems from "components/common/SearchFilterItems";

const FILTER_INPUT_ITEMS = { user: USER_FILTER_INPUT_ITEMS, code: CODE_FILTER_INPUT_ITEMS };
const CouponFilter = () => {
    const { type } = useParams();
    const dispatch = useDispatch();

    const searchList = options => {
        if (type === "user") {
            dispatch(getAllCouponList(options));
        } else if (type === "code") {
            dispatch(getAllCouponCodeList(options));
        }
    };

    return (
        <SearchFilterItems value="coupon" items={FILTER_INPUT_ITEMS[type]} onSearch={searchList} />
    );
};

export default CouponFilter;

import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { BrowserRouter } from "react-router-dom";
import App from "components/App";
import createSagaMiddleware from "redux-saga";
import rootReducer, { rootSaga } from "stores";
import { getUserInfoAsTokenInLocalStorage } from "stores/modules/auth";
import { getStorage } from "lib/storage";

const isDev = process.env.NODE_ENV === "development";
const devTools = isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = devTools || compose;
const sagaMiddleware = createSagaMiddleware();
// const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

const loadUser = () => {
    console.log("exe load user******************************************");
    try {
        const userToken = getStorage("reviewshare-admin-at");
        console.log("user:", userToken);
        if (!userToken) return;
        store.dispatch(getUserInfoAsTokenInLocalStorage());
    } catch (e) {
        console.log("Fail get user info as a token.");
    }
};

sagaMiddleware.run(rootSaga);
loadUser();

const Root = () => (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

export default Root;

import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import HideScrollBackground from "components/common/HideScrollBackground";

import "./SendEmail.scss";

const SendEmail = ({ emailForm, values, validate, onSend, isLoading, setValue }) => {
    const [modalStatus, setModalStatus] = useState(false);

    return (
        <div className="send-marketing-email">
            <form>
                <div className="row-item">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="title-label" htmlFor="template-id">
                        템플릿 ID(필수)
                    </label>
                    <input
                        type="text"
                        id="template-id"
                        name="templateId"
                        onChange={e => setValue("templateId", e.target.value.trim())}
                        ref={emailForm({ required: true })}
                    />
                </div>
                <div className="row-item">
                    <h2>대상(필수)</h2>
                    <label htmlFor="reciever-category-all">
                        <input
                            type="radio"
                            name="recieverCategory"
                            id="reciever-category-all"
                            value="All"
                            ref={emailForm({ required: true })}
                        />
                        전체
                    </label>
                    <label htmlFor="reciever-category-reviewer">
                        <input
                            type="radio"
                            name="recieverCategory"
                            id="reciever-category-reviewer"
                            value="Normal"
                            ref={emailForm}
                        />
                        리뷰어
                    </label>
                    <label htmlFor="reciever-category-biz">
                        <input
                            type="radio"
                            name="recieverCategory"
                            id="reciever-category-biz"
                            value="Seller"
                            ref={emailForm}
                        />
                        비즈니스
                    </label>
                </div>
                <div className="row-item">
                    <h2>수신 동의 여부</h2>
                    <label htmlFor="only-allow-marketing">
                        <input
                            type="checkbox"
                            name="onlyAllowMarketing"
                            id="only-allow-marketing"
                            ref={emailForm}
                        />
                        동의
                    </label>
                </div>
                <div className="row-item">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="title-label" htmlFor="reciever-id-list">
                        아이디 지정
                    </label>
                    <input
                        type="text"
                        id="reciever-id-list"
                        name="recieverIdList"
                        placeholder=",로 구분해주세요. 예) 10295,40728"
                        ref={emailForm}
                    />
                </div>
                <div className="btn-wrapper">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            const validation = await validate();

                            if (validation) {
                                setModalStatus(true);
                            } else {
                                window.alert("필수 값을 입력해주세요.");
                            }
                        }}
                    >
                        보내기
                    </Button>
                </div>
            </form>
            {isLoading && <HideScrollBackground />}
            <Modal open={modalStatus} onClose={() => setModalStatus(false)}>
                <div className="check-sending-email-modal">
                    <h2>메일은 전송하시겠습니까?</h2>
                    <ul>
                        <li>
                            <h3>템플릿 ID</h3>
                            <p>{values?.templateId}</p>
                        </li>
                        {values?.recieverCategory && (
                            <li>
                                <h3>대상</h3>
                                <p>
                                    {values?.recieverCategory === "All"
                                        ? "전체"
                                        : values?.recieverCategory === "Normal"
                                        ? "리뷰어"
                                        : "비즈니스"}
                                </p>
                            </li>
                        )}
                        <li>
                            <h3>수신 동의 여부</h3>
                            <p>{values?.onlyAllowMarketing ? "동의" : "전체(비동의 포함)"}</p>
                        </li>
                        {!!values?.recieverIdList?.length && (
                            <li>
                                <h3>아이디 지정</h3>
                                <p>{values?.recieverIdList}</p>
                            </li>
                        )}
                    </ul>
                    <Button variant="contained" color="primary" onClick={onSend}>
                        보내기
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default SendEmail;

import { withStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.common.white
    },
    "@media (max-width: 1445px)": {
        head: {
            padding: "6px 8px"
        },
        body: {
            padding: "6px 8px"
        }
    }
}))(TableCell);
export default CustomTableCell;

import { createAction, handleActions } from "redux-actions";
import { takeLeading } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as statisticAPI from "lib/api/statistic";

const [
    GET_WEEKLY_ACTIVE_REVIEWER,
    GET_WEEKLY_ACTIVE_REVIEWER_SUCCESS,
    GET_WEEKLY_ACTIVE_REVIEWER_FAILURE
] = createRequestActionTypes("statistic/GET_WEEKLY_ACTIVE_REVIEWER");
const [
    GET_WEEKLY_RETENTION_REVIEWER,
    GET_WEEKLY_RETENTION_REVIEWER_SUCCESS,
    GET_WEEKLY_RETENTION_REVIEWER_FAILURE
] = createRequestActionTypes("statistic/GET_WEEKLY_RETENTION_REVIEWER");
const [
    GET_MONTHLY_LOGIN_FREQUENCY_REVIEWER,
    GET_MONTHLY_LOGIN_FREQUENCY_REVIEWER_SUCCESS,
    GET_MONTHLY_LOGIN_FREQUENCY_REVIEWER_FAILURE
] = createRequestActionTypes("statistic/GET_MONTHLY_LOGIN_FREQUENCY_REVIEWER");
const [
    GET_MONTHLY_TRANSACTION_AND_FEE,
    GET_MONTHLY_TRANSACTION_AND_FEE_SUCCESS,
    GET_MONTHLY_TRANSACTION_AND_FEE_FAILURE
] = createRequestActionTypes("statistic/GET_MONTHLY_TRANSACTION_AND_FEE");

export const getWeeklyActiveReviewerStats = createAction(GET_WEEKLY_ACTIVE_REVIEWER);
export const getWeeklyRetentionReviewerStats = createAction(GET_WEEKLY_RETENTION_REVIEWER);
export const getMonthlyLoginFrequencyReviewerStats = createAction(
    GET_MONTHLY_LOGIN_FREQUENCY_REVIEWER
);
export const getMonthlyTransactionAndFeeStats = createAction(GET_MONTHLY_TRANSACTION_AND_FEE);

const getWeeklyActiveReviewerStatsSaga = createRequestSagaWithAlert(
    GET_WEEKLY_ACTIVE_REVIEWER,
    statisticAPI.getWeeklyActiveReviewer,
    "주간 활동 리뷰어 데이터를 가져오지 못했습니다.",
    true
);
const getWeeklyRetentionReviewerStatsSaga = createRequestSagaWithAlert(
    GET_WEEKLY_RETENTION_REVIEWER,
    statisticAPI.getWeeklyRetentionReviewer,
    "주간 재접속 리뷰어 데이터를 가져오지 못했습니다.",
    true
);
const getMonthlyLoginFrequencyReviewerStatsSaga = createRequestSagaWithAlert(
    GET_MONTHLY_LOGIN_FREQUENCY_REVIEWER,
    statisticAPI.getMonthlyLoginFrequencyReviewer,
    "리뷰어 월 평균 접속 빈도 수 데이터를 가져오지 못했습니다.",
    true
);
const getMonthlyTransactionAndFeeStatsSaga = createRequestSagaWithAlert(
    GET_MONTHLY_TRANSACTION_AND_FEE,
    statisticAPI.getMonthlyTransactionAndFeeStats,
    "월별 플랫폼 거래액 및 수수료를 가져오지 못했습니다.",
    true
);

export function* statistcSaga() {
    yield takeLeading(GET_WEEKLY_ACTIVE_REVIEWER, getWeeklyActiveReviewerStatsSaga);
    yield takeLeading(GET_WEEKLY_RETENTION_REVIEWER, getWeeklyRetentionReviewerStatsSaga);
    yield takeLeading(
        GET_MONTHLY_LOGIN_FREQUENCY_REVIEWER,
        getMonthlyLoginFrequencyReviewerStatsSaga
    );
    yield takeLeading(GET_MONTHLY_TRANSACTION_AND_FEE, getMonthlyTransactionAndFeeStatsSaga);
}

const initialState = {
    weeklyActiveReviewerData: [],
    weeklyRetentionReviewerData: [],
    monthlyLoginFrequencyReviewer: [],
    monthlyTransactionAndFee: []
};

const statistic = handleActions(
    {
        [GET_WEEKLY_ACTIVE_REVIEWER_SUCCESS]: (state, { payload }) => {
            return {
                ...state,
                weeklyActiveReviewerData: payload.sortedCount
            };
        },
        [GET_WEEKLY_RETENTION_REVIEWER_SUCCESS]: (state, { payload }) => {
            return {
                ...state,
                weeklyRetentionReviewerData: payload.sortedCount
            };
        },
        [GET_MONTHLY_LOGIN_FREQUENCY_REVIEWER_SUCCESS]: (state, { payload }) => {
            return {
                ...state,
                monthlyLoginFrequencyReviewer: payload.sortedCount
            };
        },
        [GET_MONTHLY_TRANSACTION_AND_FEE_SUCCESS]: (state, { payload }) => {
            return {
                ...state,
                monthlyTransactionAndFee: payload.list
            };
        }
    },
    initialState
);

export default statistic;

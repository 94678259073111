import React from "react";
import { useDispatch } from "react-redux";
import { WITHDRAWAL_FILTER_INPUT_ITEMS } from "scheme/pointRequest";
import { getWithdrawPointList } from "stores/modules/pointRequest";
import SearchFilterItems from "components/common/SearchFilterItems";

const LIMIT = 100;
const WithdrawPointFilter = () => {
    const dispatch = useDispatch();

    const searchList = options => {
        const newOptions = { ...options, limit: LIMIT };
        dispatch(getWithdrawPointList(newOptions));
    };

    return (
        <SearchFilterItems
            value="withdrawPoint"
            items={WITHDRAWAL_FILTER_INPUT_ITEMS}
            onSearch={searchList}
        />
    );
};

export default WithdrawPointFilter;

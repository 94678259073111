import React from "react";
import { Switch, Route } from "react-router-dom";
import ContactUsListContainer from "containers/contactUs/ContactUsListContainer";
import ContactUsDetailContainer from "containers/contactUs/ContactUsDetailContainer";

const ContactUsPage = () => (
    <Switch>
        <Route exact path="/contact-us">
            <ContactUsListContainer type="request" />
        </Route>
        <Route exact path="/contact-us/:commentId">
            <ContactUsDetailContainer type="request" />
        </Route>
    </Switch>
);

export default ContactUsPage;

import { createAction, handleActions } from "redux-actions";
import { takeLeading } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as subsAPI from "lib/api/subscription";

const [GET_STATISTICS, GET_STATISTICS_SUCCESS, GET_STATISTICS_FAILURE] = createRequestActionTypes(
    "/subs/GET_STATISTICS"
);

export const getStatistics = createAction(GET_STATISTICS);

const getStatisticsSaga = createRequestSagaWithAlert(
    GET_STATISTICS,
    subsAPI.getStatistics,
    "구독 정보를 가져오지 못했습니다.",
    true
);

export function* subsSaga() {
    yield takeLeading(GET_STATISTICS, getStatisticsSaga);
}

const initialState = {
    statistics: {
        baseMonth: null,
        main: {
            totalNumOfSubs: 0,
            newNumOfSubsAtLastMonth: 0,
            numOfUnsubsAtLastMonth: 0,
            subsBounceRate: 0,
            totalMonthlySubsFee: 0,
            averageSubsFee: 0,
            customerValue: 0
        },
        graphData: []
    }
};

const subs = handleActions(
    {
        [GET_STATISTICS_SUCCESS]: (state, { payload }) => {
            const graphData = Object.keys(payload.graphData).reduce(
                (graphDataObj, graphDataItem) => {
                    // eslint-disable-next-line no-param-reassign
                    graphDataObj[graphDataItem] = payload.graphData[graphDataItem].reverse();

                    return graphDataObj;
                },
                {}
            );

            return {
                ...state,
                statistics: {
                    baseMonth: payload.baseMonth,
                    main: {
                        totalNumOfSubs: payload.totalNumOfSubs,
                        newNumOfSubsAtLastMonth: payload.newNumOfSubsAtLastMonth,
                        numOfUnsubsAtLastMonth: payload.numOfUnsubsAtLastMonth,
                        subsBounceRate: payload.subsBounceRate,
                        totalMonthlySubsFee: payload.totalMonthlySubsFee,
                        averageSubsFee: payload.averageSubsFee,
                        customerValue: payload.customerValue
                    },
                    graphData
                }
            };
        }
    },
    initialState
);

export default subs;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import cx from "classnames";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Checkbox,
    Box
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CustomTableCell from "components/common/CustomTableCell";
import moment from "moment";
import { IconFont } from "reviewshare-common-component";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { TABLE_ITEMS } from "scheme/promotion";
import PromotionActions from "components/promotion/PromotionActions";

import "./PromotionList.scss";

const limit = 40;
const list = [
    {
        _id: 1,
        desc: "12개월 30% 할인",
        targetPlan: "custom",
        discountRate: 10,
        targetBizList: "41409,6545"
    },
    {
        _id: 2,
        desc: "첫 이용 할인",
        targetPlan: "growth",
        discountRate: 10,
        targetBizList: "모두"
    }
];
const counts = 0;
const PromotionList = () => {
    const history = useHistory();
    const { query, search } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;
    const { register, getValues, watch } = useForm();
    const idList = watch("idList");

    const [selectedItems, setSelectedItems] = useState([]);

    const onChangePagination = (e, num) => {
        const newQuery = createSearchQuery({ ...query, skip: (num - 1) * limit });
        history.replace({ path: "/promotion/subscription", search: newQuery });
    };

    const checkValues = () => {
        const values = getValues();
        console.log(values);
    };

    useEffect(() => {
        setSelectedItems([]);
    }, [search]);

    return (
        <div id="promotion-list">
            <PromotionActions idList={idList} />
            <TableContainer>
                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>
                                <Checkbox
                                    color="primary"
                                    checked={false}
                                    // onClick={() => setSelectedItems([])}
                                    onClick={checkValues}
                                />
                            </CustomTableCell>
                            {TABLE_ITEMS.map((headerItem, index) => (
                                <CustomTableCell align={headerItem.align} key={index}>
                                    {headerItem.label}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.length > 0 &&
                            list.map((coupon, index) => {
                                const isSelected = selectedItems.indexOf(coupon._id) > -1;

                                return (
                                    <TableRow
                                        key={index}
                                        hover
                                        className={cx("coupon-list-row", {
                                            "deleted-item": coupon.isDeleted
                                        })}
                                        selected={isSelected}
                                    >
                                        <CustomTableCell>
                                            <Checkbox
                                                color="primary"
                                                inputProps={{ name: "idList" }}
                                                value={coupon._id}
                                                inputRef={register}
                                            />
                                        </CustomTableCell>

                                        {TABLE_ITEMS.map(item => {
                                            if (item.value === "period") {
                                                return (
                                                    <CustomTableCell align="center">
                                                        {moment(coupon.startAt).format(
                                                            "YYYY.MM.DD"
                                                        )}
                                                        ~{moment(coupon.endAt).format("YYYY.MM.DD")}
                                                    </CustomTableCell>
                                                );
                                            }

                                            return (
                                                <CustomTableCell
                                                    className={cx({
                                                        "open-detail": item.openDetail
                                                    })}
                                                    align={item.align}
                                                    key={item.value}
                                                >
                                                    {item.openDetail && (
                                                        <IconFont icon="icon_project" />
                                                    )}
                                                    {item.convertFn
                                                        ? item.convertFn(coupon[item.value])
                                                        : coupon[item.value]}
                                                    {item.unit}
                                                </CustomTableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center" style={{ margin: "20px 0" }}>
                <Pagination
                    count={Math.ceil(counts / limit)}
                    color="primary"
                    onChange={onChangePagination}
                />
            </Box>
        </div>
    );
};

export default PromotionList;

import { createAction, handleActions } from "redux-actions";
import { takeLatest, takeLeading } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaBase } from "lib/createRequestSaga";
import * as pointRequestAPI from "lib/api/pointRequest";

const [
    GET_POINT_REQUEST_LIST,
    GET_POINT_REQUEST_LIST_SUCCESS,
    // eslint-disable-next-line
    GET_POINT_REQUEST_LIST_FAILURE
] = createRequestActionTypes("/pointRequest/GET_POINT_REQUEST_LIST");
const [
    GET_WITHDRAW_POINT_LIST,
    GET_WITHDRAW_POINT_LIST_SUCCESS,
    // eslint-disable-next-line
    GET_WITHDRAW_POINT_LIST_FAILURE
] = createRequestActionTypes("/pointRequest/GET_WITHDRAW_POINT_LIST");
const [
    CONFIRM_WITHDRAW_POINT,
    CONFIRM_WITHDRAW_POINT_SUCCESS,
    // eslint-disable-next-line
    CONFIRM_WITHDRAW_POINT_FAILURE
] = createRequestActionTypes("/pointRequest/CONFIRM_WITHDRAW_POINT");
const [
    DENY_WITHDRAW_POINT,
    DENY_WITHDRAW_POINT_SUCCESS,
    // eslint-disable-next-line
    DENY_WITHDRAW_POINT_FAILURE
] = createRequestActionTypes("/pointRequest/DENY_WITHDRAW_POINT");

export const getPointRequestList = createAction(
    GET_POINT_REQUEST_LIST,
    ({ reviewerId, status = "All", projectId, skip = 0 }) => ({
        reviewerId,
        status,
        projectId,
        skip
    })
);
export const getWithdrawPointList = createAction(GET_WITHDRAW_POINT_LIST, options => options);
export const confirmWithdrawPoint = createAction(CONFIRM_WITHDRAW_POINT, options => options);
export const denyWithdrawPoint = createAction(DENY_WITHDRAW_POINT, options => options);

const getPointRequestListSaga = createRequestSagaBase({
    type: GET_POINT_REQUEST_LIST,
    api: pointRequestAPI.getPointRequestList,
    alertErrorMsg: "포인트 내역을 가져오는 데 실패하였습니다.",
    loading: true
});

const getWithdrawPointListSaga = createRequestSagaBase({
    type: GET_WITHDRAW_POINT_LIST,
    api: pointRequestAPI.getWithdrawPointList
});
const confirmWithdrawPointSaga = createRequestSagaBase({
    type: CONFIRM_WITHDRAW_POINT,
    api: pointRequestAPI.confirmWithdrawPoint,
    alertErrorMsg: "승인 실패했습니다."
});
const denyWithdrawPointSaga = createRequestSagaBase({
    type: DENY_WITHDRAW_POINT,
    api: pointRequestAPI.denyWithdrawPoint,
    alertErrorMsg: "거절 실패했습니다."
});

export function* pointRequestSaga() {
    yield takeLatest(GET_POINT_REQUEST_LIST, getPointRequestListSaga);
    yield takeLatest(GET_WITHDRAW_POINT_LIST, getWithdrawPointListSaga);
    yield takeLeading(CONFIRM_WITHDRAW_POINT, confirmWithdrawPointSaga);
    yield takeLeading(DENY_WITHDRAW_POINT, denyWithdrawPointSaga);
}

const initialState = {
    totalWallet: 0,
    totalCount: 0,
    list: [],
    withdrawal: {
        counts: 0,
        list: []
    }
};

const pointRequest = handleActions(
    {
        [GET_POINT_REQUEST_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            totalWallet: payload.totalWallet,
            totalCount: payload.totalCount,
            list: payload.list
        }),
        [GET_WITHDRAW_POINT_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            withdrawal: { counts: payload.total, list: payload.pointRequests }
        }),
        [CONFIRM_WITHDRAW_POINT_SUCCESS]: (state, { prev }) => ({
            ...state,
            withdrawal: {
                ...state.withdrawal,
                list: state.withdrawal.list.map(item => {
                    if (prev._id.indexOf(item._id) > -1) {
                        return { ...item, status: "Confirmed" };
                    }
                    return item;
                })
            }
        }),
        [DENY_WITHDRAW_POINT_SUCCESS]: (state, { prev, payload: idList = [] }) => ({
            ...state,
            withdrawal: {
                ...state.withdrawal,
                list: state.withdrawal.list.map(item => {
                    if (idList.indexOf(item._id) > -1) {
                        return { ...item, status: "Reject", reasonForDeny: prev.reasonForDeny };
                    }
                    return item;
                })
            }
        })
    },
    initialState
);

export default pointRequest;

import axios from "axios";

const client = axios.create();

const TIME_OUT_VALUE = 1000 * 60 * 10;

client.defaults.baseURL = process.env.REACT_APP_API_URL;
client.defaults.timeout = TIME_OUT_VALUE;

client.interceptors.request.use(config => {
    if (localStorage["reviewshare-admin-at"]) {
        config.headers.Authorization = `Bearer ${localStorage["reviewshare-admin-at"]}`;
    }
    return config;
});

export default client;

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Color } from "theme";
import { convertPointRequestStatus } from "lib/utils/pointRequest";
import { POINT_REQUEST_STATUS_AT_SEARCH } from "scheme/pointRequest";

import "./ReviewerPointRequestCtrl.scss";

const ReviewerPointRequestCtrl = ({
    onSearch,
    selectedStatus,
    handleSelectedStatus,
    onSetPointRequest,
    pointRequestForm
}) => {
    const [searchOptions, setSearchOptions] = useState({
        status: "All",
        projectId: ""
    });

    return (
        <div className="reviewer-point-request-ctrl">
            <div className="search-area">
                <div className="field row">
                    <p className="field-title">분류</p>
                    <div className="field-content">
                        <Select
                            value={searchOptions.status}
                            onChange={e =>
                                setSearchOptions(prev => ({ ...prev, status: e.target.value }))
                            }
                        >
                            {POINT_REQUEST_STATUS_AT_SEARCH.map((item, index) => (
                                <MenuItem value={item.value} key={index}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="field row">
                    <p className="field-title">프로젝트 ID</p>
                    <div className="field-content">
                        <input
                            type="text"
                            name="projectId"
                            placeholder="옵션"
                            value={searchOptions.projectId}
                            onChange={e => {
                                const { value } = e.target;
                                setSearchOptions(prev => ({ ...prev, projectId: value }));
                            }}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    onSearch(searchOptions);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="btn-area">
                    <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        className="search-btn"
                        onClick={() => onSearch(searchOptions)}
                    >
                        검색
                    </Button>
                </div>
            </div>
            <form className="set-points-area">
                <div className="field row">
                    <p className="field-title">포인트</p>
                    <div className="field-content">
                        <div className="type-input-wrapper">
                            <input
                                type="number"
                                name="points"
                                ref={pointRequestForm}
                                placeholder="필수"
                            />
                            <Select
                                value={selectedStatus}
                                onChange={e => handleSelectedStatus(e.target.value)}
                            >
                                {["Reward", "Retrieve", "Addition"].map((item, index) => (
                                    <MenuItem value={item} key={index}>
                                        {convertPointRequestStatus(item)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <p className="error-message">
                            포인트 회수의 경우 음수 값으로 입력해주세요.
                        </p>
                    </div>
                </div>
                <div className="field row">
                    <p className="field-title">노출되는 메시지</p>
                    <div className="field-content">
                        <input
                            type="text"
                            name="publicMessage"
                            ref={pointRequestForm}
                            placeholder="옵션"
                        />
                    </div>
                </div>
                <div className="field row">
                    <p className="field-title">프로젝트 ID</p>
                    <div className="field-content">
                        <input
                            type="number"
                            name="projectId"
                            placeholder="옵션"
                            ref={pointRequestForm}
                        />
                    </div>
                </div>
                <div className="field row">
                    <p className="field-title">제안 ID</p>
                    <div className="field-content">
                        <input
                            type="number"
                            name="suggestionId"
                            placeholder="옵션"
                            ref={pointRequestForm}
                        />
                    </div>
                </div>
                <div className="btn-area">
                    <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        className="set-points-btn"
                        style={{ backgroundColor: Color.greenDark }}
                        onClick={() => onSetPointRequest()}
                    >
                        추가
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ReviewerPointRequestCtrl;

import React from "react";
import { Switch, Route } from "react-router-dom";
import ManageNav from "components/common/ManageNav";
import NoticeListContainer from "containers/notice/NoticeListContainer";
import NoticeWriteContainer from "containers/notice/NoticeWriteContainer";
import NoticeSearch from "components/notice/NoticeSearch";

const NoticePage = () => (
    <>
        <ManageNav
            navList={[
                { url: "/notice/list", name: "공지사항" },
                { url: "/notice/write", name: "공지사항 쓰기" }
            ]}
        />
        <Switch>
            <Route exact path="/notice/list">
                <NoticeSearch />
                <NoticeListContainer />
            </Route>
            <Route exact path="/notice/write">
                <NoticeWriteContainer />
            </Route>
        </Switch>
    </>
);

export default NoticePage;

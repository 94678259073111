import React from "react";
import SearchFilterItems from "components/common/SearchFilterItems";
import PromotionList from "components/promotion/PromotionList";
import { FILTER_INPUT_ITEMS } from "scheme/promotion";

const SubsPromotionPage = () => (
    <>
        <SearchFilterItems value="promotion" items={FILTER_INPUT_ITEMS} />
        <PromotionList />
    </>
);

export default SubsPromotionPage;

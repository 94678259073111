import React, { useEffect } from "react";
import cx from "classnames";
import { useForm } from "react-hook-form";
import { IconFont, Tag } from "reviewshare-common-component";
import TableRow from "@material-ui/core/TableRow";
import CommonTable from "components/common/CommonTable";
import CustomTableCell from "components/common/CustomTableCell";
import { useSearchQuery } from "lib/customHooks/useSearchQuery";
import { TABLE_ITEMS } from "scheme/payment";
import { returnTagColor } from "lib/common";

import "./AllPaymentList.scss";

const list = [
    {
        _id: 12345,
        status: "ready",
        bizId: 41409,
        bizName: "김명유",
        category: "card",
        amount: 10000,
        amountReal: 11000,
        usedPoints: 0,
        coupon: true,
        receiptType: "None",
        createdAt: "2023-04-14 01:01:22"
    }
];
const counts = 0;

const AllPaymentList = () => {
    const { search } = useSearchQuery();
    const { register, watch, setValue } = useForm();
    const selectedIdList = watch("idList");
    const selectedAll = watch("selectedAll");

    const handleAllCheckboxStatus = () => {
        if (selectedAll) {
            const idList = list.map(item => String(item._id));
            setValue("idList", idList);
        } else {
            setValue("idList", []);
        }
    };

    useEffect(() => {
        handleAllCheckboxStatus();
    }, [selectedAll]);

    useEffect(() => {
        setValue("idList", []);
    }, [search]);

    return (
        <div id="all-payment-list">
            <CommonTable
                counts={counts}
                headerItems={TABLE_ITEMS}
                checkboxProps={{ ref: register }}
            >
                {list?.length > 0 &&
                    list.map(payment => (
                        <TableRow
                            key={payment._id}
                            hover
                            className={cx("all-payment-list-row", {
                                "deleted-item": payment.isDeleted
                            })}
                        >
                            <CustomTableCell>
                                <input
                                    type="checkbox"
                                    name="idList"
                                    value={payment._id}
                                    ref={register}
                                />
                            </CustomTableCell>
                            {TABLE_ITEMS.map(item => {
                                if (item.value === "status") {
                                    return (
                                        <CustomTableCell align={item.align}>
                                            <Tag
                                                color={returnTagColor(payment.status)}
                                                className="size-l"
                                            >
                                                {item.convertFn(payment[item.value])}
                                            </Tag>
                                        </CustomTableCell>
                                    );
                                }

                                return (
                                    <CustomTableCell
                                        className={cx({ "open-detail": item.openDetail })}
                                        align={item.align}
                                        key={item.value}
                                    >
                                        {item.openDetail && <IconFont icon="icon_project" />}
                                        {item.convertFn
                                            ? item.convertFn(payment[item.value])
                                            : payment[item.value]}
                                    </CustomTableCell>
                                );
                            })}
                        </TableRow>
                    ))}
            </CommonTable>
        </div>
    );
};

export default AllPaymentList;

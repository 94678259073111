import React from "react";
import styled, { keyframes } from "styled-components";
import ModalWrapper from "components/common/ModalWrapper";
import HideScrollBackground from "components/common/HideScrollBackground";
import {
 Color, Font, Breakpoints, Mobile
} from "theme";

const Animation = keyframes`
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
`;
const Modal = styled.div`
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    flex-direction: column;
    background-color: white;
    width: ${props => props.modalWidth || "400px"};
    height: ${props => props.modalHeight || "auto"};
    border-radius: 10px;
    text-align: center;
    animation: ${Animation} 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

    @media ${Breakpoints.mobile} {
        width: ${props =>
            props.modalWidthMobile ? Mobile(props.modalWidthMobile) : "calc(100% - 64px)"};
        border-radius: ${Mobile(10)};
    }
`;

const ContentWrapper = styled.div`
    padding: 30px;

    @media ${Breakpoints.mobile} {
        padding: ${Mobile(50)};
    }
`;

const AlertConfirmBtn = styled.button`
    width: 100%;
    height: 50px;
    min-height: 50px;
    background-color: white;
    border: none;
    border-top: 1px solid ${Color.gray1};
    color: ${Color.mainColor};
    font-size: ${Font.size.m};
    font-weight: ${Font.weight.medium};

    @media ${Breakpoints.mobile} {
        font-size: ${Mobile(26)};
        height: ${Mobile(70)};
        min-height: ${Mobile(70)};
    }
`;
const AlertModal = ({
    modalWidth,
    modalWidthMobile,
    modalHeight,
    className,
    confirmText,
    handleModal,
    children
}) => (
    <ModalWrapper>
        <HideScrollBackground>
            <Modal
                className={className}
                modalWidth={modalWidth}
                modalWidthMobile={modalWidthMobile}
                modalHeight={modalHeight}
            >
                <ContentWrapper>{children}</ContentWrapper>
                <AlertConfirmBtn onClick={handleModal}>{confirmText || "확인"}</AlertConfirmBtn>
            </Modal>
        </HideScrollBackground>
    </ModalWrapper>
);

export default AlertModal;

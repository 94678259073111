import React, { useState } from "react";
import Button from "@material-ui/core/Button";

import "./AddFeedPick.scss";

const AddFeedPick = ({ setPickFeed }) => {
    const [inputValue, setInputValue] = useState("");

    return (
        <div id="add-feed-pick">
            <div className="set-pick-area">
                <input
                    type="number"
                    placeholder="id 입력"
                    value={inputValue}
                    onChange={e => {
                        const { value } = e.target;
                        setInputValue(value);
                    }}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            setPickFeed("add", inputValue);
                        }
                    }}
                />
                <Button
                    className="search-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => setPickFeed("add", inputValue)}
                    disabled={!inputValue}
                >
                    추가
                </Button>
            </div>
        </div>
    );
};

export default AddFeedPick;

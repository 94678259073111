import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Color } from "theme";
import { numberWithCommas } from "lib/common";

import "./ListWrapper.scss";

const ListWrapper = ({
    totalCount,
    handlePage,
    children,
    currentPage,
    pageLimit = 50,
    onRemove,
    onDownload,
    customButtons
}) => (
    <div className="user-manage-list-wrapper">
        <div className="table-menu">
            <p>전체: {numberWithCommas(totalCount)}건</p>
            <div className="button-area">
                {onDownload && (
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ backgroundColor: Color.greenDark }}
                        onClick={onDownload}
                    >
                        다운로드
                    </Button>
                )}
                {onRemove && (
                    <Button variant="contained" color="secondary" onClick={onRemove}>
                        삭제
                    </Button>
                )}
                {customButtons}
            </div>
        </div>
        <div className="table-area">{children}</div>
        <Box
            display="flex"
            justifyContent="center"
            style={{ marginTop: "20px", marginBottom: "20px" }}
        >
            <Pagination
                page={currentPage}
                count={Math.ceil(totalCount / pageLimit)}
                color="primary"
                onChange={handlePage}
            />
        </Box>
    </div>
);

export default ListWrapper;

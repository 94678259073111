import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import cx from "classnames";
import result from "lodash/result";
import TableRow from "@material-ui/core/TableRow";
import CustomTableCell from "components/common/CustomTableCell";
import moment from "moment";
import { IconFont } from "reviewshare-common-component";
import { useSearchQuery } from "lib/customHooks/useSearchQuery";
import { USER_TABLE_ITEMS, CODE_TABLE_ITEMS } from "scheme/coupon";
import { selectCouponInfo } from "stores/modules/coupon";
import CommonTable from "components/common/CommonTable";
import CouponActions from "components/coupon/CouponActions";

import "./CouponList.scss";

const TABLE_ITEMS = { user: USER_TABLE_ITEMS, code: CODE_TABLE_ITEMS };
const CouponList = () => {
    const dispatch = useDispatch();
    const { type } = useParams();
    const { search } = useSearchQuery();
    const { register, watch, setValue } = useForm();
    const selectedIdList = watch("idList");
    const selectedAll = watch("selectedAll");
    const { list, counts } = useSelector(({ coupon }) => coupon[type === "code" ? type : "all"]);

    const openDetailModal = data => {
        dispatch(selectCouponInfo(data));
    };

    const handleAllCheckboxStatus = () => {
        if (selectedAll) {
            const idList = list.map(item => String(item._id));
            setValue("idList", idList);
        } else {
            setValue("idList", []);
        }
    };

    useEffect(() => {
        handleAllCheckboxStatus();
    }, [selectedAll]);

    useEffect(() => {
        setValue("idList", []);
    }, [search]);

    return (
        <div id="coupon-list">
            <CouponActions idList={selectedIdList} counts={counts} />
            <CommonTable
                counts={counts}
                headerItems={TABLE_ITEMS[type]}
                checkboxProps={{ ref: register }}
            >
                {list?.length > 0 &&
                    list.map((coupon, index) => (
                        <TableRow
                            key={index}
                            hover
                            className={cx("coupon-list-row", {
                                "deleted-item": coupon.isDeleted
                            })}
                        >
                            <CustomTableCell>
                                <input
                                    type="checkbox"
                                    name="idList"
                                    value={coupon._id}
                                    ref={register}
                                />
                            </CustomTableCell>

                            {TABLE_ITEMS[type].map(item => {
                                if (item.value === "period") {
                                    return (
                                        <CustomTableCell align={item.align} key={item.value}>
                                            {moment(
                                                coupon.startedAt || coupon.coupon.startedAt
                                            ).format("YYYY.MM.DD")}
                                            ~
                                            {moment(coupon.endAt || coupon.coupon.endAt).format(
                                                "YYYY.MM.DD"
                                            )}
                                        </CustomTableCell>
                                    );
                                }

                                if (item.value === "serviceCollection") {
                                    return (
                                        <CustomTableCell align={item.align} key={item.value}>
                                            {item.convertFn(result(coupon, item.key)) || "프로젝트"}
                                            ({item.serviceId || 12345})
                                        </CustomTableCell>
                                    );
                                }

                                return (
                                    <CustomTableCell
                                        className={cx({
                                            "open-detail": item.openDetail
                                        })}
                                        align={item.align}
                                        key={item.value}
                                        onClick={() => {
                                            if (item.openDetail) {
                                                openDetailModal(coupon);
                                            }
                                        }}
                                    >
                                        {item.openDetail && <IconFont icon="icon_project" />}
                                        {item.convertFn
                                            ? item.convertFn(result(coupon, item.key))
                                            : result(coupon, item.key)}
                                        {item.unit}
                                    </CustomTableCell>
                                );
                            })}
                        </TableRow>
                    ))}
            </CommonTable>
        </div>
    );
};

export default CouponList;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Modal } from "@material-ui/core";
import { selectPointGift } from "stores/modules/pointGift";
import { numberWithCommas } from "lib/common";
import { returnSubtractFee } from "lib/utils/price";

import "./PointGiftModal.scss";

const TalentModal = () => {
    const dispatch = useDispatch();
    const data = useSelector(({ pointGift }) => pointGift.detail);

    const onClose = () => {
        dispatch(selectPointGift({}));
    };

    return data._id ? (
        <Modal open onClose={onClose}>
            <div id="talent-detail-modal">
                <h2 className="modal-title">선물 ID {data._id}</h2>
                <div className="modal-content">
                    <fieldset>
                        <div className="field-box">
                            <div className="field">
                                <p className="field-title">비즈니스</p>
                                <div className="field-content">
                                    {data.owner?.name}({data.owner?._id})
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">브랜드</p>
                                <div className="field-content">
                                    {data.brand?.name}({data.brand?._id})
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">리뷰어</p>
                                <div className="field-content">
                                    {data.targetList
                                        .map(target => `${target.nickname}(${target?._id})`)
                                        .join("\n")}
                                </div>
                            </div>
                        </div>

                        <div className="field-box">
                            <div className="field">
                                <p className="field-title">선물 포인트</p>
                                <div className="field-content">
                                    {numberWithCommas(data.pointsPerTarget)}P
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">리뷰어가 받는 포인트</p>
                                <div className="field-content">
                                    {numberWithCommas(
                                        returnSubtractFee({
                                            value: data.pointsPerTarget,
                                            rate: data.rateOfFee
                                        })
                                    )}
                                    P
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">총 결제 포인트</p>
                                <div className="field-content">
                                    {numberWithCommas(data.totalPoints)}P
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">수수료</p>
                                <div className="field-content">{data.rateOfFee}%</div>
                            </div>
                        </div>
                    </fieldset>
                    <div className="field">
                        <p className="field-title">선물 날짜</p>
                        <div className="field-content">
                            {moment(data.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    ) : null;
};

export default TalentModal;

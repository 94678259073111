import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import cx from "classnames";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Checkbox,
    Box
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { IconFont } from "reviewshare-common-component";
import CustomTableCell from "components/common/CustomTableCell";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { useAllOrder, useOrderAction } from "lib/customHooks/useOrder";
import { convertBoolean } from "lib/common";
import { convertOrderStatus } from "lib/utils/order";
// import OrderButtons from "components/talent/OrderButtons";

import "./OrderList.scss";

const tableHeaderList = [
    { label: "거래 ID", align: "center" },
    { label: "상태", align: "left" },
    { label: "서비스 ID", align: "center" },
    { label: "리뷰어", align: "center" },
    { label: "비즈니스", align: "center" },
    { label: "브랜드", align: "left" },
    { label: "환불 여부", align: "center" }
];
const limit = 40;
const OrderList = () => {
    const history = useHistory();
    const { category } = useParams();
    const { query, search } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;
    const { getOrderDetail } = useOrderAction();

    const { list, counts } = useAllOrder();

    const [selectedItems, setSelectedItems] = useState([]);

    const onChangePagination = (e, num) => {
        const newQuery = createSearchQuery({ ...query, skip: (num - 1) * limit });
        history.replace({ path: `/talents/${category}`, search: newQuery });
    };

    const onHandleCheck = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        const newSelectedItems = [...selectedItems];
        const index = newSelectedItems.indexOf(id);

        if (index > -1) {
            newSelectedItems.splice(index, 1);
        } else {
            newSelectedItems.push(id);
        }

        setSelectedItems(newSelectedItems);
    };

    useEffect(() => {
        setSelectedItems([]);
    }, [search]);

    return (
        <div id="order-list">
            <TableContainer>
                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>
                                <Checkbox
                                    color="primary"
                                    checked={false}
                                    onClick={() => setSelectedItems([])}
                                />
                            </CustomTableCell>
                            {tableHeaderList.map((headerItem, index) => (
                                <CustomTableCell align={headerItem.align} key={index}>
                                    {headerItem.label}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list &&
                            list.length > 0 &&
                            list.map((order, index) => {
                                const isSelected = selectedItems.indexOf(order._id) > -1;

                                return (
                                    <TableRow
                                        key={index}
                                        hover
                                        className={cx("order-list-row", {
                                            "deleted-item": order.isDeleted
                                        })}
                                        selected={isSelected}
                                    >
                                        <CustomTableCell>
                                            <Checkbox
                                                color="primary"
                                                checked={isSelected}
                                                disabled={order.isDeleted}
                                                onClick={e => onHandleCheck(e, order._id)}
                                            />
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {order._id}
                                        </CustomTableCell>
                                        <CustomTableCell
                                            className="open-detail"
                                            onClick={() => getOrderDetail(order)}
                                        >
                                            <IconFont icon="icon_project" />
                                            {convertOrderStatus(order.status)}
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {order.talentId}
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {order.talentOwner?.nickname}({order.talentOwner?._id})
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {order.owner?.name}({order.owner?._id})
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            {order.brand?.name}({order.brand?.id})
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {convertBoolean(order.paymentStatus === "refund")}
                                        </CustomTableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center" style={{ margin: "20px 0" }}>
                <Pagination
                    count={Math.ceil(counts / limit)}
                    color="primary"
                    onChange={onChangePagination}
                />
            </Box>
        </div>
    );
};

export default OrderList;

import React from "react";
import moment from "moment";
import cx from "classnames";
import { numberWithCommas } from "lib/common";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import "./StatusInfo.scss";
import { InfoBox } from "reviewshare-common-component";

const StatusInfo = ({ projectForm, projectDetail, otherDetail, handleChnageBrandModal }) => (
    <div className="project-modal-status-info">
        {projectDetail.rereviewingStatus === "deny" && (
            <InfoBox>
                <p className="bold-font">수정 거절 사유</p>
                {projectDetail.reasonForDeny}
            </InfoBox>
        )}
        <div className="status-main-info">
            <ul className="field-box column">
                <li className="field row">
                    <h3 className="field-title">등록 시간</h3>
                    <div className="field-content">
                        {moment(projectDetail.createdAt).format("YYYY-MM-DD HH:mm")}
                    </div>
                </li>
                {/* <li className="field">
                    <h3 className="field-title">조회수</h3>
                    <div className="field-content">{numberWithCommas(projectDetail.views)}</div>
                </li> */}
            </ul>
            <ul className="field-box column">
                <li className="field row">
                    <h3 className="field-title">비즈니스</h3>
                    <div className="field-content">
                        {projectDetail.owner?.name || otherDetail.owner?.name} (
                        {projectDetail.owner?._id || otherDetail.owner?._id})
                    </div>
                </li>
                <li
                    className={cx("field row", {
                        changed:
                            projectDetail.enterprise !== otherDetail.enterprise ||
                            projectDetail.brandName !== otherDetail.brandName
                    })}
                >
                    <h3 className="field-title">브랜드</h3>
                    <div className="field-content">
                        {projectDetail.brandName}({projectDetail.enterprise})
                    </div>
                    <IconButton
                        aria-label="브랜드 수정 버튼"
                        onClick={() => handleChnageBrandModal(true)}
                    >
                        <EditIcon />
                    </IconButton>
                </li>
                <li className="field row">
                    <h3 className="field-title">플랜</h3>
                    <div className="field-content">
                        {projectDetail.recruitmentForm?.plan || "basic"}
                    </div>
                </li>
            </ul>
            <ul className="field-box column">
                <li className="field row">
                    <h3 className="field-title">신청</h3>
                    <div className="field-content">
                        {numberWithCommas(projectDetail.request_count)}명
                    </div>
                </li>
                <li className="field row">
                    <h3 className="field-title">선정</h3>
                    <div className="field-content">
                        {numberWithCommas(projectDetail.chosenCount)}명
                    </div>
                </li>
                <li className="field row">
                    <h3 className="field-title">모집</h3>
                    <div className="field-content">
                        <input
                            type="number"
                            name="recruits"
                            defaultValue={projectDetail.recruits}
                            ref={projectForm}
                        />
                    </div>
                </li>
            </ul>
        </div>
    </div>
);

export default StatusInfo;

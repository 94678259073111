import React from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import ProjectRequestModal from "components/projectManage/ProjectRequestModal";
import { callDirectApi } from "lib/common";
import {
    updateProjectRequest as updateProjectRequestAPI,
    unsubmitRequest as unsubmitRequestAPI,
    changeSubmitEndAt as changeSubmitEndAtAPI,
    submitUrl as submitUrlAPI
} from "lib/api/projectRequest";

const ProjectRequestModalContainer = ({ modalStatus, requestItem, openAlertModal, onClose }) => {
    // console.log("requestItem:", requestItem);
    const { register: requestItemForm, getValues } = useForm({
        defaultValues: {
            status: requestItem.status === "preChosen" ? "chosen" : requestItem.status
        }
    });

    const unsubmitRequest = async () => {
        try {
            await callDirectApi(unsubmitRequestAPI, requestItem._id, true);
            openAlertModal();
        } catch (error) {
            alert("업데이트 실패:" + error.message);
        }
    };

    const updateProjectRequest = async () => {
        const values = getValues();
        // console.log("getValues:", values);
        try {
            await callDirectApi(
                updateProjectRequestAPI,
                {
                    requestId: requestItem._id,
                    status: values.status
                },
                true
            );
            openAlertModal();
        } catch (error) {
            alert("업데이트 실패:" + error.message);
        }
    };

    const changeSubmitEndAt = async () => {
        const values = getValues();
        try {
            await callDirectApi(
                changeSubmitEndAtAPI,
                {
                    requestId: requestItem._id,
                    submitEndAt: moment(values.submitEndAt)
                        .endOf("day")
                        .toDate()
                },
                true
            );
            openAlertModal();
        } catch (error) {
            alert("업데이트 실패:" + error.message);
        }
    };

    const submitUrl = async () => {
        const values = getValues();
        if (!values.submittedUrl || !values.submittedUrl) return;

        const formData = new FormData();
        formData.append("url", values.submittedUrl);
        formData.append("submittedAt", moment(values.submittedAt).toDate());

        try {
            await callDirectApi(
                submitUrlAPI,
                {
                    requestId: requestItem._id,
                    info: formData
                },
                true
            );
            openAlertModal();
        } catch (error) {
            alert("업데이트 실패:" + error.message);
        }
    };

    return (
        <ProjectRequestModal
            modalStatus={modalStatus}
            requestItem={requestItem}
            onClose={onClose}
            requestItemForm={requestItemForm}
            updateProjectRequest={updateProjectRequest}
            unsubmitRequest={unsubmitRequest}
            changeSubmitEndAt={changeSubmitEndAt}
            submitUrl={submitUrl}
        />
    );
};

export default ProjectRequestModalContainer;

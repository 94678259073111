export const TARGET_LIST = ["starter", "growth", "professional", "enterprise", "custom"];
export const FILTER_INPUT_ITEMS = [
    {
        label: "적용 플랜",
        value: "targetPlan",
        inputType: "select",
        array: TARGET_LIST
    },
    {
        label: "비즈니스 ID",
        value: "targetBizList",
        inputType: "text"
    },
    { label: "시작일", value: "startAt", inputType: "date" },
    { label: "종료일", value: "endAt", inputType: "date" }
];

export const TABLE_ITEMS = [
    {
        label: "프로모션 ID",
        align: "left",
        value: "_id"
    },
    {
        label: "적용 플랜",
        align: "left",
        value: "targetPlan",
        openDetail: true
    },
    {
        label: "프로모션 설명",
        align: "center",
        value: "desc"
    },
    {
        label: "대상",
        align: "center",
        value: "targetBizList"
    },
    { label: "할인율", align: "center", value: "discountRate", unit: "%" },
    { label: "사용 기간", align: "center", value: "period" }
];

import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { useForm } from "react-hook-form";
import { IconFont } from "reviewshare-common-component";
import useDirectApi from "lib/customHooks/useDirectApi";
import { numberWithCommas, callDirectApi } from "lib/common";
import { searchBiz as searchBizAPI } from "lib/api/user";

import "./SelectBiz.scss";

const csvFileToArray = string => {
    if (!string) return [];
    const formattedString = string.replace(new RegExp(`\r`, "g"), "");
    return formattedString.split("\n");
};

const SelectBiz = ({ onChange }) => {
    const { data: searchedUserResult, fetch: searchUser } = useDirectApi({
        api: searchBizAPI
    });
    const [selectedBizList, setSelectedBizList] = useState([]);
    const { register, getValues } = useForm();

    const selectUser = user => {
        if (!user?._id) return;

        setSelectedBizList(prev => [...prev, user]);
    };

    const unselectUser = userId => {
        if (!userId) return;
        const _selectedBizList = [...selectedBizList];
        const index = _selectedBizList.findIndex(biz => biz._id === userId);

        if (index > -1) {
            _selectedBizList.splice(index, 1);
            setSelectedBizList(_selectedBizList);
        }
    };

    const searchByUserId = () => {
        const { id } = getValues();
        if (!id) return;
        searchUser({ bizIdList: [id] });
    };

    const searchByCsvList = async idList => {
        if (!idList?.length) return;

        const { data } = await callDirectApi(searchBizAPI, { bizIdList: idList }, true);
        const newBizList = [...selectedBizList];
        data.bizList.forEach(item => {
            if (!newBizList.find(biz => biz._id === item._id)) {
                newBizList.push(item);
            }
        });
        setSelectedBizList(newBizList);
    };

    const uploadCsv = async e => {
        const file = e.target?.files?.[0];
        e.target.value = "";
        if (!file) return null;
        const fileReader = new FileReader();
        fileReader.onload = event => {
            const text = event.target.result;
            searchByCsvList(csvFileToArray(text));
        };

        fileReader.readAsText(file);
    };

    useEffect(() => {
        if (onChange) {
            const selecteddBizIdList = selectedBizList.map(item => item._id);
            onChange(selecteddBizIdList);
        }
    }, [selectedBizList]);

    return (
        <div id="common-select-biz">
            <div className="select-user-area">
                <div className="attach-user-box">
                    <p className="type-title">csv 첨부</p>
                    <div className="type-content">
                        <input type="file" placeholder="csv" onChange={uploadCsv} />
                    </div>
                </div>
                <div className="search-user-box">
                    <p className="type-title">비즈니스 ID 검색</p>
                    <div className="type-content">
                        <div className="search-input-area">
                            <input
                                type="text"
                                name="id"
                                ref={register}
                                placeholder="비즈니스 ID"
                                onKeyDown={e => {
                                    if (e.keyCode === 13) {
                                        searchByUserId();
                                    }
                                }}
                            />
                            <Button variant="contained" color="primary" onClick={searchByUserId}>
                                검색
                            </Button>
                        </div>
                        <div className="searched-result-area">
                            <ul>
                                {(searchedUserResult?.bizList || []).map(item => {
                                    const isSelected = !!selectedBizList.find(
                                        biz => biz._id === item._id
                                    );
                                    return (
                                        <li
                                            key={item._id}
                                            onClick={() =>
                                                isSelected
                                                    ? unselectUser(item._id)
                                                    : selectUser(item)
                                            }
                                        >
                                            <Checkbox checked={isSelected} />
                                            <span>
                                                {item.name} ({item._id})
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <IconFont icon="icon_arrow_right" />
            <div className="selected-user-area">
                <p className="type-title">
                    선택된 비즈니스 {numberWithCommas(selectedBizList?.length)}명
                </p>
                <div className="type-content">
                    <ul>
                        {selectedBizList.map(item => (
                            <li key={item._id}>
                                <span>
                                    {item.name} ({item._id})
                                </span>
                                <Button
                                    type="button"
                                    size="small"
                                    onClick={() => unselectUser(item._id)}
                                >
                                    <IconFont icon="icon_close">제거</IconFont>
                                </Button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SelectBiz;

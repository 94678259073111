export const convertTalentStatus = status => {
    switch (status) {
        case "running":
            return "거래 가능";
        case "pause":
            return "거래 중지";
        case "ready":
            return "임시저장";
        default:
            return status;
    }
};

export const convertTalentCategory = val => {
    switch (val) {
        case "cosmetic":
            return "코스메틱/미용";
        case "fashion":
            return "패션/잡화";
        case "baby":
            return "출산/육아";
        case "pet":
            return "반려동물";
        case "restaurant":
            return "맛집/카페";
        case "etc":
            return "기타";
        default:
            return val;
    }
};

export const checkPossibleRunning = status => {
    if (["running", "pause"].indexOf(status) > -1) return true;
};

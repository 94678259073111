import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import queryString from "query-string";
import ProjectSearch from "components/projectManage/ProjectSearch";
import { searchProject } from "stores/modules/project";

const ProjectSearchContainer = ({ location }) => {
    const dispatch = useDispatch();
    const query = queryString.parse(location.search);
    const {
        title,
        id,
        name,
        phoneNumber,
        ownerId,
        status,
        plan,
        type,
        category,
        sns,
        skip
    } = query;
    const { register: searchDateForm, getValues: getSearchDate } = useForm({
        defaultValues: {
            period: "all"
        }
    });
    const { register: optionForm, getValues: getOption } = useForm();
    const projectDetail = useSelector(({ project }) => project.projectDetail);
    const pagination = useSelector(({ project }) => project.pageNumber);

    const onSearchProject = () => {
        const baseDate = getSearchDate();
        const option = getOption();

        console.log("baseDate:", baseDate);
        console.log("plan:", plan);
        console.log({
            date:
                baseDate.period !== "choiceDate"
                    ? baseDate.period
                    : `${baseDate.startDate}~${baseDate.endDate}`,
            status,
            plan,
            title,
            id,
            name,
            phoneNumber,
            ownerId,
            option: option.option
        });
        dispatch(
            searchProject({
                date:
                    baseDate.period !== "choiceDate"
                        ? baseDate.period
                        : `${baseDate.startDate}~${baseDate.endDate}`,
                status,
                plan,
                type,
                category,
                sns,
                title,
                id,
                name,
                phoneNumber,
                ownerId,
                option: option.option,
                skip
            })
        );
    };

    useEffect(() => {
        if (!projectDetail._id) {
            onSearchProject();
        }
        // eslint-disable-next-line
    }, [location.search, pagination, projectDetail._id]);

    return <ProjectSearch searchDateForm={searchDateForm} search={onSearchProject} />;
};

export default withRouter(ProjectSearchContainer);

import React from "react";
import Button from "@material-ui/core/Button";
import { IconFont } from "reviewshare-common-component";
import { numberWithCommas } from "lib/common";

import "./CommonTableActions.scss";

const CommonTableActions = ({ selectedCounts, totalCounts, onDownloadCsv, children, ...rest }) => (
    <div className="common-table-actions" {...rest}>
        <ul className="count-items">
            <li>
                <p>전체: {numberWithCommas(totalCounts)}건</p>
                {onDownloadCsv && (
                    <Button
                        variant="outlined"
                        color="info"
                        className="download-csv-btn"
                        onClick={onDownloadCsv}
                    >
                        <IconFont icon="icon_download">다운로드</IconFont>
                    </Button>
                )}
            </li>
            <li>
                <p>선택: {numberWithCommas(selectedCounts)}건</p>
            </li>
        </ul>
        <div className="action-btn-items">{children}</div>
    </div>
);

export default CommonTableActions;

import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { Tag } from "reviewshare-common-component";
import EditUserInfoContainer from "containers/userManage/EditUserInfoContainer";
import ReviewerScoreRecordContainer from "containers/userManage/ReviewerScoreRecordContainer";
import ReviewerPointRequestContainer from "containers/userManage/ReviewerPointRequestContainer";
import LimitFunction from "components/userManage/LimitFunction";
import UserTagCtrl from "components/userManage/UserTagCtrl";

import "./ReviewerDetailModal.scss";

const ReviewerDetailModal = ({ tab, changeTab }) => {
    const userId = useSelector(({ user }) => user.selectedUser.userId);
    const blockProjectRequest = useSelector(({ user }) => user.userDetail?.blockProjectRequest);
    const blockPointRequest = useSelector(
        ({ user }) => user.userDetail?.reviewerStatus?.blockPointRequest
    );

    return (
        <div className="reviewer-detail-modal-wrapper">
            <nav className="manage-nav">
                <ul>
                    <li
                        className={cx({ active: tab === "userInfo" })}
                        onClick={() => changeTab("userInfo")}
                    >
                        회원 정보
                    </li>
                    <li
                        className={cx({ active: tab === "limitFunction" })}
                        onClick={() => changeTab("limitFunction")}
                    >
                        기능 제한
                    </li>
                    <li
                        className={cx({ active: tab === "scoreRecord" })}
                        onClick={() => changeTab("scoreRecord")}
                    >
                        활동 점수 내역
                    </li>
                    <li
                        className={cx({ active: tab === "pointRequest" })}
                        onClick={() => changeTab("pointRequest")}
                    >
                        포인트 내역
                    </li>
                    <li className={cx({ active: tab === "tag" })} onClick={() => changeTab("tag")}>
                        태그
                    </li>
                </ul>
            </nav>
            <div className="modal-title">
                <h2>회원 ID {userId}</h2>
                <div className="tag-list">
                    {(blockProjectRequest || blockPointRequest) && <Tag color="red">기능 제한</Tag>}
                </div>
            </div>
            {tab === "userInfo" && <EditUserInfoContainer />}
            {tab === "limitFunction" && <LimitFunction />}
            {tab === "scoreRecord" && <ReviewerScoreRecordContainer reviewerId={userId} />}
            {tab === "pointRequest" && <ReviewerPointRequestContainer reviewerId={userId} />}
            {tab === "tag" && <UserTagCtrl userId={userId} />}
        </div>
    );
};

export default ReviewerDetailModal;

import React from "react";
import Header from "components/base/Header";
import { useSelector, useDispatch } from "react-redux";
import { removeStorage } from "lib/storage";
import { logout } from "stores/modules/auth";

const HeaderContainer = () => {
    const dispatch = useDispatch();
    const businessName = useSelector(({ auth }) => auth.userInfo.user && auth.userInfo.user.name);

    const onLogout = () => {
        removeStorage("reviewshare-admin-at");
        dispatch(logout());
        window.location.reload();
    };

    return <Header name={businessName} onLogout={onLogout} />;
};

export default HeaderContainer;

import React from "react";
import WithdrawPointFilter from "components/withdrawPoint/WithdrawPointFilter";
import WithdrawPointList from "components/withdrawPoint/WithdrawPointList";

const WithdrawPointListPage = () => (
    <>
        <WithdrawPointFilter />
        <WithdrawPointList />
    </>
);

export default WithdrawPointListPage;

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { TableContainer, Table, TableHead, TableRow, TableBody } from "@material-ui/core";
import { getPostList } from "stores/modules/post";

import FaqDetailModalContainer from "containers/faq/FaqDetailModalContainer";
import CustomTableCell from "components/common/CustomTableCell";
import ListWrapper from "components/common/ListWrapper";
import { convertTarget, convertFaqTag } from "lib/common";

const tableHeaderList = [
    { label: "ID", align: "center" },
    { label: "대상", align: "center" },
    { label: "태그", align: "center" },
    { label: "제목", align: "center" },
    { label: "작성일", align: "center" },
    { label: "조회수", align: "center" }
];

const PAGE_LIMIT = 10;
const FaqListContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { query } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;

    const [faqModal, handleFaqModal] = useState({ status: false, faqObj: "" });

    const totalCount = useSelector(({ post }) => post.totalCount);
    const faqList = useSelector(({ post }) => post.list);

    const onCloseModal = () => {
        handleFaqModal({ status: false, faqObj: "" });
        dispatch(getPostList({ ...query, type: "faq", skip: Number(query.skip) || 0 }));
    };

    const setPage = pageNum => {
        const newQuery = createSearchQuery({ ...query, skip: (pageNum - 1) * PAGE_LIMIT });
        history.replace({ path: location.pathname, search: newQuery });
    };

    return (
        <>
            {faqModal.status && (
                <FaqDetailModalContainer
                    modalStatus={faqModal.status}
                    onClose={onCloseModal}
                    faqId={faqModal.faqObj.faqId}
                    faqObj={faqModal.faqObj}
                />
            )}
            <ListWrapper
                currentPage={Number(query.skip) / PAGE_LIMIT + 1 || 1}
                handlePage={(e, pageNum) => setPage(pageNum)}
                totalCount={totalCount}
                pageLimit={10}
            >
                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>
                                    <Checkbox color="primary" />
                                </CustomTableCell>
                                {tableHeaderList.map((headerItem, index) => (
                                    <CustomTableCell
                                        align={headerItem.align}
                                        key={index}
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        {headerItem.label}
                                    </CustomTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {faqList &&
                                faqList.length > 0 &&
                                faqList.map((faq, index) => (
                                    <TableRow key={index} hover>
                                        <CustomTableCell width={20}>
                                            <Checkbox
                                                color="primary"
                                                // checked={isItemSelected}
                                                onClick={() => {}}
                                            />
                                        </CustomTableCell>
                                        <CustomTableCell
                                            align="center"
                                            style={{ cursor: "pointer" }}
                                            width={20}
                                            onClick={() =>
                                                handleFaqModal({
                                                    status: true,
                                                    faqObj: faq
                                                })
                                            }
                                        >
                                            {faq.faqId}
                                        </CustomTableCell>
                                        <CustomTableCell align="center" width={100}>
                                            {convertTarget(faq.target)}
                                        </CustomTableCell>
                                        <CustomTableCell
                                            align="center"
                                            width={200}
                                            onClick={() =>
                                                handleFaqModal({
                                                    status: true,
                                                    faqObj: faq
                                                })
                                            }
                                        >
                                            {convertFaqTag(faq.tag)}
                                        </CustomTableCell>
                                        <CustomTableCell
                                            align="left"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                handleFaqModal({
                                                    status: true,
                                                    faqObj: faq
                                                })
                                            }
                                        >
                                            {faq.title}
                                        </CustomTableCell>
                                        <CustomTableCell align="center" width={200}>
                                            {moment(faq.createdAt).format("YYYY-MM-DD HH:mm")}
                                        </CustomTableCell>
                                        <CustomTableCell align="center" width={20}>
                                            {faq.views}
                                        </CustomTableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ListWrapper>
        </>
    );
};

export default FaqListContainer;

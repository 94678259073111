import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import CustomTableCell from "components/common/CustomTableCell";

const CommonTable = ({ counts, headerItems, checkboxProps, limit = 40, children }) => {
    const history = useHistory();
    const location = useLocation();
    const { query } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;

    const onChangePagination = (e, num) => {
        const newQuery = createSearchQuery({ ...query, skip: (num - 1) * limit });
        history.replace({ path: location.pathname, search: newQuery });
    };

    if (!headerItems?.length) return null;
    return (
        <>
            <TableContainer>
                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>
                                <input type="checkbox" name="selectedAll" {...checkboxProps} />
                            </CustomTableCell>
                            {headerItems.map((headerItem, index) => (
                                <CustomTableCell align={headerItem.align} key={index}>
                                    {headerItem.label}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>{children}</TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center" style={{ margin: "20px 0" }}>
                <Pagination
                    count={Math.ceil(counts / limit)}
                    color="primary"
                    onChange={onChangePagination}
                />
            </Box>
        </>
    );
};

export default CommonTable;

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import queryString from "query-string";
import { Button, TextField, Select, MenuItem } from "@material-ui/core";
import moment from "moment";
import { convertStatus, convertType, convertCategory } from "lib/utils/project";
import { convertSns } from "lib/utils/sns";

import "./ProjectSearch.scss";

const OPTION_INIT_VALUE = {
    status: ["rereviewing", "standby", "recruit", "choice", "submit", "complete"],
    plan: ["basic", "standard", "premium", "starter", "growth", "professional", "enterprise"],
    type: ["review", "content", "shortform"],
    category: ["Delivery", "Visit", "Buy", "Reporters", "None"],
    sns: [
        "NaverBlog",
        "Instagram",
        "Youtube",
        "NaverPost",
        "NaverCafe",
        "Tiktok",
        "Reels",
        "Shorts"
    ]
};
const ProjectSearch = ({ searchDateForm, history, location }) => {
    const query = queryString.parse(location.search);
    const { register: searchFieldForm, getValues: getSearchField } = useForm();

    const [selectedOptions, setSelectedOptions] = useState({
        plan: query.plan,
        status: query.status,
        type: query.type,
        category: query.category,
        sns: query.sns
    });

    const onChangeOption = ({ key, value }) => {
        const filteredValue = value === "all" || !value ? undefined : value;
        setSelectedOptions(prev => ({ ...prev, [key]: filteredValue }));
    };

    const onSearchProject = () => {
        const { title, id, name, phoneNumber, ownerId } = getSearchField();
        const options = {
            title: title || undefined,
            id: id || undefined,
            name: name || undefined,
            phoneNumber: phoneNumber || undefined,
            ownerId: ownerId || undefined,
            ...selectedOptions
        };
        const newQuery = queryString.stringify({ ...query, ...options });
        history.replace({
            pathname: location.pathname,
            search: newQuery
        });
    };

    return (
        <form className="project-search">
            <div className="search-category">
                <h3>날짜</h3>
                <div className="search-date">
                    <ul>
                        <li>
                            <label htmlFor="date-all">
                                <input
                                    type="radio"
                                    name="period"
                                    id="date-all"
                                    value="all"
                                    ref={searchDateForm}
                                />{" "}
                                전체
                            </label>
                        </li>
                        <li>
                            <label htmlFor="date-one-month">
                                <input
                                    type="radio"
                                    name="period"
                                    id="date-one-month"
                                    value="oneMonth"
                                    ref={searchDateForm}
                                />{" "}
                                1개월
                            </label>
                        </li>
                        <li>
                            <label htmlFor="date-three-month">
                                <input
                                    type="radio"
                                    name="period"
                                    id="date-three-month"
                                    value="threeMonth"
                                    ref={searchDateForm}
                                />{" "}
                                3개월
                            </label>
                        </li>
                        <li>
                            <label htmlFor="date-choice">
                                <input
                                    type="radio"
                                    name="period"
                                    id="date-choice"
                                    value="choiceDate"
                                    ref={searchDateForm}
                                />{" "}
                                기간 설정
                            </label>
                        </li>
                    </ul>
                    <div className="date-picker">
                        <TextField
                            id="date"
                            type="date"
                            defaultValue={moment().format("YYYY-MM-DD")}
                            inputProps={{
                                name: "startDate",
                                ref: searchDateForm
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <p>~</p>
                        <TextField
                            id="date"
                            type="date"
                            defaultValue={moment().format("YYYY-MM-DD")}
                            inputProps={{
                                name: "endDate",
                                ref: searchDateForm
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="search-items">
                <div className="search-item">
                    <h3>상태</h3>
                    <div className="project-status">
                        <Select
                            value={selectedOptions.status || "all"}
                            onChange={e => onChangeOption({ key: "status", value: e.target.value })}
                        >
                            <MenuItem value="all">전체</MenuItem>
                            {OPTION_INIT_VALUE.status.map((item, index) => (
                                <MenuItem value={item} key={index}>
                                    {convertStatus(item)}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="search-item">
                    <h3>플랜</h3>
                    <div className="project-plan">
                        <Select
                            value={selectedOptions.plan || "all"}
                            onChange={e => onChangeOption({ key: "plan", value: e.target.value })}
                        >
                            <MenuItem value="all">전체</MenuItem>
                            {OPTION_INIT_VALUE.plan.map((item, index) => (
                                <MenuItem value={item} key={index}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="search-item">
                    <h3>유형</h3>
                    <div className="project-type">
                        <Select
                            value={selectedOptions.type || "all"}
                            onChange={e => onChangeOption({ key: "type", value: e.target.value })}
                        >
                            <MenuItem value="all">전체</MenuItem>
                            {OPTION_INIT_VALUE.type.map((item, index) => (
                                <MenuItem value={item} key={index}>
                                    {convertType(item)}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="search-item">
                    <h3>진행 방식</h3>
                    <div className="project-category">
                        <Select
                            value={selectedOptions.category || "all"}
                            onChange={e =>
                                onChangeOption({ key: "category", value: e.target.value })
                            }
                        >
                            <MenuItem value="all">전체</MenuItem>
                            {OPTION_INIT_VALUE.category.map((item, index) => (
                                <MenuItem value={item} key={index}>
                                    {convertCategory(item)}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="search-item">
                    <h3>SNS</h3>
                    <div className="project-sns">
                        <Select
                            value={selectedOptions.sns || "all"}
                            onChange={e => onChangeOption({ key: "sns", value: e.target.value })}
                        >
                            <MenuItem value="all">전체</MenuItem>
                            {OPTION_INIT_VALUE.sns.map((item, index) => (
                                <MenuItem value={item} key={index}>
                                    {convertSns(item)}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="search-item">
                    <h3>프로젝트 ID</h3>
                    <div className="project-id">
                        <input
                            type="text"
                            name="id"
                            ref={searchFieldForm}
                            defaultValue={query.id}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    onSearchProject();
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="search-item">
                    <h3>프로젝트명</h3>
                    <div className="project-title">
                        <input
                            type="text"
                            name="title"
                            ref={searchFieldForm}
                            defaultValue={query.title}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    onSearchProject();
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="search-item">
                    <h3>비즈니스 ID</h3>
                    <div className="project-biz-id">
                        <input
                            type="text"
                            name="ownerId"
                            ref={searchFieldForm}
                            defaultValue={query.ownerId}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    onSearchProject();
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="search-item">
                    <h3>담당자명</h3>
                    <div className="project-biz-name">
                        <input
                            type="text"
                            name="name"
                            ref={searchFieldForm}
                            defaultValue={query.name}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    onSearchProject();
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="search-item">
                    <h3>담당자 연락처</h3>
                    <div className="project-phone-number">
                        <input
                            type="text"
                            name="phoneNumber"
                            ref={searchFieldForm}
                            defaultValue={query.phoneNumber}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    onSearchProject();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="search-btn-area">
                <Button variant="contained" color="primary" onClick={onSearchProject}>
                    검색
                </Button>
            </div>
        </form>
    );
};

export default withRouter(ProjectSearch);

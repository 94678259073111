import React from "react";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Tag } from "reviewshare-common-component";
import {
    convertSuggestionStatus,
    convertSuggestionReason,
    numberWithCommas,
    returnTagColor,
    convertBoolean
} from "lib/common";

import "./SuggestionModal.scss";

const SuggestionModal = ({ data, unsubmit, cancel, closeModal }) => (
    <Modal open onClose={closeModal}>
        <div id="suggestion-detail-modal">
            <div className="modal-title">
                <h2>제안 ID {data._id} </h2>
                <Tag className="size-l" color={returnTagColor(data.status)}>
                    {convertSuggestionStatus(data.status)}
                </Tag>
            </div>
            <div className="modal-content">
                <fieldset>
                    <div className="section">
                        <div className="field-box">
                            <div className="field">
                                <p className="field-title">비즈니스</p>
                                <div className="field-content">
                                    {data.from?._id}
                                    {" - "}
                                    {data.from?.name}
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">리뷰어</p>
                                <div className="field-content">
                                    {data.to?._id}
                                    {" - "}
                                    {data.to?.nickname}({data.to?.name})
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">브랜드</p>
                                <div className="field-content">
                                    {data.enterprise?._id}
                                    {" - "}
                                    {data.enterprise?.name}
                                </div>
                            </div>
                        </div>
                        <div className="field-box">
                            <div className="field">
                                <p className="field-title">포인트 지급 여부</p>
                                <div className="field-content">
                                    {convertBoolean(data.pointIssued)}
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">환불 여부</p>
                                <div className="field-content">
                                    {convertBoolean(data.isRefunded)}
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <p className="field-title">제공 내역</p>
                            <div className="field-content">{data.itemName}</div>
                        </div>
                        <div className="field">
                            <p className="field-title">제공 포인트</p>
                            <div className="field-content">{numberWithCommas(data.points)}P</div>
                        </div>
                        {data.siteUrl && (
                            <div className="field">
                                <p className="field-title">관련 사이트</p>
                                <div className="field-content">
                                    <a
                                        href={data.siteUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {data.siteUrl}
                                    </a>
                                </div>
                            </div>
                        )}
                        <div className="field">
                            <p className="field-title">가이드라인</p>
                            <div className="field-content">
                                <textarea value={data.guideLine} disabled />
                            </div>
                        </div>
                        <div className="field">
                            <p className="field-title">제안 날짜</p>
                            <div className="field-content">
                                {moment(data.createdAt).format("YYYY-MM-DD HH:mm")}
                            </div>
                        </div>
                    </div>
                    {(data.status === "Running" ||
                        data.status === "Complete" ||
                        data.status === "Submitted") && (
                        <div className="section">
                            <div className="field">
                                <p className="field-title">수락 날짜</p>
                                <div className="field-content">
                                    {moment(data.acceptedAt).format("YYYY-MM-DD HH:mm")}
                                </div>
                            </div>
                            {data.reviewUrl && (
                                <div className="field">
                                    <p className="field-title">URL</p>
                                    <div className="field-content">
                                        <a
                                            href={data.reviewUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {data.reviewUrl}
                                        </a>
                                    </div>
                                </div>
                            )}
                            {data.reviewFiles?.length > 0 && (
                                <div className="field">
                                    <p className="field-title">파일</p>
                                    <div className="field-content">
                                        <ul>
                                            {data.reviewFiles.map((item, index) => (
                                                <li key={index}>
                                                    <a
                                                        href={item.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {item.originalFileName}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                            {data.submitHistory?.length > 0 && (
                                <div className="field-box">
                                    <div className="field">
                                        <h3 className="field-title">제출 히스토리</h3>
                                        <div className="field-content">
                                            <TreeView
                                                defaultCollapseIcon={<ExpandMoreIcon />}
                                                defaultExpandIcon={<ChevronRightIcon />}
                                            >
                                                {data.submitHistory.map((history, index) => (
                                                    <TreeItem
                                                        key={index}
                                                        nodeId={`title${index}`}
                                                        label={`날짜:${
                                                            history.updatedAt
                                                                ? moment(history.updatedAt).format(
                                                                      "YYYY-MM-DD HH:mm"
                                                                  )
                                                                : ""
                                                        }`}
                                                    >
                                                        {Object.keys(history).map((key, i) => {
                                                            if (key === "updatedAt") return null;

                                                            return (
                                                                <TreeItem
                                                                    key={i}
                                                                    nodeId={`item${i}`}
                                                                    label={`${key}:${history[key]
                                                                        ?._id || history[key]}`}
                                                                />
                                                            );
                                                        })}
                                                    </TreeItem>
                                                ))}
                                            </TreeView>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {data.submitEndAt && (
                                <div className="field">
                                    <p className="field-title">제출 마감일</p>
                                    <div className="field-content">
                                        {moment(data.submitEndAt).format("YYYY-MM-DD HH:mm")}
                                    </div>
                                </div>
                            )}
                            {data.submittedAt && (
                                <div className="field">
                                    <p className="field-title">제출 날짜</p>
                                    <div className="field-content">
                                        {moment(data.submittedAt).format("YYYY-MM-DD HH:mm")}
                                    </div>
                                </div>
                            )}
                            {data.pointIssued && (
                                <div className="field">
                                    <p className="field-title">포인트 지급 날짜</p>
                                    <div className="field-content">
                                        {moment(data.pointIssuedAt).format("YYYY-MM-DD HH:mm")}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {data.status === "Rejected" && (
                        <div className="section">
                            <div className="field reason">
                                <p className="field-title">거절 이유</p>
                                <div className="field-content">
                                    <ul>
                                        {data.rejectReason?.map((item, index) => (
                                            <li key={index}>
                                                <p>{convertSuggestionReason(item.category)}</p>
                                                {item.detail && <div>{item.detail}</div>}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">거절 날짜</p>
                                <div className="field-content">
                                    {moment(data.rejectedAt).format("YYYY-MM-DD HH:mm")}
                                </div>
                            </div>
                        </div>
                    )}
                </fieldset>
            </div>
            <div className="modal-footer">
                {(data.status === "Waiting" ||
                    data.status === "Running" ||
                    data.status === "Submitted") && (
                    <Button variant="contained" color="secondary" onClick={() => cancel(data._id)}>
                        제안 취소
                    </Button>
                )}
                {(data.status === "Running" || data.status === "Submitted") && data.isSubmitted && (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "10px" }}
                        onClick={() => unsubmit(data._id)}
                    >
                        미제출 처리
                    </Button>
                )}
            </div>
        </div>
    </Modal>
);

export default SuggestionModal;

import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { Breakpoints } from "theme";

const backgroundStyle = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => (props.transparent ? "transparent" : " rgba(0, 0, 0, 0.7)")};
`;

const Background = styled("div")`
    ${backgroundStyle}
`;

const Wrapper = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    z-index: 9000;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

const ModalContents = styled.div`
    z-index: 9001;
    position: relative;

    @media ${Breakpoints.mobile} {
        width: 100%;
        > div {
            margin: 0 auto;
        }
    }
`;

const HideScrollBackground = ({ onClick, transparent, children }) => {
    useEffect(() => {
        // if (document.getElementById("main").offsetHeight >= window.innerHeight) {
        //     document.querySelector("body").style.overflowY = "scroll";
        // }
        document.getElementById("root").style.overflowY = "hidden";

        return () => {
            // if (document.querySelector("body").style.overflowY === "scroll") {
            //     document.querySelector("body").style.overflowY = "visible";
            // }
            document.getElementById("root").style.overflowY = "visible";
        };
    }, []);

    return (
        <Wrapper>
            <Background onClick={onClick} transparent={transparent} />
            <ModalContents>{children}</ModalContents>
        </Wrapper>
    );
};
export default HideScrollBackground;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Select, MenuItem } from "@material-ui/core";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { callDirectApi } from "lib/common";
import { getUserList as getUserListAPI } from "lib/api/user";
import { convertChargeStatus } from "lib/utils/payment";
import { getChargeList } from "stores/modules/payment";

import "./ChargeFilter.scss";

const STATUS_LIST = ["ready", "paid", "cancelled"];
const FILTER_INPUT_ITEMS = [
    { label: "결제 ID", value: "_id" },
    { label: "비즈니스 ID", value: "owner" },
    { label: "비즈니스명", value: "ownerName" },
    { label: "예금주명", value: "accountName" }
];
const ChargeFilter = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { query, search } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;
    const chargeDetailId = useSelector(({ payment }) => payment.chargeDetail?._id);

    const [selectedOptions, setSelectedOptions] = useState({
        status: query.status || undefined,
        _id: query._id || undefined,
        owner: query.owner || undefined,
        ownerName: query.ownerName || undefined,
        accountName: query.accountName || undefined
    });

    const onChangeOption = e => {
        const { name, value } = e.target;
        const filteredValue = value === "all" || !value ? undefined : value;
        setSelectedOptions(prev => ({ ...prev, [name]: filteredValue }));
    };

    const onSearch = () => {
        const newQuery = createSearchQuery(selectedOptions);
        history.replace({ path: "/charge", search: newQuery });
    };

    const onGetChargeList = async () => {
        const newQuery = { ...query, ownerName: undefined, method: "Charge" };

        if (query.ownerName) {
            const { data } = await callDirectApi(
                getUserListAPI,
                { ...(query.owner && { id: query.owner }), name: query.ownerName, limit: 200 },
                true
            );
            const userList = data?.list;

            if (userList?.length > 0) {
                const userIdList = userList.map(user => user._id);
                newQuery.owner = { $in: userIdList };
            }
        }

        if (query.accountName) {
            newQuery.accountName = { $regex: query.accountName };
        }
        dispatch(getChargeList(newQuery));
    };

    useEffect(() => {
        if (!chargeDetailId) {
            onGetChargeList();
        }
    }, [chargeDetailId, search]);

    return (
        <div id="charge-filter">
            <fieldset>
                <div className="field">
                    <label htmlFor="charge-filter-status">상태</label>
                    <Select
                        id="charge-filter-status"
                        value={selectedOptions.status || "all"}
                        name="status"
                        onChange={onChangeOption}
                    >
                        <MenuItem value="all">전체</MenuItem>
                        {STATUS_LIST.map((item, index) => (
                            <MenuItem value={item} key={index}>
                                {convertChargeStatus(item)}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                {FILTER_INPUT_ITEMS.map((item, index) => (
                    <div className="field" key={index}>
                        <label htmlFor={`charge-filter-${item.value}`}>{item.label}</label>
                        <input
                            type="text"
                            id={`charge-filter-${item.value}`}
                            name={item.value}
                            value={selectedOptions[item.value]}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    onSearch();
                                }
                            }}
                            onChange={onChangeOption}
                        />
                    </div>
                ))}
            </fieldset>
            <div className="button-box">
                <Button variant="contained" color="primary" onClick={onSearch}>
                    검색
                </Button>
            </div>
        </div>
    );
};

export default ChargeFilter;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { getMonthlyTransactionAndFeeStats } from "stores/modules/statistic";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

const MonthlyTransactionAndFeeGraph = () => {
    const dispatch = useDispatch();

    const monthlyTransactionAndFeeStatistics = useSelector(
        ({ statistic }) => statistic.monthlyTransactionAndFee
    );

    useEffect(() => {
        dispatch(getMonthlyTransactionAndFeeStats());
    }, []);

    if ((monthlyTransactionAndFeeStatistics || []).length === 0) {
        return null;
    }

    return (
        <div>
            <Chart
                type="bar"
                options={{
                    scales: {
                        y: {
                            type: "linear",
                            display: true,
                            position: "left"
                        },
                        y1: {
                            type: "linear",
                            display: true,
                            position: "right",

                            grid: {
                                drawOnChartArea: false
                            }
                        }
                    }
                }}
                data={{
                    labels: monthlyTransactionAndFeeStatistics.map(item => item.date),
                    datasets: [
                        {
                            type: "line",
                            label: "매출 총 이익율",
                            borderColor: "rgb(255, 99, 132)",
                            borderWidth: 2,
                            fill: false,
                            data: monthlyTransactionAndFeeStatistics.map(
                                item => item.profitMarginRatio
                            ),
                            yAxisID: "y1"
                        },
                        {
                            type: "bar",
                            label: "플랫폼 거래액",
                            backgroundColor: "rgb(75, 192, 192)",
                            data: monthlyTransactionAndFeeStatistics.map(
                                item => item.platformTrancation
                            ),
                            yAxisID: "y"
                        },
                        {
                            type: "bar",
                            label: "수수료",
                            backgroundColor: "rgb(53, 162, 235)",
                            data: monthlyTransactionAndFeeStatistics.map(item => item.totalFee),
                            yAxisID: "y"
                        }
                    ]
                }}
            />
        </div>
    );
};

export default MonthlyTransactionAndFeeGraph;

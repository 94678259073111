import React from "react";
import ChargeFilter from "components/charge/ChargeFilter";
import ChargeList from "components/charge/ChargeList";
import ChargeModal from "components/charge/ChargeModal";

const ChargeListPage = () => (
    <>
        <ChargeFilter />
        <ChargeList />
        <ChargeModal />
    </>
);

export default ChargeListPage;

import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { remove, concat } from "lodash";
import cx from "classnames";
import Checkbox from "@material-ui/core/Checkbox";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import { IconFont } from "reviewshare-common-component";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import ProjectButtons from "components/projectManage/ProjectButtons";
import CustomTableCell from "components/common/CustomTableCell";
import { getProjectDetail, removeProject } from "stores/modules/project";
import ProjectDetailModalContainer from "containers/projectManage/ProjectDetailModalContainer";
import { TableContainer, Table, TableHead, TableRow, TableBody } from "@material-ui/core";
import {
    numberWithCommas,
    convertPaymentStatusToText,
    convertProjectPlan,
    convertBoolean
} from "lib/common";
import { convertType, convertStatus } from "lib/utils/project";
import { convertSns } from "lib/utils/sns";

import "./ProjectList.scss";

const tableHeaderList = [
    { label: "상태", align: "center" },
    { label: "프로젝트 번호", align: "center" },
    { label: "프로젝트명", align: "left" },
    { label: "리워드", align: "left" },
    { label: "모집인원", align: "center" },
    { label: "유형", align: "left" },
    { label: "채널", align: "left" },
    { label: "결제여부", align: "center" },
    { label: "리픽", align: "center" },
    { label: "플랜", align: "center" },
    // { label: "부가 서비스", align: "left" },
    { label: "서비스 연락처", align: "left" },
    { label: "담당자", align: "center" },
    { label: "비즈니스계정", align: "left" },
    { label: "비즈니스연락처", align: "left" }
];

// const convertAddition = addition => {
//     if (!addition) return "";

//     if (addition.type && addition.type === "Sponsor") {
//         return "추천";
//     }

//     if (addition.topBanner) {
//         return "상단 배너";
//     }

//     console.log("부가 서비스:", JSON.stringify(addition));
//     return "확인필요";
// };

const limit = 50;
const ProjectList = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { search, query } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;

    const projectData = useSelector(({ project }) => project.projectList);
    const [isOpen, handleModal] = useState(false);
    const [selectItem, setSelectItem] = useState([]);

    const handlePage = (e, value) => {
        const newQuery = createSearchQuery({ ...query, skip: (value - 1) * limit });
        history.replace({ path: location.pathname, search: newQuery });
    };

    const openProjectModal = projectId => {
        console.log("projectId:", projectId);
        handleModal(true);
        dispatch(getProjectDetail({ projectId }));
    };

    const onHandleCheck = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        let _selectItem = concat(selectItem);

        if (selectItem.find(val => val === id)) {
            _selectItem = remove(selectItem, val => val !== id);
        } else {
            _selectItem.push(id);
        }

        setSelectItem(_selectItem);
    };

    const isSelected = id => selectItem.indexOf(id) !== -1;

    const onRemoveProject = () => {
        const boolean = window.confirm(`진짜 삭제하시겠습니까? \n ${selectItem.join(",")}`);
        if (boolean) {
            dispatch(removeProject(selectItem));
        }
    };

    useEffect(() => {
        setSelectItem([]);
    }, [search]);

    return (
        <div id="project-list">
            <ProjectButtons
                removeProject={onRemoveProject}
                projectCount={projectData && projectData.totalCount}
                selectedItems={selectItem}
            />
            <TableContainer>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>
                                <Checkbox
                                    color="primary"
                                    checked={false}
                                    onClick={() => setSelectItem([])}
                                />
                            </CustomTableCell>
                            {tableHeaderList.map((headerItem, index) => (
                                <CustomTableCell
                                    align={headerItem.align}
                                    key={index}
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    {headerItem.label}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projectData &&
                            projectData.list.map((project, index) => {
                                const isItemSelected = isSelected(project._id);
                                const isSecondaryUse =
                                    project.addition && project.addition.secondaryUse.status;

                                return (
                                    <TableRow
                                        key={index}
                                        hover
                                        classes={{
                                            root: project.isDeleted ? "deleted-item" : ""
                                        }}
                                        selected={isItemSelected}
                                    >
                                        <CustomTableCell>
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                disabled={project.isDeleted && !isItemSelected}
                                                onClick={e => onHandleCheck(e, project._id)}
                                            />
                                        </CustomTableCell>
                                        <CustomTableCell align="center" width={80}>
                                            <span
                                                className={cx(
                                                    "project-status",
                                                    project.subStatus || project.status
                                                )}
                                            >
                                                {convertStatus(project.subStatus || project.status)}
                                            </span>
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {project._id}
                                        </CustomTableCell>
                                        <CustomTableCell
                                            className="open-detail"
                                            width={280}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => openProjectModal(project._id)}
                                        >
                                            <IconFont icon="icon_project" />
                                            {project.title}
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            {project.points > 0
                                                ? numberWithCommas(project.points)
                                                : "-"}
                                            {isSecondaryUse && (
                                                <p className="red-font">
                                                    +{numberWithCommas(10000)}
                                                </p>
                                            )}
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {project.recruits}
                                        </CustomTableCell>
                                        <CustomTableCell width={120}>
                                            {convertType(project.type)}
                                        </CustomTableCell>
                                        <CustomTableCell width={120}>
                                            {project.sns !== "OtherSns"
                                                ? convertSns(project.sns)
                                                : convertSns(project.otherSns)}
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {project.payment
                                                ? convertPaymentStatusToText(project.payment.status)
                                                : "없음"}
                                        </CustomTableCell>
                                        {/* <CustomTableCell>
                                                {convertAddition(project.addition || "")}
                                            </CustomTableCell> */}
                                        <CustomTableCell>
                                            {convertBoolean(project.isPick)}
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            {convertProjectPlan(project.recruitmentForm) || "-"}
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            {project.phoneNumber.replace(/\D/g, "")}
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {project.owner?.name}({project.owner?._id})
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            {project.owner ? project.owner.email : "없음"}
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            {project.owner ? project.owner.phoneNumber : "없음"}
                                        </CustomTableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {projectData.totalCount === 0 && (
                <p style={{ textAlign: "center", padding: "80px 0" }}>프로젝트가 없습니다.</p>
            )}
            <Box display="flex" justifyContent="center" style={{ marginTop: "20px" }}>
                <Pagination
                    count={Math.ceil(projectData.totalCount / limit)}
                    color="primary"
                    onChange={handlePage}
                />
            </Box>
            {isOpen && (
                <ProjectDetailModalContainer
                    isOpen={isOpen}
                    closeModal={() => handleModal(false)}
                />
            )}
        </div>
    );
};
export default ProjectList;

import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import UserSearch from "components/userManage/UserSearch";
import { searchUser } from "stores/modules/user";

const PAGE_LIMIT = 20;
const UserSearchContainer = ({ type }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { register, getValues, reset, control } = useForm();
    const { query, search } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;
    const [blockState, setBlockState] = useState({
        projectRequest: false,
        pointRequest: false
    });

    const getUserList = () => {
        dispatch(
            searchUser({
                ...query,
                userCategory: type,
                skip: Number(query.skip) || 0,
                limit: PAGE_LIMIT
            })
        );
    };

    const onSearchUser = ({ init } = {}) => {
        const values = getValues();
        const searchObj = {
            blockList: []
        };

        if (!init) {
            Object.keys(values).forEach(key => {
                if (values[key]) {
                    searchObj[key] = values[key];
                }
            });
            Object.keys(blockState).forEach(key => {
                if (blockState[key]) {
                    searchObj.blockList.push(key);
                }
            });
        } else {
            searchObj.skip = 0;

            reset({
                id: "",
                nickname: "",
                name: "",
                email: "",
                phoneNumber: "",
                channel: "all",
                minOfAvgViews: "",
                maxOfAvgViews: ""
            });
        }

        const newQuery = createSearchQuery(searchObj);
        history.replace({ path: location.pathname, search: newQuery });
    };

    useEffect(() => {
        getUserList();
        // eslint-disable-next-line
    }, [search, type]);

    return (
        <UserSearch
            category={type}
            blockState={blockState}
            setBlockState={setBlockState}
            searchForm={register}
            searchUser={onSearchUser}
            control={control}
        />
    );
};

export default UserSearchContainer;

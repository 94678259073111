import React from "react";
import { Button } from "@material-ui/core";
import { IconFont } from "reviewshare-common-component";
import { useDispatch } from "react-redux";
import { useAllProject } from "lib/customHooks/useProject";
import { numberWithCommas } from "lib/common";
import { setPickProject } from "stores/modules/project";

import "./ProjectButtons.scss";

const ProjectButtons = ({ selectedItems, removeProject }) => {
    const dispatch = useDispatch();
    const { totalCount: counts } = useAllProject();

    const onSetPick = process => {
        dispatch(setPickProject({ idList: selectedItems, process }));
    };

    return (
        <div id="project-list-buttons">
            <ul className="count-list">
                <li>
                    <p>전체: {numberWithCommas(counts)}건</p>
                </li>
                <li>
                    <p>선택: {numberWithCommas(selectedItems.length)}건</p>
                </li>
            </ul>
            <ul className="btn-list">
                <li>
                    <Button variant="outlined" onClick={() => onSetPick("active")}>
                        리픽 <IconFont icon="icon_line_arrow_up">올리기</IconFont>
                    </Button>
                </li>
                <li>
                    <Button variant="outlined" onClick={() => onSetPick("inactive")}>
                        리픽 <IconFont icon="icon_line_arrow_down">내리기</IconFont>
                    </Button>
                </li>
                <li>
                    <Button variant="contained" color="secondary" onClick={removeProject}>
                        삭제
                    </Button>
                </li>
            </ul>
        </div>
    );
};

export default ProjectButtons;

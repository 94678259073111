import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import "styles/main.scss";
import "noto-sans-korean.css";
// import * as serviceWorker from './serviceWorker';

// const tagManagerArgs = {
//     gtmId: "GTM-WM24PH2"
// };

const isDev = process.env.NODE_ENV === "development";
if (!isDev) {
    import("@sentry/browser").then((Sentry) => {
        Sentry.init({ dsn: "https://41bfd468863c42f3816de1a516e7a54a@sentry.io/1875782" });
    });
}

ReactDOM.render(<Root />, document.getElementById("root"));

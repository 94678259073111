import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { callDirectApi } from "lib/common";
import HideScrollBackground from "components/common/HideScrollBackground";
import {
    getBizUsage as getBizUsageAPI,
    getPointRequestByReviewer as getPointRequestByReviewerAPI
} from "lib/api/statistic";

import "./DownloadStatisticData.scss";

const useStyles = makeStyles({
    root: {
        width: 275
    },
    title: {
        fontSize: 18,
        marginBottom: 5
    },
    desc: {
        fontSize: 12
    }
});

const DownloadStatisticData = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const onDownload = async name => {
        setLoading(true);
        let api;

        switch (name) {
            case "bizUsage":
                api = getBizUsageAPI;
                break;
            case "pointRequest":
                api = getPointRequestByReviewerAPI;
                break;

            default:
                break;
        }

        try {
            if (!api) {
                throw new Error("Not found API.");
            }
            const result = await callDirectApi(api, "", true);
            window.open(result.data);
        } catch (error) {
            window.alert("다운로드 실패:" + error.message);
        }

        setLoading(false);
    };

    return (
        <>
            {loading && <HideScrollBackground />}
            <div className="download-static-data">
                <Card className={classes.root}>
                    <CardContent>
                        <Typography color="textSecondary" className={classes.desc}>
                            엑셀 다운로드
                        </Typography>
                        <Typography variant="h3" className={classes.title} component="h2">
                            비즈니스 사용 데이터
                        </Typography>
                        <Typography variant="body2" component="p" className={classes.desc}>
                            신규 회원수와 프로젝트, 제안하기, 거래하기의 이용 비즈니스 수, 수수료,
                            리워드 포인트
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" color="primary" onClick={() => onDownload("bizUsage")}>
                            다운로드
                        </Button>
                    </CardActions>
                </Card>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography color="textSecondary" className={classes.desc}>
                            엑셀 다운로드
                        </Typography>
                        <Typography variant="h3" className={classes.title} component="h2">
                            리뷰어 출금 신청
                        </Typography>
                        <Typography variant="body2" component="p" className={classes.desc}>
                            회차별 출금 신청 및 지급 포인트
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button
                            size="small"
                            color="primary"
                            onClick={() => onDownload("pointRequest")}
                        >
                            다운로드
                        </Button>
                    </CardActions>
                </Card>
            </div>
        </>
    );
};

export default DownloadStatisticData;

import client from "./client";

export const getWelcomePointsInfo = userId =>
    client.get(
        `/payment/findOne?query={"where":{"owner":${userId},"category":"event","status":"paid"}}`
    );

export const getPaymentHistory = ({ userId, keyword, skip }) =>
    client.get(`/payment/history`, {
        params: {
            userId,
            keyword,
            skip
        }
    });

export const getChargeList = ({ skip, ...query }) =>
    client.get(
        `/payment/find?query={"where": ${JSON.stringify(
            query
        )}, "populate":["owner"], "sort": { "createdAt": -1 }, "skip": ${skip || 0}, "limit": 40}`
    );

export const confirmCharge = paymentId => client.put("/payment/charge", { id: paymentId });

import React from "react";
import { useParams } from "react-router-dom";
import ManageNav from "components/common/ManageNav";
import AllPaymentListContainer from "containers/paymentList/AllPaymentListContainer";
import PaymentListContainer from "containers/paymentList/PaymentListContainer";

const PaymentListPage = () => {
    const { type } = useParams();

    return (
        <>
            <ManageNav
                navList={[
                    // { url: "/payment/all", name: "전체" },
                    { url: "/payment/biz", name: "특정 비즈니스" }
                ]}
            />
            {type === "all" && <AllPaymentListContainer />}
            {type === "biz" && <PaymentListContainer />}
        </>
    );
};

export default PaymentListPage;

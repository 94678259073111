import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as reviewerStatusAPI from "lib/api/reviewerStatus";

const [GET_VERIFIED_LIST, GET_VERIFIED_LIST_SUCCESS] = createRequestActionTypes(
    "reviewerStatus/GET_VERIFIED_LIST"
);

export const getVerifiedList = createAction(GET_VERIFIED_LIST);

const getVerifiedListSaga = createRequestSagaWithAlert(
    GET_VERIFIED_LIST,
    reviewerStatusAPI.getVerifiedList,
    "목록을 가져오는 데 실패하였습니다.",
    true
);

export function* reviewerStatusSaga() {
    yield takeLatest(GET_VERIFIED_LIST, getVerifiedListSaga);
}

const initialState = {
    list: [],
    totalCount: 0
};

const reviewerStatus = handleActions(
    {
        [GET_VERIFIED_LIST_SUCCESS]: (state, { payload }) => ({
            list: payload.list,
            totalCount: payload.totalCount
        })
    },
    initialState
);

export default reviewerStatus;

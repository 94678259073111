import React from "react";
import ManageNav from "components/common/ManageNav";
import { Switch, Route } from "react-router-dom";
import SendEmailContainer from "containers/emailMarketing/SendEmailContainer";

const EmailMarketingPage = () => (
    <>
        <ManageNav navList={[{ url: "/email-marketing", name: "이메일 보내기" }]} />
        <Switch>
            <Route exact path="/email-marketing">
                <SendEmailContainer />
            </Route>
        </Switch>
    </>
);

export default EmailMarketingPage;

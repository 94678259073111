import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Routes from "Routes";
import { Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getCategoryList } from "stores/modules/common";
import Loading from "components/common/Loading";

// const useTrackPage = () => {
//     const location = useLocation();
//     useEffect(() => {
//         window.gtag("config", "UA-134266866-1", {
//             page_location: location.pathname,
//             page_path: location.pathname
//         });

//         window.gtag("config", "AW-854806619", {
//             page_location: location.pathname
//         });
//     }, [location]);
// };

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCategoryList());
    }, []);

    return (
        // const isShowLoading = useSelector(({ loading }) => loading.show);
        // useTrackPage();
        <>
            <Helmet>
                <title>리뷰쉐어 관리자</title>
                <meta name="description" content="리뷰쉐어 관리자 페이지입니다." />
            </Helmet>
            <Loading />
            {/* <Loading isShow={isShowLoading} /> */}
            <Switch>
                <Routes />
            </Switch>
        </>
    );
};
export default App;

/* eslint-disable import/prefer-default-export */
export const EMAIL_LIST_SCHEME = [
    "직접입력",
    "naver.com",
    "daum.net",
    "hanmail.net",
    "nate.com",
    "gmail.com",
    "hotmail.com"
];

export const BRAND_LIST_SCHEME = [
    "패션뷰티",
    "미용",
    "유아동",
    "식품건강",
    "숙박",
    "맛집",
    "디지털",
    "생활용품",
    "앱리뷰",
    "지역문화",
    "웹서비스",
    "기타"
];

export const PLAN_LIST_SCHEME = [
    { label: "없음(Basic)", value: false },
    { label: "Basic", value: "basic" },
    { label: "Standard", value: "standard" },
    { label: "Premium", value: "premium" },
    { label: "Starter", value: "starter" },
    { label: "Growth", value: "growth" },
    { label: "Professional", value: "professional" },
    { label: "Enterprise", value: "Enterprise" }
];

export const ADDITION_LIST_SCHEME = [
    { label: "추천", value: "recommend" },
    { label: "비공개", value: "private" },
    { label: "긴급", value: "emergency" },
    { label: "2차 활용", value: "secondaryUse" }
];

export const GENDER_LIST_SCHEME = [
    { label: "여자", value: "Female" },
    { label: "남자", value: "Male" }
];

export const FAQ_REVIEWER_TAG = [
    { label: "리뷰어 이용방법", value: "reHowToUse" },
    { label: "거래하기", value: "reOrder" },
    { label: "제안하기", value: "reSuggestion" },
    { label: "리뷰어 신청", value: "reRequest" },
    { label: "리뷰어 선정", value: "reChosen" },
    { label: "리뷰 작성", value: "reWrite" },
    { label: "리뷰 제출", value: "reSubmit" },
    { label: "포인트 관리", value: "rePoints" },
    { label: "계정 관리", value: "reAccount" },
    { label: "기타", value: "reEtc" }
];

export const FAQ_BIZ_TAG = [
    { label: "비즈니스 이용방법", value: "bizHowToUse" },
    { label: "구독형", value: "bizSubs" },
    { label: "거래하기", value: "bizOrder" },
    { label: "제안하기", value: "bizSuggestion" },
    { label: "프로젝트 등록", value: "bizProject" },
    { label: "리뷰어 모집", value: "bizRecruit" },
    { label: "리뷰어 선정", value: "bizChosen" },
    { label: "리뷰 제출 확인", value: "bizCheckReview" },
    { label: "결제 관리", value: "bizPayment" },
    { label: "계정 관리", value: "bizAccount" },
    { label: "기타", value: "bizEtc" }
];

export const bizSubsMainStatistics = {
    totalNumOfSubs: { label: "구독 고객 수", desc: "(지난 한 달간 신규 구독 및 갱신 고객 수)" },
    newNumOfSubsAtLastMonth: { label: "신규 구독 수", desc: "(지난 한달간)" },
    numOfUnsubsAtLastMonth: { label: "구독 해지 수", desc: "(지난 한달간)" },
    subsBounceRate: {
        label: "구독 이탈율",
        desc: "(지난 한 달간 구독 해지수/지지난 달의 구독 고객수)"
    },
    totalMonthlySubsFee: {
        label: "월 구독료",
        desc: "(지난 한 달간 신규, 갱신 구독 고객의 결제액 총합)"
    },
    averageSubsFee: { label: "평균 구독료", desc: "(지난 달 월 구독료/지난 달 구독 고객 수)" },
    customerValue: { label: "고객 생애 가치", desc: "(지난 달 편균 구독료/지난 달 구독 이탈율)" }
};

export const POINT_REQUEST_DENY_REASON = [
    { label: "계좌 정보 불일치", value: "bankAccountInfo" },
    { label: "주민번호 불일치", value: "idCardNum" },
    { label: "출금 취소 요청", value: "cancelRequest" },
    { label: "블랙리스트", value: "blackList" },
    { label: "리뷰 유지기간 미준수", value: "notKeepReview" },
    { label: "부적절한 서비스 이용", value: "inappropriateReason" }
];

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { getPostList } from "stores/modules/post";
import { convertTarget } from "lib/common";
import { FAQ_REVIEWER_TAG, FAQ_BIZ_TAG } from "scheme/Scheme";

import "./FaqSearch.scss";

const faqInfoList = [{ label: "키워드", value: "search" }];
const FaqSearch = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { query, search } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;

    const [selectedOptions, setSelectedOptions] = useState({
        target: query.target || undefined,
        tag: query.tag || undefined,
        search: query.search || undefined
    });

    const onGetList = () => {
        dispatch(getPostList({ ...query, type: "faq", skip: Number(query.skip) || 0 }));
    };

    const onSearch = () => {
        const newQuery = createSearchQuery(selectedOptions);
        history.replace({ path: "/faq/list", search: newQuery });
    };

    const onChangeOption = e => {
        const { name, value } = e.target;
        const filteredValue = value === "all" || !value ? undefined : value;
        setSelectedOptions(prev => ({ ...prev, [name]: filteredValue }));
    };

    useEffect(() => {
        onGetList();
    }, [search]);

    return (
        <div className="faq-search">
            <ul className="search-items">
                <li className="search-item">
                    <h3>대상</h3>
                    <Select
                        id="faq-filter-target"
                        value={selectedOptions.target || "all"}
                        name="target"
                        onChange={onChangeOption}
                    >
                        <MenuItem value="all">전체</MenuItem>
                        {["reviewer", "biz"].map((target, index) => (
                            <MenuItem value={target} key={index}>
                                {convertTarget(target)}
                            </MenuItem>
                        ))}
                    </Select>
                </li>
                <li className="search-item">
                    <h3>태그</h3>
                    <Select
                        id="faq-filter-tag"
                        value={selectedOptions.tag || "all"}
                        name="tag"
                        onChange={onChangeOption}
                    >
                        <MenuItem value="all">전체</MenuItem>
                        {FAQ_REVIEWER_TAG.map((tag, index) => (
                            <MenuItem value={tag.value} key={index}>
                                [리뷰어] {tag.label}
                            </MenuItem>
                        ))}
                        {FAQ_BIZ_TAG.map((tag, index) => (
                            <MenuItem value={tag.value} key={index}>
                                [비즈니스] {tag.label}
                            </MenuItem>
                        ))}
                    </Select>
                </li>
                {faqInfoList.map((item, index) => (
                    <li className="search-item" key={index}>
                        <h3>{item.label}</h3>
                        <input
                            type="text"
                            name={item.value}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    search();
                                }
                            }}
                            defaultValue={query[item.value]}
                            onChange={onChangeOption}
                        />
                    </li>
                ))}
            </ul>
            <div className="btn-area">
                <Button
                    className="search-btn"
                    variant="contained"
                    color="primary"
                    onClick={onSearch}
                >
                    검색
                </Button>
            </div>
        </div>
    );
};
export default FaqSearch;

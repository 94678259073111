export const convertCategory = category => {
    switch (category) {
        case "card":
            return "카드";
        case "deposit":
            return "무통장입금(가상계좌)";
        case "trans":
            return "실시간 계좌이체";
        default:
            return category;
    }
};

export const convertPaymentStatus = status => {
    switch (status) {
        case "ready":
            return "대기";
        case "pass":
            return "통과";
        case "point":
            return "포인트 결제";
        case "refund":
            return "포인트 환급";
        case "subtraction":
            return "포인트 차감";
        case "event":
            return "웰컴포인트";
        case "kakaoPay":
            return "카카오페이";
        case "cancelled":
            return "소멸";
        default:
            return status;
    }
};

export const convertChargeStatus = status => {
    switch (status) {
        case "ready":
            return "대기";
        case "paid":
            return "완료";
        case "cancelled":
            return "취소";
        default:
            return status;
    }
};

export const convertReceiptType = type => {
    switch (type) {
        case "TaxBill":
            return "세금계산서";
        case "EvidenceBiz":
            return "현금영수증(사업자 지출증빙)";
        case "InComeDeduct":
            return "현금영수증(소득공제)";
        case "None":
            return "미발행";
        default:
            return type;
    }
};

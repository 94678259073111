import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";
import Checkbox from "@material-ui/core/Checkbox";
import { TableContainer, Table, TableHead, TableRow, TableBody } from "@material-ui/core";

import { removeComment } from "stores/modules/comment";
import ContactUsSearchContainer from "containers/contactUs/ContactUsSearchContainer";
import CustomTableCell from "components/common/CustomTableCell";
import ListWrapper from "components/common/ListWrapper";
import { convertRequestStatus, convertRequestType } from "lib/common";

const tableHeaderList = {
    request: [
        { label: "ID", align: "center" },
        { label: "상태", align: "center" },
        { label: "문의 유형", align: "center" },
        { label: "제목", align: "center" },
        { label: "작성인", align: "center" },
        { label: "작성일", align: "center" }
    ],

    requestCall: [
        { label: "ID", align: "center" },
        { label: "상태", align: "center" },
        { label: "상담 내용", align: "center" },
        { label: "희망 날짜", align: "center" },
        { label: "전화번호", align: "center" },
        { label: "작성인", align: "center" },
        { label: "작성일", align: "center" }
    ]
};

const ContactUsListContainer = ({ type }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState([]);

    const params = queryString.parse(location.search);
    const totalCount = useSelector(({ comment }) => comment.totalCount);
    const commentList = useSelector(({ comment }) => comment.list);

    const setPage = pageNum => {
        params.page = pageNum;
        const query = queryString.stringify(params);
        history.push(`${location.pathname}?${query}`);
    };

    const onHandleCheck = id => {
        if (!id) return;

        const _selectItem = [...selectedItem];
        const index = _selectItem.indexOf(id);

        if (index > -1) {
            _selectItem.splice(index, 1);
        } else {
            _selectItem.push(id);
        }

        setSelectedItem(_selectItem);
    };

    const onRemove = () => {
        dispatch(removeComment(selectedItem));
    };

    useEffect(() => {
        if (params.page) {
            setCurrentPage(Number(params.page));
        }
    }, [params.page]);

    useEffect(() => {
        setSelectedItem([]);
    }, [location.search]);

    return (
        <>
            <ContactUsSearchContainer type={type} />
            <ListWrapper
                currentPage={currentPage}
                handlePage={(e, pageNum) => setPage(pageNum)}
                pageLimit={30}
                totalCount={totalCount}
                onRemove={onRemove}
            >
                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>
                                    <Checkbox color="primary" />
                                </CustomTableCell>
                                {tableHeaderList[type].map((headerItem, index) => (
                                    <CustomTableCell
                                        align={headerItem.align}
                                        key={index}
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        {headerItem.label}
                                    </CustomTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {commentList &&
                                commentList.length > 0 &&
                                commentList.map((comment, index) => {
                                    const isSelected = selectedItem.indexOf(comment.requestId) > -1;

                                    return (
                                        <TableRow
                                            key={index}
                                            hover
                                            onClick={() =>
                                                history.push(
                                                    `${location.pathname}/${comment.requestId}`
                                                )
                                            }
                                        >
                                            <CustomTableCell width={20}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={isSelected}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        onHandleCheck(comment.requestId);
                                                    }}
                                                />
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width={20}
                                            >
                                                {comment.requestId}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width={80}
                                            >
                                                {convertRequestStatus(comment.status)}
                                            </CustomTableCell>
                                            {type === "request" && (
                                                <CustomTableCell
                                                    align="center"
                                                    style={{ cursor: "pointer" }}
                                                    width={200}
                                                >
                                                    {convertRequestType(comment.type)}
                                                </CustomTableCell>
                                            )}
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width={200}
                                            >
                                                {comment.title}
                                            </CustomTableCell>
                                            {type === "requestCall" && (
                                                <CustomTableCell
                                                    align="center"
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            textOverflow: "ellipsis",
                                                            maxWidth: "400px",
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap"
                                                        }}
                                                    >
                                                        {comment.desiredDate || "-"}
                                                    </p>
                                                </CustomTableCell>
                                            )}
                                            {type === "requestCall" && (
                                                <CustomTableCell
                                                    align="center"
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <p>{comment.requestPhoneNumber}</p>
                                                </CustomTableCell>
                                            )}
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width={200}
                                            >
                                                {comment.name || comment.owner}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width={200}
                                            >
                                                {moment(comment.createdAt).format(
                                                    "YYYY-MM-DD HH:mm"
                                                )}
                                            </CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {totalCount === 0 && (
                    <p style={{ textAlign: "center", padding: "80px 0" }}>1:1 문의가 없습니다.</p>
                )}
            </ListWrapper>
        </>
    );
};

export default ContactUsListContainer;

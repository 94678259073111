import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";

import "./SearchFilterItems.scss";

const SearchFilterItems = ({ value, items, onSearch }) => {
    const history = useHistory();
    const location = useLocation();
    const { search, query } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;

    const [selectedOptions, setSelectedOptions] = useState(query);

    const changeOption = e => {
        const { name, value } = e.target;
        const filteredValue = value === "none" || !value ? undefined : value;
        setSelectedOptions(prev => ({ ...prev, [name]: filteredValue }));
    };

    const searchResult = () => {
        const newQuery = createSearchQuery(selectedOptions);
        history.replace({ path: location.pathname, search: newQuery });
    };

    useEffect(() => {
        if (onSearch) {
            onSearch({ ...selectedOptions, skip: Number(query.skip) || 0 });
        }
    }, [search, location.pathname]);

    if (!value || !items?.length) return null;
    return (
        <div id={`${value}-filter`} className="common-filter-items">
            <fieldset>
                {items.map((item, index) => (
                    <div className="field" key={index}>
                        <label htmlFor={`${value}-filter-${item.value}`}>{item.label}</label>
                        {item.inputType === "text" && (
                            <input
                                type="text"
                                id={`${value}-filter-${item.value}`}
                                name={item.value}
                                value={selectedOptions[item.value]}
                                onKeyDown={e => {
                                    if (e.keyCode === 13) {
                                        searchResult();
                                    }
                                }}
                                onChange={changeOption}
                            />
                        )}
                        {item.inputType === "select" && (
                            <Select
                                id={`${value}-filter-${item.value}`}
                                value={selectedOptions[item.value] || "none"}
                                name={item.value}
                                onChange={changeOption}
                            >
                                <MenuItem value="none">{item.label} 전체</MenuItem>
                                {item.array.map((selectItem, selectIndex) => (
                                    <MenuItem value={selectItem} key={selectIndex}>
                                        {item.convertFn ? item.convertFn(selectItem) : selectItem}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        {item.inputType === "date" && (
                            <TextField
                                type="date"
                                value={selectedOptions[item.value] || undefined}
                                inputProps={{
                                    name: item.value
                                }}
                                onChange={changeOption}
                            />
                        )}
                    </div>
                ))}
            </fieldset>
            <div className="button-box">
                <Button variant="contained" color="primary" onClick={searchResult}>
                    검색
                </Button>
            </div>
        </div>
    );
};

export default SearchFilterItems;

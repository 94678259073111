import React from "react";
import BannerListContainer from "containers/bannerList/BannerListContainer";
import BannerContainer from "containers/bannerList/BannerContainer";

const BannerListPage = () => (
    <>
        <BannerListContainer />
        <BannerContainer />
    </>
);

export default BannerListPage;

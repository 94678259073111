import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import produce from "immer";
import createRequestSaga, { createRequestActionTypes } from "lib/createRequestSaga";
import * as projectAPI from "lib/api/project";

const [SEARCH_PROJECT, SEARCH_PROJECT_SUCCESS, SEARCH_PROJECT_FAILURE] = createRequestActionTypes(
    "/project/SEARCH_PROJECT"
);

const [
    GET_PROJECT_DETAIL,
    GET_PROJECT_DETAIL_SUCCESS,
    GET_PROJECT_DETAIL_FAILURE
] = createRequestActionTypes("/project/GET_PROJECT_DETAIL");
const [REMOVE_PROJECT, REMOVE_PROJECT_SUCCESS, REMOVE_PROJECT_FAILURE] = createRequestActionTypes(
    "/project/REMOVE_PROJECT"
);
const [
    SET_PICK_PROJECT,
    SET_PICK_PROJECT_SUCCESS,
    SET_PICK_PROJECT_FAILURE
] = createRequestActionTypes("/project/SET_PICK_PROJECT");
const [
    SET_REREVIEWING_STATUS,
    SET_REREVIEWING_STATUS_SUCCESS,
    SET_REREVIEWING_STATUS_FAILURE
] = createRequestActionTypes("/project/SET_REREVIEWING_STATUS");

const RESET_PROJECT_DETAIL = "/project/RESET_PROJECT_DETAIL";
const HANDLE_PAGE_NUMBER = "/project/HANDLE_PAGE_NUMBER";

export const resetProjectDetail = createAction(RESET_PROJECT_DETAIL);
export const searchProject = createAction(SEARCH_PROJECT, searchQuery => searchQuery);

export const getProjectDetail = createAction(GET_PROJECT_DETAIL, projectId => projectId);
export const removeProject = createAction(REMOVE_PROJECT);
export const setPickProject = createAction(SET_PICK_PROJECT);
export const setRereviewingStatus = createAction(SET_REREVIEWING_STATUS);

export const handlePageNumber = createAction(HANDLE_PAGE_NUMBER);

const searchProjectSaga = createRequestSaga(SEARCH_PROJECT, projectAPI.getProjectList);
const getProjectDetailSaga = createRequestSaga(GET_PROJECT_DETAIL, projectAPI.getProject);
const removeProjectSaga = createRequestSaga(REMOVE_PROJECT, projectAPI.removeProject);
const setPickProjectSaga = createRequestSaga(SET_PICK_PROJECT, projectAPI.setPickProject);
const setRereviewingStatusSaga = createRequestSaga(
    SET_REREVIEWING_STATUS,
    projectAPI.setRereviewingStatus
);

export function* projectSaga() {
    yield takeLatest(SEARCH_PROJECT, searchProjectSaga);
    yield takeLatest(GET_PROJECT_DETAIL, getProjectDetailSaga);
    yield takeLatest(REMOVE_PROJECT, removeProjectSaga);
    yield takeLatest(SET_PICK_PROJECT, setPickProjectSaga);
    yield takeLatest(SET_REREVIEWING_STATUS, setRereviewingStatusSaga);
}

const initialState = {
    searchQuery: {},
    pageNumber: 1,
    projectList: {
        list: [],
        totalCount: 0
    },
    projectDetail: {},
    reReviewingProjectDetail: {},
    projectDetailError: null,
    projectListError: null
};

const project = handleActions(
    {
        [RESET_PROJECT_DETAIL]: state => ({
            ...state,
            projectDetail: initialState.projectDetail,
            reReviewingProjectDetail: initialState.reReviewingProjectDetail
        }),
        [SEARCH_PROJECT_SUCCESS]: (state, { payload: projectList }) => ({
            ...state,
            projectList
        }),
        [SEARCH_PROJECT_FAILURE]: (state, { payload: error }) => ({
            ...state,
            projectListError: error.response
        }),
        [handlePageNumber]: (state, { payload }) => ({
            ...state,
            pageNumber: payload
        }),
        [GET_PROJECT_DETAIL_SUCCESS]: (state, { payload }) => ({
            ...state,
            projectDetail: { ...payload, rereviewingProject: undefined },
            reReviewingProjectDetail:
                payload.rereviewingProject || initialState.reReviewingProjectDetail
        }),
        [GET_PROJECT_DETAIL_FAILURE]: (state, { payload }) => ({
            ...state,
            projectDetailError: payload
        }),
        [REMOVE_PROJECT_SUCCESS]: (state, { prev }) =>
            produce(state, draft => {
                draft.projectList.list = state.projectList.list.map(item => {
                    if (prev.indexOf(item._id) > -1) {
                        return { ...item, isDeleted: true };
                    }
                    return item;
                });
            }),
        [SET_PICK_PROJECT_SUCCESS]: (state, { prev }) =>
            produce(state, draft => {
                draft.projectList.list = state.projectList.list.map(item => {
                    if (prev.idList.indexOf(item._id) > -1) {
                        return {
                            ...item,
                            isPick: prev.process === "active"
                        };
                    }
                    return item;
                });
            }),
        [SET_REREVIEWING_STATUS_SUCCESS]: (state, { prev }) =>
            produce(state, draft => {
                draft.projectList.list = state.projectList.list.map(item => {
                    if (item._id === prev.projectId) {
                        return {
                            ...item,
                            subStatus: undefined
                        };
                    }
                    return item;
                });
            })
    },
    initialState
);

export default project;

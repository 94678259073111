import React from "react";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Color } from "theme";

import "./ReviewerScoreRecordCtrl.scss";

const ReviewerScoreRecordCtrl = ({
    searchForm,
    setScoreForm,
    onSearch,
    setScoreRecord,
    selectedReason,
    onSelectReason,
    showScoreValueField
}) => (
    <div className="reviewer-score-record-ctrl">
        <div className="search-area">
            <div className="field row">
                <p className="field-title">프로젝트 ID</p>
                <div className="field-content">
                    <input
                        type="text"
                        placeholder="필수"
                        name="projectId"
                        ref={searchForm}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onSearch({ newSearch: true });
                            }
                        }}
                    />
                </div>
            </div>
            <div className="btn-area">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onSearch({ newSearch: true })}
                >
                    검색
                </Button>
            </div>
        </div>
        <div className="add-score-record-area">
            <ul>
                <li className="field row">
                    <p className="field-title">사유</p>
                    <div className="field-content">
                        <div className="reason-input-wrapper">
                            <input
                                type="text"
                                name="reasonByAdmin"
                                ref={setScoreForm}
                                className="reason-input"
                                placeholder="필수"
                            />
                            <Select
                                value={selectedReason}
                                onChange={onSelectReason}
                                className="reason-list"
                            >
                                <MenuItem value={"etc"}>기타</MenuItem>
                                <MenuItem value={"notFollow"}>가이드 라인 불이행</MenuItem>
                                <MenuItem value={"noShow"}>연락두절 또는 노쇼</MenuItem>
                                <MenuItem value={"cheat"}>허위 URL/비공개 또는 삭제</MenuItem>
                            </Select>
                        </div>
                    </div>
                </li>
                {showScoreValueField && (
                    <li className="field row">
                        <p className="field-title">점수</p>
                        <div className="field-content">
                            <input
                                type="number"
                                name="value"
                                ref={setScoreForm}
                                placeholder="필수"
                            />
                        </div>
                    </li>
                )}
                <li className="field row">
                    <p className="field-title">프로젝트 ID</p>
                    <div className="field-content">
                        <input
                            type="number"
                            name="projectId"
                            ref={setScoreForm}
                            placeholder="옵션"
                        />
                    </div>
                </li>
            </ul>
            <div className="btn-area">
                <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: Color.greenDark }}
                    onClick={setScoreRecord}
                >
                    추가
                </Button>
            </div>
        </div>
    </div>
);

export default ReviewerScoreRecordCtrl;

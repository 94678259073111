import { createAction, handleActions } from "redux-actions";
import { takeLeading } from "redux-saga/effects";
import produce from "immer";
import {
    createRequestActionTypes,
    createRequestSagaWithAlert,
    createRequestSagaBase
} from "lib/createRequestSaga";
import * as userAPI from "lib/api/user";
import { getBizSubsInfo as getBizSubsInfoAPI } from "lib/api/subscription";

// eslint-disable-next-line
const [SEARCH_USER, SEARCH_USER_SUCCESS, SEARCH_USER_FAILURE] = createRequestActionTypes(
    "/user/SEARCH_USER"
);
const SELECT_USER = "/user/SELECT_USER";
// eslint-disable-next-line
const [GET_USER_INFO, GET_USER_INFO_SUCCESS, GET_USER_INFO_FAILURE] = createRequestActionTypes(
    "user/GET_GET_USER_INFO"
);
const [
    SET_BLOCK_PROJECT_REQUEST,
    SET_BLOCK_PROJECT_REQUEST_SUCCESS,
    SET_BLOCK_PROJECT_REQUEST_FAILURE
] = createRequestActionTypes("user/SET_BLOCK_REQUEST_PROJECT");
const [
    SET_BLOCK_POINT_REQUEST,
    SET_BLOCK_POINT_REQUEST_SUCCESS,
    SET_BLOCK_POINT_REQUEST_FAILURE
] = createRequestActionTypes("user/SET_BLOCK_POINT_PROJECT");
const [
    GET_BIZ_SUBS_INFO,
    GET_BIZ_SUBS_INFO_SUCCESS,
    GET_BIZ_SUBS_INFO_FAILURE
] = createRequestActionTypes("auth/GET_BIZ_SUBS_INFO");

export const searchUser = createAction(SEARCH_USER, searchObj => searchObj);
export const selectUser = createAction(SELECT_USER);
export const getUserInfo = createAction(GET_USER_INFO, userId => userId);
export const setBlockProjectRequest = createAction(SET_BLOCK_PROJECT_REQUEST);
export const setBlockPointRequest = createAction(SET_BLOCK_POINT_REQUEST);
export const getBizSubsInfo = createAction(GET_BIZ_SUBS_INFO);

const searchUserSaga = createRequestSagaWithAlert(
    SEARCH_USER,
    userAPI.getUserList,
    "유저 목록을 가져오는 데 실패하였습니다.",
    true
);
const getUserInfoSaga = createRequestSagaWithAlert(
    GET_USER_INFO,
    userAPI.getUserInfo,
    "회원 정보를 가져오는 데 실패하였습니다.",
    true
);
const setBlockProjectRequestSaga = createRequestSagaBase({
    type: SET_BLOCK_PROJECT_REQUEST,
    api: userAPI.setBlockProjectRequest,
    alertErrorMsg: "프로젝트 신청 제한 변경에 실패하였습니다."
});
const setBlockPointRequestSaga = createRequestSagaBase({
    type: SET_BLOCK_POINT_REQUEST,
    api: userAPI.setBlockPointRequest,
    alertErrorMsg: "출금 신청 제한 변경에 실패하였습니다."
});
const getBizSubsInfoSaga = createRequestSagaWithAlert(GET_BIZ_SUBS_INFO, getBizSubsInfoAPI);

export function* userSaga() {
    yield takeLeading(SEARCH_USER, searchUserSaga);
    yield takeLeading(GET_USER_INFO, getUserInfoSaga);
    yield takeLeading(SET_BLOCK_PROJECT_REQUEST, setBlockProjectRequestSaga);
    yield takeLeading(SET_BLOCK_POINT_REQUEST, setBlockPointRequestSaga);
    yield takeLeading(GET_BIZ_SUBS_INFO, getBizSubsInfoSaga);
}

const initialState = {
    reviewer: {
        list: [],
        total: 0
    },
    biz: {
        list: [],
        total: 0
    },
    subsInfo: {},
    selectedUser: {
        userId: null,
        userType: null
    },
    userDetail: null
};

const user = handleActions(
    {
        [SEARCH_USER_SUCCESS]: (state, { payload }) => {
            if (payload.userCategory === "reviewer") {
                return {
                    ...state,
                    reviewer: {
                        list: payload.list,
                        total: payload.total
                    }
                };
            } else {
                return {
                    ...state,
                    biz: {
                        list: payload.list,
                        total: payload.total
                    }
                };
            }
        },
        [SELECT_USER]: (state, { payload }) => ({
            ...state,
            selectedUser: {
                userId: payload?.userId || null,
                userType: payload?.userType || null,
                tab: payload?.tab || null
            }
        }),
        [GET_USER_INFO]: state => ({
            ...state,
            userDetail: null
        }),
        [GET_USER_INFO_SUCCESS]: (state, { payload }) => ({
            ...state,
            userDetail: payload.user
        }),
        [SET_BLOCK_PROJECT_REQUEST_SUCCESS]: (state, { prev }) => ({
            ...state,
            userDetail: { ...state.userDetail, blockProjectRequest: prev.process === "assign" }
        }),
        [SET_BLOCK_POINT_REQUEST_SUCCESS]: (state, { prev }) =>
            produce(state, draft => {
                draft.userDetail.reviewerStatus.blockPointRequest = prev.blockStatus === "block";
            }),
        [GET_BIZ_SUBS_INFO_SUCCESS]: (state, { payload }) => ({
            ...state,
            subsInfo: payload
        })
    },
    initialState
);

export default user;

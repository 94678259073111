import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@material-ui/core/Modal";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { IconFont } from "reviewshare-common-component";
import { Color } from "theme";
import { convertBannerPosition } from "lib/utils/banner";
import { BANNER_POSITION_LIST } from "scheme/banner";

import "./BannerModal.scss";

const TARGET_SCHEME = [
    {
        label: "리뷰어",
        value: "reviewer"
    },
    {
        label: "비즈니스",
        value: "business"
    },
    {
        label: "비회원",
        value: "visitor"
    }
];
const BannerModal = ({ data = {}, isCreate, closeModal, createBanner, editBanner }) => {
    const { register, getValues, setValue } = useForm({
        shouldUnregister: false
    });
    const [preview, setPreview] = useState(["", ""]);
    const [fileList, setFileList] = useState(["", ""]);

    useEffect(() => {
        if (data._id) {
            setValue("target", data.target);
        }
        // eslint-disable-next-line
    }, []);

    const onUploadImage = (file, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onloadend = () => {
            const fileArray = [...fileList];
            fileArray[index] = file[0];

            const previewArray = preview;
            previewArray[index] = reader.result;

            setFileList(fileArray);
            setPreview(previewArray);
        };
    };

    const submit = type => {
        if (!type) return;
        const formValues = getValues();
        const info = { ...formValues, fileList };

        console.log(info);

        if (type === "create") {
            createBanner(info);
        } else if (type === "edit") {
            info._id = data._id;
            editBanner(info);
        }
    };

    return (
        <Modal open onClose={closeModal}>
            <div id="create-banner-modal">
                <h2 className="modal-title">{data?._id ? `배너 ID ${data._id}` : "배너 생성"}</h2>
                <div className="modal-content">
                    <div className="field">
                        <p className="field-title">이미지</p>
                        <div className="field-content">
                            <div className="upload-image-item">
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="create-banner-pc-image"
                                    style={{ display: "none" }}
                                    onChange={e => onUploadImage(e.target.files, 0)}
                                />
                                <label htmlFor="create-banner-pc-image">
                                    <p>(pc)</p>
                                    <div
                                        className="upload-image-box"
                                        style={{
                                            backgroundImage: `url(${preview[0] ||
                                                data.photos?.[0].secure_url})`
                                        }}
                                    >
                                        <IconFont icon="icon_plus">추가</IconFont>
                                    </div>
                                </label>
                            </div>
                            <div className="upload-image-item">
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="create-banner-mobile-image"
                                    style={{ display: "none" }}
                                    onChange={e => onUploadImage(e.target.files, 1)}
                                />
                                <label htmlFor="create-banner-mobile-image">
                                    <p>(mobile)</p>
                                    <div
                                        className="upload-image-box"
                                        style={{
                                            backgroundImage: `url(${preview[1] ||
                                                data?.photos?.[1].secure_url})`
                                        }}
                                    >
                                        <IconFont icon="icon_plus">추가</IconFont>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="field position">
                        <p className="field-title">
                            <label>위치</label>
                        </p>
                        <div className="field-content">
                            {BANNER_POSITION_LIST.map(position => (
                                <label className="checkbox-item">
                                    <input
                                        type="radio"
                                        id={`create-banner-position-${position}`}
                                        name="position"
                                        value={position}
                                        defaultChecked={
                                            isCreate
                                                ? position === "mainBottom"
                                                : data.position === position
                                        }
                                        ref={register}
                                    />
                                    {convertBannerPosition(position)}
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className="input-items">
                        <div className="field name">
                            <p className="field-title">
                                <label htmlFor="create-banner-alt">배너명</label>
                            </p>
                            <div className="field-content">
                                <input
                                    type="text"
                                    id="create-banner-name"
                                    placeholder="리뷰쉐어 ios 앱 출시 배너"
                                    name="name"
                                    defaultValue={data.name}
                                    ref={register}
                                />
                            </div>
                        </div>
                        <div className="field alt">
                            <p className="field-title">
                                <label htmlFor="create-banner-name">대체 텍스트(웹 접근성)</label>
                            </p>
                            <div className="field-content">
                                <input
                                    type="text"
                                    id="create-banner-alt"
                                    placeholder="이미지에 적힌 텍스트를 입력해주세요"
                                    name="alt"
                                    defaultValue={data.alt}
                                    ref={register}
                                />
                            </div>
                        </div>
                        <div className="field link">
                            <p className="field-title">
                                <label htmlFor="create-banner-link">링크</label>
                            </p>
                            <div className="field-content">
                                <input
                                    type="text"
                                    id="create-banner-link"
                                    placeholder="https://reviewshare.io"
                                    name="link"
                                    defaultValue={data.link}
                                    ref={register}
                                />
                            </div>
                        </div>
                        <div className="field color">
                            <p className="field-title">
                                <label htmlFor="create-banner-color">색상</label>
                            </p>
                            <div className="field-content">
                                <input
                                    type="text"
                                    id="create-banner-color"
                                    placeholder="#000000"
                                    name="backgroundColor"
                                    defaultValue={data.backgroundColor}
                                    ref={register}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="field">
                        <p className="field-title">노출 대상</p>
                        <div className="field-content">
                            {TARGET_SCHEME.map((target, index) => (
                                <div className="checkbox-item" key={index}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                value={target.value}
                                                inputRef={register}
                                                inputProps={{ name: "target" }}
                                                defaultChecked={
                                                    data.target?.indexOf(target.value) > -1
                                                }
                                            />
                                        }
                                        label={target.label}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="field row">
                        <p className="field-title">노출 여부 (활성화)</p>
                        <div className="field-content">
                            <Checkbox
                                color="primary"
                                defaultChecked={isCreate ? true : data.isShowing}
                                inputRef={register}
                                inputProps={{ name: "isShowing" }}
                            />
                        </div>
                    </div>
                    <div className="field index">
                        <p className="field-title">
                            <label htmlFor="create-banner-index">
                                순서 (값이 높을수록 앞에 위치)
                            </label>
                        </p>
                        <div className="field-content">
                            <input
                                type="number"
                                id="create-banner-index"
                                placeholder="0"
                                name="index"
                                defaultValue={data.index}
                                ref={register}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    {isCreate ? (
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: Color.greenDark }}
                            onClick={() => submit("create")}
                        >
                            추가
                        </Button>
                    ) : (
                        <Button variant="contained" color="primary" onClick={() => submit("edit")}>
                            수정
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default BannerModal;

import React from "react";
import moment from "moment";
import { Modal } from "@material-ui/core";
import { Tag } from "reviewshare-common-component";
import { numberWithCommas, returnTagColor } from "lib/common";
import { convertOrderStatus } from "lib/utils/order";
import { useOrderState, useOrderAction } from "lib/customHooks/useOrder";

import "./OrderModal.scss";

const OrderModal = () => {
    const { resetOrderDetail } = useOrderAction();
    const data = useOrderState("detail");

    const onClose = () => {
        resetOrderDetail();
    };

    return data._id ? (
        <Modal open onClose={onClose}>
            <div id="order-detail-modal">
                <div className="modal-title">
                    <h2>거래 ID {data._id}</h2>
                    <Tag className="size-l" color={returnTagColor(data.status)}>
                        {convertOrderStatus(data.status)}
                    </Tag>
                </div>
                <div className="modal-content">
                    <fieldset>
                        <div className="field-box">
                            <div className="field">
                                <p className="field-title">서비스 ID</p>
                                <div className="field-content">{data.talentId}</div>
                            </div>
                            <div className="field">
                                <p className="field-title">리뷰어</p>
                                <div className="field-content">
                                    {data.talentOwner?._id}
                                    {" - "}
                                    {data.talentOwner?.nickname}({data.talentOwner.name})
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">비즈니스</p>
                                <div className="field-content">
                                    {data.owner?._id}
                                    {" - "}
                                    {data.owner?.name}
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">브랜드</p>
                                <div className="field-content">
                                    {data.brand?.id}
                                    {" - "}
                                    {data.brand?.name}
                                </div>
                            </div>
                        </div>
                        <div className="field-box">
                            <div className="field row">
                                <p className="field-title">결제 여부</p>
                                <div className="field-content">
                                    {data.paymentStatus === "refund" ? (
                                        <Tag color="red-lighter" className="size-l">
                                            환불 완료
                                        </Tag>
                                    ) : data.paymentStatus === "paid" ? (
                                        <Tag color="blue-lighter" className="size-l">
                                            지급 완료
                                        </Tag>
                                    ) : data.paymentStatus === "complete" ? (
                                        <Tag color="blue-lighter" className="size-l">
                                            결제 완료
                                        </Tag>
                                    ) : null}
                                </div>
                            </div>
                            <div className="field row">
                                <p className="field-title">실제 결제 금액</p>
                                <div className="field-content">
                                    {numberWithCommas(data.price && data.price.realAmount)}P
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="field">
                                <p className="field-title">요청 사항</p>
                                <div className="field-content">
                                    <textarea value={data.requestMessage} disabled />
                                </div>
                            </div>
                            {data.rejectMessage && (
                                <div className="field">
                                    <p className="field-title">거절 메시지</p>
                                    <div className="field-content">
                                        <textarea value={data.rejectMessage} disabled />
                                    </div>
                                </div>
                            )}
                            {data.results && data.results.length > 0 && (
                                <div className="field result">
                                    <p className="field-title">결과물 </p>
                                    <ul className="field-content">
                                        {data.results.map((item, index) => (
                                            <li key={index}>
                                                {item.type}:{" "}
                                                <a
                                                    href={
                                                        item.type === "url"
                                                            ? item.value
                                                            : item.id?.url
                                                    }
                                                    target="_blank"
                                                    rel="nosublink noopener noreferrer"
                                                >
                                                    {item.type === "url"
                                                        ? item.value
                                                        : item.id?.filename}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {data.cancelMessage && (
                                <div className="field">
                                    <p className="field-title">취소 메시지</p>
                                    <div className="field-content">
                                        <textarea value={data.cancelMessage} disabled />
                                    </div>
                                </div>
                            )}
                            {data.statusDate && data.statusDate.request && (
                                <div className="field">
                                    <p className="field-title">거래 요청 날짜</p>
                                    <div className="field-content">
                                        {moment(data.statusDate.request).format("YYYY-MM-DD HH:mm")}
                                    </div>
                                </div>
                            )}
                            {data.statusDate && data.statusDate.running && (
                                <div className="field">
                                    <p className="field-title">거래 수락 날짜</p>
                                    <div className="field-content">
                                        {moment(data.statusDate.running).format("YYYY-MM-DD HH:mm")}
                                    </div>
                                </div>
                            )}
                            {data.statusDate && data.statusDate.reject && (
                                <div className="field">
                                    <p className="field-title">거래 거절 날짜</p>
                                    <div className="field-content">
                                        {moment(data.statusDate.reject).format("YYYY-MM-DD HH:mm")}
                                    </div>
                                </div>
                            )}
                            {data.statusDate && data.statusDate.requestCancel && (
                                <div className="field">
                                    <p className="field-title">거래 취소 (비즈니스) 날짜</p>
                                    <div className="field-content">
                                        {moment(data.statusDate.requestCancel).format(
                                            "YYYY-MM-DD HH:mm"
                                        )}
                                    </div>
                                </div>
                            )}
                            {data.statusDate && data.statusDate.cancel && (
                                <div className="field">
                                    <p className="field-title">거래 취소 (어드민) 날짜</p>
                                    <div className="field-content">
                                        {moment(data.statusDate.cancel).format("YYYY-MM-DD HH:mm")}
                                    </div>
                                </div>
                            )}
                            {data.statusDate && data.statusDate.submit && (
                                <div className="field">
                                    <p className="field-title">결과물 제출 날짜</p>
                                    <div className="field-content">
                                        {moment(data.statusDate.submit).format("YYYY-MM-DD HH:mm")}
                                    </div>
                                </div>
                            )}
                            {data.statusDate && data.statusDate.complete && (
                                <div className="field">
                                    <p className="field-title">거래 완료 날짜</p>
                                    <div className="field-content">
                                        {moment(data.statusDate.complete).format(
                                            "YYYY-MM-DD HH:mm"
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </fieldset>
                </div>
            </div>
        </Modal>
    ) : null;
};

export default OrderModal;

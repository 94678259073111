import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { Color } from "theme";
import SelectBiz from "components/common/SelectBiz";
import SelectUserType from "components/coupon/SelectUserType";
import EnterCouponInfo from "components/coupon/EnterCouponInfo";
import { createCoupon as createCouponAction } from "stores/modules/coupon";

import "./CreateCouponModal.scss";

const defaultValues = {
    targetUserIdList: [],
    targetService: "project",
    name: "",
    startedAt: moment().format("YYYY-MM-DD"),
    endAt: moment().format("YYYY-MM-DD"),
    discountRate: 0,
    minSpendPrice: 0,
    maxDiscountPrice: 0,
    isSendingNoti: true
};
const CreateCouponModal = ({ closeModal }) => {
    const dispatch = useDispatch();
    const { register: createForm, getValues } = useForm({
        defaultValues,
        shouldUnregister: false
    });
    const [targetUserIdList, setTargetUserIdList] = useState([]);

    const createCoupon = () => {
        const {
            targetService,
            name,
            startedAt,
            endAt,
            discountRate,
            minSpendPrice,
            maxDiscountPrice,
            isSendingNoti
        } = getValues();

        if (!targetService || !name || !startedAt || !endAt || !discountRate) {
            window.alert("입력값을 확인해주세요");
        }

        const values = {
            targetUserIdList,
            targetService,
            name,
            startedAt,
            endAt,
            discountRate,
            minSpendPrice,
            maxDiscountPrice,
            isSendingNoti
        };

        dispatch(createCouponAction(values));

        console.log(values);
    };

    const selectBizList = bizList => {
        setTargetUserIdList(bizList);
    };

    return (
        <Modal open onClose={closeModal}>
            <div id="create-coupon-modal">
                <h2 className="modal-title">쿠폰 생성</h2>
                {/* <input ref={createForm} name="targetUserIdList" /> */}
                <div className="modal-content">
                    <SelectUserType>
                        <SelectBiz onChange={selectBizList} />
                    </SelectUserType>
                    <EnterCouponInfo createForm={createForm} />
                </div>
                <div className="modal-footer">
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: Color.greenDark }}
                        onClick={createCoupon}
                    >
                        생성
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CreateCouponModal;

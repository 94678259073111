import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconFont } from "reviewshare-common-component";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import { callDirectApi } from "lib/common";
import { createTag as createTagAPI, removeTag as removeTagAPI } from "lib/api/tag";

import style from "./TagItem.module.scss";

const cx = classnames.bind(style);

// mode: ["view", "edit"];
const TagItem = ({ mode = "view", data, isSelected, selectTag }) => {
    const { register, getValues } = useForm({});
    const [localData, setLocalData] = useState({});
    const [editMode, setEditMode] = useState(false);

    const onEditTag = async () => {
        if (!editMode) {
            setEditMode(true);
            return;
        }
        const { name, isPublic } = getValues();
        const formattedName = name.replace(/( )/g, "");

        if (!formattedName) return;
        try {
            const res = await callDirectApi(
                createTagAPI,
                { id: data._id, name: formattedName, isPublic, usage: "user" },
                true
            );
            setEditMode(false);
            setLocalData(res.data);
        } catch (e) {
            window.alert("태그 수정에 실패했습니다.");
        }
    };

    const onRemoveTag = async () => {
        const confirm = window.confirm("해당 태그를 삭제하시겠습니까?");
        if (!confirm) return;
        try {
            await callDirectApi(removeTagAPI, data._id, true);
            setLocalData({});
        } catch (e) {
            window.alert("태그 삭제에 실패했습니다.");
        }
    };

    useEffect(() => {
        setLocalData(data);
    }, [data]);

    if (!localData?._id) return null;
    return (
        <li
            className={cx(style.tagItem, {
                [style.active]: isSelected
            })}
        >
            <div
                className={style.tagSelectArea}
                onClick={() => {
                    if (!editMode) {
                        selectTag();
                    }
                }}
            >
                <Checkbox
                    inputProps={{
                        name: "isPublic"
                    }}
                    inputRef={register}
                    icon={<VisibilityOffIcon />}
                    checkedIcon={<VisibilityIcon />}
                    className={cx(style.publicCheckbox, { [style.active]: editMode })}
                    defaultChecked={localData.isPublic}
                />
                {editMode ? (
                    <input
                        type="text"
                        name="name"
                        defaultValue={localData.name}
                        placeholder="태그명 입력"
                        ref={register}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onEditTag();
                            }
                        }}
                    />
                ) : (
                    <>
                        <Checkbox color="primary" checked={isSelected} />
                        <span className={style.publicIcon}>
                            {localData.isPublic ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </span>
                        <span className={style.tagName}>{localData.name}</span>
                    </>
                )}
            </div>
            {mode === "edit" && (
                <>
                    <button type="button" className={style.editBtn} onClick={onEditTag}>
                        {editMode ? <CheckIcon /> : <EditIcon />}
                    </button>
                    {editMode ? (
                        <button
                            type="button"
                            className={style.removeBtn}
                            onClick={() => setEditMode(false)}
                        >
                            <IconFont icon="icon_close">취소</IconFont>
                        </button>
                    ) : (
                        <button type="button" className={style.removeBtn} onClick={onRemoveTag}>
                            <DeleteIcon />
                        </button>
                    )}
                </>
            )}
        </li>
    );
};

export default TagItem;

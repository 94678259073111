import React from "react";
import { Controller } from "react-hook-form";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useSearchQuery } from "lib/customHooks/useSearchQuery";
import { convertSns } from "lib/utils/sns";

import "./UserSearch.scss";

const userInfoList = [
    { label: "회원 ID", value: "id", target: ["reviewer", "biz"] },
    { label: "닉네임", value: "nickname", target: ["reviewer"] },
    { label: "이름", value: "name", target: ["reviewer", "biz"] },
    { label: "이메일", value: "email", target: ["reviewer", "biz"] },
    { label: "휴대폰 번호", value: "phoneNumber", target: ["reviewer", "biz"] },
    { label: "브랜드명", value: "brandName", target: ["biz"] }
];
const blockFnList = [
    { label: "프로젝트 신청 제한", value: "projectRequest" },
    { label: "출금 신청 제한", value: "pointRequest" }
];
const shortformList = [
    { label: "숏폼 채널", value: "channel" },
    { label: "평균 조회수 최소", value: "minOfAvgViews" },
    { label: "평균 조회수 최대(0은 무제한)", value: "maxOfAvgViews" }
];
const shortformChannel = ["Reels", "Shorts", "Tiktok"];

const UserSearch = ({ category, blockState, setBlockState, searchForm, searchUser, control }) => {
    const { query } = useSearchQuery();

    const onSearchUser = () => {
        searchUser();
    };

    return (
        <div className="user-search">
            <ul className="search-items">
                {userInfoList.map((item, index) => {
                    if (item.target.indexOf(category) === -1) return null;

                    return (
                        <li className="search-item" key={index}>
                            <h3>{item.label}</h3>
                            <input
                                type="text"
                                name={item.value}
                                ref={searchForm}
                                onKeyDown={e => {
                                    if (e.keyCode === 13) {
                                        onSearchUser();
                                    }
                                }}
                                defaultValue={query[item.value]}
                            />
                        </li>
                    );
                })}

                {category === "reviewer" && (
                    <>
                        {blockFnList.map((item, index) => (
                            <li className="search-item row" key={index}>
                                <h3>{item.label}</h3>

                                <Switch
                                    checked={blockState[item.value]}
                                    onClick={() =>
                                        setBlockState(prev => ({
                                            ...prev,
                                            [item.value]: !prev[item.value]
                                        }))
                                    }
                                />
                            </li>
                        ))}
                        {shortformList.map((item, index) => (
                            <li className="search-item" key={index}>
                                <h3>{item.label}</h3>
                                {item.value === "channel" ? (
                                    <Controller
                                        name={item.value}
                                        control={control}
                                        defaultValue={query[item.value] || "all"}
                                        as={
                                            <Select>
                                                <MenuItem value="all">전체</MenuItem>
                                                {shortformChannel.map((channel, cIndex) => (
                                                    <MenuItem value={channel} key={cIndex}>
                                                        {convertSns(channel)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        }
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        name={item.value}
                                        ref={searchForm}
                                        onKeyDown={e => {
                                            if (e.keyCode === 13) {
                                                onSearchUser();
                                            }
                                        }}
                                        defaultValue={query[item.value]}
                                    />
                                )}
                            </li>
                        ))}
                    </>
                )}
            </ul>
            <div className="btn-area">
                <Button
                    className="search-btn"
                    variant="contained"
                    color="primary"
                    onClick={onSearchUser}
                >
                    검색
                </Button>
                <Button variant="contained" onClick={() => searchUser({ init: true })}>
                    초기화
                </Button>
            </div>
        </div>
    );
};
export default UserSearch;

import React from "react";
import moment from "moment";
import { IconFont } from "reviewshare-common-component";
import Checkbox from "@material-ui/core/Checkbox";
import { TableContainer, Table, TableHead, TableRow, TableBody } from "@material-ui/core";
import CustomTableCell from "components/common/CustomTableCell";
import { numberWithCommas } from "lib/common";
import { convertPointRequestStatus } from "lib/utils/pointRequest";

const tableHeaderList = [
    { label: "선택", align: "center" },
    { label: "분류", align: "center" },
    { label: "포인트 ID", align: "center" },
    { label: "포인트", align: "center" },
    { label: "프로젝트/제안/서비스 ID", align: "center" },
    { label: "내용", align: "center" },
    { label: "날짜", align: "center" }
];

const ReviewerPointRequestList = ({ pointRequestList }) => (
    <TableContainer>
        <Table size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    {tableHeaderList.map((headerItem, index) => (
                        <CustomTableCell
                            align={headerItem.align}
                            key={index}
                            style={{ whiteSpace: "nowrap" }}
                        >
                            {headerItem.label}
                        </CustomTableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {pointRequestList &&
                    pointRequestList.length > 0 &&
                    pointRequestList.map((pointRequest, index) => (
                        <TableRow key={index} hover>
                            <CustomTableCell align="center">
                                <Checkbox color="primary" />
                            </CustomTableCell>
                            <CustomTableCell align="center" style={{ whiteSpace: "nowrap" }}>
                                {convertPointRequestStatus(pointRequest.status)}
                            </CustomTableCell>
                            <CustomTableCell align="center">{pointRequest.id}</CustomTableCell>
                            <CustomTableCell align="center">
                                {numberWithCommas(pointRequest.points)}
                            </CustomTableCell>
                            <CustomTableCell align="center">
                                {pointRequest.projectId}
                            </CustomTableCell>
                            <CustomTableCell align="center">
                                {pointRequest?.securityDeposit?._id && (
                                    <IconFont icon="icon_deposit">보증금</IconFont>
                                )}
                                {pointRequest.projectTitle}
                            </CustomTableCell>
                            <CustomTableCell align="center">
                                {moment(pointRequest.createdAt).format("YYYY-MM-DD HH:mm")}
                            </CustomTableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
        {!pointRequestList || (pointRequestList.length === 0 && <p>포인트 내역이 없습니다.</p>)}
    </TableContainer>
);

export default ReviewerPointRequestList;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import { Tag } from "reviewshare-common-component";
import { selectCouponInfo } from "stores/modules/coupon";
import { numberWithCommas, returnTagColor } from "lib/common";
import { convertCouponTarget, convertCouponStatus } from "lib/utils/coupon";

import "./CouponDetailModal.scss";

const CouponDetailModal = () => {
    const dispatch = useDispatch();
    const detail = useSelector(({ coupon }) => coupon.detail);

    const closeModal = () => {
        dispatch(selectCouponInfo({}));
    };

    return (
        <Modal open={!!detail._id} onClose={closeModal}>
            <div id="coupon-detail-modal">
                <h2 className="modal-title">
                    쿠폰 ID {detail._id || detail.coupon?._id}
                    {detail.status && (
                        <Tag color={returnTagColor(detail.status)} className="size-l">
                            {convertCouponStatus(detail.status)}
                        </Tag>
                    )}
                </h2>
                <div className="modal-content">
                    {detail.owner && (
                        <fieldset>
                            <h3>비즈니스 정보</h3>
                            <div className="field-box owner">
                                <div className="field">
                                    <p className="field-title">비즈니스 ID</p>
                                    <div className="field-content">{detail.owner._id}</div>
                                </div>
                                <div className="field">
                                    <p className="field-title">이름</p>
                                    <div className="field-content">{detail.owner.name}</div>
                                </div>
                                <div className="field">
                                    <p className="field-title">휴대폰번호</p>
                                    <div className="field-content">{detail.owner.phoneNumber}</div>
                                </div>
                            </div>
                        </fieldset>
                    )}
                    <fieldset>
                        <h3>쿠폰 정보</h3>
                        <div className="field-box">
                            <div className="field">
                                <p className="field-title">쿠폰명</p>
                                <div className="field-content">
                                    {detail.name || detail.coupon?.name}
                                </div>
                            </div>
                        </div>
                        <div className="etc-info">
                            <div className="field-box column">
                                <div className="field row">
                                    <p className="field-title">쿠폰 코드</p>
                                    <div className="field-content">
                                        {detail.code || detail.coupon?.code}
                                    </div>
                                </div>
                                <div className="field row">
                                    <p className="field-title">사용 가능 서비스</p>
                                    <div className="field-content">
                                        {convertCouponTarget(
                                            detail.targetService || detail.coupon?.targetService
                                        )}
                                    </div>
                                </div>
                                <div className="field row">
                                    <p className="field-title">사용 기간</p>
                                    <div className="field-content">
                                        {moment(
                                            detail.startedAt || detail.coupon?.startedAt
                                        ).format("YYYY.MM.DD")}{" "}
                                        ~{" "}
                                        {moment(detail.endAt || detail.coupon?.endAt).format(
                                            "YYYY.MM.DD"
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="field-box column">
                                <div className="field row">
                                    <p className="field-title">할인율</p>
                                    <div className="field-content">
                                        {detail.discountRate || detail.coupon?.discountRate}%
                                    </div>
                                </div>
                                <div className="field row">
                                    <p className="field-title">최소 사용 금액</p>
                                    <div className="field-content">
                                        {numberWithCommas(
                                            detail.minSpendPrice || detail.coupon?.minSpendPrice
                                        ) || "-"}
                                    </div>
                                </div>
                                <div className="field row">
                                    <p className="field-title">최대 할인 금액</p>
                                    <div className="field-content">
                                        {numberWithCommas(
                                            detail.maxDiscountPrice ||
                                                detail.coupon?.maxDiscountPrice
                                        ) || "-"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    {detail.targetUserIdList?.length > 0 ? (
                        <fieldset>
                            <div className="field biz">
                                <p className="field-title">지정된 비즈니스</p>
                                <div className="field-content">
                                    <ul>
                                        {detail.targetUserIdList.map(item => (
                                            <li key={item._id}>
                                                {item.name} ({item._id})
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </fieldset>
                    ) : (
                        <fieldset>
                            <h3>쿠폰 사용 정보</h3>
                            <div className="flex">
                                <div className="field-box column">
                                    <div className="field row">
                                        <p className="field-title">사용한 서비스</p>
                                        <div className="field-content">-</div>
                                    </div>
                                    <div className="field row">
                                        <p className="field-title">사용 날짜</p>
                                        <div className="field-content">
                                            {detail.statusDate?.used
                                                ? moment(detail.statusDate.used).format(
                                                      "YYYY.MM.DD"
                                                  )
                                                : "-"}
                                        </div>
                                    </div>
                                    <div className="field row">
                                        <p className="field-title">회수 날짜</p>
                                        <div className="field-content">
                                            {detail.statusDate?.retrieve
                                                ? moment(detail.statusDate.retrieve).format(
                                                      "YYYY.MM.DD"
                                                  )
                                                : "-"}
                                        </div>
                                    </div>
                                </div>
                                <div className="field-box column">
                                    <div className="field row">
                                        <p className="field-title">총 금액</p>
                                        <div className="field-content">
                                            {numberWithCommas(detail.totalPrice) || "-"}
                                        </div>
                                    </div>
                                    <div className="field row">
                                        <p className="field-title">할인된 금액</p>
                                        <div className="field-content">
                                            {numberWithCommas(detail.discountPrice) || "-"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default CouponDetailModal;

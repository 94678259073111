export const convertStatus = status => {
    switch (status) {
        case "Reviewing":
        case "standby":
            return "검수중";
        case "Running":
            return "진행중";
        case "Complete":
            return "진행완료";
        case "recruit":
            return "모집중";
        case "choice":
            return "선정중";
        case "submit":
            return "제출중";
        case "complete":
            return "마감";
        case "rereviewing":
            return "수정 요청";
        default:
            return status;
    }
};
export const convertRereviewingStatus = status => {
    switch (status) {
        case "request":
            return "수정 요청";
        case "deny":
            return "수정 요청 거절";
        default:
            return "없음";
    }
};

export const getProjectStatusByParams = status => {
    switch (status) {
        case "recruit":
            return "모집 중인 프로젝트가 없습니다.";
        case "submit":
            return "제출 중인 프로젝트가 없습니다.";
        case "complete":
            return "제출 완료된 프로젝트가 없습니다.";
        default:
            return "검수 중인 프로젝트가 없습니다.";
    }
};

export const convertType = type => {
    switch (type) {
        case "review":
            return "리뷰";
        case "content":
            return "콘텐츠";
        case "event":
            return "이벤트";
        case "survey":
            return "설문";
        case "interview":
            return "인터뷰";
        case "shortform":
            return "숏폼";
        default:
            return type;
    }
};

export const convertCategory = category => {
    switch (category) {
        case "Delivery":
            return "배송형";
        case "Visit":
            return "방문형";
        case "Buy":
            return "구매형";
        case "Reporters":
            return "기자단";
        case "None":
            return "자율형";
    }
};

export const convertStatusByBiz = projectStatus => {
    switch (projectStatus) {
        case "standby":
            return "검수중";
        case "recruit":
            return "모집중";
        case "choice":
            return "선정중";
        case "submit":
            return "제출중";
        case "complete":
            return "마감";
        case "rereviewing":
            return "수정 요청";
        default:
            return projectStatus;
    }
};

export const convertProjectPlan = recruitmentForm => {
    if (recruitmentForm) {
        return recruitmentForm.plan;
    }
    return "";
};

export const convertApplyCancelReason = reason => {
    switch (reason) {
        case "noIntention":
            return "프로젝트 진행의사 없어짐";
        case "lookWrong":
            return "프로젝트 정보 잘못 봄";
        case "personalSchedule":
            return "개인 스케줄로 프로젝트 진행 불가";
        case "accident":
            return "특수사항(상해,사고 등)";
        case "deliveryDelay":
            return "배송 지연";
        case "dissolution":
            return "서비스 종료(매장 폐업)";
        case "productDefect":
            return "제품 불량";
        case "unbookable":
            return "예약 불가";
        case "reviewerFault":
            return "리뷰어측 귀책(노쇼, 일정 지키지 않음, 연락두절 등)";
        case "bizFault":
            return "브랜드측 귀책(매장 폐업, 배송 일정 지연, 제품 불량, 예약불가, 연락두절 등)";
        case "etcReviewer":
            return "기타(리뷰어 개인사정)";
        case "etcBiz":
            return "기타(브랜드 개인사정)";
        default:
            return reason;
    }
};

export const convertRequestStatus = status => {
    switch (status) {
        case "request":
            return "신청";
        case "chosen":
            return "선정";
        case "cancelByBizFault":
            return "선정 취소(비즈니스 귀책)";
        case "cancelByReviewerFault":
            return "프로젝트 취소(리뷰어 귀책)";
        case "applyForCancel":
            return "취소 요청";
        case "submitted":
            return "제출";
        case "unsubmitted":
            return "미제출";
        default:
            return status;
    }
};

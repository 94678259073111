import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";
import Checkbox from "@material-ui/core/Checkbox";
import { TableContainer, Table, TableHead, TableRow, TableBody } from "@material-ui/core";

import FeedbackSearchContainer from "containers/feedback/FeedbackSearchContainer";
import ListWrapper from "components/common/ListWrapper";
import CustomTableCell from "components/common/CustomTableCell";
import { callDirectApi } from "lib/common";
import { removeFeedbacks as removeFeedbacksAPI } from "lib/api/feedback";
import { selectFeedback } from "stores/modules/feedback";

const tableHeaderList = [
    { label: "ID", align: "center" },
    { label: "작성자", align: "center" },
    { label: "리뷰어", align: "center" },
    { label: "브랜드", align: "center" },
    { label: "내용", align: "center" },
    { label: "신고됨", align: "center" },
    { label: "작성시간", align: "center" }
];

const FeedbackContainer = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState([]);

    const totalCount = useSelector(({ feedback }) => feedback.totalCount);
    const feedbackList = useSelector(({ feedback }) => feedback.list);

    const params = queryString.parse(location.search);

    const setPage = pageNum => {
        params.page = pageNum;
        const query = queryString.stringify(params);
        history.push(`${location.pathname}?${query}`);
    };

    const onHandleCheck = id => {
        if (!id) return;

        const _selectItem = [...selectedItem];
        const index = _selectItem.indexOf(id);

        if (index > -1) {
            _selectItem.splice(index, 1);
        } else {
            _selectItem.push(id);
        }

        setSelectedItem(_selectItem);
    };

    const onRemove = async () => {
        try {
            console.log("selectedItem:", selectedItem);
            await callDirectApi(
                removeFeedbacksAPI,
                { feedbackIdList: selectedItem, action: "delete" },
                true
            );
            history.go(0);
        } catch (error) {
            window.alert(error.message);
        }
    };

    const openDetailModal = feedback => {
        dispatch(selectFeedback(feedback));
    };

    useEffect(() => {
        if (params.page) {
            setCurrentPage(Number(params.page));
        }
    }, [params.page]);

    useEffect(() => {
        setSelectedItem([]);
    }, [location.search]);

    return (
        <>
            <FeedbackSearchContainer />
            <ListWrapper
                pageLimit={30}
                currentPage={currentPage}
                handlePage={(e, pageNum) => setPage(pageNum)}
                totalCount={totalCount}
                onRemove={onRemove}
            >
                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>
                                    <Checkbox color="primary" />
                                </CustomTableCell>
                                {tableHeaderList.map((headerItem, index) => (
                                    <CustomTableCell
                                        align={headerItem.align}
                                        key={index}
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        {headerItem.label}
                                    </CustomTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!feedbackList?.length &&
                                feedbackList.map((feedback, index) => {
                                    const isSelected = selectedItem.indexOf(feedback._id) > -1;
                                    return (
                                        <TableRow
                                            key={index}
                                            hover
                                            classes={{
                                                root: feedback.isDeleted ? "deleted-item" : ""
                                            }}
                                            onClick={() => openDetailModal(feedback)}
                                        >
                                            <CustomTableCell width={20}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={isSelected}
                                                    disabled={feedback.isDeleted}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        onHandleCheck(feedback._id);
                                                    }}
                                                />
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width="5%"
                                            >
                                                {feedback._id}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width="5%"
                                            >
                                                {`${feedback.owner.name}(${feedback.owner._id})`}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width="5%"
                                            >
                                                {`${feedback.target.nickname}(${feedback.target._id})`}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width="5%"
                                            >
                                                {`${feedback.enterprise.name}(${feedback.enterprise._id})`}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width="60%"
                                            >
                                                {feedback.content}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width="5%"
                                            >
                                                {feedback.isReported && "O"}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                width="10%"
                                            >
                                                {moment(feedback.createdAt).format(
                                                    "YYYY-MM-DD HH:mm"
                                                )}
                                            </CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {totalCount === 0 && (
                    <p style={{ textAlign: "center", padding: "80px 0" }}>후기가 없습니다.</p>
                )}
            </ListWrapper>
        </>
    );
};

export default FeedbackContainer;

import styled from "styled-components";

export const Mobile = val => {
    if (typeof val === "string") {
        if (val.endsWith("px")) {
            return `${(val.substr(0, val.length - 2) / 720) * 100}vw`;
        }
        if (val.endsWith("%") || val === "auto" || val === "initial" || val === "inherit") {
            return val;
        }
        return `${(val / 720) * 100}vw`;
    }
    return `${(val / 720) * 100}vw`;
};

export const Laptop = val => {
    if (typeof val === "string") {
        if (val.endsWith("px")) {
            return `${val.substr(0, val.length - 2) * 0.8}px`;
        }
        if (val.endsWith("%") || val === "auto" || val === "initial" || val === "inherit") {
            return val;
        }
        return `${val * 0.8}px`;
    }
    return `${val * 0.8}px`;
};

export const rem = val => `${((val * 1) / 16) * 1}rem`;

export const Layout = {
    indentMobile: `${Mobile(32)}`
};

export const Font = {
    size: {
        xs: "12px",
        s: "14px",
        m: "16px",
        l: "22px"
    },
    weight: {
        thin: 300,
        normal: 400,
        medium: 500,
        bold: 700
    }
};

export const Color = {
    black: "#000000",
    white: "#ffffff",
    gray0: "#f7f7fa",
    gray1: "#e6e8ed",
    gray2: "#9da1a8",
    gray3: "#52565d",
    gray4: "#33363b",
    gray5: "#202225",

    primaryColor: "#0067ff",
    secondaryColor: "#003cff",
    tertiaryColor: "#5a00ff",

    mainColor: "#0067ff",
    activeColor: "#003cff",
    activeColorLight: "#e5f0ff",
    activeColorGray: "#f7f7fa",

    blueLightest: "#f7faff",

    redLighter: "#fcf1f0",
    blueLighter: "#eef5ff",

    yellowLight: "#ffe368",
    orangeLight: "#ff9d5a",
    redLight: "#eb8276",
    greenLight: "#8bdb8e",
    blueLight: "#5e9fff",
    purpleLight: "#c189f9",

    yellow: "#ffd400",
    orange: "#ff7326",
    red: "#fa4238",
    green: "#02c639",
    blue: "#0067ff",
    purple: "#9727f1",

    yellowDark: "#ffbf00",
    orangeDark: "#ff5300",
    redDark: "#d41100",
    greenDark: "#01a31d",
    blueDark: "#003ffc",
    purpleDark: "#7b00e0",

    Instagram: "#c32aa3",
    NaverBlog: "#02c639",
    Facebook: "#0d33a2",
    Youtube: "#fa4238",
    KakaoStory: "#fbc800",
    NaverPost: "#01beff",
    NaverCafe: "#0d33a2",
    AppReview: "#15ceC0"
};

export const Breakpoints = {
    webL: "screen and (max-width: 1444px)",
    web: "screen and (min-width: 768px)",
    mobile: "screen and (max-width: 767px)"
};

export const OnlyWeb = `@media ${Breakpoints.mobile} {
        display: none !important;
    }`;

export const OnlyMobile = `@media ${Breakpoints.web} {
        display: none !important;
    }`;

export const Ellipsis = `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ContentBox = styled.div`
    padding: 0 ${rem(40)};
    border: ${rem(1)} solid ${Color.gray1};
    border-radius: ${rem(6)};
    background-color: ${Color.white};
`;

export const ContentTitle = styled.div`
    margin: 0 ${rem(-40)};
    padding: ${rem(23)} ${rem(40)} ${rem(20)};
    border-bottom: ${rem(1)} solid ${Color.gray1};
    font-size: ${rem(18)};
    font-weight: ${Font.weight.bold};
    line-height: 1.5;
`;

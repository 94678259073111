import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as FeedbackAPI from "lib/api/feedback";

const [GET_FEEDBACK_LIST, GET_FEEDBACK_LIST_SUCCESS] = createRequestActionTypes(
    "feedback/GET_FEEDBACK_LIST"
);

const SELECT_FEEDBACK = "feedback/SELECT_FEEDBACK";

export const getFeedbackList = createAction(GET_FEEDBACK_LIST);
export const selectFeedback = createAction(SELECT_FEEDBACK);

const getFeedbackListSaga = createRequestSagaWithAlert(
    GET_FEEDBACK_LIST,
    FeedbackAPI.getFeedbackList,
    "후기를 가져오는 데 실패하였습니다.",
    true
);

export function* feedbackSaga() {
    yield takeLatest(GET_FEEDBACK_LIST, getFeedbackListSaga);
}

const initialState = {
    list: [],
    totalCount: 0,
    selectedFeedback: {
        id: null,
        target: {
            _id: null,
            nickname: null
        },
        owner: {
            _id: null,
            name: null
        },
        enterprise: {
            _id: null,
            name: null
        },
        content: null,
        reportHistory: [],
        isReported: false
    }
};

const feedback = handleActions(
    {
        [GET_FEEDBACK_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            list: payload.list,
            totalCount: payload.totalCounts
        }),
        [SELECT_FEEDBACK]: (state, { payload }) => ({
            ...state,
            selectedFeedback: {
                id: payload?._id,
                target: {
                    _id: payload?.target?._id,
                    nickname: payload?.target?.nickname
                },
                owner: {
                    _id: payload?.owner?._id,
                    name: payload?.owner?.name
                },
                enterprise: {
                    _id: payload?.enterprise?._id,
                    name: payload?.enterprise?.name
                },
                content: payload?.content,
                reportHistory: payload?.reportHistory,
                isReported: payload?.isReported
            }
        })
    },
    initialState
);

export default feedback;

import React from "react";
import cx from "classnames";
import moment from "moment";
import { Tag, IconFont } from "reviewshare-common-component";
import {
    convertGrade,
    convertLoginMethod,
    convertBoolean,
    numberWithCommas,
    convertVerifyStatus
} from "lib/common";
import CustomTableCell from "components/common/CustomTableCell";

const UserListItem = ({ value, user, ...rest }) => (
    <CustomTableCell className={cx({ "open-detail": value === "name" })} {...rest}>
        {value === "name" && <IconFont icon="icon_project" />}
        {(() => {
            switch (value) {
                case "block":
                    return (
                        (user.blockProjectRequest || user.reviewerStatus?.blockPointRequest) && (
                            <Tag color="red">제한</Tag>
                        )
                    );
                case "gender":
                    return user.gender === "Female" ? "여" : "남";
                case "grade":
                    return (
                        (user.score &&
                            `${convertGrade(
                                user.blackUser ? "z" : user.score.grade
                            )}(${numberWithCommas(user.score.total)})`) ||
                        "-"
                    );
                case "address":
                    return (
                        <span
                            title={`${user.address?.address || user.address?.roadAddress} ${
                                user.addressDetail
                            }`}
                        >
                            {user.address?.address || user.address?.roadAddress}
                            {user.addressDetail}
                        </span>
                    );
                case "portfolio":
                    return (
                        <a
                            href={`${process.env.REACT_APP_MAIN_SERVICE_URL}/portfolio/${user.portfolioUrl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            @{user.portfolioUrl}
                        </a>
                    );
                case "NaverBlog":
                case "Instagram":
                case "Youtube":
                case "Tiktok":
                    // eslint-disable-next-line no-case-declarations
                    const sns = user.sns.find(sns => sns.category === value);
                    return sns?.path ? (
                        <>
                            <a
                                href={`${sns.domain}${sns.path}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                title={`${sns.domain}${sns.path}`}
                            >
                                @{sns.path}
                            </a>
                        </>
                    ) : (
                        "-"
                    );
                case "enterprise":
                    const brandNames =
                        user.enterprises?.length > 0
                            ? user.enterprises.map(enterprise => enterprise.name).join(",")
                            : "-";
                    return <span title={brandNames}>{brandNames}</span>;
                case "enterpriseCounts":
                    return numberWithCommas(user.enterprises?.length);
                case "points":
                    return `${numberWithCommas(user.wallet?.[0]?.total)}P`;
                case "createdAt":
                    return moment(user.createdAt).format("YYYY-MM-DD HH:mm");
                case "memo":
                    return <span title={user.memo}>{user.memo}</span>;
                case "loginMethod":
                    return convertLoginMethod(user.loginMethod);
                case "isAllowMarketing":
                    return convertBoolean(user.isAllowMarketing);
                case "verifyStatus":
                    return convertVerifyStatus(user.reviewerStatus?.verifyStatus);
                case "reelsAvgViews":
                    return numberWithCommas(user.snsInfo?.instagram?.shortform?.avgViews) || "";
                case "shortsAvgViews":
                    return numberWithCommas(user.snsInfo?.youtube?.shortform?.avgViews) || "";
                case "tiktokAvgViews":
                    return numberWithCommas(user.snsInfo?.tiktok?.shortform?.avgViews) || "";
                default:
                    return user[value];
            }
        })()}
    </CustomTableCell>
);

export default UserListItem;

import React from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import PostEditor from "components/common/PostEditor";

import "./NoticeDetailModal.scss";

const NoticeDetailModal = ({
    notice,
    noticeId,
    register,
    setNoticeContents,
    updateData,
    noticeContents
}) => (
    <div className="notice-detail-modal">
        <h2 className="modal-title">공지 ID {noticeId}</h2>
        <div className="modal-content">
            <div className="editor-area">
                <PostEditor
                    contentValue={noticeContents}
                    postForm={register}
                    postType="notice"
                    setValue={setNoticeContents}
                    onSubmit={updateData}
                />
                <div className="field row">
                    <p className="field-title">작성일</p>
                    <div className="field-content">
                        {moment(notice.createdAt).format("YYYY-MM-DD HH:mm")}
                    </div>
                </div>
            </div>
        </div>
        <div className="modal-footer">
            <div className="btn-area">
                <Button
                    variant="contained"
                    color="primary"
                    className="submit-btn"
                    onClick={updateData}
                >
                    수정
                </Button>
            </div>
        </div>
    </div>
);

export default NoticeDetailModal;

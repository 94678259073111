import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getStorage } from "lib/storage";
import BusinessPageTemplate from "components/base/BusinessPageTemplate";

const PrivateRoute = ({ ...rest }) => {
    const isLogin = getStorage("reviewshare-admin-at");

    return (
        <>
            {isLogin ? (
                <BusinessPageTemplate>
                    <Route {...rest} />
                </BusinessPageTemplate>
            ) : (
                <Redirect
                    to={{
                        pathname: "/"
                    }}
                />
            )}
        </>
    );
};

export default PrivateRoute;

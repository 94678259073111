import { createAction, handleActions } from "redux-actions";
import { takeLeading } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaBase } from "lib/createRequestSaga";
import produce from "immer";
import * as orderAPI from "lib/api/order";

export const RESET_ORDER_DETAIL = "order/RESET_ORDER_DETAIL";
export const [
    GET_ALL_ORDER_LIST,
    GET_ALL_ORDER_LIST_SUCCESS,
    GET_ALL_ORDER_LIST_FAILURE
] = createRequestActionTypes("order/GET_ALL_ORDER_LIST");

export const GET_ORDER_DETAIL = "order/GET_ORDER_DETAIL";

export const [
    UPDATE_ORDER_DETAIL,
    UPDATE_ORDER_DETAIL_SUCCESS,
    UPDATE_ORDER_DETAIL_FAILURE
] = createRequestActionTypes("order/UPDATE_ORDER_DETAIL");

export const resetOrderDetail = createAction(RESET_ORDER_DETAIL);
export const getAllOrderList = createAction(GET_ALL_ORDER_LIST);
export const getOrderDetail = createAction(GET_ORDER_DETAIL);

const initialState = {
    all: {
        counts: 0,
        list: []
    },
    detail: {}
};

const getAllOrderListSaga = createRequestSagaBase({
    type: GET_ALL_ORDER_LIST,
    api: orderAPI.getAllOrderList
});

export function* orderSaga() {
    yield takeLeading(GET_ALL_ORDER_LIST, getAllOrderListSaga);
}

const order = handleActions(
    {
        [RESET_ORDER_DETAIL]: state =>
            produce(state, draft => {
                draft.detail = initialState.detail;
            }),
        [GET_ALL_ORDER_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.all = {
                    counts: payload.totalCounts,
                    list: payload.list
                };
            }),
        [GET_ORDER_DETAIL]: (state, { payload }) =>
            produce(state, draft => {
                draft.detail = payload;
            })
    },
    initialState
);

export default order;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { getPostList } from "stores/modules/post";
import { convertTarget } from "lib/common";

import "./NoticeSearch.scss";

const NoticeSearch = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { query, search } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;

    const [selectedOptions, setSelectedOptions] = useState({
        target: query.target || undefined
    });

    const onGetList = () => {
        dispatch(
            getPostList({
                ...query,
                type: "notice",
                target: query.target || null,
                skip: Number(query.skip) || 0
            })
        );
    };

    const onSearch = () => {
        const newQuery = createSearchQuery(selectedOptions);
        history.replace({ path: "/notice/list", search: newQuery });
    };

    const onChangeOption = e => {
        const { name, value } = e.target;
        const filteredValue = value === "all" || !value ? undefined : value;
        setSelectedOptions(prev => ({ ...prev, [name]: filteredValue }));
    };

    useEffect(() => {
        onGetList();
    }, [search]);

    return (
        <div className="notice-search">
            <ul className="search-items">
                <li className="search-item">
                    <h3>대상</h3>
                    <Select
                        id="notice-filter-target"
                        value={selectedOptions.target || "all"}
                        name="target"
                        onChange={onChangeOption}
                    >
                        <MenuItem value="all">전체</MenuItem>
                        {["reviewer", "biz"].map((target, index) => (
                            <MenuItem value={target} key={index}>
                                {convertTarget(target)}
                            </MenuItem>
                        ))}
                    </Select>
                </li>
            </ul>
            <div className="btn-area">
                <Button
                    className="search-btn"
                    variant="contained"
                    color="primary"
                    onClick={onSearch}
                >
                    검색
                </Button>
            </div>
        </div>
    );
};
export default NoticeSearch;

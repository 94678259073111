export const convertDepositStatus = status => {
    switch (status) {
        case "giveBack":
            return "리뷰어에게 환급";
        case "wait":
            return "예치중";
        case "lose":
            return "비즈니스에게 전달";
        default:
            return status;
    }
};

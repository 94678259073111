import React from "react";
import Button from "@material-ui/core/Button";
import { Editor } from "reviewshare-common-component";
import { convertManagerName } from "lib/common";

import "./ContactUsReply.scss";

const managerList = [
    "Yerin", // 이예린
    "Seongjun", // 권성준
    "Pine" // 박계환
];

const ContactUsReply = ({
    templateList,
    replyForm,
    contentValue,
    setValue,
    onSubmit,
    searchForm,
    submitsearch,
    handleFile,
    attachments
}) => (
    <div className="reply-area">
        <div className="template-area">
            <form className="search-area" onSubmit={submitsearch}>
                <input
                    type="text"
                    name="keyword"
                    ref={searchForm}
                    placeholder="템플릿 제목을 검색해주세요."
                />
                <Button variant="contained" color="primary" className="submit-btn" type="submit">
                    검색
                </Button>
            </form>
            <div className="template-list">
                <ul>
                    {templateList.length > 0 &&
                        templateList.map((template, index) => (
                            <li key={index} onClick={() => setValue(template.contents)}>
                                {template.title}
                            </li>
                        ))}
                </ul>
            </div>
        </div>
        <form className="reply-contents" onSubmit={onSubmit}>
            <div className="manager-area">
                <h3>담당자</h3>
                <div>
                    {managerList.map((name, index) => (
                        <label htmlFor={`manager-${name}`} key={index}>
                            <input
                                type="radio"
                                name="manager"
                                value={name}
                                id={`manager-${name}`}
                                ref={replyForm}
                            />
                            {convertManagerName(name)}
                        </label>
                    ))}
                </div>
            </div>
            <div className="editor-area">
                <Editor value={contentValue} onChange={contentValue => setValue(contentValue)} />
            </div>
            <div className="attachments-area">
                <input
                    type="file"
                    id="attachments"
                    multiple
                    onChange={e => handleFile({ type: "add", files: e.target.files })}
                />
                {attachments.length !== 0 && (
                    <ul className="attachment-list">
                        {attachments.map((attachment, index) => (
                            <li key={index} onClick={() => handleFile({ type: "remove", index })}>
                                <p>{attachment.name}</p>
                                <span>x</span>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div className="btn-area">
                <Button variant="contained" color="primary" className="submit-btn" type="submit">
                    작성
                </Button>
            </div>
        </form>
    </div>
);

export default ContactUsReply;

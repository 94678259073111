import React from "react";
import cx from "classnames";
import { remove } from "lodash";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Checkbox,
    Button,
    Box
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { Color } from "theme";
import { convertBannerPosition } from "lib/utils/banner";
import CustomTableCell from "components/common/CustomTableCell";

import "./BannerList.scss";

const tableHeaderList = [
    { label: "배너 ID", align: "center" },
    { label: "배너명", align: "center" },
    { label: "노출 대상", align: "center" },
    { label: "위치", align: "center" },
    { label: "노출 여부", align: "center" }
];

const BannerList = ({
    list,
    counts,
    limit,
    selectedItem,
    setSelectedItem,
    openDetailModal,
    openCreateModal,
    removeBanner,
    changePaginateion
}) => {
    const isSelected = id => selectedItem.indexOf(id) !== -1;

    const onHandleCheck = id => {
        let _selectItem = [...selectedItem];

        if (selectedItem.find(val => val === id)) {
            _selectItem = remove(selectedItem, val => val !== id);
        } else {
            _selectItem.push(id);
        }

        setSelectedItem(_selectItem);
    };

    return (
        <div id="banner-list">
            <div className="button-box">
                <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: Color.greenDark }}
                    onClick={openCreateModal}
                >
                    생성
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => removeBanner(selectedItem)}
                >
                    삭제
                </Button>
            </div>
            <TableContainer>
                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>
                                <Checkbox color="primary" />
                            </CustomTableCell>
                            {tableHeaderList.map((headerItem, index) => (
                                <CustomTableCell
                                    align={headerItem.align}
                                    key={index}
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    {headerItem.label}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list &&
                            list.length > 0 &&
                            list.map((banner, index) => {
                                const isItemSelected = isSelected(banner._id);

                                return (
                                    <TableRow
                                        key={index}
                                        hover
                                        className={cx("suggestion-list-row", {
                                            "deleted-item": banner.isDeleted
                                        })}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <CustomTableCell>
                                            <Checkbox
                                                color="primary"
                                                disabled={banner.isDeleted && !isItemSelected}
                                                checked={isItemSelected}
                                                onClick={() => onHandleCheck(banner._id)}
                                            />
                                        </CustomTableCell>
                                        <CustomTableCell
                                            align="center"
                                            onClick={() => openDetailModal(banner._id)}
                                        >
                                            {banner._id}
                                        </CustomTableCell>
                                        <CustomTableCell
                                            align="center"
                                            onClick={() => openDetailModal(banner._id)}
                                        >
                                            {banner.name}
                                        </CustomTableCell>
                                        <CustomTableCell
                                            align="center"
                                            onClick={() => openDetailModal(banner._id)}
                                        >
                                            {banner.target
                                                .map(val => {
                                                    switch (val) {
                                                        case "reviewer":
                                                            return "리뷰어";
                                                        case "business":
                                                            return "비즈니스";
                                                        case "visitor":
                                                            return "비회원";
                                                        default:
                                                            return "";
                                                    }
                                                })
                                                .join(", ")}
                                        </CustomTableCell>
                                        <CustomTableCell
                                            align="center"
                                            onClick={() => openDetailModal(banner._id)}
                                        >
                                            {convertBannerPosition(banner.position)}
                                        </CustomTableCell>
                                        <CustomTableCell
                                            align="center"
                                            onClick={() => openDetailModal(banner._id)}
                                        >
                                            {banner.isShowing ? "O" : "X"}
                                        </CustomTableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center" style={{ margin: "20px 0" }}>
                <Pagination
                    count={Math.ceil(counts / limit)}
                    color="primary"
                    onChange={changePaginateion}
                />
            </Box>
        </div>
    );
};

export default BannerList;

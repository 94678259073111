import { createAction, handleActions } from "redux-actions";
import { takeLeading } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaBase } from "lib/createRequestSaga";
import produce from "immer";
import * as couponAPI from "lib/api/coupon";

export const [
    GET_ALL_COUPON_LIST,
    GET_ALL_COUPON_LIST_SUCCESS,
    GET_ALL_COUPON_LIST_FAILURE
] = createRequestActionTypes("coupon/GET_ALL_COUPON_LIST");
export const [
    GET_ALL_COUPON_CODE_LIST,
    GET_ALL_COUPON_CODE_LIST_SUCCESS,
    GET_ALL_COUPON_CODE_LIST_FAILURE
] = createRequestActionTypes("coupon/GET_ALL_COUPON_CODE_LIST");
export const [
    CREATE_COUPON,
    CREATE_COUPON_SUCCESS,
    CREATE_COUPON_FAILURE
] = createRequestActionTypes("coupon/CREATE_COUPON");
export const SELECT_COUPON_INFO = "coupon/SELECT_COUPON_INFO";

export const createCoupon = createAction(CREATE_COUPON);
export const selectCouponInfo = createAction(SELECT_COUPON_INFO);
export const getAllCouponList = createAction(GET_ALL_COUPON_LIST);
export const getAllCouponCodeList = createAction(GET_ALL_COUPON_CODE_LIST);

const initialState = {
    all: {
        counts: 0,
        list: []
    },
    code: {
        counts: 0,
        list: []
    },
    detail: {}
};

const createCouponSaga = createRequestSagaBase({
    type: CREATE_COUPON,
    api: couponAPI.createCoupon,
    alertSuccessMsg: "쿠폰이 생성되었습니다.",
    alertSuccessPath: "reload",
    alertErrorMsg: "쿠폰 생성에 실패하였습니다."
});
const getAllCouponListSaga = createRequestSagaBase({
    type: GET_ALL_COUPON_LIST,
    api: couponAPI.getAllCouponList
});
const getAllCouponCodeListSaga = createRequestSagaBase({
    type: GET_ALL_COUPON_CODE_LIST,
    api: couponAPI.getAllCouponCodeList
});

export function* couponSaga() {
    yield takeLeading(CREATE_COUPON, createCouponSaga);
    yield takeLeading(GET_ALL_COUPON_LIST, getAllCouponListSaga);
    yield takeLeading(GET_ALL_COUPON_CODE_LIST, getAllCouponCodeListSaga);
}

const coupon = handleActions(
    {
        [SELECT_COUPON_INFO]: (state, { payload }) =>
            produce(state, draft => {
                draft.detail = payload;
            }),
        [GET_ALL_COUPON_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.all = {
                    counts: payload.totalCounts,
                    list: payload.list
                };
            }),
        [GET_ALL_COUPON_CODE_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.code = {
                    counts: payload.totalCounts,
                    list: payload.list
                };
            })
    },
    initialState
);

export default coupon;

import { createAction, handleActions } from "redux-actions";
import { takeLatest, takeLeading, call, put } from "redux-saga/effects";
import produce from "immer";
import {
    createRequestActionTypes,
    createRequestSagaWithAlert,
    createRequestSagaBase
} from "lib/createRequestSaga";
import * as CommentAPI from "lib/api/comment";

const [
    GET_COMMENT_LIST,
    GET_COMMENT_LIST_SUCCESS,
    // eslint-disable-next-line
    GET_COMMENT_LIST_FAILURE
] = createRequestActionTypes("comment/GET_COMMENT_LIST");
const [
    REPLY_COMMENT,
    REPLY_COMMENT_SUCCESS,
    // eslint-disable-next-line
    REPLY_COMMENT_FAILURE
] = createRequestActionTypes("comment/REPLY_COMMENT");

const [
    REMOVE_COMMENT,
    REMOVE_COMMENT_SUCCESS,
    // eslint-disable-next-line
    REMOVE_COMMENT_FAILURE
] = createRequestActionTypes("comment/REMOVE_COMMENT");

const [
    GET_COMMENT,
    GET_COMMENT_SUCCESS,
    // eslint-disable-next-line
    GET_COMMENT_FAILURE
] = createRequestActionTypes("comment/GET_COMMENT");

export const getCommentList = createAction(GET_COMMENT_LIST);
export const replyComment = createAction(REPLY_COMMENT, obj => obj);
export const removeComment = createAction(REMOVE_COMMENT, idList => idList);

export const getComment = createAction(GET_COMMENT, commentId => commentId);

const getCommentListSaga = createRequestSagaWithAlert(
    GET_COMMENT_LIST,
    CommentAPI.getCommentList,
    "1:1 문의 리스트를 가져오는 데 실패하였습니다.",
    true
);

const getCommentSaga = createRequestSagaWithAlert(
    GET_COMMENT,
    CommentAPI.getComment,
    "1:1 문의를 가져오는 데 실패하였습니다.",
    true
);

function* replyCommentSaga(action) {
    try {
        const response = yield call(CommentAPI.replyComment, action.payload.replyData);
        yield put({
            type: REPLY_COMMENT_SUCCESS,
            payload: response.data,
            prev: action.payload
        });
        window.alert("작성이 완료되었습니다.");
        window.location = action.payload.successPath;
    } catch (error) {
        window.alert("작성에 실패하였습니다.");
        yield put({
            type: REPLY_COMMENT_FAILURE,
            payload: { error: error.response },
            prev: action.payload
        });
    }
}

const removeCommentSaga = createRequestSagaWithAlert(
    REMOVE_COMMENT,
    CommentAPI.removeComment,
    "1:1 문의를 삭제하는 데 실패하였습니다.",
    true
);

export function* commentSaga() {
    yield takeLatest(GET_COMMENT_LIST, getCommentListSaga);
    yield takeLeading(REPLY_COMMENT, replyCommentSaga);
    yield takeLeading(REMOVE_COMMENT, removeCommentSaga);
    yield takeLatest(GET_COMMENT, getCommentSaga);
}

const initialState = {
    list: [],
    totalCount: 0,
    detailContents: null
};

const comment = handleActions(
    {
        [GET_COMMENT_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            list: payload.list,
            totalCount: payload.totalCount
        }),
        [GET_COMMENT]: state => ({
            ...state,
            detailContents: initialState.detailContents
        }),
        [GET_COMMENT_SUCCESS]: (state, { payload }) => ({
            ...state,
            detailContents: payload
        }),
        [REPLY_COMMENT_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                console.log(prev.requestId);
                if (prev.requestId === state.detailContents?.requestId) {
                    draft.state.detailContents.reply = state.detailContents.reply.push(payload);
                }
            }),
        [REMOVE_COMMENT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.list = state.list.filter(item => {
                    if (payload.idList.indexOf(item.requestId) > -1) {
                        return false;
                    }
                    return true;
                });
            })
    },
    initialState
);

export default comment;

import { convertPointRequestStatus, convertReasonForDeny } from "lib/utils/pointRequest";
import { numberWithCommas, convertDate } from "lib/common";

export const STATUS_LIST = [
    // "Issued",
    "Request",
    "Confirmed",
    "Denied"
    // "Reject",
    // "Refund",
    // "Reward",
    // "Retrieve",
    // "Addition",
    // "Gift"
];

export const WITHDRAW_DENY_REASON_LIST = [
    "bankAccountInfo",
    "idCardNum",
    "cancelRequest",
    "blackList",
    "notKeepReview",
    "inappropriateReason"
];

export const WITHDRAWAL_FILTER_INPUT_ITEMS = [
    { label: "요청 ID", value: "requestId", inputType: "text" },
    { label: "리뷰어 ID", value: "userId", inputType: "text" },
    { label: "리뷰어 이름", value: "name", inputType: "text" },
    {
        label: "상태",
        value: "status",
        inputType: "select",
        array: STATUS_LIST,
        convertFn: convertPointRequestStatus
    },
    { label: "요청일~", value: "createdAt", inputType: "date" }
];

export const WITHDRAWAL_TABLE_ITEMS = [
    {
        label: "요청 ID",
        align: "center",
        value: "id",
        key: "_id"
    },
    {
        label: "상태",
        align: "center",
        value: "status",
        key: "status",
        convertFn: convertPointRequestStatus
    },
    {
        label: "예금주명",
        align: "center",
        value: "accountHolder",
        key: "accountHolder"
    },
    {
        label: "계좌번호",
        align: "center",
        value: "accountNumber",
        key: "accountNumber"
    },
    {
        label: "주민등록번호",
        align: "center",
        value: "idCardNumber",
        key: "idCardNumber.value"
    },
    {
        label: "총 금액",
        align: "center",
        value: "subtractionPointAmount",
        key: "subtractionPointAmount",
        convertFn: numberWithCommas
    },
    {
        label: "세금 제외 금액",
        align: "center",
        value: "exchangeAmount",
        key: "exchangeAmount",
        convertFn: numberWithCommas
    },
    {
        label: "거절 사유",
        align: "center",
        value: "reasonForDeny",
        key: "reasonForDeny",
        convertFn: convertReasonForDeny
    },
    {
        label: "요청일",
        align: "center",
        value: "createdAt",
        key: "createdAt",
        convertFn: convertDate
    },
    { label: "리뷰어", align: "center", value: "owner", key: "user._id" },
    { label: "휴대폰번호", align: "center", value: "ownerPhoneNumber", key: "user.phoneNumber" }
];

export const POINT_REQUEST_STATUS_AT_SEARCH = [
    { label: "전체", id: "statusAll", value: "All" },
    { label: "적립", id: "statusIssued", value: "Issued" },
    { label: "추가 포인트", id: "statusAddition", value: "Addition" },
    { label: "출금신청", id: "statusRequest", value: "Request" },
    { label: "출금신청 승인", id: "statusConfirmed", value: "Confirmed" },
    { label: "출금신청 거절", id: "statusDenied", value: "Denied" },
    { label: "적립", id: "statusReward", value: "Reward" },
    { label: "포인트회수", id: "statusRetrieve", value: "Retrieve" }
];

export const convertBannerPosition = position => {
    switch (position) {
        case "mainTop":
            return "메인 상단";
        case "mainBottom":
            return "메인 하단";
        case "reviewerMypage":
            return "리뷰어 마이페이지";
        case "talentList":
            return "거래하기 페이지";
        case "portfolioList":
            return "제안하기 페이지";
        case "bizIntro":
            return "비즈니스 인트로 상단";
        default:
            return position;
    }
};

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import ReviewerVerifiedSearch from "components/reviewerVerified/ReviewerVerifiedSearch";
import { getVerifiedList } from "stores/modules/reviewerStatus";

const ReviewerVerifiedSearchContainer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const params = queryString.parse(location.search);
    const { register, handleSubmit } = useForm({
        defaultValues: {
            status: params.status || "Request"
        }
    });

    const onSearch = async data => {
        const query = [];
        Object.keys(data).forEach((key, index) => {
            if (data[key]) {
                if (index === 0) {
                    query.push("?");
                } else {
                    query.push("&");
                }
                query.push(`${key}=${data[key]}`);
            }
        });

        history.replace(`${location.pathname}${query.join("")}`);
    };

    useEffect(() => {
        if (params.page) {
            params.skip = (params.page - 1) * 30;
        }
        if (!params.status || params.status === "Request") {
            params.status = "Request";
        }
        dispatch(getVerifiedList(params));
        // eslint-disable-next-line
    }, [dispatch, location.search]);

    return <ReviewerVerifiedSearch searchForm={register} onSubmit={handleSubmit(onSearch)} />;
};

export default ReviewerVerifiedSearchContainer;

import * as Sentry from "@sentry/browser";
import moment from "moment";

export const numberWithCommas = x => {
    if (x) {
        return typeof x !== "number"
            ? x.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return 0;
};

export const addHyphenToNumber = val =>
    val
        ? val
              .replace(/[^0-9]/g, "")
              .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/, "$1-$2-$3")
              .replace("--", "-")
        : "";

export const convertBoolean = status => {
    return status ? "O" : "X";
};

export const convertDate = date => {
    return moment(date).format("YYYY.MM.DD");
};

export const convertDateWithTime = date => {
    return moment(date).format("YYYY.MM.DD HH:MM:ss");
};

export const countArrayLength = array => {
    return numberWithCommas(array?.length);
};

export const getProjectStatusByParams = status => {
    switch (status) {
        case "recruit":
            return "모집 중인 프로젝트가 없습니다.";
        case "submit":
            return "제출 중인 프로젝트가 없습니다.";
        case "complete":
            return "제출 완료된 프로젝트가 없습니다.";
        default:
            return "검수 중인 프로젝트가 없습니다.";
    }
};

export const convertProjectCategory = category => {
    switch (category) {
        case "Delivery":
            return "배송형";
        case "Visit":
            return "방문형";
        case "Buy":
            return "구매형";
        case "Reporters":
            return "기자단";
        case "None":
            return "자율형";
    }
};

export const convertPaymentStatusToText = paymentStatus => {
    switch (paymentStatus) {
        case "paid":
            return "O";
        case "ready":
            return "X";
        case "pass":
            return "통과";
        default:
            return paymentStatus;
    }
};

export const convertPaymentCategoryToText = paymentCategory => {
    switch (paymentCategory) {
        case "card":
            return "카드";
        case "point":
            return "포인트";
        case "deposit":
            return "무통장";
        case "trans":
            return "계좌이체";
        default:
            return paymentCategory;
    }
};

export const convertAgeRangeToText = ageRange => {
    switch (ageRange) {
        case "10s":
            return "10대";
        case "20s":
            return "20대";
        case "30s":
            return "30대";
        case "40s":
            return "40대";
        case "50s":
            return "50대 이상";

        default:
            return ageRange;
    }
};

export const callDirectApi = async (callFunction, params, isGetReturnVal) => {
    if (!callFunction) {
        return console.warn("api 함수를 넣어주세요.");
    }

    try {
        const res = await callFunction(params);
        if (isGetReturnVal) {
            return res;
        }
        return res.status === 200;
    } catch (error) {
        Sentry.captureException(error);
        console.log("error::", error.response);
        if (isGetReturnVal) {
            throw new Error(
                error.response.data.message || error.response.data || "서버 에러입니다."
            );
        }
        return false;
    }
};

export const openNewTab = url => {
    window.open(url, "_blank");
};

export const convertGrade = grade => {
    switch (grade) {
        case "b":
            return "실버";
        case "c":
            return "골드";
        case "d":
            return "다이아";
        case "z":
            return "블랙리스트";
        default:
            return "화이트";
    }
};

export const convertLoginMethod = loginMethod => {
    switch (loginMethod) {
        case "local":
            return "일반";
        case "naver":
            return "네이버";
        case "apple":
            return "애플";

        default:
            return "-";
    }
};

export const convertScoreType = type => {
    switch (type) {
        case "register":
            return "회원가입";
        case "login":
            return "로그인";
        case "correctSubmit":
            return "기간 내 리뷰 제출 완료";
        case "evaluate":
            return "품평";
        case "recommend":
            return "리뷰어 평가 : 추천";
        case "noneRecommend":
            return "리뷰어 평가 : 비추천";
        case "cancel":
            return "선정 후 취소";
        case "unsubmit":
            return "기간 내 리뷰 미제출";
        case "notFollow":
            return "가이드 라인 불이행";
        case "noShow":
            return "연락두절 또는 노쇼";
        case "cheat":
            return "허위 URL/비공개 또는 삭제";

        default:
            return "-";
    }
};

export const convertSuggestionStatus = status => {
    switch (status) {
        case "Waiting":
            return "수락 대기 중";
        case "Running":
            return "진행 중";
        case "Submitted":
            return "리뷰 제출 완료";
        case "Rejected":
            return "거절";
        case "NoResponse":
            return "무응답 취소";
        case "Refunded":
            return "환급완료";
        case "Complete":
            return "종료";
        case "Canceled":
            return "제안 취소";
        case "Issued":
            return "포인트 지급 완료";
        default:
            return status;
    }
};

export const convertSuggestionReason = reason => {
    switch (reason) {
        case "itemName":
            return "제공 내역이 부족함";
        case "points":
            return "리워드 포인트가 부족함";
        case "guideLine":
            return "가이드라인 난이도가 높음";
        case "submitEndAt":
            return "제출 기간이 짧음";
        case "etc":
            return "기타";
        default:
            return reason;
    }
};

export const convertFaqTag = tag => {
    switch (tag) {
        case "reHowToUse":
            return "리뷰어 이용방법";
        case "reOrder":
            return "거래하기";
        case "reSuggestion":
            return "제안하기";
        case "reRequest":
            return "리뷰어 신청";
        case "reChosen":
            return "리뷰어 선정";
        case "reWrite":
            return "리뷰 작성";
        case "reSubmit":
            return "리뷰 제출";
        case "rePoints":
            return "포인트 관리";
        case "reAccount":
            return "계정 관리";
        case "reEtc":
            return "기타";

        case "bizHowToUse":
            return "비즈니스 이용방법";
        case "bizSubs":
            return "구독형";
        case "bizOrder":
            return "거래하기";
        case "bizSuggestion":
            return "제안하기";
        case "bizProject":
            return "프로젝트 등록";
        case "bizRecruit":
            return "리뷰어 모집";
        case "bizChosen":
            return "리뷰어 선정";
        case "bizCheckReview":
            return "리뷰 제출 확인";
        case "bizPayment":
            return "결제 관리";
        case "bizAccount":
            return "계정 관리";
        case "bizEtc":
            return "기타";

        default:
            return "-";
    }
};

export const convertTarget = target => {
    const lowerCaseTarget = target.toLowerCase();

    switch (lowerCaseTarget) {
        case "all":
            return "전체";

        case "normal":
        case "reviewer":
            return "리뷰어";

        case "seller":
        case "business":
        case "biz":
            return "비즈니스";

        default:
            return "-";
    }
};

export const convertRequestStatus = status => {
    switch (status) {
        case "request":
            return "미해결";
        case "complete":
            return "해결";
        case "cancel":
            return "취소";

        default:
            return "-";
    }
};

export const convertRequestType = type => {
    switch (type) {
        case "howTouse":
            return "이용방법 문의";
        case "project":
            return "프로젝트 관련문의";
        case "point":
            return "포인트 관련문의";
        case "loginAndService":
            return "로그인/서비스 이용 문의";
        case "suggestToUs":
            return "리뷰쉐어에게 제안하기";
        case "selfService":
            return "셀프 서비스 문의";
        case "partnerService":
            return "파트너 서비스 문의";
        case "paymentService":
            return "결제 관련 문의";
        case "tax":
            return "세금계산서/현금영수증 문의";
        case "etc":
            return "기타";

        default:
            return "-";
    }
};

export const convertProjectPlan = recruitmentForm => {
    if (recruitmentForm) {
        return recruitmentForm.plan;
    }
    return "";
};

export const convertReceiptType = type => {
    switch (type) {
        case "TaxBill":
            return "세금계산서 발행";
        case "EvidenceBiz":
            return "현금영수증\n(사업자 지출 증빙)";
        case "InComeDeduct":
            return "현금영수증\n(소득 공제)";
        default:
            return "";
    }
};

export const returnTagColor = status => {
    switch (status.toLowerCase()) {
        case "running":
        case "submit":
        case "submitted":
        case "paid":
        case "reward":
        case "issued":
        case "gift":
            return "blue-lighter";
        case "request":
        case "ready":
        case "waiting":
        case "recruit":
        case "choice":
            return "green-lighter";
        case "reject":
        case "rejected":
        case "cancel":
        case "canceled":
        case "cancelled":
        case "pause":
        case "noresponse":
        case "refund":
        case "denied":
            return "red-lighter";
        case "deny":
            return "red";
        case "confirmed":
        case "complete":
            return "gray";
        case "expired":
        case "retrieve":
            return "gray-light";
        default:
            return "gray";
    }
};

export const convertVerifyStatus = status => {
    switch (status) {
        case "Request":
            return "신청";
        case "Deny":
            return "거절";
        case "Verified":
            return "인증됨";

        default:
            return "-";
    }
};

export const convertManagerName = nickname => {
    switch (nickname) {
        case "Tom":
            return "김효진";
        case "Pine":
            return "박계환";
        case "Yerin":
            return "이예린";
        case "Seongjun":
            return "권성준";
        default:
            return nickname;
    }
};

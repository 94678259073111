import React from "react";
import MessengerFilter from "components/messenger/MessengerFilter";
import MessageBoxList from "components/messenger/MessageBoxList";
import MessageList from "components/messenger/MessageList";

const MessengerPage = () => (
    <>
        <MessengerFilter />
        <div className="flex">
            <MessageBoxList />
            <MessageList />
        </div>
    </>
);

export default MessengerPage;

import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import cx from "classnames";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Checkbox,
    Box
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CustomTableCell from "components/common/CustomTableCell";
import { IconFont } from "reviewshare-common-component";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { useAllTalent, useTalentAction } from "lib/customHooks/useTalent";
import { numberWithCommas, convertBoolean } from "lib/common";
import { convertTalentStatus } from "lib/utils/talent";
import { convertTalentCategory } from "lib/utils/category";
import TalentButtons from "components/talent/TalentButtons";

import "./TalentList.scss";

const tableHeaderList = [
    { label: "서비스 ID", align: "center" },
    { label: "상태", align: "center" },
    { label: "서비스명", align: "left" },
    { label: "카테고리", align: "center" },
    { label: "가격", align: "center" },
    { label: "리뷰쉐어 픽", align: "center" },
    { label: "리뷰어 ID", align: "center" },
    { label: "닉네임", align: "center" }
];
const limit = 40;
const TalentList = () => {
    const history = useHistory();
    const { category } = useParams();
    const { query, search } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;
    const { getTalentDetail } = useTalentAction();

    const { list, counts } = useAllTalent();

    const [selectedItems, setSelectedItems] = useState([]);

    const onChangePagination = (e, num) => {
        const newQuery = createSearchQuery({ ...query, skip: (num - 1) * limit });
        history.replace({ path: `/talents/${category}`, search: newQuery });
    };

    const onHandleCheck = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        const newSelectedItems = [...selectedItems];
        const index = newSelectedItems.indexOf(id);

        if (index > -1) {
            newSelectedItems.splice(index, 1);
        } else {
            newSelectedItems.push(id);
        }

        setSelectedItems(newSelectedItems);
    };

    useEffect(() => {
        setSelectedItems([]);
    }, [search]);

    return (
        <div id="talent-list">
            <TalentButtons selectedItems={selectedItems} />
            <TableContainer>
                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>
                                <Checkbox
                                    color="primary"
                                    checked={false}
                                    onClick={() => setSelectedItems([])}
                                />
                            </CustomTableCell>
                            {tableHeaderList.map((headerItem, index) => (
                                <CustomTableCell align={headerItem.align} key={index}>
                                    {headerItem.label}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list &&
                            list.length > 0 &&
                            list.map((talent, index) => {
                                const isSelected = selectedItems.indexOf(talent._id) > -1;

                                return (
                                    <TableRow
                                        key={index}
                                        hover
                                        className={cx("talent-list-row", {
                                            "deleted-item": talent.isDeleted
                                        })}
                                        selected={isSelected}
                                    >
                                        <CustomTableCell>
                                            <Checkbox
                                                color="primary"
                                                checked={isSelected}
                                                disabled={talent.isDeleted && !isSelected}
                                                onClick={e => onHandleCheck(e, talent._id)}
                                            />
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {talent._id}
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {convertTalentStatus(talent.status)}
                                        </CustomTableCell>
                                        <CustomTableCell
                                            className="open-detail"
                                            onClick={() => getTalentDetail(talent._id)}
                                        >
                                            <IconFont icon="icon_project" />
                                            {talent.title}
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {convertTalentCategory(talent.category?.name)}
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {numberWithCommas(talent.price?.real)}P
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {convertBoolean(talent.pick?.active)}
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {talent.owner?._id}
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                            {talent.owner?.nickname}
                                        </CustomTableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center" style={{ margin: "20px 0" }}>
                <Pagination
                    count={Math.ceil(counts / limit)}
                    color="primary"
                    onChange={onChangePagination}
                />
            </Box>
        </div>
    );
};

export default TalentList;

import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import useDirectApi from "lib/customHooks/useDirectApi";
import { numberWithCommas, callDirectApi } from "lib/common";
import { getFeedPickList as getFeedPickListAPI, setPickList as setPickListAPI } from "lib/api/feed";
import AddFeedPick from "components/feed/AddFeedPick";

import "./FeedPickList.scss";

const FeedPickList = () => {
    const { data: feedPick, fetch: getFeedPickList } = useDirectApi({
        api: getFeedPickListAPI
    });
    const [checked, setChecked] = useState([]);

    const onGetFeedPickList = () => {
        getFeedPickList({ limit: 999 });
    };

    const onSetPickFeed = async (type, value) => {
        if (["add", "remove"].indexOf(type) === -1) return;
        try {
            if (type === "add") {
                if (!value) return;
                await callDirectApi(setPickListAPI, {
                    feedIdList: [value],
                    process: "active"
                });
            }
            if (type === "remove") {
                await callDirectApi(setPickListAPI, { feedIdList: checked, process: "inactive" });
            }
            onGetFeedPickList();
        } catch (e) {
            window.alert("설정에 실패했습니다.");
        }
    };

    const handleToggle = value => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    useEffect(() => {
        onGetFeedPickList();
    }, []);

    return (
        <div id="feed-pick-page">
            <AddFeedPick setPickFeed={onSetPickFeed} />
            <div className="feed-list">
                <p className="total-counts">
                    <span>
                        선택 {numberWithCommas(checked.length)}/
                        {numberWithCommas(feedPick?.totalCount)}
                    </span>
                    <Button
                        className="search-btn"
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => onSetPickFeed("remove")}
                        disabled={!checked.length}
                    >
                        삭제
                    </Button>
                </p>
                <div className="feed-items">
                    {(feedPick?.list || []).map(feed => {
                        const labelId = `feed-${feed.id}`;

                        return (
                            <ListItem
                                key={feed.id}
                                role="listitem"
                                className="feed-item"
                                button
                                onClick={() => handleToggle(feed.id)}
                            >
                                <div className="checkbox-icon">
                                    <Checkbox
                                        checked={checked.indexOf(feed.id) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            "aria-labelledby": labelId
                                        }}
                                    />
                                </div>
                                <div className="feed-img">
                                    <img src={feed.reviewPhoto} alt="피드 이미지" />
                                </div>
                                <span className="feed-id">{feed.id}</span>
                            </ListItem>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default FeedPickList;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { TableContainer, Table, TableHead, TableRow, TableBody } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import { getPostList } from "stores/modules/post";
import NoticeDetailModalContainer from "containers/notice/NoticeDetailModalContainer";
import CustomTableCell from "components/common/CustomTableCell";
import ListWrapper from "components/common/ListWrapper";
import { convertTarget, callDirectApi } from "lib/common";
import { removePost as removePostAPI } from "lib/api/post";

const tableHeaderList = [
    { label: "ID", align: "center" },
    { label: "대상", align: "center" },
    { label: "제목", align: "center" },
    { label: "작성일", align: "center" },
    { label: "조회수", align: "center" }
];

const PAGE_LIMIT = 10;
const NoticeListContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { search, query } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;

    const [noticeModal, handleNoticeModal] = useState(false);
    const [selectedNoticeIdForModal, setNoticeIdForModal] = useState(null);
    const [checkedList, handleCheckedList] = useState([]);

    const totalCount = useSelector(({ post }) => post.totalCount);
    const noticeList = useSelector(({ post }) => post.list);

    const openNoticeModal = noticeId => {
        handleNoticeModal(true);
        setNoticeIdForModal(noticeId);
    };

    const onCloseNoticeModal = () => {
        handleNoticeModal(false);
        setNoticeIdForModal(null);
        dispatch(getPostList({ ...query, type: "notice", skip: Number(query.skip) || 0 }));
    };

    const handlePage = (e, pageNum) => {
        const newQuery = createSearchQuery({ ...query, skip: (pageNum - 1) * PAGE_LIMIT });
        history.replace({ path: location.pathname, search: newQuery });
    };

    const onClickItem = noticeId => {
        if (checkedList.indexOf(noticeId) >= 0) {
            handleCheckedList(checkedList.filter(id => id !== noticeId));
        } else {
            handleCheckedList([...checkedList, noticeId]);
        }
    };

    const onRemove = async () => {
        if (checkedList.length === 0) {
            return;
        }
        console.log("checkedList:", checkedList);

        try {
            await callDirectApi(removePostAPI, checkedList.join(","), true);
            history.go(0);
        } catch (error) {
            console.log("Error in remove posts:", error);
            window.alert("삭제에 실패하였습니다.");
        }
    };

    useEffect(() => {
        handleCheckedList([]);
    }, [search]);

    return (
        <>
            {noticeModal && (
                <NoticeDetailModalContainer
                    modalStatus={noticeModal}
                    noticeId={selectedNoticeIdForModal}
                    onClose={onCloseNoticeModal}
                />
            )}
            <ListWrapper
                currentPage={Number(query.skip) / PAGE_LIMIT + 1 || 1}
                handlePage={handlePage}
                totalCount={totalCount}
                pageLimit={10}
                onRemove={onRemove}
            >
                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>
                                    <Checkbox color="primary" />
                                </CustomTableCell>
                                {tableHeaderList.map((headerItem, index) => (
                                    <CustomTableCell
                                        align={headerItem.align}
                                        key={index}
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        {headerItem.label}
                                    </CustomTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {noticeList &&
                                noticeList.length > 0 &&
                                noticeList.map((notice, index) => {
                                    return (
                                        <TableRow key={index} hover>
                                            <CustomTableCell width={20}>
                                                <Checkbox
                                                    color="primary"
                                                    onChange={() => {
                                                        onClickItem(notice.noticeId);
                                                    }}
                                                />
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => openNoticeModal(notice.noticeId)}
                                                width={20}
                                            >
                                                {notice.noticeId}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="center"
                                                width={100}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {convertTarget(notice.target)}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                align="left"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => openNoticeModal(notice.noticeId)}
                                            >
                                                {notice.title}
                                            </CustomTableCell>
                                            <CustomTableCell align="center" width={200}>
                                                {moment(notice.createdAt).format(
                                                    "YYYY-MM-DD HH:mm"
                                                )}
                                            </CustomTableCell>
                                            <CustomTableCell align="center" width={20}>
                                                {notice.views}
                                            </CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ListWrapper>
        </>
    );
};

export default NoticeListContainer;

import React from "react";
import { Link } from "react-router-dom";
import { Button, IconFont } from "reviewshare-common-component";

import "./Header.scss";

import reviewshareLogo from "assets/images/logo/reviewshare_logo.svg";

const Header = ({ name, onLogout }) => (
    <header className="business-center-header">
        <div className="logo-area">
            <a href={process.env.REACT_APP_MAIN_SERVICE_URL} className="reviewshare-logo">
                <img src={reviewshareLogo} alt="리뷰쉐어 로고" />
            </a>
            <Link to="/main" className="admin-title">
                <h2>관리자 센터</h2>
            </Link>
            <a
                href={`${process.env.REACT_APP_API_URL}/v2/signin/facebook`}
                className="rs-button theme4 size-xs connect-insta-btn square"
                rel="nosublink"
            >
                <IconFont icon="icon_instagram" />
            </a>
        </div>
        <div className="header-menu">
            <p className="business-name">{name} 담당자님</p>
            {/* <Link to="/" className="move-manage-my-info-btn">
                내 정보 관리
            </Link> */}
            <Button className="logout-btn theme8 size-xs" onClick={onLogout}>
                로그아웃
            </Button>
        </div>
    </header>
);

export default Header;

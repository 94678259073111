import React from "react";
import cx from "classnames";
import Button from "@material-ui/core/Button";

import "./PaymentFilter.scss";

const PaymentFilter = ({ search, filter, setFilter }) => (
    <div id="payment-filter">
        <fieldset>
            <div className="field">
                <label htmlFor="payment-filter-id">비즈니스 ID</label>
                <input
                    type="text"
                    id="payment-filter-id"
                    className={cx({ isRequired: !filter.userId })}
                    value={filter.userId}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            search();
                        }
                    }}
                    onChange={e => {
                        const { value } = e.target;
                        setFilter(prev => ({ ...prev, userId: value }));
                    }}
                    placeholder="필수"
                />
            </div>
            <div className="field">
                <label htmlFor="suggestion-filter-keyword">프로젝트/제안/서비스 ID</label>
                <input
                    type="text"
                    id="suggestion-filter-keyword"
                    value={filter.keyword}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            search();
                        }
                    }}
                    onChange={e => {
                        const { value } = e.target;
                        setFilter(prev => ({ ...prev, keyword: value }));
                    }}
                    placeholder="선택"
                />
            </div>
        </fieldset>
        <div className="button-box">
            <Button variant="contained" color="primary" onClick={search}>
                검색
            </Button>
        </div>
    </div>
);

export default PaymentFilter;

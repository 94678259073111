import React from "react";
import HeaderContainer from "containers/core/HeaderContainer";
import SideMenuContainer from "containers/core/SideMenuContainer";

import "./BusinessPageTemplate.scss";

const BusinessPageTemplate = ({ children }) => (
    <div className="business-page-template">
        <HeaderContainer />
        <div className="page-body">
            <SideMenuContainer />
            <div className="main-contents">
                <div className="main-contents-body">{children}</div>
            </div>
        </div>
    </div>
);

export default BusinessPageTemplate;

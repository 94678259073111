import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "@material-ui/core/Modal";

import AlertModal from "components/common/AlertModal";
import NoticeDetailModal from "components/notice/NoticeDetailModal";
import { getNotice } from "stores/modules/post";
import { callDirectApi } from "lib/common";
import { updatePost as updatePostAPI } from "lib/api/post";

let noticeDefaultValue = {
    title: "",
    target: ""
};

const NoticeDetailModalContainer = ({ modalStatus, onClose, noticeId }) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: noticeDefaultValue,
        shouldUnregister: false
    });
    const [noticeContents, setNoticeContents] = useState("");
    const [alertModal, handleAlertModal] = useState(false);
    const notice = useSelector(({ post }) => post.detailContents);

    const submitData = async data => {
        try {
            await callDirectApi(
                updatePostAPI,
                {
                    postId: noticeId,
                    postObj: {
                        contents: noticeContents,
                        title: data.title,
                        type: "notice",
                        target: data.target,
                        isHide: data.isHide
                    }
                },
                true
            );
            handleAlertModal(true);
        } catch (error) {
            console.log("Write notice error.");
            console.log(error);
            window.alert("수정에 실패하였습니다." + error);
        }
    };

    useEffect(() => {
        dispatch(getNotice(noticeId));
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if (notice) {
            setNoticeContents(notice.contents);
            noticeDefaultValue.title = notice.title;
            noticeDefaultValue.target = notice.target;
            noticeDefaultValue.isHide = notice.isHide;

            setValue("title", notice.title);
            setValue("target", notice.target);
            setValue("isHide", notice.isHide);
        }
        // eslint-disable-next-line
    }, [notice, setValue]);

    if (!notice) return null;

    return (
        <Modal open={modalStatus} onClose={onClose}>
            <div>
                {alertModal && (
                    <AlertModal
                        handleModal={() => {
                            handleAlertModal(false);
                            onClose();
                        }}
                    >
                        <p>수정이 완료되었습니다.</p>
                    </AlertModal>
                )}
                <NoticeDetailModal
                    notice={notice}
                    noticeId={noticeId}
                    register={register}
                    noticeContents={noticeContents}
                    setNoticeContents={setNoticeContents}
                    updateData={handleSubmit(submitData)}
                />
            </div>
        </Modal>
    );
};

export default NoticeDetailModalContainer;

import React, { useState } from "react";
import { IconFont } from "reviewshare-common-component";
import moment from "moment";
import { Button, TextField, Modal } from "@material-ui/core";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { addHyphenToNumber, numberWithCommas } from "lib/common";
import { convertApplyCancelReason } from "lib/utils/project";
import { convertDepositStatus } from "lib/utils/securityDeposit";

import "./ProjectRequestModal.scss";

const STATUS_LIST = [
    { label: "신청", value: "request", defaultDisabled: true },
    { label: "선정", value: "chosen" },
    { label: "선정 취소(비즈니스 귀책)", value: "cancelByBizFault" },
    { label: "프로젝트 취소(리뷰어 귀책)", value: "cancelByReviewerFault" },
    { label: "취소 요청", value: "applyForCancel", defaultDisabled: true },
    { label: "제출", value: "submitted", defaultDisabled: true },
    { label: "미제출", value: "unsubmitted", defaultDisabled: true }
];
const ProjectRequestModal = ({
    modalStatus,
    requestItem,
    onClose,
    requestItemForm,
    updateProjectRequest,
    unsubmitRequest,
    changeSubmitEndAt,
    submitUrl
}) => {
    const [editSubmittedInfoStatus, setEditSubmittedInfoStatus] = useState(false);

    return (
        <Modal open={modalStatus} onClose={onClose}>
            <div className="project-request-modal">
                <h2 className="modal-title">신청 ID {requestItem._id}</h2>
                <div className="modal-contents">
                    <div className="field-box">
                        <div className="field status action">
                            <h3 className="field-title">상태</h3>
                            <div className="field-content">
                                <ul className="status-list">
                                    {STATUS_LIST.map((item, index) => (
                                        <li key={index}>
                                            <input
                                                type="radio"
                                                name="status"
                                                id={`modal-status-${item.value}`}
                                                value={item.value}
                                                ref={requestItemForm}
                                                disabled={
                                                    item.defaultDisabled || requestItem.pointIssued
                                                }
                                            />
                                            <label htmlFor={`modal-status-${item.value}`}>
                                                {item.label}
                                            </label>
                                        </li>
                                    ))}
                                    {/* <li>
                                        <input
                                            type="radio"
                                            name="status"
                                            id="staus-preChosen"
                                            value="preChosen"
                                            ref={requestItemForm}
                                        />
                                        <label htmlFor="staus-preChosen">임시 선정</label>
                                    </li> */}
                                </ul>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={updateProjectRequest}
                                    disabled={requestItem.pointIssued}
                                >
                                    상태 변경
                                </Button>
                            </div>
                            {requestItem.pointIssued && (
                                <p className="caution-text">
                                    * 이미 포인트가 지급되어 상태 변경이 불가합니다. 개발팀에게
                                    요청주세요. (미제출 처리는 가능)
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="date-item-list">
                        <div className="field-box">
                            <div className="field row">
                                <h3 className="field-title">
                                    보증금 <IconFont icon="deposit" />
                                </h3>
                                <div className="field-content">
                                    <p>{numberWithCommas(requestItem?.securityDeposit?.points)}P</p>
                                </div>
                            </div>
                            <div className="field row">
                                <h3 className="field-title">상태</h3>
                                <div className="field-content">
                                    <p>
                                        {convertDepositStatus(
                                            requestItem?.securityDeposit?.status
                                        ) || "-"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="etc-info">
                        <div className="field-box column">
                            <div className="field row">
                                <h3 className="field-title">리뷰어 ID</h3>
                                <div className="field-content">
                                    <p>{requestItem.reviewerId}</p>
                                </div>
                            </div>
                            <div className="field row">
                                <h3 className="field-title">리뷰어 이메일</h3>
                                <div className="field-content">
                                    <p>{requestItem.email}</p>
                                </div>
                            </div>
                            <div className="field row">
                                <h3 className="field-title">리뷰어 닉네임(이름)</h3>
                                <div className="field-content">
                                    <p>
                                        {requestItem.nickname}({requestItem.name})
                                    </p>
                                </div>
                            </div>
                            <div className="field row">
                                <h3 className="field-title">리뷰어 휴대폰 번호</h3>
                                <div className="field-content">
                                    <p>{addHyphenToNumber(requestItem.phoneNumber)}</p>
                                </div>
                            </div>
                            <div className="field row">
                                <h3 className="field-title">리뷰어 sns</h3>
                                <div className="field-content">
                                    {requestItem.sns ? (
                                        <a
                                            href={requestItem.sns}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {requestItem.sns}
                                        </a>
                                    ) : (
                                        "-"
                                    )}
                                </div>
                            </div>
                            <div className="field row">
                                <h3 className="field-title">리뷰어 주소</h3>
                                <div className="field-content">{requestItem.address || "-"}</div>
                            </div>
                        </div>
                        <div className="field-box column">
                            <div className="field row">
                                <h3 className="field-title">프로젝트 ID</h3>
                                <div className="field-content">
                                    <p>{requestItem.projectId}</p>
                                </div>
                            </div>
                            <div className="field row">
                                <h3 className="field-title">프로젝트명</h3>
                                <div className="field-content">
                                    <p className="overflow-ellipse">{requestItem.projectTitle}</p>
                                </div>
                            </div>
                            <div className="field row">
                                <h3 className="field-title">프로젝트 sns</h3>
                                <div className="field-content">
                                    <p>{requestItem.projectChannel || "-"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="date-item-list">
                        <div className="field-box">
                            <div className="field">
                                <h3 className="field-title">신청일</h3>
                                <div className="field-content">
                                    <p>
                                        {moment(requestItem.requestedAt).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="field">
                                <h3 className="field-title">선정일</h3>
                                <div className="field-content">
                                    <p>{moment(requestItem.choiseDay).format("YYYY-MM-DD")}</p>
                                </div>
                            </div>

                            <div className="field submitEndAt action">
                                <h3 className="field-title">마감일</h3>
                                <div className="field-content">
                                    <TextField
                                        id="date"
                                        type="date"
                                        defaultValue={moment(requestItem.submitEndAt).format(
                                            "YYYY-MM-DD"
                                        )}
                                        inputProps={{
                                            name: "submitEndAt",
                                            ref: requestItemForm
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={changeSubmitEndAt}
                                        disabled={
                                            [
                                                "chosen",
                                                "applyForCancel",
                                                "submitted",
                                                "unsubmitted"
                                            ].indexOf(requestItem.status) === -1
                                        }
                                    >
                                        마감일 변경
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <ul className="field-box">
                        {requestItem.submittedAt && (
                            <div className="field submittedAt">
                                <h3 className="field-title">제출일</h3>
                                <div className="field-content">
                                    <p>
                                        {moment(requestItem.submittedAt).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )}
                                    </p>
                                </div>
                            </div>
                        )}
                        <li className="field">
                            <h3 className="field-title">결과물</h3>
                            <div className="field-content">
                                {requestItem.submittedUrl || requestItem.submittedFile ? (
                                    <a
                                        href={requestItem.submittedUrl || requestItem.submittedFile}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {requestItem.submittedUrl || requestItem.submittedFile}
                                    </a>
                                ) : (
                                    "없음"
                                )}
                            </div>
                        </li>
                        {requestItem.originFile && requestItem.originFile.length > 0 && (
                            <li className="field">
                                <h3 className="field-title">원본 파일</h3>
                                <div className="field-content">
                                    {requestItem.originFile.map((item, index) => (
                                        <p key={index}>
                                            <a
                                                href={item.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {item.url}
                                            </a>
                                        </p>
                                    ))}
                                </div>
                            </li>
                        )}
                    </ul>
                    {requestItem.submitHistory?.length > 0 && (
                        <div className="field-box">
                            <div className="field">
                                <h3 className="field-title">제출 히스토리</h3>
                                <div className="field-content">
                                    <TreeView
                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                        defaultExpandIcon={<ChevronRightIcon />}
                                    >
                                        {requestItem.submitHistory.map((history, index) => (
                                            <TreeItem
                                                key={index}
                                                nodeId={`title${index}`}
                                                label={`날짜:${
                                                    history.updatedAt
                                                        ? moment(history.updatedAt).format(
                                                              "YYYY-MM-DD HH:mm"
                                                          )
                                                        : ""
                                                }`}
                                            >
                                                {Object.keys(history).map((key, i) => {
                                                    if (key === "updatedAt") return null;

                                                    return (
                                                        <TreeItem
                                                            key={i}
                                                            nodeId={`item${i}`}
                                                            label={`${key}:${history[key]?._id ||
                                                                history[key]}`}
                                                        />
                                                    );
                                                })}
                                            </TreeItem>
                                        ))}
                                    </TreeView>
                                </div>
                            </div>
                        </div>
                    )}
                    <button
                        type="button"
                        className="edit-submitted-info-btn"
                        onClick={() => setEditSubmittedInfoStatus(!editSubmittedInfoStatus)}
                    >
                        제출 정보 변경{" "}
                        <IconFont
                            icon={editSubmittedInfoStatus ? "icon_arrow_up" : "icon_arrow_down"}
                        />
                    </button>
                    {editSubmittedInfoStatus && (
                        <>
                            <div className="field-box">
                                <div className="field row action new-submit-info">
                                    <TextField
                                        id="date"
                                        type="datetime-local"
                                        label="제출일"
                                        defaultValue={moment(
                                            requestItem.submittedAt || new Date()
                                        ).format("YYYY-MM-DD HH:mm:ss")}
                                        inputProps={{
                                            name: "submittedAt",
                                            ref: requestItemForm,
                                            disabled: !requestItem.projectChannel
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                    <input
                                        type="text"
                                        name="submittedUrl"
                                        defaultValue={requestItem.submittedUrl}
                                        disabled={!requestItem.projectChannel}
                                        ref={requestItemForm}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitUrl}
                                        disabled={!requestItem.projectChannel}
                                    >
                                        {requestItem.submittedAt ? "변경" : "제출"}
                                    </Button>
                                </div>
                            </div>
                            <p className="caution-text">제출방식이 url인 경우에만 이용해주세요.</p>
                        </>
                    )}
                    <hr />
                    {requestItem.cancelReason && (
                        <li className="field">
                            <h3 className="field-title">취소 사유</h3>
                            <div className="field-content">
                                <p>{convertApplyCancelReason(requestItem.cancelReason)}</p>
                                <textarea value={requestItem.cancelReasonDetail} disabled />
                            </div>
                        </li>
                    )}
                    {requestItem.applyForCanceledAt && (
                        <div className="field">
                            <h3 className="field-title">취소 요청일</h3>
                            <div className="field-content">
                                <p>
                                    {moment(requestItem.applyForCanceledAt).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                    )}
                                </p>
                            </div>
                        </div>
                    )}
                    {(requestItem.status === "cancelByBizFault" ||
                        requestItem.status === "cancelByReviewerFault") && (
                        <div className="field">
                            <h3 className="field-title">취소일</h3>
                            <div className="field-content">
                                <p>
                                    {requestItem.cancelledAt
                                        ? moment(requestItem.cancelledAt).format(
                                              "YYYY-MM-DD HH:mm:ss"
                                          )
                                        : "-"}
                                </p>
                            </div>
                        </div>
                    )}
                    <hr />
                    <div className="field">
                        <h3 className="field-title">선택한 옵션</h3>
                        <div className="field-content">
                            <p>{requestItem.options || "없음"}</p>
                        </div>
                    </div>
                    <div className="field">
                        <h3 className="field-title">전략</h3>
                        <div className="field-content">
                            <textarea value={requestItem.messageRequest || "없음"} disabled />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={unsubmitRequest}
                        disabled={!requestItem.submittedAt}
                    >
                        미제출 처리
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ProjectRequestModal;

import client from "./client";

export const getUserList = searchObj => client.post("/admin/users", searchObj);
export const getUserListByTag = (options = {}) =>
    client.get("/v3/users/admin/tags", { params: options });
export const getUserInfo = userId => client.get(`/admin/users/${userId}`);
export const searchBiz = options => client.post("/v3/users/admin/target-biz", options);
export const setBlockProjectRequest = ({ reviewerId, process }) =>
    client.patch(`/v3/users/${reviewerId}/impossible-participate`, { process });
export const setBlockPointRequest = ({ reviewerId, blockStatus, denyObject }) =>
    client.patch(`/reviewerStatus/${reviewerId}/impossiblePointRequest`, {
        blockStatus,
        denyObject
    });

export const sendMarketingEmail = emailData =>
    client.post(`${process.env.REACT_APP_SERVERLESS_URL}/mails/send`, emailData);

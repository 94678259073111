import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { forEach } from "lodash";
import ModalWrapper from "components/projectDetailModal/ModalWrapper";
import StatusInfo from "components/projectDetailModal/StatusInfo";
import DetailInfo from "components/projectDetailModal/DetailInfo";
import CropModal from "components/projectDetailModal/CropModal";
import ChangeBrandModal from "components/projectDetailModal/ChangeBrandModal";
import useDirectApi from "lib/customHooks/useDirectApi";
import GuidelineEditor from "components/projectDetailModal/GuidelineEditor";
import AlertModal from "components/common/AlertModal";
import HideScrollBackground from "components/common/HideScrollBackground";
import { resetProjectDetail } from "stores/modules/project";
import { updateProject as updateProjectAPI } from "lib/api/project";
import { createPhoto as createPhotoAPI } from "lib/api/photo";

const geocoder = window.daum?.maps?.services ? new window.daum.maps.services.Geocoder() : null;

const commaText = "&#44;";
const ProjectDetailModalContainer = ({ isOpen, closeModal }) => {
    const dispatch = useDispatch();
    const { register: projectForm, getValues, setValue, watch } = useForm();
    const imgRef = useRef(null);

    const formValues = watch(["type", "category", "sns", "itemOptions"]);

    const [projectError, setProjectError] = useState(null);
    const [guideline, setGuideline] = useState("");
    const [projectImageList, handleProjectImage] = useState([]);
    const [isOpenCropModal, handleCropModal] = useState(false);
    const [crop, setCrop] = useState({ aspect: 1 / 1 });
    const [newProjectAddress, setNewProjectAddress] = useState(null);
    const [projectAddress, setProjectAddress] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const projectDetail = useSelector(({ project }) => project.projectDetail);
    const reReviewingProjectDetail = useSelector(({ project }) => project.reReviewingProjectDetail);
    const [loading, setLoading] = useState(false);
    const [isOpenChangeBrandModal, handleChnageBrandModal] = useState(false);

    const { data: projectObj, fetch: fetchUpdateProject, error: updateError } = useDirectApi({
        api: updateProjectAPI
    });

    const hasRequestModify = !!reReviewingProjectDetail?._id;

    // TODO
    // projectCategory에 따라 주소, 제공내역등 바꿔 보여주기

    const removeProjectImage = index => {
        handleProjectImage(() =>
            projectImageList.map((image, i) => {
                if (i === index) {
                    return null;
                }
                return image;
            })
        );
    };

    const uploadImage = (index, file, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onloadend = () => {
            handleProjectImage(() => {
                const newPhotos = [...projectImageList];
                newPhotos[index] = {
                    file: file[0],
                    imagePreviewUrl: reader.result
                };
                return newPhotos;
            });

            if (callback) {
                callback();
            }
        };
    };

    const changeMainImage = file => {
        uploadImage(0, file, () => {
            handleCropModal(true);
        });
    };

    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);

    const createCropPreview = async (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = scaleX * crop.width;
        canvas.height = scaleY * crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            scaleX * crop.width,
            scaleY * crop.height,
            0,
            0,
            scaleX * crop.width,
            scaleY * crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error("Canvas is empty"));
                    return;
                }
                blob.name = fileName;
                //   window.URL.revokeObjectURL(previewUrl);
                //  window.URL.createObjectURL(blob);
                setCroppedImage(blob);
            }, "image/jpeg");
        });
    };

    const makeCroppedImage = crop => {
        if (imgRef.current && crop.width && crop.height) {
            createCropPreview(imgRef.current, crop, projectImageList[0].file.name);
        }
    };

    const closeCropModal = () => {
        if (croppedImage) {
            uploadImage(0, [croppedImage]);
        }
        handleCropModal(false);
    };

    const convertToTextFormat = values => {
        console.log("VALUES:", values);
        const newProjectObj = {};
        const otherSnsList = ["SelfMarket", "OnlineComm", "NaverCafe", "AppReview"];

        forEach(values, (value, key) => {
            if (value) {
                if (key === "itemOptions") {
                    const itemOptions = [];

                    forEach(value, item => {
                        if (item.option && item.value) {
                            itemOptions.push(item);
                        }
                    });

                    if (itemOptions.length > 0) {
                        newProjectObj.itemOptions = itemOptions;
                    } else {
                        newProjectObj.itemOptions = null;
                    }
                    return;
                }

                if (key === "hashTags") {
                    const hashTagArray = value.split(",");

                    if (hashTagArray.length === 0) return;
                    newProjectObj.hashTags = hashTagArray;
                    return;
                }

                if (key === "sns") {
                    if (otherSnsList.includes(value)) {
                        newProjectObj.otherSns = value;
                        newProjectObj.sns = "OtherSns";
                        return;
                    }
                }

                newProjectObj[key] = value;
            } else {
                newProjectObj[key] = null;
            }
        });

        if (newProjectAddress) {
            newProjectObj.address = newProjectAddress;
        }

        return newProjectObj;
    };

    const checkImageList = async () => {
        const imagePromise = [];

        projectImageList.forEach(async (image, index) => {
            if (!image) return;
            if (!image._id) {
                const formData = new FormData();
                formData.append("file", image.file);

                if (index === 0) {
                    formData.append("tags", "PROFILE");
                } else {
                    formData.append("tags", "DETAIL");
                }
                imagePromise.push(createPhotoAPI(formData));
            } else {
                imagePromise.push(null);
            }
        });

        const receivedPhotos = await Promise.all(imagePromise);
        const projectPhotos = projectImageList
            .filter(img => img)
            .map((projectImage, index) => {
                if (projectImage._id) {
                    return projectImage._id;
                }
                return receivedPhotos[index].data.photo._id;
            });

        return projectPhotos;
    };

    const updateProject = async () => {
        const values = getValues({ nest: true });
        const newProjectObj = convertToTextFormat(values);
        newProjectObj.photos = await checkImageList();
        newProjectObj.guideLine = guideline;
        fetchUpdateProject({
            projectId: projectDetail._id,
            projectObj: newProjectObj
        });
    };

    const searchAddress = () => {
        if (window.daum) {
            new window.daum.Postcode({
                oncomplete: data => {
                    geocoder.addressSearch(data.address, (results, status) => {
                        if (status === window.daum.maps.services.Status.OK) {
                            const result = results[0];
                            setNewProjectAddress(result.address);
                        }
                    });
                }
            }).open();
        }
    };

    const onAddOption = (e, index) => {
        const key = `itemOptions[${index}].value`;
        const { value } = e.target;
        const newValue = value.trim().replace(new RegExp(",", "g"), commaText);
        const values = getValues();
        setValue(key, `${values[key] ? `${values[key]},` : ""}${newValue}`);
        setValue(`newItemOption[${index}]`, "");
    };

    const onRemoveOption = (key, index) => {
        const values = getValues();
        const newValue = values[key].split(",").filter((val, itemIndex) => index !== itemIndex);
        setValue(key, newValue);
    };

    useEffect(() => {
        setNewProjectAddress(null);

        return () => {
            dispatch(resetProjectDetail());
        };
    }, []);

    useEffect(() => {
        if (!projectDetail?._id) return;

        handleProjectImage(() => [...projectDetail.photos]);

        if (projectDetail.address) {
            setProjectAddress(projectDetail.address.title || projectDetail.address.address_name);

            if (!projectDetail.address.title && !projectDetail.address.address_name) {
                setProjectAddress(
                    projectDetail.address.roadAddress || projectDetail.address.address
                );
            }
        }

        if (!projectDetail.owner || !projectDetail.owner.name) {
            projectDetail.owner = {};
            projectDetail.owner.name = "탈퇴한 회원";
        }

        if (projectDetail.guideLine.indexOf("</") >= 0) {
            setGuideline(projectDetail.guideLine);
        } else {
            setGuideline({
                ops: [
                    {
                        insert: projectDetail.guideLine
                    }
                ]
            });
        }
    }, [projectDetail?._id]);

    useEffect(() => {
        if (projectObj) {
            setLoading(false);
            closeModal();
        }

        if (updateError) {
            setLoading(false);
            setProjectError(updateError);
        }
        // eslint-disable-next-line
    }, [projectObj, updateError]);

    if (!projectDetail._id) return null;

    return (
        <>
            {loading && <HideScrollBackground />}
            <ModalWrapper isOpen={isOpen} closeModal={closeModal} updateProject={updateProject}>
                {projectError && (
                    <AlertModal handleModal={() => setProjectError(null)}>
                        <p>{projectError}</p>
                    </AlertModal>
                )}
                <div className="modal-content-wrapper">
                    <div className="prev-info">
                        <StatusInfo
                            projectDetail={projectDetail}
                            otherDetail={reReviewingProjectDetail}
                            projectForm={projectForm}
                            handleChnageBrandModal={handleChnageBrandModal}
                        />
                        <ChangeBrandModal
                            isOpen={isOpenChangeBrandModal}
                            closeModal={() => {
                                handleChnageBrandModal(false);
                            }}
                            brandInfo={{
                                id: projectDetail.enterprise,
                                name: projectDetail.brandName,
                                category: projectDetail.enterpriseCategory,
                                owner: projectDetail.owner._id
                            }}
                            projectId={projectDetail._id}
                            isSubs={projectDetail.recruitmentForm?.type === "subscription"}
                        />
                        <DetailInfo
                            guidelineEditor={
                                hasRequestModify ? (
                                    <div
                                        className="re-guideline"
                                        dangerouslySetInnerHTML={{
                                            __html: guideline
                                        }}
                                    />
                                ) : (
                                    <GuidelineEditor
                                        guideline={guideline}
                                        setGuideline={setGuideline}
                                    />
                                )
                            }
                            projectImageList={projectImageList}
                            removeProjectImage={removeProjectImage}
                            uploadImage={uploadImage}
                            changeMainImage={file => changeMainImage(file)}
                            projectForm={projectForm}
                            formValues={formValues}
                            addOption={onAddOption}
                            removeOption={onRemoveOption}
                            projectDetail={projectDetail}
                            otherDetail={reReviewingProjectDetail}
                            searchAddress={searchAddress}
                            newProjectAddress={newProjectAddress}
                            projectAddress={projectAddress}
                            updateProject={updateProject}
                        />
                        <CropModal
                            isOpen={isOpenCropModal}
                            closeModal={closeCropModal}
                            image={projectImageList[0]}
                            crop={crop}
                            setCrop={setCrop}
                            onLoad={onLoad}
                            makeCroppedImage={makeCroppedImage}
                        />
                    </div>
                    {hasRequestModify && (
                        <div className="next-info">
                            <StatusInfo
                                projectDetail={{
                                    ...reReviewingProjectDetail,
                                    request_count: projectDetail.request_count,
                                    chosenCount: projectDetail.chosenCount,
                                    recruits: projectDetail.recruits
                                }}
                                otherDetail={projectDetail}
                                projectForm={projectForm}
                            />
                            <DetailInfo
                                isRereviewing
                                guidelineEditor={
                                    <div
                                        className="re-guideline"
                                        dangerouslySetInnerHTML={{
                                            __html: reReviewingProjectDetail?.guideLine
                                        }}
                                    />
                                }
                                projectImageList={reReviewingProjectDetail?.photos}
                                projectForm={projectForm}
                                projectDetail={reReviewingProjectDetail}
                                newProjectAddress={newProjectAddress}
                                projectAddress={projectAddress}
                                otherDetail={projectDetail}
                            />
                        </div>
                    )}
                </div>
            </ModalWrapper>
        </>
    );
};

export default ProjectDetailModalContainer;

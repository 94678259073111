import React from "react";
import { useSelector } from "react-redux";
import EditUserInfo from "components/userManage/EditUserInfo";

const EditUserInfoContainer = () => {
    const userInfo = useSelector(({ user }) => user.userDetail);

    if (!userInfo) return null;

    return <EditUserInfo userInfo={userInfo} />;
};

export default EditUserInfoContainer;

import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IconFont } from "reviewshare-common-component";
import { getUserListByTag as getUserListByTagAPI } from "lib/api/user";
import { getAllTagList as getAllTagListAPI } from "lib/api/tag";
import { convertTarget, numberWithCommas, callDirectApi } from "lib/common";
import { selectUser } from "stores/modules/user";
import useDirectApi from "lib/customHooks/useDirectApi";
import TagList from "components/tag/TagList";

import style from "./SearchUserByTag.module.scss";

const SearchUserByTag = () => {
    const dispatch = useDispatch();
    const tagListRef = useRef();
    const {
        data: allTagData,
        fetch: getAllTagList,
        isFetching: isSearchingAllTagList
    } = useDirectApi({
        api: getAllTagListAPI
    });

    const allTagList = useMemo(() => allTagData?.list || [], [allTagData]);
    const [userList, setUserList] = useState([]);
    const [userCounts, setUserCounts] = useState(0);
    const [isSearchingUserList, setIsSearchingUserList] = useState(false);

    const openUserDetailMoal = user => {
        if (!user?._id) return;
        const formattedCategory = user.category === "Seller" ? "biz" : "reviewer";
        dispatch(selectUser({ userId: user._id, userType: formattedCategory, tab: "tag" }));
    };

    const onSearchByTag = async skip => {
        const { selectedTagList } = tagListRef.current;
        if (!selectedTagList?.length) return;

        try {
            setIsSearchingUserList(true);
            const { data } = await callDirectApi(
                getUserListByTagAPI,
                { ids: selectedTagList.join(","), skip, limit: 20 },
                true
            );

            if (skip > 0) {
                setUserList(prev => [...prev, ...data.list]);
            } else {
                setUserList(data.list);
            }
            setUserCounts(data.searchResultCount);
        } catch (e) {
        } finally {
            setIsSearchingUserList(false);
        }
    };

    useEffect(() => {
        getAllTagList();
    }, []);

    return (
        <div className={style.wrapper}>
            <div className={style.tagList}>
                <TagList
                    type="all"
                    tagList={allTagList}
                    isSearching={isSearchingAllTagList}
                    ref={tagListRef}
                />
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => onSearchByTag()}
                >
                    검색
                </Button>
            </div>
            <div className={style.userList}>
                <p className={style.userListTitle}>
                    해당 태그를 보유한 유저 목록({numberWithCommas(userCounts)})
                </p>
                <div className={style.scrollArea}>
                    <ul>
                        {userList.length > 0 &&
                            userList.map(item => (
                                <li
                                    onClick={() => openUserDetailMoal(item)}
                                    key={item._id}
                                    className={style.userItem}
                                >
                                    [{convertTarget(item.category)}] {item.nickname || item.name}(
                                    {item._id})
                                    <IconFont icon="icon_arrow_right">자세히 보기</IconFont>
                                </li>
                            ))}
                    </ul>

                    {isSearchingUserList ? (
                        <CircularProgress />
                    ) : userCounts > userList.length ? (
                        <button type="button" onClick={() => onSearchByTag(userList.length)}>
                            더보기
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default SearchUserByTag;

import { createAction, handleActions } from "redux-actions";
import { takeLeading } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaBase } from "lib/createRequestSaga";
import produce from "immer";
import * as talentAPI from "lib/api/talent";

export const RESET_TALENT_DETAIL = "talent/RESET_TALENT_DETAIL";
export const [
    GET_ALL_TALENT_LIST,
    GET_ALL_TALENT_LIST_SUCCESS,
    GET_ALL_TALENT_LIST_FAILURE
] = createRequestActionTypes("talent/GET_ALL_TALENT_LIST");

export const [
    GET_TALENT_DETAIL,
    GET_TALENT_DETAIL_SUCCESS,
    GET_TALENT_DETAIL_FAILURE
] = createRequestActionTypes("talent/GET_TALENT_DETAIL");

export const [
    UPDATE_TALENT_DETAIL,
    UPDATE_TALENT_DETAIL_SUCCESS,
    UPDATE_TALENT_DETAIL_FAILURE
] = createRequestActionTypes("talent/UPDATE_TALENT_DETAIL");

export const [
    DELETE_TALENT,
    DELETE_TALENT_SUCCESS,
    DELETE_TALENT_FAILURE
] = createRequestActionTypes("talent/DELETE_TALENT");

export const [
    SET_PICK_TALENT,
    SET_PICK_TALENT_SUCCESS,
    SET_PICK_TALENT_FAILURE
] = createRequestActionTypes("talent/SET_PICK_TALENT");

export const resetTalentDetail = createAction(RESET_TALENT_DETAIL);
export const getAllTalentList = createAction(GET_ALL_TALENT_LIST);
export const getTalentDetail = createAction(GET_TALENT_DETAIL);
export const updateTalentDetail = createAction(UPDATE_TALENT_DETAIL);
export const deleteTalent = createAction(DELETE_TALENT);
export const setPickTalent = createAction(SET_PICK_TALENT);

const initialState = {
    all: {
        counts: 0,
        list: []
    },
    detail: {}
};

const getAllTalentListSaga = createRequestSagaBase({
    type: GET_ALL_TALENT_LIST,
    api: talentAPI.getAllTalentList
});

const getTalentDetailSaga = createRequestSagaBase({
    type: GET_TALENT_DETAIL,
    api: talentAPI.getTalentDetail
});

const updateTalentDetailSaga = createRequestSagaBase({
    type: UPDATE_TALENT_DETAIL,
    api: talentAPI.updateTalentDetail,
    loading: true,
    alertErrorMsg: "서비스 업데이트에 실패했습니다."
});

const deleteTalentSaga = createRequestSagaBase({
    type: DELETE_TALENT,
    api: talentAPI.deleteTalent,
    loading: true,
    alertErrorMsg: "서비스 삭제에 실패했습니다."
});

const setPickTalentSaga = createRequestSagaBase({
    type: SET_PICK_TALENT,
    api: talentAPI.setPickTalent
});

export function* talentSaga() {
    yield takeLeading(GET_ALL_TALENT_LIST, getAllTalentListSaga);
    yield takeLeading(GET_TALENT_DETAIL, getTalentDetailSaga);
    yield takeLeading(UPDATE_TALENT_DETAIL, updateTalentDetailSaga);
    yield takeLeading(DELETE_TALENT, deleteTalentSaga);
    yield takeLeading(SET_PICK_TALENT, setPickTalentSaga);
}

const talent = handleActions(
    {
        [RESET_TALENT_DETAIL]: state =>
            produce(state, draft => {
                draft.detail = initialState.detail;
            }),
        [GET_ALL_TALENT_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.all = {
                    counts: payload.totalCounts,
                    list: payload.list
                };
            }),
        [GET_TALENT_DETAIL_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.detail = payload;
            }),
        [UPDATE_TALENT_DETAIL_SUCCESS]: state =>
            produce(state, draft => {
                draft.detail = initialState.detail;
            }),
        [DELETE_TALENT_SUCCESS]: state =>
            produce(state, draft => {
                draft.detail = initialState.detail;
            }),
        [SET_PICK_TALENT_SUCCESS]: (state, { prev }) =>
            produce(state, draft => {
                draft.all.list = state.all.list.map(item => {
                    if (prev.talentIdList.indexOf(item._id) > -1) {
                        return {
                            ...item,
                            pick: { ...item.pick, active: prev.process === "active" }
                        };
                    }
                    return item;
                });
            })
    },
    initialState
);

export default talent;

import React from "react";
import styled from "styled-components";
import cx from "classnames";
import { rem } from "theme";

const Icon = styled.i`
    font-size: ${props => (props.size ? props.size : rem(16))};

    span {
        display: none;
    }
`;
const IconFont = ({
 className, icon, size, style, onClick, children
}) => {
    const processedClassName = cx(className, icon, "icon");
    return (
        <Icon className={processedClassName} size={size} style={style} onClick={onClick}>
            <span>{children}</span>
        </Icon>
    );
};
export default IconFont;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AllPaymentList from "components/paymentList/AllPaymentList";
import { resetWalletInfo, getMyWallet } from "stores/modules/wallet";
import { resetPaymentInfo, getPaymentList } from "stores/modules/payment";
import { FILTER_INPUT_ITEMS } from "scheme/payment";
import SearchFilterItems from "components/common/SearchFilterItems";

const limit = 10;

const initialOptions = {
    userId: "",
    keyword: "",
    skip: 0,
    limit
};

const AllPaymentLIstContainer = () => {
    const dispatch = useDispatch();
    const { paymentList, paymentCounts } = useSelector(({ payment }) => payment);
    const { totalPoints } = useSelector(({ wallet }) => wallet);

    const [filter, setFilter] = useState(initialOptions);

    const onSearch = () => {
        if (!filter.userId) return;
        dispatch(getMyWallet(filter.userId));
        setFilter(prev => ({ ...prev, skip: 0 }));
        dispatch(getPaymentList({ ...filter, skip: 0 }));
    };

    useEffect(() => {
        onSearch();

        return () => {
            dispatch(resetPaymentInfo());
            dispatch(resetWalletInfo());
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div>
                <SearchFilterItems value="all-payment" items={FILTER_INPUT_ITEMS} />
                <AllPaymentList
                    list={paymentList}
                    counts={paymentCounts}
                    totalPoints={totalPoints}
                />
            </div>
        </>
    );
};

export default AllPaymentLIstContainer;

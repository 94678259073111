import React from "react";
import Button from "@material-ui/core/Button";
import { useSearchQuery } from "lib/customHooks/useSearchQuery";

import "./ReviewerVerifiedSearch.scss";

const ReviewerVerifiedSearch = ({ searchForm, onSubmit }) => {
    const { query } = useSearchQuery();

    return (
        <form className="reviewerVerified-search-area" onSubmit={onSubmit}>
            <div className="row-item">
                <h2>상태</h2>
                <ul>
                    <li>
                        <label htmlFor="status-request">
                            <input
                                type="radio"
                                name="status"
                                id="status-request"
                                value="Request"
                                ref={searchForm}
                            />
                            인증 요청
                        </label>
                    </li>
                    <li>
                        <label htmlFor="status-verified">
                            <input
                                type="radio"
                                name="status"
                                id="status-verified"
                                value="Verified"
                                ref={searchForm}
                            />
                            인증 완료
                        </label>
                    </li>
                </ul>
            </div>
            <div className="search-items">
                <div className="search-item">
                    <h3>리뷰어 ID</h3>
                    <input
                        type="text"
                        placeholder="리뷰어 ID를 입력해주세요."
                        ref={searchForm}
                        defaultValue={query.reviewerId}
                        name="reviewerId"
                    />
                </div>
            </div>
            <div className="btn-area">
                <Button variant="contained" color="primary" className="submit-btn" type="submit">
                    검색
                </Button>
            </div>
        </form>
    );
};

export default ReviewerVerifiedSearch;

import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Select, MenuItem } from "@material-ui/core";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { useOrderAction } from "lib/customHooks/useOrder";
import { convertOrderStatus } from "lib/utils/order";

import "./OrderFilter.scss";

const STATUS_LIST = [
    "request",
    "requestCancel",
    "reject",
    "running",
    "submit",
    "cancel",
    "complete"
];
const OrderFilter = () => {
    const history = useHistory();
    const { category } = useParams();
    const { query, search } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;
    const { getAllOrderList } = useOrderAction();

    const [selectedOptions, setSelectedOptions] = useState({
        status: query.status || undefined,
        id: query.id || undefined,
        talentId: query.talentId || undefined,
        reviewerId: query.reviewerId || undefined,
        bizId: query.bizId || undefined
    });

    const onChangeOption = e => {
        const { name, value } = e.target;
        const filteredValue = value === "all" || !value ? undefined : value;
        setSelectedOptions(prev => ({ ...prev, [name]: filteredValue }));
    };

    const onSearch = () => {
        const newQuery = createSearchQuery(selectedOptions);
        history.replace({ path: `/talents/${category}`, search: newQuery });
    };

    useEffect(() => {
        getAllOrderList(query);
    }, [search]);

    return (
        <div id="order-filter">
            <fieldset>
                <div className="field">
                    <label htmlFor="order-filter-status">상태</label>
                    <Select
                        id="order-filter-status"
                        value={selectedOptions.status || "all"}
                        name="status"
                        onChange={onChangeOption}
                    >
                        <MenuItem value="all">전체</MenuItem>
                        {STATUS_LIST.map((item, index) => (
                            <MenuItem value={item} key={index}>
                                {convertOrderStatus(item)}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div className="field">
                    <label htmlFor="order-filter-id">거래 ID</label>
                    <input
                        type="text"
                        id="order-filter-id"
                        name="id"
                        value={selectedOptions.id}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onSearch();
                            }
                        }}
                        onChange={onChangeOption}
                    />
                </div>
                <div className="field">
                    <label htmlFor="order-filter-talentId">서비스 ID</label>
                    <input
                        type="text"
                        id="order-filter-talentId"
                        name="talentId"
                        value={selectedOptions.talentId}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onSearch();
                            }
                        }}
                        onChange={onChangeOption}
                    />
                </div>
                <div className="field">
                    <label htmlFor="order-filter-reviewer-id">리뷰어 ID</label>
                    <input
                        type="text"
                        id="order-filter-reviewer-id"
                        name="reviewerId"
                        value={selectedOptions.reviewerId}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onSearch();
                            }
                        }}
                        onChange={onChangeOption}
                    />
                </div>
                <div className="field">
                    <label htmlFor="order-filter-bizId">비즈니스 ID</label>
                    <input
                        type="text"
                        id="order-filter-bizId"
                        name="bizId"
                        value={selectedOptions.bizId}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onSearch();
                            }
                        }}
                        onChange={onChangeOption}
                    />
                </div>
            </fieldset>
            <div className="button-box">
                <Button variant="contained" color="primary" onClick={onSearch}>
                    검색
                </Button>
            </div>
        </div>
    );
};

export default OrderFilter;

import client from "./client";

export const getReviewerScoreRecordList = ({ reviewerId, projectId, skip = 0, type }) =>
    client.get(
        `/admin/scoreRecords?reviewerId=${reviewerId}&skip=${skip}${
            projectId ? `&projectId=${projectId}` : ""
        }${type ? `&type=${type}` : ""}`
    );

export const setScoreRecord = scoreRecordObj => client.post("/admin/scoreRecords", scoreRecordObj);

export const cancelScoreRecord = ids => client.post("/admin/scoreRecords/cancel", ids);

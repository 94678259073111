import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { Button } from "reviewshare-common-component";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { useMessageBoxList } from "lib/customHooks/useMessenger";
import { selectMessageBox } from "stores/modules/messenger";
import { useSelectedMessageBox } from "lib/customHooks/useMessenger";

import "./MessageBoxList.scss";

const MessageBoxList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { query } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;

    const { list, counts } = useMessageBoxList();
    const messageBoxId = useSelectedMessageBox("messageBoxId");

    const onSelectMessageBox = boxInfo => {
        if (!boxInfo?.brandId || !boxInfo?.reviewerId || !boxInfo.brandId) return;

        dispatch(
            selectMessageBox({
                messageBoxId: boxInfo.messageBoxId,
                reviewerId: boxInfo.reviewerId,
                brandId: boxInfo.brandId
            })
        );
    };

    const onGetMoreMessageBoxList = () => {
        const newQuery = createSearchQuery({ ...query, skip: list.length });
        history.replace({ path: "/messenger", search: newQuery });
    };

    return (
        <div id="message-box-list">
            <ul>
                {list.map((item, index) => (
                    <li
                        key={index}
                        onClick={() => onSelectMessageBox(item)}
                        className={cx({ active: messageBoxId === item.messageBoxId })}
                    >
                        <p className="brand-name">브랜드: {item.brandName}</p>
                        <p className="reviewer-nickname">리뷰어: {item.nickname}</p>
                    </li>
                ))}
            </ul>
            {list.length < counts && (
                <Button className="theme5 size-s more-btn" onClick={onGetMoreMessageBoxList}>
                    더 보기
                </Button>
            )}
        </div>
    );
};

export default MessageBoxList;

import React from "react";
import { useForm } from "react-hook-form";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { Color } from "theme";
import SelectUser from "components/promotion/SelectUser";
import EnterPromotionInfo from "components/promotion/EnterPromotionInfo";

import "./CreatePromotionModal.scss";

const CreatePromotionModal = ({ closeModal }) => {
    const { register: createForm, getValues } = useForm();

    const createCoupon = () => {
        const {
            targetPlan,
            desc,
            startAt,
            endAt,
            discountRate
            // minSpendPrice,
            // maxDiscountPrice
        } = getValues();
        // console.log(minSpendPrice,maxDiscountPrice)
        if (!targetPlan || !desc || !startAt || !endAt || !discountRate) {
            window.alert("입력값을 확인해주세요");
        }
    };

    return (
        <Modal open onClose={closeModal}>
            <div id="create-promotion-modal">
                <h2 className="modal-title">프로모션 생성</h2>
                <div className="modal-content">
                    <SelectUser />
                    <EnterPromotionInfo createForm={createForm} />
                </div>
                <div className="modal-footer">
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: Color.greenDark }}
                        onClick={createCoupon}
                    >
                        생성
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CreatePromotionModal;

import React from "react";
import cx from "classnames";
import { useUserDetail } from "lib/customHooks/useUser";
import EditUserInfoContainer from "containers/userManage/EditUserInfoContainer";
import BizSubsInfo from "components/subscription/BizSubsInfo";
import UserTagCtrl from "components/userManage/UserTagCtrl";

const BizDetailModal = ({ tab, changeTab, children }) => {
    const userId = useUserDetail("_id");

    return (
        <div className="reviewer-detail-modal-wrapper">
            <nav className="manage-nav">
                <ul>
                    <li
                        className={cx({ active: tab === "userInfo" })}
                        onClick={() => changeTab("userInfo")}
                    >
                        회원 정보
                    </li>
                    <li
                        className={cx({ active: tab === "subsInfo" })}
                        onClick={() => changeTab("subsInfo")}
                    >
                        구독 정보
                    </li>
                    <li className={cx({ active: tab === "tag" })} onClick={() => changeTab("tag")}>
                        태그
                    </li>
                </ul>
            </nav>
            <h2 className="modal-title">회원 ID {userId}</h2>
            {tab === "userInfo" && <EditUserInfoContainer />}
            {tab === "subsInfo" && <BizSubsInfo />}
            {tab === "tag" && <UserTagCtrl userId={userId} />}
            <div className="modal-body">{children}</div>
        </div>
    );
};

export default BizDetailModal;

import { useSelector } from "react-redux";

// state
export const useUserState = value => useSelector(({ auth }) => auth[value]);

export const useUserDetail = value =>
    useSelector(({ user }) =>
        value ? user.userDetail && user.userDetail[value] : user.userDetail
    );
export const useSubsInfo = value =>
    useSelector(({ user }) => (value ? user.subsInfo[value] : user.subsInfo));

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { Color } from "theme";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { getUserExcel as getUserExcelAPI } from "lib/api/statistic";
import { setVerifiedStatus as setVerifiedStatusAPI } from "lib/api/reviewerStatus";
import { callDirectApi } from "lib/common";
import { selectUser } from "stores/modules/user";
import HideScrollBackground from "components/common/HideScrollBackground";
import ListWrapper from "components/common/ListWrapper";
import CustomTableCell from "components/common/CustomTableCell";
import UserListItem from "components/userManage/UserListItem/UserListItem";

const PAGE_LIMIT = 20;
const tableHeaderList = {
    reviewer: [
        { label: "회원 ID", align: "center", value: "_id" },
        { label: "이름", align: "center", value: "name" },
        { label: "닉네임", align: "center", value: "nickname" },
        { label: "등급", align: "center", value: "grade" },
        { label: "인증", align: "center", value: "verifyStatus" },
        { label: "이메일", align: "center", value: "email" },
        { label: "핸드폰 번호", align: "center", value: "phoneNumber" },
        { label: "가입방식", align: "center", value: "loginMethod" },
        { label: "기능 제한", align: "center", value: "block" },
        { label: "메모", align: "left", value: "memo" },
        { label: "포트폴리오", align: "left", value: "portfolio" },
        { label: "블로그", align: "left", value: "NaverBlog", width: `100px` },
        { label: "인스타", align: "left", value: "Instagram", width: `100px` },
        { label: "유튜브", align: "left", value: "Youtube", width: `100px` },
        { label: "틱톡", align: "left", value: "Tiktok", width: `100px` },
        { label: "릴스 평균 조회수", align: "left", value: "reelsAvgViews", width: `100px` },
        { label: "숏츠 평균 조회수", align: "left", value: "shortsAvgViews", width: `100px` },
        { label: "틱톡 평균 조회수", align: "left", value: "tiktokAvgViews", width: `100px` },
        { label: "주소", align: "left", value: "address" },
        { label: "성별", align: "center", value: "gender" },
        { label: "출생년도", align: "center", value: "birthOfYears" },
        { label: "가입일", align: "center", value: "createdAt" },
        { label: "마케팅수신", align: "center", value: "isAllowMarketing" }
    ],
    biz: [
        { label: "회원 ID", align: "center", value: "_id" },
        { label: "이름", align: "center", value: "name" },
        { label: "이메일", align: "center", value: "email" },
        { label: "핸드폰 번호", align: "center", value: "phoneNumber" },
        { label: "브랜드 수", align: "center", value: "enterpriseCounts" },
        { label: "브랜드명", align: "left", value: "enterprise" },
        { label: "보유 포인트", align: "center", value: "points" },
        { label: "가입방식", align: "center", value: "loginMethod" },
        { label: "가입일", align: "center", value: "createdAt" },
        { label: "마케팅수신", align: "center", value: "isAllowMarketing" }
    ]
};

const UserListContainer = ({ type }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { query } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;

    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const userList = useSelector(({ user }) => user[type].list);
    const userCounts = useSelector(({ user }) => user[type].total);

    const openUserDetailMoal = userId => {
        dispatch(selectUser({ userId, userType: type }));
    };

    const handlePage = (e, num) => {
        const newQuery = createSearchQuery({ ...query, skip: (num - 1) * PAGE_LIMIT });
        history.replace({ path: location.pathname, search: newQuery });
    };

    const onHandleCheck = id => {
        if (!id) return;

        if (id === "all") {
            if (selectedItem.length !== userList.length || selectedItem.length === 0) {
                setSelectedItem(userList.map(user => user._id));
                setSelectedAll(true);
            } else {
                setSelectedItem([]);
                setSelectedAll(false);
            }
            return;
        }

        const hasId = selectedItem.indexOf(id) >= 0;

        if (hasId) {
            setSelectedItem(selectedItem.filter(selectedId => selectedId !== id));
        } else {
            setSelectedItem(prev => [...prev, id]);
        }
    };

    const onDownload = async () => {
        setLoading(true);
        try {
            const result = await callDirectApi(
                getUserExcelAPI,
                type === "biz" ? "Seller" : "Normal",
                true
            );
            window.open(result.data);
        } catch (error) {
            // eslint-disable-next-line no-alert
            window.alert(`다운로드 실패: ${error.message}`);
        }

        setLoading(false);
    };

    const setVerifiedReviewer = async () => {
        try {
            await callDirectApi(
                setVerifiedStatusAPI,
                { reviewerIdList: selectedItem, type: "confirm" },
                true
            );
            history.go(0);
        } catch (error) {
            // eslint-disable-next-line no-alert
            window.alert(`인증 실패: ${error.message}`);
        }
    };

    useEffect(() => {
        setSelectedItem([]);
        setSelectedAll(false);
    }, [userList]);

    return (
        <>
            {loading && <HideScrollBackground />}
            <ListWrapper
                currentPage={Number(query.skip) / PAGE_LIMIT + 1 || 1}
                handlePage={handlePage}
                totalCount={userCounts}
                pageLimit={PAGE_LIMIT}
                onDownload={onDownload}
                customButtons={
                    type === "reviewer" && (
                        <Button
                            variant="contained"
                            style={{ backgroundColor: Color.purpleLight }}
                            disabled={!selectedItem.length}
                            onClick={setVerifiedReviewer}
                        >
                            추가 리워드 인증
                        </Button>
                    )
                }
            >
                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                {type === "reviewer" && (
                                    <CustomTableCell>
                                        <Checkbox
                                            color="primary"
                                            checked={selectedAll}
                                            onClick={() => onHandleCheck("all")}
                                        />
                                    </CustomTableCell>
                                )}
                                {tableHeaderList[type].map((headerItem, index) => (
                                    <CustomTableCell
                                        align={headerItem.align}
                                        key={index}
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        {headerItem.label}
                                    </CustomTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userList?.length > 0 &&
                                userList.map((user, index) => {
                                    const isSelected = selectedItem.indexOf(user._id) > -1;

                                    return (
                                        <TableRow key={index} hover>
                                            {type === "reviewer" && (
                                                <CustomTableCell width={20}>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isSelected}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            onHandleCheck(user._id);
                                                        }}
                                                    />
                                                </CustomTableCell>
                                            )}
                                            {tableHeaderList[type].map((item, itemIndex) => (
                                                <UserListItem
                                                    key={itemIndex}
                                                    user={user}
                                                    align={item.align}
                                                    value={item.value}
                                                    onClick={() =>
                                                        item.value === "name" &&
                                                        openUserDetailMoal(user._id)
                                                    }
                                                />
                                            ))}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ListWrapper>
        </>
    );
};

export default UserListContainer;

import { createAction, handleActions } from "redux-actions";
import { takeLeading } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaBase } from "lib/createRequestSaga";
import produce from "immer";
import * as messengerAPI from "lib/api/messenger";

export const RESET_MESSENGER = "messenger/RESET_MESSENGER";
export const SELECT_MESSAGE_BOX = "messenger/SELECT_MESSAGE_BOX";
export const [
    GET_MESSAGE_BOX_LIST,
    GET_MESSAGE_BOX_LIST_SUCCESS,
    GET_MESSAGE_BOX_LIST_FAILURE
] = createRequestActionTypes("messenger/GET_MESSAGE_BOX_LIST");
export const [
    GET_MESSAGE_LIST,
    GET_MESSAGE_LIST_SUCCESS,
    GET_MESSAGE_LIST_FAILURE
] = createRequestActionTypes("messenger/GET_MESSAGE_LIST");

export const resetMessenger = createAction(RESET_MESSENGER);
export const selectMessageBox = createAction(SELECT_MESSAGE_BOX);
export const getMessageBoxList = createAction(GET_MESSAGE_BOX_LIST);
export const getMessageList = createAction(GET_MESSAGE_LIST);

const initialState = {
    messageBox: {
        counts: 0,
        list: []
    },

    selectedMessageBoxOptions: {
        reviewerId: null,
        brandId: null
    },

    selectedMessageBox: {
        messageBoxId: null,
        counterpart: {},
        receiver: {},
        list: [],

        // 전체 리스트를 기준으로 했을때 이전, 다음내역이 있는지
        hasPrevious: false,
        hasNext: false
    }
};

const getMessageBoxListSaga = createRequestSagaBase({
    type: GET_MESSAGE_BOX_LIST,
    api: messengerAPI.getMessageBoxList
});

const getMessageListSaga = createRequestSagaBase({
    type: GET_MESSAGE_LIST,
    api: messengerAPI.getMessageList
});

export function* messengerSaga() {
    yield takeLeading(GET_MESSAGE_BOX_LIST, getMessageBoxListSaga);
    yield takeLeading(GET_MESSAGE_LIST, getMessageListSaga);
}

const messenger = handleActions(
    {
        [RESET_MESSENGER]: () => ({
            ...initialState
        }),
        [SELECT_MESSAGE_BOX]: (state, { payload }) =>
            produce(state, draft => {
                draft.selectedMessageBoxOptions = {
                    reviewerId: payload.reviewerId,
                    brandId: payload.brandId
                };
                draft.selectedMessageBox = {
                    ...initialState.selectedMessageBox,
                    messageBoxId: payload.messageBoxId
                };
            }),
        [GET_MESSAGE_BOX_LIST_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                draft.messageBox = {
                    counts: payload.totalCounts,
                    list: prev.skip > 0 ? [...state.messageBox.list, ...payload.list] : payload.list
                };
                if (!prev.skip) {
                    draft.selectedMessageBoxOptions = initialState.selectedMessageBoxOptions;
                    draft.selectedMessageBox = initialState.selectedMessageBox;
                }
            }),
        [GET_MESSAGE_LIST_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                const { counterpart, receiver } = payload;
                const oldList = state.selectedMessageBox.list;
                const newList = payload.messageList;

                draft.selectedMessageBox = {
                    ...initialState.selectedMessageBox,
                    messageBoxId: payload.messageBoxId,
                    counterpart,
                    receiver,
                    list: prev.after ? [...oldList, ...newList] : [...newList, ...oldList]
                };

                if (oldList.length < 1) {
                    draft.selectedMessageBox.hasPrevious = payload.hasPrevious;
                    draft.selectedMessageBox.hasNext = payload.hasNext;
                } else {
                    draft.selectedMessageBox.hasPrevious =
                        oldList[0].messageId > newList[0].messageId
                            ? payload.hasPrevious
                            : state.selectedMessageBox.hasPrevious;
                    draft.selectedMessageBox.hasNext =
                        oldList[oldList.length - 1].messageId <
                        newList[newList.length - 1].messageId
                            ? payload.hasNext
                            : state.selectedMessageBox.hasNext;
                }
            })
    },
    initialState
);

export default messenger;

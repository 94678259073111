import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import ReviewerDetailModal from "components/userManage/ReviewerDetailModal";
import BizDetailModal from "components/userManage/BizDetailModal";
import { getUserInfo, selectUser } from "stores/modules/user";

const UserDetailModal = () => {
    const dispatch = useDispatch();

    const userId = useSelector(({ user }) => user.selectedUser.userId);
    const userType = useSelector(({ user }) => user.selectedUser.userType);
    const userTab = useSelector(({ user }) => user.selectedUser.tab);
    const [tab, setTab] = useState("userInfo");

    const changeTab = tabName => {
        setTab(tabName);
    };

    const closeUserDetailModal = () => {
        dispatch(selectUser(null));
    };

    useEffect(() => {
        if (userId) {
            dispatch(getUserInfo(userId));
            setTab(userTab || "userInfo");
        } else {
            setTab("userInfo");
        }
    }, [userId, userTab]);

    if (!userId || !userType) return null;
    return (
        <Modal open onClose={closeUserDetailModal}>
            <div>
                {userType === "reviewer" ? (
                    <ReviewerDetailModal tab={tab} changeTab={changeTab} />
                ) : (
                    <BizDetailModal tab={tab} changeTab={changeTab} />
                )}
            </div>
        </Modal>
    );
};

export default UserDetailModal;

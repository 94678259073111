import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as pointGiftAPI from "lib/api/pointGift";

export const SELECT_POINT_GIFT = "pointGift/SELECT_POINT_GIFT";
const [
    GET_POINT_GIFT_LIST,
    GET_POINT_GIFT_LIST_SUCCESS,
    // eslint-disable-next-line
    GET_POINT_GIFT_LIST_FAILURE
] = createRequestActionTypes("pointGift/GET_POINT_GIFT_LIST");

export const selectPointGift = createAction(SELECT_POINT_GIFT);
export const getPointGiftList = createAction(GET_POINT_GIFT_LIST);

const getPointGiftListSaga = createRequestSagaWithAlert(
    GET_POINT_GIFT_LIST,
    pointGiftAPI.getAllPointGiftList,
    "포인트 선물 목록을 가져오는 데 실패하였습니다."
);

export function* pointGiftSaga() {
    yield takeLatest(GET_POINT_GIFT_LIST, getPointGiftListSaga);
}

const initialState = {
    list: [],
    counts: 0,
    detail: {}
};

const pointGift = handleActions(
    {
        [GET_POINT_GIFT_LIST]: state => ({
            ...state,
            list: [],
            counts: 0
        }),
        [SELECT_POINT_GIFT]: (state, { payload }) => ({
            ...state,
            detail: payload
        }),
        [GET_POINT_GIFT_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            list: payload.list,
            counts: payload.totalCounts
        })
    },
    initialState
);

export default pointGift;

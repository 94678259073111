import React from "react";
import moment from "moment";
import {
    convertRequestStatus,
    convertRequestType,
    convertTarget,
    convertManagerName
} from "lib/common";

import "./ContactUsDetail.scss";

const ContactUsDetail = ({ comment, type }) => (
    <div className="contact-us-detail">
        <div className="request-comment">
            <div className="field row">
                <h3 className="field-title">ID</h3>
                <div className="field-content">
                    <p>{comment.requestId}</p>
                </div>
            </div>
            <div className="field row">
                <h3 className="field-title">상태</h3>
                <div className="field-content">
                    <p>{convertRequestStatus(comment.requestStatus)}</p>
                </div>
            </div>
            <div className="field row">
                <h3 className="field-title">작성일</h3>
                <div className="field-content">
                    <p>{moment(comment.createdAt).format("YYYY-MM-DD HH:mm")}</p>
                </div>
            </div>
            <div className="field row">
                <h3 className="field-title">회원 구분</h3>
                <div className="field-content">
                    <p>
                        {comment.ownerCategory
                            ? convertTarget(comment.ownerCategory.toLowerCase())
                            : "알 수 없음"}
                    </p>
                </div>
            </div>
            {type === "request" && (
                <div className="field row">
                    <h3 className="field-title">작성자</h3>
                    <div className="field-content">
                        <p>
                            {comment.ownerId ? `[${comment.ownerId}] ` : ""}
                            {comment.ownerNickname
                                ? `${comment.ownerNickname} (${comment.owner})`
                                : comment.owner}
                        </p>
                    </div>
                </div>
            )}
            {type === "requestCall" && !comment.ownerId && (
                <div className="field row">
                    <h3 className="field-title">이름</h3>
                    <div className="field-content">
                        <p>{comment.name || "-"}</p>
                    </div>
                </div>
            )}
            {type === "request" && (
                <div className="field row">
                    <h3 className="field-title">제목</h3>
                    <div className="field-content">
                        <p>{comment.requestTitle || "-"}</p>
                    </div>
                </div>
            )}
            {type === "requestCall" && (
                <div className="field row">
                    <h3 className="field-title">이메일</h3>
                    <div className="field-content">
                        <p>{comment.requestEmail || "-"}</p>
                    </div>
                </div>
            )}
            {type === "requestCall" && (
                <div className="field row">
                    <h3 className="field-title">휴대폰 번호</h3>
                    <div className="field-content">
                        <p>{comment.requestPhoneNumber || "-"}</p>
                    </div>
                </div>
            )}
            {type === "request" && (
                <div className="field row">
                    <h3 className="field-title">문의 유형</h3>
                    <div className="field-content">
                        <p>{convertRequestType(comment.requestType)}</p>
                    </div>
                </div>
            )}
            {type === "requestCall" && (
                <div className="field row">
                    <h3 className="field-title">희망 날짜</h3>
                    <div className="field-content">
                        <p>{comment.desiredDate || "-"}</p>
                    </div>
                </div>
            )}
            {type === "requestCall" && (
                <div className="field row">
                    <h3 className="field-title">이전 페이지</h3>
                    <div className="field-content">
                        <p>{comment.previousPage || "-"}</p>
                    </div>
                </div>
            )}
            <div className="field">
                <h3 className="field-title">내용</h3>
                <div className="field-content">
                    <p className="request-contents">{comment.contents}</p>
                </div>
            </div>
            {comment.attachments && comment.attachments.length > 0 && (
                <div className="attachment-list field">
                    <h3 className="field-title">첨부파일</h3>
                    <div className="field-content">
                        <ul>
                            {comment.attachments.map(file => (
                                <li key={file._id}>
                                    [{file._id}]{" "}
                                    <a
                                        className="attachment-item"
                                        href={file.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {file.originalFileName}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
        {comment.reply &&
            comment.reply.map((reply, index) => (
                <div className="reply-comment" key={index}>
                    <div className="field row">
                        <h3 className="field-title">담당자</h3>
                        <p>{convertManagerName(reply.manager)}</p>
                    </div>
                    <div className="field row">
                        <h3 className="field-title">작성일</h3>
                        <p>{moment(reply.createdAt).format("YYYY-MM-DD HH:mm")}</p>
                    </div>
                    <div className="field">
                        <h3 className="field-title">내용</h3>
                        <div
                            className="request-contents"
                            dangerouslySetInnerHTML={{ __html: reply.contents }}
                        />
                    </div>
                    {reply.attachments && reply.attachments.length > 0 && (
                        <div className="attachment-list field">
                            <h3 className="field-title">첨부파일</h3>
                            {reply.attachments.map(replyFile => (
                                <a
                                    className="attachment-item"
                                    href={replyFile.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {replyFile._id}
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            ))}
    </div>
);

export default ContactUsDetail;

import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Select, MenuItem } from "@material-ui/core";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { useTalentAction, useTalentState } from "lib/customHooks/useTalent";
import { convertTalentStatus } from "lib/utils/talent";

import "./TalentFilter.scss";

const STATUS_LIST = ["ready", "running", "pause"];
const TalentFilter = () => {
    const history = useHistory();
    const { category } = useParams();
    const { query, search } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;
    const { getAllTalentList } = useTalentAction();
    const detailInfo = useTalentState("detail");

    const [selectedOptions, setSelectedOptions] = useState({
        status: query.status || undefined,
        pick: query.pick ? Boolean(query.pick) : undefined,
        title: query.title || undefined,
        id: query.id || undefined,
        reviewerId: query.reviewerId || undefined,
        nickname: query.nickname || undefined
    });

    const onChangeOption = e => {
        const { name, value } = e.target;
        const filteredValue = value === "all" || !value ? undefined : value;
        setSelectedOptions(prev => ({ ...prev, [name]: filteredValue }));
    };

    const onSearch = () => {
        const newQuery = createSearchQuery(selectedOptions);
        history.replace({ path: `/talents/${category}`, search: newQuery });
    };

    useEffect(() => {
        if (!detailInfo._id) {
            getAllTalentList(query);
        }
    }, [search, detailInfo?._id]);

    return (
        <div id="talent-filter">
            <fieldset>
                <div className="field">
                    <label htmlFor="talent-filter-status">상태</label>
                    <Select
                        id="talent-filter-status"
                        value={selectedOptions.status || "all"}
                        name="status"
                        onChange={onChangeOption}
                    >
                        <MenuItem value="all">전체</MenuItem>
                        {STATUS_LIST.map((item, index) => (
                            <MenuItem value={item} key={index}>
                                {convertTalentStatus(item)}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                {/* <div className="field">
                    <label htmlFor="talent-filter-category">카테고리</label>
                    <Select
                        id="talent-filter-category"
                        value={selectedOptions.category || "all"}
                        onChange={onChangeOption}
                    >
                        <MenuItem value="all">전체</MenuItem>
                    </Select>
                </div> */}
                <div className="field">
                    <label htmlFor="talent-filter-pick">리뷰쉐어 픽</label>
                    <Select
                        id="talent-filter-pick"
                        name="pick"
                        value={selectedOptions.pick || "all"}
                        onChange={onChangeOption}
                    >
                        <MenuItem value="all">전체</MenuItem>
                        <MenuItem value="true">O</MenuItem>
                        <MenuItem value="false">X</MenuItem>
                    </Select>
                </div>
                <div className="field">
                    <label htmlFor="talent-filter-id">서비스 ID</label>
                    <input
                        type="text"
                        id="talent-filter-id"
                        name="id"
                        value={selectedOptions.id}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onSearch();
                            }
                        }}
                        onChange={onChangeOption}
                    />
                </div>
                <div className="field">
                    <label htmlFor="talent-filter-title">서비스명</label>
                    <input
                        type="text"
                        id="talent-filter-title"
                        name="title"
                        value={selectedOptions.title}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onSearch();
                            }
                        }}
                        onChange={onChangeOption}
                    />
                </div>
                <div className="field">
                    <label htmlFor="talent-filter-reviewer-id">리뷰어 ID</label>
                    <input
                        type="text"
                        id="talent-filter-reviewer-id"
                        name="reviewerId"
                        value={selectedOptions.reviewerId}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onSearch();
                            }
                        }}
                        onChange={onChangeOption}
                    />
                </div>
                <div className="field">
                    <label htmlFor="talent-filter-nickname">닉네임</label>
                    <input
                        type="text"
                        id="talent-filter-nickname"
                        name="nickname"
                        value={selectedOptions.nickname}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onSearch();
                            }
                        }}
                        onChange={onChangeOption}
                    />
                </div>
            </fieldset>
            <div className="button-box">
                <Button variant="contained" color="primary" onClick={onSearch}>
                    검색
                </Button>
            </div>
        </div>
    );
};

export default TalentFilter;

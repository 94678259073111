import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";

import { numberWithCommas, callDirectApi } from "lib/common";
import { getPointRequestList } from "stores/modules/pointRequest";
import { setPointRequest as setPointRequestAPI } from "lib/api/pointRequest";
import ReviewerPointRequestCtrl from "components/userManage/ReviewerPointRequestCtrl";
import ReviewerPointRequestList from "components/userManage/ReviewerPointRequestList";

const ReviewerPointRequestContainer = ({ reviewerId }) => {
    const PAGE_LIMIT = 10;
    const dispatch = useDispatch();
    const { register: searchForm, getValues } = useForm({
        defaultValues: {
            status: "All"
        }
    });
    const { register: pointRequestForm, getValues: getPointRequestValues } = useForm();

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedStatus, handleSelectedStatus] = useState("Reward");

    const pointRequestTotalWallet = useSelector(({ pointRequest }) => pointRequest.totalWallet);
    const totalCount = useSelector(({ pointRequest }) => pointRequest.totalCount);
    const pointRequestList = useSelector(({ pointRequest }) => pointRequest.list);

    const onSearchPointRequest = options => {
        const { projectId, status } = options || {};

        if (options) {
            setCurrentPage(1);
        }

        dispatch(
            getPointRequestList({
                reviewerId,
                skip: options ? 0 : (currentPage - 1) * PAGE_LIMIT,
                ...(projectId && { projectId }),
                ...(status && { status })
            })
        );
    };

    const onSetPointRequest = async () => {
        const { points, publicMessage, projectId, suggestionId } = getPointRequestValues();

        try {
            await callDirectApi(
                setPointRequestAPI,
                {
                    reviewerId,
                    pointRequestObj: {
                        points: Number(points),
                        status: selectedStatus,
                        ...(publicMessage && { publicMessage }),
                        ...(projectId && { projectId }),
                        ...(suggestionId && { suggestionId })
                    }
                },
                true
            );
            onSearchPointRequest(true);
        } catch (error) {
            console.log("Set pointRequest error:", error);
            window.alert("추가 실패");
        }
    };

    useEffect(() => {
        onSearchPointRequest();
        // eslint-disable-next-line
    }, [currentPage]);

    console.log(totalCount / PAGE_LIMIT);
    return (
        <div className="modal-content">
            <h3 className="point-box">총 포인트: {numberWithCommas(pointRequestTotalWallet)}P</h3>
            <ReviewerPointRequestCtrl
                searchForm={searchForm}
                onSearch={onSearchPointRequest}
                selectedStatus={selectedStatus}
                handleSelectedStatus={handleSelectedStatus}
                onSetPointRequest={onSetPointRequest}
                pointRequestForm={pointRequestForm}
            />
            <ReviewerPointRequestList pointRequestList={pointRequestList} />
            <Box
                display="flex"
                justifyContent="center"
                style={{ marginTop: "20px", marginBottom: "20px" }}
            >
                <Pagination
                    page={currentPage}
                    count={Math.ceil(totalCount / PAGE_LIMIT)}
                    color="primary"
                    onChange={(e, pageNum) => setCurrentPage(pageNum)}
                />
            </Box>
        </div>
    );
};

export default ReviewerPointRequestContainer;

import client from "./client";

// export const searchMessageBox = ({ keyword, viewType = "all", limit, skip } = {}) =>
//     client.get("/v3/messageBoxes/integration", {
//         params: {
//             keyword,
//             where: viewType,
//             limit: viewType === "all" ? 5 : limit || 30,
//             skip
//         }
//     });

// export const searchTo = ({ nickname, brandName, limit, skip } = {}) =>
//     client.get("/v3/messages/receivers", {
//         params: {
//             nickname,
//             brandName,
//             limit: limit || 30,
//             skip
//         }
//     });
export const getMessageBoxList = ({ userId, brandId, limit, skip } = {}) =>
    client.get("/v3/messageBoxes", {
        params: {
            userId,
            brand: brandId,
            limit: limit || 30,
            skip
        }
    });

export const getMessageList = ({
    userId,
    reviewerId,
    brandId,
    lastMessageId,
    containLastMsg,
    after,
    limit
} = {}) =>
    client.get("/v3/messageBoxes/messages", {
        params: {
            userId,
            reviewer: reviewerId,
            brand: brandId,
            lastMessageId,
            containLastMsg,
            after,
            limit: limit || 10
        }
    });

export const sendMessage = formData => client.post("/v3/messages", formData);

import React from "react";
import { Button } from "@material-ui/core";
import { IconFont } from "reviewshare-common-component";
import { useAllTalent, useTalentAction } from "lib/customHooks/useTalent";
import { numberWithCommas } from "lib/common";

import "./TalentButtons.scss";

const TalentButtons = ({ selectedItems }) => {
    const { setPickTalent } = useTalentAction();

    const { counts } = useAllTalent();

    const onSetPick = process => {
        setPickTalent({ idList: selectedItems, process });
    };

    return (
        <div id="talent-list-buttons">
            <ul className="count-list">
                <li>
                    <p>전체: {numberWithCommas(counts)}건</p>
                </li>
                <li>
                    <p>선택: {numberWithCommas(selectedItems.length)}건</p>
                </li>
            </ul>
            <ul className="btn-list">
                <li>
                    <Button variant="outlined" onClick={() => onSetPick("active")}>
                        리픽 <IconFont icon="icon_line_arrow_up">올리기</IconFont>
                    </Button>
                </li>
                <li>
                    <Button variant="outlined" onClick={() => onSetPick("inactive")}>
                        리픽 <IconFont icon="icon_line_arrow_down">내리기</IconFont>
                    </Button>
                </li>
            </ul>
        </div>
    );
};

export default TalentButtons;

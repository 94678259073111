import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import moment from "moment";

import AlertModal from "components/common/AlertModal";
import PostEditor from "components/common/PostEditor";
import { callDirectApi } from "lib/common";
import { updatePost as updatePostAPI } from "lib/api/post";

let faqDefaultValue = {
    title: "",
    target: "",
    faqTag: ""
};

const FaqDetailModalContainer = ({ modalStatus, onClose, faqId, faqObj }) => {
    const { register, handleSubmit, setValue, watch } = useForm({
        defaultValues: faqDefaultValue,
        shouldUnregister: false
    });
    const [faqContents, setFaqContents] = useState("");
    const [alertModal, handleAlertModal] = useState(false);
    const [faqTagTarget, setFaqTagTarget] = useState(null);
    const target = watch("target");

    const submitData = async data => {
        try {
            await callDirectApi(
                updatePostAPI,
                {
                    postId: faqId,
                    postObj: {
                        contents: faqContents,
                        title: data.title,
                        type: "faq",
                        target: data.target,
                        faqTag: data.faqTag
                    }
                },
                true
            );
            handleAlertModal(true);
        } catch (error) {
            console.log("Write faq error.");
            console.log(error);
            window.alert("수정에 실패하였습니다." + error);
        }
    };

    useEffect(() => {
        if (faqObj) {
            setFaqContents(faqObj.contents);
            faqDefaultValue.title = faqObj.title;
            faqDefaultValue.target = faqObj.target;
            faqDefaultValue.faqTag = faqObj.tag;

            setValue("title", faqObj.title);
            setValue("target", faqObj.target);
            setValue("faqTag", faqObj.tag);
        }
        // eslint-disable-next-line
    }, [faqObj, setValue]);

    useEffect(() => {
        if (target) {
            setFaqTagTarget(target);
        }
    }, [target]);

    return (
        <Modal open={modalStatus} onClose={onClose}>
            <div>
                {alertModal && (
                    <AlertModal
                        handleModal={() => {
                            handleAlertModal(false);
                            onClose();
                        }}
                    >
                        <p>수정이 완료되었습니다.</p>
                    </AlertModal>
                )}
                <div className="common-modal-style">
                    <h2 className="modal-title">FAQ ID {faqObj.faqId}</h2>
                    <div className="modal-content">
                        <div className="editor-area">
                            <PostEditor
                                contentValue={faqContents}
                                postForm={register}
                                postType="faq"
                                setValue={setFaqContents}
                                faqTagTarget={faqTagTarget}
                            />
                            <div className="field row">
                                <p className="field-title">작성일</p>
                                <div className="field-content">
                                    {moment(faqObj.createdAt).format("YYYY-MM-DD HH:mm")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            variant="contained"
                            color="primary"
                            className="submit-btn"
                            onClick={handleSubmit(submitData)}
                        >
                            수정
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FaqDetailModalContainer;

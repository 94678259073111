import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as suggestionAPI from "lib/api/suggestion";

const [
    GET_SUGGESTION_LIST,
    GET_SUGGESTION_LIST_SUCCESS,
    // eslint-disable-next-line
    GET_SUGGESTION_LIST_FAILURE
] = createRequestActionTypes("/suggestion/GET_SUGGESTION_LIST");

const SELECT_SUGGESTION_ID = "/suggestion/SELECT_SUGGESTION_ID";

const [
    GET_SUGGESTION_INFO,
    GET_SUGGESTION_INFO_SUCCESS,
    // eslint-disable-next-line
    GET_SUGGESTION_INFO_FAILURE
] = createRequestActionTypes("/suggestion/GET_SUGGESTION_INFO");

const [
    CANCEL_SUGGESTION,
    CANCEL_SUGGESTION_SUCCESS,
    // eslint-disable-next-line
    CANCEL_SUGGESTION_FAILURE
] = createRequestActionTypes("/suggestion/CANCEL_SUGGESTION");

const [
    UNSUBMIT_SUGGESTION,
    UNSUBMIT_SUGGESTION_SUCCESS,
    // eslint-disable-next-line
    UNSUBMIT_SUGGESTION_FAILURE
] = createRequestActionTypes("/suggestion/UNSUBMIT_SUGGESTION");

export const getSuggestionList = createAction(GET_SUGGESTION_LIST, obj => obj);
export const selectSuggestionId = createAction(SELECT_SUGGESTION_ID, suggestionId => suggestionId);
export const getSuggestionInfo = createAction(GET_SUGGESTION_INFO);
export const cancelSuggestion = createAction(CANCEL_SUGGESTION, suggestionId => suggestionId);
export const unsubmitSuggestion = createAction(UNSUBMIT_SUGGESTION, suggestionId => suggestionId);

const getSuggestionListSaga = createRequestSagaWithAlert(
    GET_SUGGESTION_LIST,
    suggestionAPI.getSuggestionList,
    "제안 목록을 가져오는 데 실패하였습니다."
);

const getSuggestionInfoSaga = createRequestSagaWithAlert(
    GET_SUGGESTION_INFO,
    suggestionAPI.getSuggestion,
    "제안 정보을 가져오는 데 실패하였습니다."
);

const cancelSuggestionSaga = createRequestSagaWithAlert(
    CANCEL_SUGGESTION,
    suggestionAPI.cancel,
    "제안 취소에 실패하였습니다."
);

const unsubmitSuggestionSaga = createRequestSagaWithAlert(
    UNSUBMIT_SUGGESTION,
    suggestionAPI.unsubmit,
    "미제출 처리에 실패하였습니다."
);

export function* suggestionSaga() {
    yield takeLatest(GET_SUGGESTION_LIST, getSuggestionListSaga);
    yield takeLatest(GET_SUGGESTION_INFO, getSuggestionInfoSaga);
    yield takeLatest(CANCEL_SUGGESTION, cancelSuggestionSaga);
    yield takeLatest(UNSUBMIT_SUGGESTION, unsubmitSuggestionSaga);
}

const initialState = {
    suggestionList: [],
    suggestionCounts: 0,
    selectedSuggestionId: null,
    suggestionInfo: {}
};

const suggestion = handleActions(
    {
        [GET_SUGGESTION_LIST]: state => ({
            ...state,
            suggestionList: [],
            suggestionCounts: 0
        }),
        [GET_SUGGESTION_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            suggestionList: payload.suggestionList,
            suggestionCounts: payload.counts
        }),
        [SELECT_SUGGESTION_ID]: (state, { payload }) => ({
            ...state,
            selectedSuggestionId: payload,
            suggestionInfo: {}
        }),
        [GET_SUGGESTION_INFO]: state => ({
            ...state,
            suggestionInfo: {}
        }),
        [GET_SUGGESTION_INFO_SUCCESS]: (state, { payload }) => ({
            ...state,
            suggestionInfo: payload.suggestion
        }),
        [CANCEL_SUGGESTION_SUCCESS]: state => ({
            ...state,
            selectedSuggestionId: null,
            suggestionInfo: {}
        }),
        [UNSUBMIT_SUGGESTION_SUCCESS]: state => ({
            ...state,
            selectedSuggestionId: null,
            suggestionInfo: {}
        })
    },
    initialState
);

export default suggestion;

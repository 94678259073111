import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SuggestionFilter from "components/suggestionList/SuggestionFilter";
import SuggestionList from "components/suggestionList/SuggestionList";
import { getSuggestionList, selectSuggestionId } from "stores/modules/suggestion";

const limit = 40;

const initialOptions = {
    id: "",
    from: "",
    to: "",
    skip: 0,
    limit
};

const SuggestionListContainer = () => {
    const dispatch = useDispatch();
    const { suggestionList, suggestionCounts, selectedSuggestionId } = useSelector(
        ({ suggestion }) => suggestion
    );

    const [filter, setFilter] = useState(initialOptions);

    const onSearch = () => {
        dispatch(getSuggestionList(filter));
    };

    const onChangePaginateion = (e, index) => {
        const _filter = { ...filter, skip: filter.limit * (index - 1) };
        setFilter(_filter);
        dispatch(getSuggestionList(_filter));
    };

    const onOpenDetailModal = suggestionId => {
        if (!suggestionId) return;
        dispatch(selectSuggestionId(suggestionId));
    };

    useEffect(() => {
        if (!selectedSuggestionId) {
            onSearch();
        }
        // eslint-disable-next-line
    }, [selectedSuggestionId]);

    return (
        <>
            <div>
                <SuggestionFilter filter={filter} setFilter={setFilter} search={onSearch} />
                <SuggestionList
                    list={suggestionList}
                    counts={suggestionCounts}
                    limit={limit}
                    openDetailModal={onOpenDetailModal}
                    changePaginateion={onChangePaginateion}
                />
            </div>
        </>
    );
};

export default SuggestionListContainer;

export const convertPointsType = type => {
    switch (type) {
        case "project":
            return "프로젝트";
        case "product":
            return "상품 구매";
        case "suggestion":
            return "1:1 제안";
        case "talent":
            return "거래하기";
        case "reviewshare":
        case "event":
            return "기타";
        case "withdrawal":
            return "출금 신청";
        case "etc":
            return "기타";
        default:
            return type;
    }
};

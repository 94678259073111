import produce from "immer";

export const OPEN_ALERT = "alert/OPEN_ALERT";
export const CLOSE_ALERT = "alert/CLOSE_ALERT";

const initialState = {
    alert: {
        status: false,
        text: null,
        path: null
    }
};

export default function alert(state = initialState, action) {
    switch (action.type) {
        case OPEN_ALERT:
            return produce(state, draft => {
                draft.alert = {
                    status: true,
                    text: action.payload.text,
                    path: action.payload.path
                };
            });
        case CLOSE_ALERT:
            return produce(state, draft => {
                draft.alert = {
                    status: false,
                    text: null,
                    path: null
                };
            });

        default:
            return { ...state };
    }
}

export function closeAlert() {
    return {
        type: CLOSE_ALERT
    };
}

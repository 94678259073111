import React from "react";
import { Switch, Route } from "react-router-dom";
import ManageNav from "components/common/ManageNav";
import FaqListContainer from "containers/faq/FaqListContainer";
import FaqSearch from "components/faq/FaqSearch";
import FaqWriteContainer from "containers/faq/FaqWriteContainer";

const FaqPage = () => (
    <>
        <ManageNav
            navList={[
                { url: "/faq/list", name: "FAQ 목록" },
                { url: "/faq/write", name: "FAQ 쓰기" }
            ]}
        />
        <Switch>
            <Route exact path="/faq/list">
                <FaqSearch />
                <FaqListContainer />
            </Route>
            <Route exact path="/faq/write">
                <FaqWriteContainer />
            </Route>
        </Switch>
    </>
);

export default FaqPage;

import React from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import "./CropModal.scss";

const CropModal = ({
 isOpen, closeModal, crop, setCrop, image, onLoad, makeCroppedImage
}) => (
    <Modal open={isOpen} onClose={closeModal}>
        <div className="image-crop-modal-contents">
            <ReactCrop
                src={image && image.imagePreviewUrl}
                crop={crop}
                onImageLoaded={onLoad}
                onChange={(newCrop) => setCrop(newCrop)}
                onComplete={makeCroppedImage}
            />
            <Button variant="contained" color="primary" onClick={closeModal}>
                완료
            </Button>
        </div>
    </Modal>
);

export default CropModal;

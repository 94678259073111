import React from "react";
import cx from "classnames";
import { useForm } from "react-hook-form";

import "./SelectUserType.scss";

const SelectUserType = ({ children }) => {
    const { register, watch } = useForm({
        defaultValues: {
            type: "user"
        }
    });
    const type = watch("type");

    return (
        <div id="coupon-select-user-type">
            <div className="field coupon-type-list">
                <p className="field-title">생성 방식</p>
                <div className="field-content">
                    <label>
                        <input type="radio" name="type" value="user" ref={register} />
                        유저 지정
                    </label>
                    <label>
                        <input type="radio" name="type" value="code" ref={register} />
                        쿠폰 코드 발행
                    </label>
                </div>
            </div>
            <div className={cx("coupon-type code", { active: type === "code" })}>
                * 쿠폰 코드는 자동 생성됩니다.
            </div>
            <div className={cx("coupon-type user", { active: type === "user" })}>{children}</div>
        </div>
    );
};

export default SelectUserType;

import client from "./client";

export const getWeeklyActiveReviewer = () => client.get("/v3/statistics/weekly-active-reviewers");
export const getWeeklyRetentionReviewer = () =>
    client.get("/v3/statistics/weekly-retention-reviewers");
export const getUserExcel = category =>
    client.get(`/v3/statistics/download-user-excel?category=${category}`);
export const getMonthlyLoginFrequencyReviewer = () =>
    client.get("/v3/statistics/monthly-login-average");
export const getMonthlyTransactionAndFeeStats = () =>
    client.get("/v3/statistics/monthly-platform-transaction-and-fee");
export const getBizUsage = () => client.get("/v3/statistics/biz-usage");
export const getPointRequestByReviewer = () => client.get("/v3/statistics/reviewer-points");

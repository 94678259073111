import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as postAPI from "lib/api/post";

const [GET_POST_LIST, GET_POST_LIST_SUCCESS] = createRequestActionTypes("post/GET_POST_LIST");
const [GET_NOTICE, GET_NOTICE_SUCCESS] = createRequestActionTypes("post/GET_NOTICE");

export const getPostList = createAction(GET_POST_LIST, options => options);
export const getNotice = createAction(GET_NOTICE, noticeId => noticeId);

const getPostListSaga = createRequestSagaWithAlert(
    GET_POST_LIST,
    postAPI.getPostList,
    "목록을 가져오는 데 실패하였습니다.",
    true
);

const getNoticeSaga = createRequestSagaWithAlert(
    GET_NOTICE,
    postAPI.getNotice,
    "공지사항을 가져오는 데 실패하였습니다.",
    true
);

export function* postSaga() {
    yield takeLatest(GET_POST_LIST, getPostListSaga);
    yield takeLatest(GET_NOTICE, getNoticeSaga);
}

const initialState = {
    list: [],
    totalCount: 0,
    detailContents: null
};

const post = handleActions(
    {
        [GET_POST_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            list: payload.list,
            totalCount: payload.totalCount
        }),
        [GET_NOTICE]: state => ({
            ...state,
            detailContents: null
        }),
        [GET_NOTICE_SUCCESS]: (state, { payload }) => ({
            ...state,
            detailContents: payload
        })
    },
    initialState
);

export default post;

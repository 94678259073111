import { useState, useCallback } from "react";
import { callDirectApi } from "lib/common";

const useDirectApi = ({ api }) => {
    const [receivedData, setData] = useState(null);
    const [receivedError, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const fetchData = useCallback(
        async apiParams => {
            setIsFetching(true);
            try {
                const res = await callDirectApi(api, apiParams, true);
                setData(res.data || true);
            } catch (error) {
                setError(error.message);
            }
            setIsFetching(false);
            // console.log("res.data:", res.data);
        },
        // eslint-disable-next-line
        [api]
    );

    return { data: receivedData, fetch: fetchData, error: receivedError, isFetching };
};

export default useDirectApi;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { Tag } from "reviewshare-common-component";
import { confirmCharge as confirmChargeAPI } from "lib/api/payment";
import { numberWithCommas, returnTagColor, callDirectApi } from "lib/common";
import { convertCategory, convertChargeStatus, convertReceiptType } from "lib/utils/payment";
import { selectChargeInfo } from "stores/modules/payment";

import "./ChargeModal.scss";

const RECEIPT_ITEMS = [
    { label: "발행 휴대폰번호", value: "sellerPhoneNumber" },
    { label: "사업자번호", value: "sellerBusinessNumber" },
    { label: "상호명", value: "sellerBusinessName" },
    { label: "업태", value: "sellerBusinessStatus" },
    { label: "종목", value: "sellerCategory" },
    { label: "대표자명", value: "sellerName" },
    { label: "사업장 소재지", value: "sellerLocation" },
    { label: "발행 이메일", value: "sellerEmail" }
];

const ChargeModal = () => {
    const dispatch = useDispatch();
    const data = useSelector(({ payment }) => payment.chargeDetail);

    const onClose = () => {
        dispatch(selectChargeInfo(null));
    };

    const onConfirm = async () => {
        try {
            await callDirectApi(confirmChargeAPI, data._id);
            onClose();
        } catch (error) {
            window.alert("업데이트 실패:" + error.message);
        }
    };

    return data?._id ? (
        <Modal open onClose={onClose}>
            <div id="charge-detail-modal">
                <div className="modal-title">
                    <h2>결제 ID {data._id}</h2>
                    <Tag color={returnTagColor(data.status)} className="size-l">
                        {convertChargeStatus(data.status)}
                    </Tag>
                </div>
                <div className="modal-content">
                    <fieldset>
                        <div className="field-box">
                            <div className="field">
                                <p className="field-title">비즈니스 ID</p>
                                <div className="field-content">
                                    {data.owner?.name}({data.owner?._id})
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">입금 정보</p>
                                <div className="field-content">
                                    {data.bankName} {data.bankAccount} ({data.accountHolder})
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">예금주명</p>
                                <div className="field-content">{data.accountName || "-"}</div>
                            </div>
                        </div>
                        <div className="field-box">
                            <div className="field">
                                <p className="field-title">결제 방식</p>
                                <div className="field-content">
                                    {convertCategory(data.category)}
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">충전 금액</p>
                                <div className="field-content">
                                    {numberWithCommas(data.amount)}P
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">결제 금액</p>
                                <div className="field-content">
                                    {numberWithCommas(data.amountReal)}
                                </div>
                            </div>
                        </div>
                        <div className="field-box">
                            <div className="field">
                                <p className="field-title">입금 기한</p>
                                <div className="field-content">
                                    {data.limitDate
                                        ? moment(data.limitDate).format("YYYY-MM-DD HH:mm:ss")
                                        : "-"}
                                </div>
                            </div>
                            <div className="field">
                                <p className="field-title">충전 요청 일자</p>
                                <div className="field-content">
                                    {moment(data.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                </div>
                            </div>
                            {data.confirmedAt && (
                                <div className="field">
                                    <p className="field-title">충전 승인 일자</p>
                                    <div className="field-content">
                                        {moment(data.confirmedAt).format("YYYY-MM-DD HH:mm:ss")}
                                    </div>
                                </div>
                            )}
                        </div>
                        <hr />
                        <div className="field">
                            <p className="field-title">지출증빙</p>
                            <div className="field-content">
                                {convertReceiptType(data.receiptType)}
                            </div>
                        </div>
                        {RECEIPT_ITEMS.map((item, index) => (
                            <div className="field" key={index}>
                                <p className="field-title">{item.label}</p>
                                <div className="field-content">{data[item.value] || "-"}</div>
                            </div>
                        ))}
                    </fieldset>
                </div>
                <div className="modal-footer">
                    <div className="btn-area">
                        <Button
                            disabled={data.status !== "ready" || data.category !== "deposit"}
                            variant="contained"
                            color="primary"
                            onClick={onConfirm}
                        >
                            입금 확인
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    ) : null;
};

export default ChargeModal;

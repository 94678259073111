import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getComment } from "stores/modules/comment";
import ContactUsDetail from "components/contactUs/ContactUsDetail";
import PreviousContactUsList from "components/contactUs/PreviousContactUsList";
import ContactUsReplyContainer from "containers/contactUs/ContactUsReplyContainer";
import { callDirectApi } from "lib/common";
import { getCommentList as getCommentListAPI } from "lib/api/comment";

const ContactUsDetailContainer = ({ type }) => {
    const { commentId } = useParams();
    const dispatch = useDispatch();

    const [previousList, setPreviousList] = useState([]);
    const comment = useSelector(({ comment }) => comment.detailContents);

    const getPreviousList = async () => {
        try {
            const result = await callDirectApi(
                getCommentListAPI,
                {
                    email: comment.email
                },
                true
            );

            setPreviousList(result.data && result.data.list);
        } catch (error) {
            console.log("error getPreviousList");
            console.log(error);
        }
    };

    useEffect(() => {
        if (commentId) {
            window.scrollTo(0, 0);
            dispatch(getComment(commentId));
            setPreviousList([]);
        }
        // eslint-disable-next-line
    }, [commentId]);

    useEffect(() => {
        if (comment?.requestId === Number(commentId)) {
            getPreviousList();
        }
        // eslint-disable-next-line
    }, [comment?.requestId]);

    if (!comment?.requestId) return null;

    return (
        <>
            <ContactUsDetail comment={comment} type={type} />
            <ContactUsReplyContainer pageType={type} />
            <PreviousContactUsList list={previousList} currentCommentId={commentId} />
        </>
    );
};

export default ContactUsDetailContainer;

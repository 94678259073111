import React from "react";
import ManageNav from "components/common/ManageNav";
import CouponFilter from "components/coupon/CouponFilter";
import CouponList from "components/coupon/CouponList";
import CouponDetailModal from "components/coupon/CouponDetailModal";

const CouponListPage = () => (
    <>
        <ManageNav
            navList={[
                { url: "/coupon/user", name: "유저 기준" },
                { url: "/coupon/code", name: "쿠폰 기준" }
            ]}
        />
        <CouponFilter />
        <CouponList />
        <CouponDetailModal />
    </>
);

export default CouponListPage;

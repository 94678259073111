import React from "react";
import PointGiftFilter from "components/pointGift/PointGiftFilter";
import PointGiftList from "components/pointGift/PointGiftList";
import PointGiftModal from "components/pointGift/PointGiftModal";

const PointGiftListPage = () => (
    <>
        <PointGiftFilter />
        <PointGiftList />
        <PointGiftModal />
    </>
);

export default PointGiftListPage;

import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from "react";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { IconFont } from "reviewshare-common-component";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import { callDirectApi } from "lib/common";
import { createTag as createTagAPI } from "lib/api/tag";
import TagItem from "components/tag/TagItem";

import style from "./TagList.module.scss";

const TITLE_SCHEME = { user: "유저에게 적용된 태그들", all: "전체 태그 목록" };
const cx = classnames.bind(style);
const TagList = forwardRef(
    ({ type, isSearching, tagList = [], handleTag, excludeIdList = [] }, ref) => {
        const { register, getValues, setValue } = useForm({});
        const { register: searchRegister, getValues: searchGetValues } = useForm({});
        const [localTagList, setLocalTagList] = useState([]);
        const [searchKeyword, setSearchKeyword] = useState("");
        const [selectedTagList, setSelectedTagList] = useState([]);
        const [createMode, setCreateMode] = useState(false);
        const [isFetching, setIsFetching] = useState({});
        const selectedTagIdList = useMemo(() => selectedTagList.map(item => item._id), [
            selectedTagList
        ]);
        const title = useMemo(() => TITLE_SCHEME[type], [type]);

        const onAddTag = async () => {
            if (!createMode) {
                return setCreateMode(true);
            }
            const { name, isPublic } = getValues();
            const formattedName = name.replace(/( )/g, "");
            if (!formattedName) return;
            try {
                setIsFetching(prev => ({ ...prev, add: true }));
                const { data } = await callDirectApi(
                    createTagAPI,
                    { name: formattedName, isPublic, usage: "user" },
                    true
                );
                setLocalTagList(prev => [data, ...prev]);
                setValue("name", "");
                setIsFetching(prev => ({ ...prev, add: false }));
            } catch (e) {}
        };

        const onSearchTag = e => {
            e.target.blur();
            const { keyword } = searchGetValues();
            setSearchKeyword(keyword || "");
        };

        const onSelectTag = tag => {
            const tagId = tag._id;
            const newTagList = [...selectedTagList];
            const index = selectedTagIdList.indexOf(tagId);

            if (index > -1) {
                newTagList.splice(index, 1);
            } else {
                newTagList.push(tag);
            }

            setSelectedTagList(newTagList);
        };

        const onChangeStatus = async () => {
            setIsFetching(prev => ({ ...prev, changeStatus: true }));
            await handleTag({ tagIdList: selectedTagIdList });
            setSelectedTagList([]);
            setIsFetching(prev => ({ ...prev, changeStatus: false }));
        };

        useEffect(() => {
            setLocalTagList(tagList || []);
        }, [tagList]);

        useImperativeHandle(ref, () => ({
            selectedTagList: selectedTagIdList
        }));

        return (
            <>
                <div className={`${style.wrapper} tag-list`}>
                    <p className={style.title}>{title}</p>
                    {type === "all" && (
                        <div
                            className={cx(`${style.addArea} add-tag-area`, {
                                [style.active]: createMode
                            })}
                        >
                            <Checkbox
                                inputProps={{ name: "isPublic" }}
                                inputRef={register}
                                icon={<VisibilityOffIcon />}
                                checkedIcon={<VisibilityIcon />}
                            />
                            <input
                                type="text"
                                name="name"
                                placeholder="태그명 입력"
                                ref={register}
                                onKeyDown={e => {
                                    if (e.keyCode === 13) {
                                        onAddTag();
                                    }
                                }}
                            />
                            <Button
                                variant={createMode ? "contained" : "outlined"}
                                color="primary"
                                onClick={onAddTag}
                                className={style.addBtn}
                                disabled={isFetching.add}
                            >
                                {isFetching.add ? (
                                    <CircularProgress className={style.btnSpin} />
                                ) : (
                                    "추가"
                                )}
                            </Button>
                            {createMode && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setCreateMode(false)}
                                    className={style.cancelBtn}
                                >
                                    취소
                                </Button>
                            )}
                        </div>
                    )}
                    <div className={`${style.searchArea} search-tag-area`}>
                        <input
                            type="text"
                            name="keyword"
                            placeholder="태그 검색"
                            ref={searchRegister}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    onSearchTag(e);
                                }
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSearchTag}
                            className={style.searchBtn}
                        >
                            <IconFont icon="icon_search">검색</IconFont>
                        </Button>
                    </div>
                    {localTagList.length > 0 ? (
                        <ul className={style.tagItems}>
                            {localTagList.map(item => {
                                if (searchKeyword && item.name.indexOf(searchKeyword) === -1) {
                                    return null;
                                }
                                const isSelected = selectedTagIdList.indexOf(item._id) > -1;
                                if (excludeIdList.indexOf(item._id) > -1) return null;
                                return (
                                    <TagItem
                                        key={item._id}
                                        data={item}
                                        isSelected={isSelected}
                                        selectTag={() => onSelectTag(item)}
                                        mode={type === "all" ? "edit" : "view"}
                                    />
                                );
                            })}
                        </ul>
                    ) : isSearching ? (
                        <div className={style.spin}>
                            <CircularProgress />
                        </div>
                    ) : null}
                </div>
                {handleTag && (
                    <Button
                        className={style.confirmBtn}
                        onClick={onChangeStatus}
                        disabled={!selectedTagList?.length || isFetching.changeStatus}
                    >
                        {isFetching.changeStatus ? (
                            <CircularProgress />
                        ) : (
                            <IconFont icon="icon_arrow_right">확인</IconFont>
                        )}
                    </Button>
                )}
            </>
        );
    }
);

export default TagList;

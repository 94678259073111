import React, { useState } from "react";
import { callDirectApi } from "lib/common";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

import { createPost as createPostAPI } from "lib/api/post";
import PostEditor from "components/common/PostEditor";
import AlertModal from "components/common/AlertModal";

const NoticeWriteContainer = () => {
    const history = useHistory();
    const [value, setValue] = useState("");
    const [alertModal, handleAlertModal] = useState(false);
    const { register, handleSubmit } = useForm();

    const closeModal = () => {
        handleAlertModal(false);
        history.go(0);
    };

    const submitData = async data => {
        try {
            await callDirectApi(
                createPostAPI,
                {
                    contents: value,
                    title: data.title,
                    type: "notice",
                    target: data.target,
                    noticeTag: data.noticeTag,
                    isHide: data.isHide
                },
                true
            );
            handleAlertModal(true);
        } catch (error) {
            console.log("Write notice error.");
            console.log(error);
            window.alert("작성에 실패하였습니다." + error.message);
        }
    };

    return (
        <>
            {alertModal && (
                <AlertModal handleModal={closeModal}>
                    <p>작성이 완료되었습니다.</p>
                </AlertModal>
            )}
            <div style={{ backgroundColor: "white", padding: "20px" }}>
                <PostEditor
                    contentValue={value}
                    postForm={register}
                    postType="notice"
                    setValue={setValue}
                />
                <div className="flex justify-center" style={{ marginTop: "20px" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        className="submit-btn"
                        onClick={handleSubmit(submitData)}
                    >
                        작성
                    </Button>
                </div>
            </div>
        </>
    );
};

export default NoticeWriteContainer;

import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { uniq } from "lodash";
import moment from "moment";
import { Button } from "reviewshare-common-component";
import { useMessengerState, useSelectedMessageBox } from "lib/customHooks/useMessenger";
import { useSearchQuery } from "lib/customHooks/useSearchQuery";
import { getMessageList } from "stores/modules/messenger";
import MessageItem from "components/messenger/MessageItem";

import "./MessageList.scss";

const LIMIT = 30;
const MessageList = () => {
    const dispatch = useDispatch();
    const scrollElement = useRef();

    const { userId } = useSearchQuery().query;

    const { reviewerId, brandId } = useMessengerState("selectedMessageBoxOptions");
    const { list, hasPrevious, receiver, counterpart, messageBoxId } = useSelectedMessageBox();

    const [lastScrollHeight, setLastScrollHeight] = useState(0);

    const onGetPrevMessageList = () => {
        const lastMessageId = list[0].messageId;
        dispatch(
            getMessageList({
                userId,
                reviewerId,
                brandId,
                lastMessageId,
                limit: LIMIT
            })
        );
    };

    const onChangeScrollPosition = height => {
        scrollElement.current.scrollTop = height;
    };

    const onLoadedImage = e => {
        const filteredList = uniq(list.map(item => item.messageId));
        const currentScrollHeight = scrollElement.current.scrollHeight;

        if (filteredList.length <= LIMIT) {
            onChangeScrollPosition(currentScrollHeight);
        }
        setLastScrollHeight(currentScrollHeight);
    };

    useEffect(() => {
        if (reviewerId && brandId) {
            dispatch(getMessageList({ userId, reviewerId, brandId, limit: LIMIT }));
        }
    }, [reviewerId, brandId]);

    useEffect(() => {
        if (list.length > 0) {
            const currentScrollHeight = scrollElement.current.scrollHeight;
            setLastScrollHeight(currentScrollHeight);

            if (lastScrollHeight > 0) {
                onChangeScrollPosition(currentScrollHeight - lastScrollHeight);
            } else {
                onChangeScrollPosition(currentScrollHeight);
            }
        }
        // eslint-disable-next-line
    }, [list.length]);

    useEffect(() => {
        setLastScrollHeight(0);
        // eslint-disable-next-line
    }, [messageBoxId]);

    return (
        <div id="message-list" ref={scrollElement} onLoad={onLoadedImage}>
            <ul>
                <>
                    {hasPrevious && (
                        <Button
                            className="theme5 size-s m-size-s get-list-btn prev-btn"
                            onClick={onGetPrevMessageList}
                        >
                            이전 내용 불러오기
                        </Button>
                    )}
                    {list.map((item, index) => {
                        let hasNextItem = false;
                        let isNextItem = false;
                        let dateFirstItem = false;

                        if (index === 0) {
                            dateFirstItem = true;
                        }

                        if (index > 0) {
                            const prev = list[index - 1];
                            if (
                                moment(item.createdAt).diff(moment(prev.createdAt), "minutes") ===
                                    0 &&
                                prev.type === item.type
                            ) {
                                isNextItem = true;
                            }

                            if (
                                moment(item.createdAt)
                                    .endOf("day")
                                    .diff(moment(prev.createdAt).endOf("day"), "days") > 0
                            ) {
                                dateFirstItem = true;
                            }
                        }

                        if (index < list.length - 1) {
                            const next = list[index + 1];
                            if (
                                moment(item.createdAt).diff(moment(next.createdAt), "minutes") ===
                                    0 &&
                                next.type === item.type
                            ) {
                                hasNextItem = true;
                            }
                        }

                        return (
                            <React.Fragment key={index}>
                                {dateFirstItem && (
                                    <MessageItem
                                        key={`${index}-date`}
                                        data={{
                                            type: "date",
                                            contents: {
                                                type: "text",
                                                value: moment(item.createdAt)
                                            },
                                            createdAt: moment(item.createdAt)
                                        }}
                                    />
                                )}
                                <MessageItem
                                    key={`${index}-content`}
                                    data={item}
                                    receiver={receiver}
                                    counterpart={counterpart}
                                    isNextItem={isNextItem}
                                    hasNextItem={hasNextItem}
                                />
                            </React.Fragment>
                        );
                    })}
                </>
            </ul>
        </div>
    );
};

export default MessageList;

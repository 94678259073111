import client from "./client";

export const getBannerList = ({ name, position, isShowing, skip, limit }) =>
    client.get("/v2/banners/list/admin", {
        params: { name, position, isShowing, skip, limit: limit || 50 }
    });
export const getBanner = id => client.get(`/v2/banners/info/${id}/admin`);
export const create = obj => client.post("/v2/banners/create/admin", obj);
export const edit = obj => client.post("/v2/banners/edit/admin", obj);
export const remove = ids => client.delete("/v2/banners/remove/admin", { data: { ids } });

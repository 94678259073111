import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import "./PreviousContactUsList.scss";

const PreviousContactUsList = ({ list, currentCommentId }) => (
    <div className="previous-contact-us-list-area">
        <h3>이전 문의 내역(최대 30개)</h3>
        <ul>
            {list?.length > 0 &&
                list.map((item, index) => (
                    <li key={index} title={item.title}>
                        <Link to={`/contact-us/${item.requestId}`}>
                            {item.title.length >= 20
                                ? `${item.title.substring(0, 20)}...`
                                : item.title}
                            ({moment(item.createdAt).format("YYYY-MM-DD HH:mm")})
                        </Link>
                        {Number(currentCommentId) === Number(item.requestId) && "(보고있는 문의)"}
                    </li>
                ))}
            {(!list || list.length === 0) && <p>이전 문의 내역이 없습니다.</p>}
        </ul>
    </div>
);

export default PreviousContactUsList;

import React from "react";
import { Switch, Route } from "react-router-dom";
import ContactUsListContainer from "containers/contactUs/ContactUsListContainer";
import ContactUsDetailContainer from "containers/contactUs/ContactUsDetailContainer";

const CallRequestPage = () => (
    <Switch>
        <Route exact path="/call-request">
            <ContactUsListContainer type="requestCall" />
        </Route>
        <Route exact path="/call-request/:commentId">
            <ContactUsDetailContainer type="requestCall" />
        </Route>
    </Switch>
);

export default CallRequestPage;

export const SHOW_LOADING = "loading/SHOW_LOADING";
export const HIDE_LOADING = "loading/HIDE_LOADING";

const initialState = {
    status: false
};

export default function loading(state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADING:
            return { status: true };
        case HIDE_LOADING:
            return { status: false };

        default:
            return { ...state };
    }
}

export function showLoading() {
    return {
        type: SHOW_LOADING
    };
}

export function hideLoading() {
    return {
        type: HIDE_LOADING
    };
}

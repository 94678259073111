import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import ReviewerScoreRecordList from "components/userManage/ReviewerScoreRecordList";
import ReviewerScoreRecordCtrl from "components/userManage/ReviewerScoreRecordCtrl";
import { getReviewerScoreRecordList } from "stores/modules/scoreRecord";
import { callDirectApi, convertScoreType, convertGrade, numberWithCommas } from "lib/common";
import {
    setScoreRecord as setScoreRecordAPI,
    cancelScoreRecord as cancelScoreRecordAPI
} from "lib/api/scoreRecord";

const PAGE_LIMIT = 20;

const ReviewerScoreRecordContainer = ({ reviewerId }) => {
    const dispatch = useDispatch();
    const { register: searchForm, getValues: getSearchValue } = useForm();
    const {
        register: setScoreForm,
        getValues: getScoreInputValue,
        setValue: setScoreInputValue
    } = useForm();

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedIdArray, setSelectedIdArray] = useState([]);
    const [selectedReason, handleSelectedReason] = useState("etc");
    const [showScoreValueField, handleScoreValueField] = useState(true);

    const grade = useSelector(({ scoreRecord }) => scoreRecord.reviewerScoreRecordList.grade);
    const score = useSelector(({ scoreRecord }) => scoreRecord.reviewerScoreRecordList.score);
    const scoreRecordList = useSelector(
        ({ scoreRecord }) => scoreRecord.reviewerScoreRecordList.list
    );
    const totalCount = useSelector(({ scoreRecord }) => scoreRecord.reviewerScoreRecordList.counts);

    const onCheckedItem = scoreRecordId => {
        const index = selectedIdArray.indexOf(scoreRecordId);

        if (index < 0) {
            setSelectedIdArray(pre => [...pre, scoreRecordId]);
        } else {
            setSelectedIdArray(pre => [
                ...pre.slice(0, index),
                ...pre.slice(index + 1, pre.length)
            ]);
        }
    };

    const onSelectReason = e => {
        handleSelectedReason(e.target.value);
    };

    const onCancelScoreRecord = async () => {
        if (selectedIdArray.length === 0) return;

        try {
            await callDirectApi(cancelScoreRecordAPI, { ids: selectedIdArray }, true);
            onGetScoreRecordList({ newSearch: true });
            setSelectedIdArray([]);
        } catch (error) {
            console.log("Cancel Score Record Error:", error);
            window.alert("삭제 실패");
        }
    };

    const onGetScoreRecordList = ({ newSearch } = {}) => {
        const { projectId } = getSearchValue();

        if (newSearch) {
            setCurrentPage(1);
        }

        dispatch(
            getReviewerScoreRecordList({
                reviewerId,
                skip: newSearch ? 0 : (currentPage - 1) * PAGE_LIMIT,
                ...(projectId && { projectId })
            })
        );
    };

    const setScoreRecord = async () => {
        const value = getScoreInputValue();
        value.owner = reviewerId;
        value.type = selectedReason;

        try {
            await callDirectApi(setScoreRecordAPI, value, true);
            onGetScoreRecordList({ newSearch: true });
        } catch (error) {
            console.log("Set Score Record Error:", error);
            window.alert("추가 실패");
        }
    };

    useEffect(() => {
        onGetScoreRecordList();
        // eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        if (
            selectedReason === "noShow" ||
            selectedReason === "notFollow" ||
            selectedReason === "cheat"
        ) {
            setScoreInputValue("reasonByAdmin", convertScoreType(selectedReason));
            handleScoreValueField(false);
        } else {
            setScoreInputValue("reasonByAdmin", "");
            handleScoreValueField(true);
        }
        // eslint-disable-next-line
    }, [selectedReason]);

    return (
        <div className="modal-content">
            <h3 className="point-box">
                등급: {convertGrade(grade)}({numberWithCommas(score)})
            </h3>
            <ReviewerScoreRecordCtrl
                searchForm={searchForm}
                setScoreForm={setScoreForm}
                onSearch={onGetScoreRecordList}
                setScoreRecord={setScoreRecord}
                selectedReason={selectedReason}
                onSelectReason={onSelectReason}
                showScoreValueField={showScoreValueField}
            />
            <ReviewerScoreRecordList
                scoreRecordList={scoreRecordList}
                onCheckedItem={onCheckedItem}
                selectedIdArray={selectedIdArray}
                isPossibleCancel={selectedIdArray.length > 0}
                onCancelScoreRecord={onCancelScoreRecord}
            />
            <Box
                display="flex"
                justifyContent="center"
                style={{ marginTop: "20px", marginBottom: "20px" }}
            >
                <Pagination
                    page={currentPage}
                    count={Math.ceil(totalCount / PAGE_LIMIT)}
                    color="primary"
                    onChange={(e, pageNum) => setCurrentPage(pageNum)}
                />
            </Box>
        </div>
    );
};

export default ReviewerScoreRecordContainer;

import React from "react";
import FeedbackContainer from "containers/feedback/FeedbackContainer";
import FeedbackDetailModal from "components/feedback/FeedbackDetailModal";

const FeedbackPage = () => (
    <>
        <FeedbackContainer />
        <FeedbackDetailModal />
    </>
);

export default FeedbackPage;

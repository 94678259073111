import React from "react";
import IconFont from "components/common/IconFont";
import { Button } from "reviewshare-common-component";

import "./Login.scss";

import reviewshareLogo from "assets/images/logo/reviewshare_logo.svg";

const Login = ({ loginForm, errors, onLogin }) => (
    <div className="login">
        <header className="login-header">
            <a href={process.env.REACT_APP_MAIN_SERVICE_URL} className="reviewshare-logo">
                <img src={reviewshareLogo} alt="리뷰쉐어 로고" />
            </a>
            <a href={process.env.REACT_APP_ADMIN_URL} className="admin-main-link">
                Admin
            </a>
        </header>

        <div className="login-title-img">
            <h2>관리자 로그인</h2>
        </div>
        <form>
            <input
                type="text"
                placeholder="이메일 주소 또는 아이디"
                name="identifier"
                ref={loginForm({ required: true })}
            />
            <input
                type="password"
                placeholder="비밀번호"
                name="password"
                ref={loginForm({ required: true })}
                onKeyDown={(e) => e.keyCode === 13 && onLogin()}
            />
            <div className="error-msg">
                {errors.identifier && errors.identifier.type === "required" && (
                    <p>이메일 주소 또는 아이디를 입력해주세요.</p>
                )}
                {!errors.identifier && errors.password && errors.password.type === "required" && (
                    <p>비밀번호를 입력해주세요.</p>
                )}
            </div>
            <div className="login-options">
                <input type="checkbox" id="saveId" name="saveId" ref={loginForm} />
                <label htmlFor="saveId">
                    <IconFont icon="icon_checkbox_off">아이디 저장 취소 체크박스</IconFont>
                    <IconFont icon="icon_checkbox_on">아이디 저장 체크박스</IconFont>
                    <p>아이디 저장</p>
                </label>
            </div>
            <Button className="theme1 size-m login-btn" onClick={onLogin}>
                로그인
            </Button>
        </form>
    </div>
);

export default Login;

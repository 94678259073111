export const convertPointRequestStatus = status => {
    switch (status) {
        case "Addition":
            return "추가포인트";
        case "Issued":
            return "적립";
        case "Request":
            return "출금신청";
        case "Confirmed":
            return "출금승인";
        case "Refund":
        case "Reject":
        case "Denied":
            return "출금거절";
        case "Reward":
            return "적립";
        case "Retrieve":
            return "포인트회수";
        case "Gift":
            return "선물";

        default:
            return status;
    }
};

export const convertReasonForDeny = reason => {
    switch (reason) {
        case "bankAccountInfo":
            return "계좌 정보 불일치";
        case "idCardNum":
            return "주민번호 불일치";
        case "cancelRequest":
            return "출금 취소 요청";
        case "blackList":
            return "블랙리스트";
        case "notKeepReview":
            return "리뷰 유지기간 미준수";
        case "inappropriateReason":
            return "부적절한 서비스 이용";
        default:
            return reason;
    }
};

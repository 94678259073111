import React from "react";
import { Switch, Route } from "react-router-dom";
import ManageNav from "components/common/ManageNav";
import UserSearchContainer from "containers/userManage/UserSearchContainer";
import UserListContainer from "containers/userManage/UserListContainer";
import SearchUserByTag from "components/userManage/SearchUserByTag";
import UserDetailModal from "components/userManage/UserDetailModal";

const UserManagePage = () => (
    <>
        <ManageNav
            navList={[
                { url: "/user/reviewer", name: "리뷰어 관리" },
                { url: "/user/biz", name: "비즈니스 관리" },
                { url: "/user/tag", name: "태그별 유저 관리" }
            ]}
        />
        <UserDetailModal />

        <Switch>
            <Route exact path="/user/reviewer">
                <UserSearchContainer type="reviewer" />
                <UserListContainer type="reviewer" />
            </Route>
            <Route path="/user/biz">
                <UserSearchContainer type="biz" />
                <UserListContainer type="biz" />
            </Route>
            <Route path="/user/tag">
                <SearchUserByTag />
            </Route>
        </Switch>
    </>
);

export default UserManagePage;

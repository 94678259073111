import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchQuery } from "lib/customHooks/useSearchQuery";
import BannerFilter from "components/bannerList/BannerFilter";
import BannerList from "components/bannerList/BannerList";
import BannerModal from "components/bannerList/BannerModal";
import { getBannerList, selectBannerId, createBanner, removeBanner } from "stores/modules/banner";

const limit = 40;

const initialOptions = {
    name: "",
    isShowing: undefined,
    position: undefined,
    skip: 0,
    limit
};

const BannerListContainer = () => {
    const dispatch = useDispatch();
    const { search } = useSearchQuery();
    const { bannerList, bannerCounts, selectedBannerId } = useSelector(({ banner }) => banner);

    const [selectedItem, setSelectedItem] = useState([]);
    const [filter, setFilter] = useState(initialOptions);
    const [createModalStatus, setCreateModalStatus] = useState(false);

    const onSearch = () => {
        dispatch(getBannerList(filter));
    };

    const onCreateBanner = info => {
        if (!info.name) return;
        if (info.fileList.filter(val => !val).length) return;

        const form = new FormData();
        form.append("photos", info.fileList[0]);
        form.append("photos", info.fileList[1]);
        form.append("position", info.position);
        form.append("alt", info.alt);
        form.append("isShowing", info.isShowing);
        form.append("index", info.index);
        form.append("name", info.name);
        form.append("link", info.link);
        form.append("backgroundColor", info.backgroundColor);
        form.append("target", info.target);
        dispatch(createBanner(form));
    };

    const onChangeOption = e => {
        const { name, value } = e.target;
        const filteredValue = value === "all" || !value ? undefined : value;
        setFilter(prev => ({ ...prev, [name]: filteredValue }));
    };

    const onChangePaginateion = (e, index) => {
        const _filter = { ...filter, skip: filter.limit * (index - 1) };
        setFilter(_filter);
        dispatch(getBannerList(_filter));
    };

    const onOpenDetailModal = bannerId => {
        if (!bannerId) return;
        dispatch(selectBannerId(bannerId));
    };

    const onOpenCreateModal = () => {
        setCreateModalStatus(true);
    };

    const onRemoveBanner = items => {
        if (!items || !items.length) return;
        dispatch(removeBanner(items));
        setSelectedItem([]);
    };

    useEffect(() => {
        if (!selectedBannerId) {
            onSearch();
        }
        // eslint-disable-next-line
    }, [selectedBannerId]);

    useEffect(() => {
        setSelectedItem([]);
    }, [search]);

    useEffect(() => {
        setCreateModalStatus(false);
    }, [bannerList?.[0]?._id]);

    return (
        <>
            <div>
                <BannerFilter filter={filter} setFilter={onChangeOption} search={onSearch} />
                <BannerList
                    list={bannerList}
                    counts={bannerCounts}
                    limit={limit}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    openDetailModal={onOpenDetailModal}
                    openCreateModal={onOpenCreateModal}
                    removeBanner={onRemoveBanner}
                    changePaginateion={onChangePaginateion}
                />
                {createModalStatus && (
                    <BannerModal
                        isCreate
                        closeModal={() => setCreateModalStatus(false)}
                        createBanner={onCreateBanner}
                    />
                )}
            </div>
        </>
    );
};

export default BannerListContainer;

import React from "react";
import { useSelector } from "react-redux";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { bizSubsMainStatistics } from "scheme/Scheme";

const SubsStatisticsGraphView = ({ graphDataTarget }) => {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
    const graphData = useSelector(({ subs }) => subs.statistics.graphData);

    if (graphData.length === 0) {
        return null;
    }

    return (
        <div>
            <Bar
                height={70}
                data={{
                    labels: graphData[graphDataTarget].map(item => Object.keys(item)[0]),
                    datasets: [
                        {
                            label: bizSubsMainStatistics[graphDataTarget].label,
                            data: graphData[graphDataTarget].map(item => Object.values(item)[0])
                        }
                    ]
                }}
            />
        </div>
    );
};

export default SubsStatisticsGraphView;

import React from "react";
import Button from "@material-ui/core/Button";
import { useSearchQuery } from "lib/customHooks/useSearchQuery";

import "./ContactUsSearch.scss";

const ContactUsSearch = ({ searchForm, onSubmit }) => {
    const { query } = useSearchQuery();

    return (
        <form className="contact-us-search-area" onSubmit={onSubmit}>
            <div className="row-item">
                <h2>상태</h2>
                <ul>
                    <li>
                        <label htmlFor="status-all">
                            <input
                                type="radio"
                                name="status"
                                id="status-all"
                                value="all"
                                ref={searchForm}
                            />
                            전체
                        </label>
                    </li>
                    <li>
                        <label htmlFor="status-request">
                            <input
                                type="radio"
                                name="status"
                                id="status-request"
                                value="request"
                                ref={searchForm}
                            />
                            미해결
                        </label>
                    </li>
                    <li>
                        <label htmlFor="status-complete">
                            <input
                                type="radio"
                                name="status"
                                id="status-complete"
                                value="complete"
                                ref={searchForm}
                            />
                            해결
                        </label>
                    </li>
                    <li>
                        <label htmlFor="status-cancel">
                            <input
                                type="radio"
                                name="status"
                                id="status-cancel"
                                value="cancel"
                                ref={searchForm}
                            />
                            취소
                        </label>
                    </li>
                </ul>
            </div>
            <div className="search-items">
                <div className="search-item">
                    <h3>회원 ID</h3>
                    <input
                        type="text"
                        placeholder="회원 ID를 입력해주세요."
                        ref={searchForm}
                        defaultValue={query.userId}
                        name="userId"
                    />
                </div>
                <div className="search-item">
                    <h3>이메일</h3>
                    <input
                        type="text"
                        placeholder="이메일을 입력해주세요."
                        ref={searchForm}
                        defaultValue={query.email}
                        name="email"
                    />
                </div>
                <div className="search-item">
                    <h3>이름</h3>
                    <input
                        type="text"
                        placeholder="이름을 입력해주세요."
                        ref={searchForm}
                        defaultValue={query.name}
                        name="name"
                    />
                </div>
                <div className="search-item">
                    <h3>문의 내용</h3>
                    <input
                        type="text"
                        placeholder="내용을 입력해주세요."
                        ref={searchForm}
                        defaultValue={query.contents}
                        name="contents"
                    />
                </div>
                <div className="search-item">
                    <h3>답변 내용</h3>
                    <input
                        type="text"
                        placeholder="내용을 입력해주세요."
                        ref={searchForm}
                        defaultValue={query.replyContents}
                        name="replyContents"
                    />
                </div>
            </div>
            <div className="btn-area">
                <Button variant="contained" color="primary" className="submit-btn" type="submit">
                    검색
                </Button>
            </div>
        </form>
    );
};

export default ContactUsSearch;

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { Button, Select, MenuItem } from "@material-ui/core";
import { getProjectRequestList } from "stores/modules/projectRequest";
import { convertRequestStatus } from "lib/utils/project";

import "./ProjectRequestSearch.scss";

const REQUEST_STATUS_LIST = [
    "request",
    "chosen",
    "cancelByBizFault",
    "cancelByReviewerFault",
    "applyForCancel",
    "submitted",
    "unsubmitted"
];

const PAGE_LIMIT = 10;
const ProjectRequestSearch = ({ history }) => {
    const dispatch = useDispatch();
    const { query, search } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;

    const { register, handleSubmit } = useForm({
        defaultValues: {
            status: query.status || "all"
        }
    });

    const [selectedOptions, setSelectedOptions] = useState({
        status: query.status
    });

    const onChangeOption = e => {
        const { name, value } = e.target;
        const filteredValue = value === "all" || !value ? undefined : value;
        setSelectedOptions(prev => ({ ...prev, [name]: filteredValue }));
    };

    const onSearch = async data => {
        const newQuery = createSearchQuery({
            ...query,
            ...data,
            ...selectedOptions,
            page: undefined
        });
        history.replace({ pathname: "/manage/projectRequest", search: newQuery });
    };

    useEffect(() => {
        dispatch(
            getProjectRequestList({
                ...query,
                skip: query.page ? (query.page - 1) * 10 : undefined,
                limit: PAGE_LIMIT
            })
        );
    }, [search]);

    return (
        <form className="project-request-search" onSubmit={handleSubmit(onSearch)}>
            <div className="search-items">
                <div className="search-item">
                    <h3>리뷰어 ID</h3>
                    <input
                        type="text"
                        name="reviewerId"
                        ref={register}
                        defaultValue={query.reviewerId}
                    />
                </div>
                <div className="search-item">
                    <h3>프로젝트 ID</h3>
                    <input
                        type="text"
                        name="projectId"
                        ref={register}
                        defaultValue={query.projectId}
                    />
                </div>
                <div className="search-item">
                    <h3>상태</h3>
                    <Select
                        value={selectedOptions.status || "all"}
                        name="status"
                        onChange={onChangeOption}
                    >
                        <MenuItem value="all">전체</MenuItem>
                        {REQUEST_STATUS_LIST.map((item, index) => (
                            <MenuItem value={item} key={index}>
                                {convertRequestStatus(item)}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div className="search-item">
                    <h3>신청 ID</h3>
                    <input
                        type="text"
                        name="requestId"
                        ref={register}
                        defaultValue={query.requestId}
                    />
                </div>
                <div className="search-item">
                    <h3>닉네임</h3>
                    <input
                        type="text"
                        name="nickname"
                        ref={register}
                        defaultValue={query.nickname}
                    />
                </div>
                <div className="search-item">
                    <h3>프로젝트명</h3>
                    <input
                        type="text"
                        name="projectTitle"
                        ref={register}
                        defaultValue={query.projectTitle}
                    />
                </div>
            </div>
            <div className="btn-area">
                <Button variant="contained" color="primary" className="submit-btn" type="submit">
                    검색
                </Button>
            </div>
        </form>
    );
};

export default withRouter(ProjectRequestSearch);

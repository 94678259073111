import React from "react";
import { Switch, Route } from "react-router-dom";
import ManageNav from "components/common/ManageNav";
import ProjectSearchContainer from "containers/projectManage/ProjectSearchContainer";
import ProjectList from "components/projectManage/ProjectList";
import ProjectRequestSearch from "components/projectManage/ProjectRequestSearch";
import ProjectRequestListContainer from "containers/projectManage/ProjectRequestListContainer";

const ProjectManagePage = () => (
    <>
        <ManageNav
            navList={[
                { url: "/manage/project", name: "프로젝트 조회" },
                { url: "/manage/projectRequest", name: "리뷰어 참여 조회" }
            ]}
        />
        <Switch>
            <Route exact path="/manage/project">
                <ProjectSearchContainer />
                <ProjectList />
            </Route>
            <Route path="/manage/projectRequest">
                <ProjectRequestSearch />
                <ProjectRequestListContainer />
            </Route>
        </Switch>
    </>
);

export default ProjectManagePage;

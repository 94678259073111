import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

import { selectFeedback } from "stores/modules/feedback";
import { callDirectApi } from "lib/common";
import { removeFeedbacks as removeFeedbacksAPI } from "lib/api/feedback";

import "./FeedbackDetailModal.scss";

const convertReason = reason => {
    switch (reason) {
        case "personalData":
            return "개인 정보 노출";
        case "cursed":
            return "욕설 및 비방";
        case "illegalOrObscene":
            return "불법/음란/선정성";
        case "falseInformation":
            return "허위 사실 유포";
        case "unrelated":
            return "관련 없는 후기";
        case "etc":
            return "기타";

        default:
            return "-";
    }
};

const FeedbackDetailModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const feedbackData = useSelector(({ feedback }) => feedback.selectedFeedback);

    const onCancleReported = async feedbackId => {
        try {
            if (!feedbackId) {
                throw new Error("Feedback ID가 없습니다.");
            }

            await callDirectApi(
                removeFeedbacksAPI,
                { feedbackIdList: [feedbackId], action: "keep" },
                true
            );
            history.go(0);
        } catch (error) {
            window.alert(error.message);
        }
    };

    return (
        <Modal open={feedbackData.id} onClose={() => dispatch(selectFeedback({}))}>
            <div className="feedback-detail-modal">
                <div className="modal-title">
                    <h2>후기 편지 ID {feedbackData.id}</h2>
                </div>
                <div className="modal-content">
                    <ul>
                        <li>
                            <h3>작성일</h3>
                            <p>{moment(feedbackData.createdAt).format("YYYY-MM-DD HH:mm")}</p>
                        </li>
                        <li>
                            <h3>리뷰어</h3>
                            <p>
                                {feedbackData.target.nickname}({feedbackData.target._id})
                            </p>
                        </li>
                        <li>
                            <h3>비즈니스</h3>
                            <p>
                                {feedbackData.owner.name}({feedbackData.owner._id})
                            </p>
                        </li>
                        <li>
                            <h3>브랜드</h3>
                            <p>
                                {feedbackData.enterprise.name}({feedbackData.enterprise._id})
                            </p>
                        </li>
                        <li>
                            <h3>신고 여부</h3>
                            {feedbackData.isReported ? "O" : "X"}
                        </li>
                        <li>
                            <h3>신고 내용</h3>
                            {feedbackData.reportHistory?.length
                                ? feedbackData.reportHistory.map((reportedItem, index) => (
                                      <li key={index}>
                                          {convertReason(reportedItem.reportReason)}{" "}
                                          {reportedItem.reportReasonDetail &&
                                              `- ${reportedItem.reportReasonDetail}`}{" "}
                                          신고일:{" "}
                                          {moment(reportedItem.reportedAt).format(
                                              "YYYY-MM-DD HH:mm"
                                          )}
                                      </li>
                                  ))
                                : "-"}
                        </li>
                        <li>
                            <h3>내용</h3>
                            <p className="contents">{feedbackData.content}</p>
                        </li>
                    </ul>
                </div>
                {feedbackData.isReported && (
                    <div className="btn-area">
                        <Button
                            variant="contained"
                            color="primary"
                            className="cancel-reported-btn"
                            onClick={() => onCancleReported(feedbackData.id)}
                        >
                            신고 취소
                        </Button>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default FeedbackDetailModal;

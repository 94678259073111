import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "containers/core/PrivateRoute";
import {
    LoginPage,
    MainPage,
    ProjectManagePage,
    UserManagePage,
    TalentListPage,
    SuggestionListPage,
    PointGiftListPage,
    PaymentListPage,
    ChargeListPage,
    WithdrawPointListPage,
    MessengerPage,
    CouponListPage,
    SubsPromotionPage,
    BannerListPage,
    FeedPickPage,
    NoticePage,
    ContactUsPage,
    CallRequestPage,
    TemplatePage,
    FaqPage,
    FeedbackPage,
    ReviewerVerifiedPage,
    EmailMarketingPage
} from "./pages";

export default () => (
    <Switch>
        <Route exact path="/" component={LoginPage} />
        <PrivateRoute exact path="/main/:statisticsName">
            <MainPage />
        </PrivateRoute>
        <PrivateRoute exact path="/manage/:type">
            <ProjectManagePage />
        </PrivateRoute>
        <PrivateRoute exact path="/user/:category">
            <UserManagePage />
        </PrivateRoute>
        <PrivateRoute exact path="/notice/:type">
            <NoticePage />
        </PrivateRoute>
        <PrivateRoute exact path="/talent/:category">
            <TalentListPage />
        </PrivateRoute>
        <PrivateRoute exact path="/suggestions">
            <SuggestionListPage />
        </PrivateRoute>
        <PrivateRoute exact path="/pointGift">
            <PointGiftListPage />
        </PrivateRoute>
        <PrivateRoute exact path="/payment/:type">
            <PaymentListPage />
        </PrivateRoute>
        <PrivateRoute exact path="/charge">
            <ChargeListPage />
        </PrivateRoute>
        <PrivateRoute exact path="/pointRequests">
            <WithdrawPointListPage />
        </PrivateRoute>
        <PrivateRoute exact path="/messenger">
            <MessengerPage />
        </PrivateRoute>
        <PrivateRoute exact path="/banners">
            <BannerListPage />
        </PrivateRoute>
        <PrivateRoute exact path="/feeds">
            <FeedPickPage />
        </PrivateRoute>
        <PrivateRoute exact path="/coupon/:type">
            <CouponListPage />
        </PrivateRoute>
        <PrivateRoute exact path="/promotion/subscription">
            <SubsPromotionPage />
        </PrivateRoute>
        <PrivateRoute path="/contact-us">
            <ContactUsPage />
        </PrivateRoute>
        <PrivateRoute path="/call-request">
            <CallRequestPage />
        </PrivateRoute>
        <PrivateRoute exact path="/template/:type">
            <TemplatePage />
        </PrivateRoute>
        <PrivateRoute exact path="/faq/:type">
            <FaqPage />
        </PrivateRoute>
        <PrivateRoute exact path="/feedbacks">
            <FeedbackPage />
        </PrivateRoute>
        <PrivateRoute exact path="/reviewer-verified">
            <ReviewerVerifiedPage />
        </PrivateRoute>
        <PrivateRoute exact path="/email-marketing">
            <EmailMarketingPage />
        </PrivateRoute>
        <Route path="*">
            <Redirect to={{ pathname: "/" }} />
        </Route>
    </Switch>
);

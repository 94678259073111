import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as ProjectRequestAPI from "lib/api/projectRequest";

const [
    GET_PROJECR_REQUEST_LIST,
    GET_PROJECR_REQUEST_LIST_SUCCESS,
    // eslint-disable-next-line
    GET_PROJECR_REQUEST_LIST_FAILURE
] = createRequestActionTypes("projectRequest/GET_PROJECR_REQUEST_LIST");

export const getProjectRequestList = createAction(GET_PROJECR_REQUEST_LIST);

const getProjectRequestListSaga = createRequestSagaWithAlert(
    GET_PROJECR_REQUEST_LIST,
    ProjectRequestAPI.getProjectRequestList,
    "프로젝트 신청 내역을 가져오는 데 실패하였습니다.",
    true
);

export function* projectRequestSaga() {
    yield takeLatest(GET_PROJECR_REQUEST_LIST, getProjectRequestListSaga);
}

const initialState = {
    list: [],
    totalCount: 0,
    detailContents: null
};

const projectRequest = handleActions(
    {
        [GET_PROJECR_REQUEST_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            list: payload.list,
            totalCount: payload.totalCount
        })
    },
    initialState
);

export default projectRequest;

import client from "./client";

export const getPointRequestList = ({ reviewerId, projectId, status, skip }) =>
    client.get(`/admin/pointRequests/${reviewerId}`, {
        params: {
            projectId,
            status,
            skip
        }
    });

export const setPointRequest = ({ reviewerId, pointRequestObj }) =>
    client.post(`/admin/pointRequests/${reviewerId}`, pointRequestObj);

export const getWithdrawPointList = query => client.get("/pointRequest/find", { params: query });
export const downloadPointRequest = query => client.get("/pointRequest/csv", { params: query });

export const confirmWithdrawPoint = ({ _id }) =>
    client.get("/pointRequest/confirm", { params: { _id } });
export const denyWithdrawPoint = ({ _id, reasonForDeny }) =>
    client.get("/pointRequest/deny", { params: { _id, reasonForDeny } });

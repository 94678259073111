import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Color } from "theme";
import CommonTableActions from "components/common/CommonTableActions";
import CreateCouponModal from "components/coupon/CreateCouponModal";

const CouponActions = ({ idList, counts }) => {
    const { type } = useParams();
    const [actionState, setActionState] = useState(null);

    const retrieveCoupon = () => {
        console.log("retrieve", idList);
    };

    const removeCoupon = () => {
        console.log("remove", idList);
    };

    return (
        <>
            {actionState === "create" && (
                <CreateCouponModal closeModal={() => setActionState(null)} />
            )}
            <CommonTableActions totalCounts={counts} selectedCounts={idList?.length}>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ backgroundColor: Color.greenDark }}
                    onClick={() => setActionState("create")}
                >
                    생성
                </Button>
                {type === "user" && (
                    <>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ backgroundColor: Color.gray3 }}
                            onClick={retrieveCoupon}
                        >
                            회수
                        </Button>
                        <Button variant="contained" color="secondary" onClick={removeCoupon}>
                            삭제
                        </Button>
                    </>
                )}
            </CommonTableActions>
        </>
    );
};

export default CouponActions;

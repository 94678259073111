import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { getWeeklyRetentionReviewerStats } from "stores/modules/statistic";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const WeeklyRetentionReviewer = () => {
    const dispatch = useDispatch();

    const weeklyRetentionReviewerStatistics = useSelector(
        ({ statistic }) => statistic.weeklyRetentionReviewerData
    );

    useEffect(() => {
        dispatch(getWeeklyRetentionReviewerStats());
    }, []);

    if (weeklyRetentionReviewerStatistics.length === 0) {
        return null;
    }

    return (
        <div>
            <Line
                height={70}
                data={{
                    labels: weeklyRetentionReviewerStatistics.map(item =>
                        moment()
                            .set("years", Object.keys(item)[0].split("-")[0])
                            .set("weeks", Object.keys(item)[0].split("-")[1])
                            .format("YYYY-MM-DD")
                    ),
                    datasets: [
                        {
                            label: "주간 리뷰어 재접속 수",
                            data: weeklyRetentionReviewerStatistics.map(
                                item => Object.values(item)[0]
                            ),
                            borderColor: "rgb(255,191,0)"
                        }
                    ]
                }}
            />
        </div>
    );
};

export default WeeklyRetentionReviewer;

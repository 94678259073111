import { useSelector } from "react-redux";

// state
export const useMessengerState = value => useSelector(({ messenger }) => messenger[value]);
export const useMessageBoxList = value =>
    useSelector(({ messenger }) => (value ? messenger.messageBox[value] : messenger.messageBox));
export const useSelectedMessageBox = value =>
    useSelector(({ messenger }) =>
        value ? messenger.selectedMessageBox[value] : messenger.selectedMessageBox
    );

import React from "react";
import moment from "moment";
import { convertTarget, convertBoolean } from "lib/common";
import { convertSns } from "lib/utils/sns";

import "./EditUserInfo.scss";

const EditUserInfo = ({ userInfo }) => (
    <>
        <div className="modal-content user-manage-edit-user-info">
            <div className="field-box">
                <div className="field">
                    <p className="field-title">회원 유형</p>
                    <div className="field-content">{convertTarget(userInfo.category)}</div>
                </div>
                <div className="field">
                    <p className="field-title">이메일(아이디)</p>
                    <div className="field-content">
                        <input type="text" defaultValue={userInfo.email} />
                    </div>
                </div>
                <div className="field">
                    <p className="field-title">이름</p>
                    <div className="field-content">
                        <input type="text" defaultValue={userInfo.name} />
                    </div>
                </div>
                {userInfo.category === "Normal" && (
                    <div className="field">
                        <p className="field-title">닉네임</p>
                        <div className="field-content">
                            <p>{userInfo.nickname}</p>
                        </div>
                    </div>
                )}
            </div>
            <div className="field row">
                <p className="field-title">전화번호</p>
                <div className="field-content">
                    <input type="text" defaultValue={userInfo.phoneNumber} />
                </div>
            </div>
            {userInfo.category === "Normal" ? (
                <>
                    <div className="field row">
                        <p className="field-title">성별</p>
                        <div className="field-content">
                            <ul className="label-list">
                                <li>
                                    <input
                                        type="radio"
                                        id="user-gender-female"
                                        defaultChecked={userInfo.gender === "Female"}
                                    />
                                    <label htmlFor="user-gender-female">여성</label>
                                </li>
                                <li>
                                    <input
                                        type="radio"
                                        id="user-gender-male"
                                        defaultChecked={userInfo.gender === "Male"}
                                    />
                                    <label htmlFor="user-gender-male">남성</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="address field row">
                        <p className="field-title">자택주소</p>
                        <div className="field-content">
                            <p>{userInfo.address?.address || userInfo.address?.roadAddress}</p>
                            <input type="text" defaultValue={userInfo.addressDetail} />
                        </div>
                    </div>
                    <div className="field row">
                        <p className="field-title">출생년도</p>
                        <div className="field-content">
                            <input type="text" defaultValue={userInfo.birthOfYears} />
                        </div>
                    </div>
                    <div className="field row">
                        <p className="field-title">SNS</p>
                        <div className="field-content">
                            <ul className="sns-list">
                                {userInfo.sns.map(item => (
                                    <li key={item.category}>
                                        <p>{convertSns(item.category)}</p>
                                        <input type="text" defaultValue={item.path} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="field row">
                        <p className="field-title">메모</p>
                        <div className="field-content">
                            <textarea defaultValue={userInfo.memo} className="memo-input" />
                        </div>
                    </div>
                    <div className="field row">
                        <p className="field-title">포트폴리오 URL</p>
                        <div className="field-content">
                            <a
                                href={`https://reviewshare.io/portfolio/${userInfo.portfolioUrl}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >{`https://reviewshare.io/portfolio/${userInfo.portfolioUrl}`}</a>
                        </div>
                    </div>
                </>
            ) : (
                <div className="field row">
                    <p className="field-title">브랜드</p>
                    <div className="field-content">
                        <ul className="list">
                            {userInfo.enterprises?.map(item => (
                                <li key={item._id}>
                                    {item._id} - [{item.category}] {item.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            <div className="field row">
                <p className="field-title">마케팅 수신 동의</p>
                <div className="field-content">{convertBoolean(userInfo.isAllowMarketing)}</div>
            </div>
            <div className="field row">
                <p className="field-title">블랙리스트 여부</p>
                <div className="field-content">{convertBoolean(userInfo.blackUser)}</div>
            </div>
            <div className="field row">
                <p className="field-title">가입일</p>
                <div className="field-content">
                    {moment(userInfo.createdAt).format("YYYY-MM-DD HH:MM:SS")}
                </div>
            </div>
            <div className="field row">
                <p className="field-title">가입 방식</p>
                <div className="field-content">{userInfo.loginMethod}</div>
            </div>
        </div>

        {/* {userInfo.category === "Normal" && (
                <div className="modal-footer user-manage-edit-actions">
                    <div className="btn-area">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={onStopUsing}
                            disabled={userInfo.blockProjectRequest}
                        >
                            프로젝트 신청 제한
                        </Button>
                    </div>
                    <InfoBox
                        className="size-s"
                        color={userInfo.blockProjectRequest ? "red" : "gray"}
                    >
                        {!userInfo.blockProjectRequest && <IconFont icon="icon_info" />}
                        {userInfo.blockProjectRequest
                            ? "프로젝트 신청 제한 + 신청한 프로젝트(모집중인 상태)가 취소 처리되었습니다."
                            : "프로젝트 신청 제한 + 신청한 프로젝트(모집중인 상태)를 취소하는 기능입니다."}
                    </InfoBox>
                </div>
            )} */}
    </>
);

export default EditUserInfo;

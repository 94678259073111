import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import produce from "immer";
import { createRequestActionTypes, createRequestSagaBase } from "lib/createRequestSaga";
import { getCategoryList as getCategoryListAPI } from "lib/api/category";

export const [
    GET_CATEGORY_LIST,
    GET_CATEGORY_LIST_SUCCESS,
    GET_CATEGORY_LIST_FAILURE
] = createRequestActionTypes("common/GET_CATEGORY_LIST");

export const getCategoryList = createAction(GET_CATEGORY_LIST);

const getCategoryListSaga = createRequestSagaBase({
    type: GET_CATEGORY_LIST,
    api: getCategoryListAPI
});

export function* commonSaga() {
    yield takeLatest(GET_CATEGORY_LIST, getCategoryListSaga);
}

const initialState = { category: {} };

const common = handleActions(
    {
        [GET_CATEGORY_LIST_SUCCESS]: (state, { payload, prev = {} }) =>
            produce(state, draft => {
                if (prev.target) {
                    draft.category[prev.target] = payload;
                } else {
                    // all
                    const newCategoryObj = {};
                    [...payload].forEach(category => {
                        if (newCategoryObj[category.target]) {
                            newCategoryObj[category.target].push(category);
                        } else {
                            newCategoryObj[category.target] = [category];
                        }
                    });
                    draft.category = newCategoryObj;
                }
            })
    },
    initialState
);

export default common;

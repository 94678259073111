import React from "react";
import Button from "@material-ui/core/Button";
import { useSearchQuery } from "lib/customHooks/useSearchQuery";

import "./FeedbackSearch.scss";

const FeedbackSearch = ({ searchForm, onSubmit }) => {
    const { query } = useSearchQuery();

    return (
        <form className="feedback-search-area" onSubmit={onSubmit}>
            <div className="row-item">
                <h2>상태</h2>
                <ul>
                    <li>
                        <label htmlFor="status-all">
                            <input
                                type="radio"
                                name="status"
                                id="status-all"
                                value="all"
                                ref={searchForm}
                            />
                            전체
                        </label>
                    </li>
                    <li>
                        <label htmlFor="status-reported">
                            <input
                                type="radio"
                                name="status"
                                id="status-reported"
                                value="reported"
                                ref={searchForm}
                            />
                            신고됨
                        </label>
                    </li>
                </ul>
            </div>
            <div className="search-items">
                <div className="search-item">
                    <h3>후기 ID</h3>
                    <input
                        type="text"
                        placeholder="후기 ID를 입력해주세요."
                        ref={searchForm}
                        defaultValue={query.feedbackId}
                        name="feedbackId"
                    />
                </div>
                <div className="search-item">
                    <h3>리뷰어 ID</h3>
                    <input
                        type="text"
                        placeholder="리뷰어 ID를 입력해주세요."
                        ref={searchForm}
                        defaultValue={query.targetId}
                        name="targetId"
                    />
                </div>
                <div className="search-item">
                    <h3>비즈니스 ID</h3>
                    <input
                        type="text"
                        placeholder="비즈니스 ID를 입력해주세요."
                        ref={searchForm}
                        defaultValue={query.ownerId}
                        name="ownerId"
                    />
                </div>
                <div className="search-item">
                    <h3>브랜드 ID</h3>
                    <input
                        type="text"
                        placeholder="브랜드 ID를 입력해주세요."
                        ref={searchForm}
                        defaultValue={query.brandId}
                        name="brandId"
                    />
                </div>
            </div>
            <div className="btn-area">
                <Button variant="contained" color="primary" className="submit-btn" type="submit">
                    검색
                </Button>
            </div>
        </form>
    );
};

export default FeedbackSearch;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Color } from "theme";
import { WITHDRAW_DENY_REASON_LIST } from "scheme/pointRequest";
import { convertReasonForDeny, convertPointRequestStatus } from "lib/utils/pointRequest";
import { useSearchQuery } from "lib/customHooks/useSearchQuery";
import { downloadPointRequest as downloadPointRequestAPI } from "lib/api/pointRequest";
import useDirectApi from "lib/customHooks/useDirectApi";
import { confirmWithdrawPoint, denyWithdrawPoint } from "stores/modules/pointRequest";
import CommonTableActions from "components/common/CommonTableActions";

const WithdrawPointActions = ({ idList, counts }) => {
    const dispatch = useDispatch();
    const { query } = useSearchQuery();
    const { data: csv, fetch: downloadPointRequest } = useDirectApi({
        api: downloadPointRequestAPI
    });

    const [denyReason, setDenyReason] = useState("bankAccountInfo");

    const confirmWithdraw = () => {
        if (!idList?.length) return;
        console.log("confirm", idList);
        const newIdList = idList.map(item => Number(item));
        dispatch(confirmWithdrawPoint({ _id: newIdList }));
    };

    const denyWithdraw = () => {
        if (!idList?.length || !denyReason) return;
        // https://reviewshare.io/pointRequest/deny?_id=133381&reasonForDeny=inappropriateReason
        console.log("reject", idList);
        const newIdList = idList.map(item => Number(item));
        dispatch(denyWithdrawPoint({ _id: newIdList, reasonForDeny: denyReason }));
    };

    const downloadCsv = () => {
        downloadPointRequest(query);
    };

    const convertCsv = items => {
        if (!items?.length) return;

        const row = [
            "No",
            "요청 ID",
            "주민번호",
            "리뷰어 ID",
            "이메일",
            "닉네임",
            "이름",
            "예금주",
            "계좌번호",
            "입금은행",
            "상태",
            "차감 포인트",
            "출금액",
            "휴대폰번호",
            "요청일"
        ];

        const rowArray = [row.join(",")];
        items.forEach((item, index) => {
            if (!item) return;
            const newItem = {};
            newItem["No"] = index + 1;
            newItem["요청ID"] = item._id;
            newItem["주민번호"] = item.idCardNumber || "-";
            newItem["신청자ID"] = item.user?._id || "-";
            newItem["계정"] = item.user?.email || "-";
            newItem["닉네임"] = item.user?.nickname || "-";
            newItem["이름"] = item.user?.name || "-";
            newItem["예금주"] = item.accountHolder || "-";
            newItem["계좌번호"] = `♬ ${item.accountNumber}`;
            newItem["입금은행"] = item.bankName || "-";
            newItem["상태"] = convertPointRequestStatus(item.status);
            newItem["차감포인트"] = item.subtractionPointAmount || "-";
            newItem["출금액"] = item.exchangeAmount || "-";
            newItem["전화번호"] = item.user?.phoneNumber || "-";
            newItem["요청일"] = moment(item.createdAt).format("YYYY-MM-DD HH:mm");
            // 어느 항목인지 쉽게 구분하기 위해 객체로 만들어줌

            rowArray.push(Object.values(newItem).join(","));
        });
        const jsonData = JSON.parse(JSON.stringify(rowArray.join("\n")));
        const filename = `포인트 출금 관리_${new Date().toLocaleString()}.csv`;

        const data = URL.createObjectURL(
            new Blob([`\ufeff${jsonData}`], { type: "text/csv;charset=utf-8" })
        );

        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", filename);
        link.click();
        URL.revokeObjectURL(data);
    };

    useEffect(() => {
        if (csv?.data) {
            convertCsv(csv.data);
        }
    }, [csv?.data]);

    return (
        <>
            {/* {actionState === "deny" && (
                <div />
            )} */}
            <CommonTableActions
                id="withdraw-point-list-actions"
                totalCounts={counts}
                selectedCounts={idList?.length}
                onDownloadCsv={downloadCsv}
            >
                <Select
                    value={denyReason}
                    onChange={e => {
                        const { value } = e.target;
                        setDenyReason(value);
                    }}
                >
                    {WITHDRAW_DENY_REASON_LIST.map(reason => (
                        <MenuItem value={reason} key={reason}>
                            {convertReasonForDeny(reason)}
                        </MenuItem>
                    ))}
                </Select>
                <Button variant="contained" color="secondary" onClick={denyWithdraw}>
                    거절
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ backgroundColor: Color.greenDark }}
                    onClick={confirmWithdraw}
                >
                    승인
                </Button>
            </CommonTableActions>
        </>
    );
};

export default WithdrawPointActions;

import { convertBoolean, convertDate, numberWithCommas } from "lib/common";
import { convertReceiptType, convertCategory, convertPaymentStatus } from "lib/utils/payment";

export const STATUS_LIST = [
    "ready",
    "paid",
    "failed",
    "cancelled",
    "pass",
    "refund",
    "subtraction"
    // "cashRefund",
    // "readyForRefund"
];

export const FILTER_INPUT_ITEMS = [
    { label: "결제 ID", value: "id", inputType: "text" },
    {
        label: "상태",
        value: "status",
        inputType: "select",
        array: STATUS_LIST,
        convertFn: convertPaymentStatus
    },
    { label: "비즈니스 ID", value: "bizId", inputType: "text" },
    { label: "비즈니스명", value: "bizName", inputType: "text" },
    { label: "휴대폰 번호", value: "bizPhoneNumber", inputType: "text" },
    { label: "프로젝트/제안/거래 ID", value: "serviceId", inputType: "text" }
];

export const TABLE_ITEMS = [
    { label: "결제 ID", align: "center", value: "_id" },
    {
        label: "상태",
        align: "center",
        value: "status",
        openDetail: true,
        convertFn: convertPaymentStatus
    },
    { label: "비즈니스 ID", align: "center", value: "bizId" },
    { label: "비즈니스명", align: "center", value: "bizName" },
    { label: "결제 방식", align: "center", value: "category", convertFn: convertCategory },
    { label: "결제 금액", align: "center", value: "amount", convertFn: numberWithCommas },
    { label: "사용 포인트", align: "center", value: "usedPoints", convertFn: numberWithCommas },
    { label: "실 결제 금액", align: "center", value: "amountReal", convertFn: numberWithCommas },
    { label: "쿠폰 사용 여부", align: "center", value: "coupon", convertFn: convertBoolean },
    { label: "지출증빙", align: "center", value: "receiptType", convertFn: convertReceiptType },
    { label: "날짜", align: "center", value: "createdAt", convertFn: convertDate }
];

import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import moment from "moment";
import { TableContainer, Table, TableHead, TableRow, TableBody, Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { IconFont, Tag } from "reviewshare-common-component";
import CustomTableCell from "components/common/CustomTableCell";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { numberWithCommas, returnTagColor } from "lib/common";
import { convertChargeStatus, convertReceiptType } from "lib/utils/payment";
import { selectChargeInfo } from "stores/modules/payment";

import "./ChargeList.scss";

const tableHeaderList = [
    { label: "상태", align: "center" },
    { label: "결제 ID", align: "center" },
    { label: "비즈니스", align: "center" },
    { label: "충전 금액", align: "center" },
    { label: "결제 금액", align: "center" },
    { label: "예금주명", align: "center" },
    { label: "지출증빙", align: "center" },
    { label: "요청 일자", align: "center" },
    { label: "입금 기한", align: "center" }
];
const limit = 40;
const ChargeList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { query } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;

    const { list, counts } = useSelector(({ payment }) => payment.charge);

    const onChangePagination = (e, num) => {
        const newQuery = createSearchQuery({ ...query, skip: (num - 1) * limit });
        history.replace({ path: "/charge", search: newQuery });
    };

    const onSelectChargeInfo = info => {
        dispatch(selectChargeInfo(info));
    };

    return (
        <div id="charge-list">
            <TableContainer>
                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {tableHeaderList.map((headerItem, index) => (
                                <CustomTableCell align={headerItem.align} key={index}>
                                    {headerItem.label}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.length > 0 &&
                            list.map((payment, index) => (
                                <TableRow
                                    key={index}
                                    hover
                                    className={cx("charge-list-row", {
                                        "deleted-item": payment.isDeleted
                                    })}
                                >
                                    <CustomTableCell align="center">
                                        <Tag
                                            color={returnTagColor(payment.status)}
                                            className="size-l"
                                        >
                                            {convertChargeStatus(payment.status)}
                                        </Tag>
                                    </CustomTableCell>
                                    <CustomTableCell
                                        align="center"
                                        className="open-detail"
                                        onClick={() => onSelectChargeInfo(payment)}
                                    >
                                        <IconFont icon="icon_project" />
                                        {payment._id}
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {payment.owner?.name}({payment.owner?._id})
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {numberWithCommas(payment.amount)}
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {numberWithCommas(payment.amountReal)}
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {payment.accountName}
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {convertReceiptType(payment.receiptType)}
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {moment(payment.createdAt).format("YYYY-MM-DD")}
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {payment.limitDate
                                            ? moment(payment.limitDate).format("YYYY-MM-DD")
                                            : "-"}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center" style={{ margin: "20px 0" }}>
                <Pagination
                    count={Math.ceil(counts / limit)}
                    color="primary"
                    onChange={onChangePagination}
                />
            </Box>
        </div>
    );
};

export default ChargeList;

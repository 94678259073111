import React, { useEffect } from "react";
import ContactUsSearch from "components/contactUs/ContactUsSearch";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { getCommentList } from "stores/modules/comment";

const ContactUsSearchContainer = ({ type }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const params = queryString.parse(location.search);
    console.log("params:", params);
    const { register, handleSubmit } = useForm({
        defaultValues: {
            status: params.status || "request"
        }
    });

    const onSearchContactUs = async data => {
        console.log("data:", data);
        const query = [];
        Object.keys(data).forEach((key, index) => {
            if (data[key]) {
                if (index === 0) {
                    query.push("?");
                } else {
                    query.push("&");
                }
                query.push(`${key}=${data[key]}`);
            }
        });

        history.replace(`${location.pathname}${query.join("")}`);
    };

    useEffect(() => {
        params.type = type;
        if (params.page) {
            params.skip = (params.page - 1) * 30;
        }
        if (!params.status) {
            params.status = "request";
        }
        if (params.status === "all") {
            params.status = null;
        }
        dispatch(getCommentList(params));
        // eslint-disable-next-line
    }, [dispatch, location.search]);

    return <ContactUsSearch searchForm={register} onSubmit={handleSubmit(onSearchContactUs)} />;
};

export default ContactUsSearchContainer;

import client from "./client";

export const getManagedProjectList = ({ brandId, projectStatus, limit, skip }) =>
    client.get(
        `/campaign/managedProjectList?brandId=${brandId}&projectStatus=${projectStatus}&limit=${limit}&skip=${skip}`
    );

export const getManagedProjectCount = ({ brandId }) =>
    client.get(`/campaign/projectCount?brandId=${brandId}`);

export const getProjectList = ({
    date,
    status,
    plan,
    type,
    category,
    sns,
    title,
    id,
    name,
    phoneNumber,
    ownerId,
    option,
    skip
}) =>
    client.get("/admin/projectList", {
        params: {
            date,
            status,
            plan,
            type,
            category,
            sns,
            title,
            id,
            name,
            phoneNumber,
            ownerId,
            option,
            skip
        }
    });

export const getProject = ({ projectId }) => client.get(`/admin/projects/${projectId}`);

export const updateProject = ({ projectId, projectObj }) =>
    client.patch(`/admin/projects/${projectId}`, projectObj);
export const confirmProject = ({ projectId }) => client.put("/campaign/confirm", { id: projectId });
export const setRereviewingStatus = ({ projectId, info }) =>
    client.patch(`/v3/campaigns/admin/${projectId}/rereviewing`, info);
export const cancelProject = ({ projectId }) => client.put("/campaign/cancel", { id: projectId });
export const setSponsorProject = ({ idList, action }) =>
    client.patch(`/admin/sponsorProject`, { idList, action });
export const removeProject = id => client.delete(`/campaign?_id=${id}`);
export const setPickProject = ({ process, idList }) =>
    client.patch("/v3/campaigns/pick", { process, idList });
export const updateBrand = ({ projectId, updateValues }) =>
    client.patch(`/v3/campaigns/admin/${projectId}/brand`, updateValues);

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { callDirectApi } from "lib/common";
import { sendMarketingEmail as sendMarketingEmailAPI } from "lib/api/user";
import SendEmail from "components/emailMarketing/SendEmail";
import AlertModal from "components/common/AlertModal";

const SendEmailContainer = () => {
    const history = useHistory();

    const [alertModalStatus, handleModalStatus] = useState(false);
    const [numberOfSent, setNumberOfSent] = useState(null);
    const [isLoading, setLoadingStatus] = useState(false);

    const { register, setValue, watch, getValues, triggerValidation } = useForm({
        defaultValues: {
            onlyAllowMarketing: true
        }
    });
    const values = watch();

    const onSend = async () => {
        const inputValues = getValues();
        console.log("inputValues:", inputValues);
        setLoadingStatus(true);

        try {
            const result = await callDirectApi(
                sendMarketingEmailAPI,
                {
                    ...inputValues,
                    ...(inputValues.recieverIdList.length && {
                        recieverIdList: inputValues.recieverIdList.replace(/\s/g, "").split(",")
                    })
                },
                true
            );
            console.log("result:", result);

            if (result.status === 200) {
                setNumberOfSent(result.data.successCount);
                handleModalStatus(true);
            } else {
                throw new Error("개발팀에 문의해주세요.");
            }
        } catch (error) {
            window.alert(error.message);
        }
        setLoadingStatus(false);
    };

    return (
        <>
            {alertModalStatus && (
                <AlertModal handleModal={() => history.go(0)}>
                    {numberOfSent} 명에게 전송 요청했습니다.
                </AlertModal>
            )}
            <SendEmail
                emailForm={register}
                values={values}
                validate={triggerValidation}
                onSend={onSend}
                isLoading={isLoading}
                setValue={setValue}
            />
        </>
    );
};

export default SendEmailContainer;

import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { getPointGiftList } from "stores/modules/pointGift";

import "./PointGiftFilter.scss";

const filterItems = [
    {
        value: "pointGiftId",
        label: "선물 ID"
    },
    {
        value: "reviewerId",
        label: "리뷰어 ID"
    },
    {
        value: "reviewerNickname",
        label: "닉네임"
    },
    {
        value: "bizId",
        label: "비즈니스 ID"
    },
    {
        value: "brandId",
        label: "브랜드 ID"
    },
    {
        value: "brandName",
        label: "브랜드명"
    }
];
const PointGiftFilter = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { query, search } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;

    const [selectedOptions, setSelectedOptions] = useState({
        reviewerId: query.reviewerId || undefined,
        reviewerNickname: query.reviewerNickname || undefined,
        bizId: query.bizId || undefined,
        brandId: query.brandId || undefined,
        brandName: query.brandName || undefined
    });

    useEffect(() => {
        dispatch(getPointGiftList(query));
    }, [search]);

    const onSearch = () => {
        const newQuery = createSearchQuery(selectedOptions);
        history.replace({ path: location.pathname, search: newQuery });
    };

    return (
        <div id="point-gift-filter">
            <fieldset>
                {filterItems.map((item, index) => (
                    <div className="field" key={index}>
                        <label htmlFor="point-gift-filter-name">{item.label}</label>
                        <input
                            type="text"
                            id={`point-gift-filter-${item.value}`}
                            value={selectedOptions[item.value]}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    onSearch();
                                }
                            }}
                            onChange={e => {
                                const { value } = e.target;
                                setSelectedOptions(prev => ({
                                    ...prev,
                                    [item.value]: value || undefined
                                }));
                            }}
                        />
                    </div>
                ))}
            </fieldset>
            <div className="button-box">
                <Button variant="contained" color="primary" onClick={onSearch}>
                    검색
                </Button>
            </div>
        </div>
    );
};

export default PointGiftFilter;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Login from "components/login/Login/Login";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { login as tryToLogin, getUserInfoAsTokenInLocalStorage } from "stores/modules/auth";
import AlertModal from "components/common/AlertModal";
import HideScrollBackground from "components/common/HideScrollBackground";
import { setStorage, getStorage, removeStorage } from "lib/storage";

const LoginContainer = () => {
    const { register, handleSubmit, errors, getValues, setValue } = useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loginPageError, setLoginPageError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { user, token } = useSelector(({ auth }) => ({
        user: auth.userInfo.user,
        token: auth.userInfo.token
    }));
    const loginError = useSelector(({ auth }) => auth.userInfoError);

    const onLogin = loginInfo => {
        setLoading(true);
        dispatch(tryToLogin(loginInfo));
    };

    const closeModal = () => {
        setLoginPageError(null);
    };

    useEffect(() => {
        const savedId = getStorage("reviewshare-bi");

        if (savedId) {
            setValue("identifier", savedId);
            setValue("saveId", true);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (loginError) {
            setLoading(false);
            setLoginPageError("failLogin");
            return;
        }
        if (user && token) {
            const loginFormValues = getValues();

            if (loginFormValues.saveId) {
                setStorage("reviewshare-bi", loginFormValues.identifier);
            }
            if (!loginFormValues.saveId) {
                removeStorage("reviewshare-bi");
            }
            setStorage("reviewshare-admin-at", token);
            dispatch(getUserInfoAsTokenInLocalStorage());
            history.push("/main/kpi");
        }
        // eslint-disable-next-line
    }, [dispatch, user, token, loginError]);

    return (
        <>
            {loading && <HideScrollBackground />}
            {loginPageError === "failLogin" && (
                <AlertModal handleModal={closeModal}>
                    <p>
                        {loginError.data
                            ? loginError.data.message
                            : "아이디 또는 비밀번호가 일치하지 않습니다."}
                    </p>
                </AlertModal>
            )}
            <Login loginForm={register} errors={errors} onLogin={handleSubmit(onLogin)} />
        </>
    );
};

export default LoginContainer;

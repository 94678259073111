import client from "./client";

export const getProjectRequestList = ({
    status,
    projectTitle,
    projectId,
    requestId,
    reviewerId,
    nickname,
    skip,
    limit
}) =>
    client.get("/v3/campaignRequests/admin", {
        params: { status, projectTitle, projectId, requestId, reviewerId, nickname, skip, limit }
    });

export const updateProjectRequest = ({ requestId, status }) =>
    client.patch(`/v3/campaignrequests/admin/${requestId}/status`, { status });

export const unsubmitRequest = reqeustId =>
    client.patch("/campaignRequest/unSubmit", { id: reqeustId });

export const changeSubmitEndAt = ({ submitEndAt, requestId }) =>
    client.patch(`/campaignRequest/submitEndDay/${requestId}`, { newSubmitEndDay: submitEndAt });
export const submitUrl = ({ info, requestId }) =>
    client.patch(`/campaignRequest/submit/${requestId}`, info);

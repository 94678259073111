import React from "react";
import { FAQ_REVIEWER_TAG, FAQ_BIZ_TAG } from "scheme/Scheme";
import { Editor } from "reviewshare-common-component";

import "./PostEditor.scss";

const PostEditor = ({ contentValue, setValue, postForm, postType, faqTagTarget }) => (
    <form className="post-editor">
        <div className="column-item">
            <label htmlFor="notice-title" className="item-title">
                제목
            </label>
            <input
                type="text"
                id="notice-title"
                className="size-l"
                name="title"
                ref={postForm}
                placeholder="제목을 입력해주세요."
            />
        </div>
        {postType === "notice" && (
            <div className="column-item row">
                <label htmlFor="notice-isHide" className="item-title">
                    숨기기
                </label>
                <div className="item-content">
                    <input type="checkbox" id="notice-isHide" name="isHide" ref={postForm} />
                </div>
            </div>
        )}
        <div className="column-item">
            <p className="item-title">대상</p>
            <ul>
                {postType === "notice" && (
                    <li>
                        <input
                            type="radio"
                            id="target-all"
                            name="target"
                            value="all"
                            ref={postForm}
                        />
                        <label htmlFor="target-all">전체</label>
                    </li>
                )}
                <li>
                    <input
                        type="radio"
                        id="target-reviewer"
                        name="target"
                        value="reviewer"
                        ref={postForm}
                    />
                    <label htmlFor="target-reviewer">리뷰어</label>
                </li>
                <li>
                    <input type="radio" id="target-biz" name="target" value="biz" ref={postForm} />
                    <label htmlFor="target-biz">비즈니스</label>
                </li>
            </ul>
        </div>
        {postType === "faq" && (
            <div className="column-item">
                <p className="item-title">태그</p>
                <ul>
                    {faqTagTarget === "reviewer" && (
                        <>
                            {FAQ_REVIEWER_TAG.map((tag, index) => (
                                <li key={index}>
                                    <input
                                        name="faqTag"
                                        type="radio"
                                        id={`faq-tag-${tag.value}`}
                                        value={tag.value}
                                        ref={postForm}
                                    />
                                    <label htmlFor={`faq-tag-${tag.value}`}>{tag.label}</label>
                                </li>
                            ))}
                        </>
                    )}
                    {faqTagTarget === "biz" && (
                        <>
                            {FAQ_BIZ_TAG.map((tag, index) => (
                                <li key={index}>
                                    <input
                                        name="faqTag"
                                        type="radio"
                                        id={`faq-tag-${tag.value}`}
                                        value={tag.value}
                                        ref={postForm}
                                    />
                                    <label htmlFor={`faq-tag-${tag.value}`}>{tag.label}</label>
                                </li>
                            ))}
                        </>
                    )}
                </ul>
            </div>
        )}

        <div className="editor-area">
            <Editor value={contentValue} onChange={contentValue => setValue(contentValue)} />
        </div>
    </form>
);

export default PostEditor;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import cx from "classnames";
import Modal from "@material-ui/core/Modal";
import { Button, Select, MenuItem } from "@material-ui/core";
import { CautionList } from "reviewshare-common-component";
import useDirectApi from "lib/customHooks/useDirectApi";
import { getBrandList as getBrandListAPI } from "lib/api/brand";
import { updateBrand as updateBrandAPI } from "lib/api/project";
import { getProjectDetail } from "stores/modules/project";

import "./ChangeBrandModal.scss";

const ChangeBrandModal = ({ isOpen, closeModal, brandInfo, projectId, isSubs }) => {
    const BRAND_CATEGORY = [
        "코스메틱",
        "미용",
        "패션/잡화",
        "식품",
        "생활용품",
        "출산/육아",
        "디지털/가전",
        "스포츠/건강",
        "반려동물",
        "맛집",
        "여행/숙박",
        "지역/문화",
        "기타"
    ];
    const dispatch = useDispatch();
    const { register: brandForm, getValues, control, watch, reset } = useForm({
        defaultValues: {
            mode: "edit",
            name: brandInfo.name,
            category: brandInfo.category,
            changedExistsBrandId: null,
            newBrandName: null,
            newBrandCategory: BRAND_CATEGORY[0]
        }
    });
    const mode = watch("mode");
    const [bizBrandList, setBizBrandList] = useState([]);

    const { data: brandList, fetch: getBrandList } = useDirectApi({
        api: getBrandListAPI
    });
    const { data: updateStatus, fetch: updateBrand, error: updateError } = useDirectApi({
        api: updateBrandAPI,
        showError: true
    });

    const onChange = action => {
        // action = ["edit", "change", "add"]
        const values = getValues();
        const updateValues = {};
        updateValues.action = action;

        if (action === "edit") {
            updateValues.id = brandInfo.id;
            updateValues.name = values.name;
            updateValues.category = values.category;
        } else if (action === "change") {
            updateValues.id = values.changedExistsBrandId;
        } else {
            updateValues.name = values.newBrandName;
            updateValues.category = values.newBrandCategory;
        }

        updateBrand({ projectId, updateValues });
    };

    useEffect(() => {
        reset({
            mode: "edit",
            name: brandInfo.name,
            category: brandInfo.category,
            changedExistsBrandId: null,
            newBrandName: null,
            newBrandCategory: BRAND_CATEGORY[0]
        });
    }, [brandInfo]);

    useEffect(() => {
        if (brandInfo && brandInfo.owner) {
            getBrandList({ ownerId: brandInfo.owner, isSubs });
        }
    }, [brandInfo.owner]);

    useEffect(() => {
        if (brandList && brandList.length > 0) {
            setBizBrandList(brandList);
        }
    }, [brandList]);

    useEffect(() => {
        if (updateStatus === 204) {
            closeModal();
            dispatch(getProjectDetail({ projectId }));
        }
    }, [updateStatus, updateError]);

    return (
        <Modal open={isOpen} onClose={closeModal}>
            <div className="change-brand-modal-contents">
                <div>
                    <div className={cx("change-item", { active: mode === "edit" })}>
                        <label>
                            <input type="radio" name="mode" value="edit" ref={brandForm} />
                            기존 브랜드 정보 수정
                        </label>
                        <div>
                            <ul className="field-box column">
                                <li className="field row">
                                    <h3 className="field-title">ID</h3>
                                    <div className="field-content">{brandInfo.id}</div>
                                </li>
                                <li className="field row">
                                    <h3 className="field-title">이름</h3>
                                    <div className="field-content">
                                        <input type="text" name="name" ref={brandForm} />
                                    </div>
                                </li>
                                <li className="field row">
                                    <h3 className="field-title">카테고리</h3>
                                    <div className="field-content">
                                        <Controller
                                            name="category"
                                            control={control}
                                            as={
                                                <Select>
                                                    {BRAND_CATEGORY.map((category, index) => (
                                                        <MenuItem key={index} value={category}>
                                                            {category}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            }
                                        />
                                    </div>
                                </li>
                            </ul>
                            <CautionList
                                title=""
                                items={[
                                    "해당 브랜드로 주고 받은 쪽지의 브랜드 정보도 같이 변경됩니다.",
                                    "해당 브랜드로 진행한 이전 프로젝트 내용은 바뀌지 않습니다."
                                ]}
                            />
                            <div className="btn-area">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => onChange("edit")}
                                >
                                    수정
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={cx("change-item", { active: mode === "change" })}>
                        <label>
                            <input type="radio" name="mode" value="change" ref={brandForm} />
                            다른 브랜드로 변경
                        </label>
                        <div>
                            <Controller
                                name="changedExistsBrandId"
                                control={control}
                                as={
                                    <Select>
                                        {bizBrandList.map((brandItem, brandIndex) => (
                                            <MenuItem key={brandIndex} value={brandItem._id}>
                                                {brandItem._id} - [{brandItem.category}]{" "}
                                                {brandItem.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                            />
                            <div className="btn-area">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => onChange("change")}
                                >
                                    변경
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={cx("change-item", { active: mode === "add" })}>
                        <label>
                            <input type="radio" name="mode" value="add" ref={brandForm} />
                            새로운 브랜드 추가 및 적용
                        </label>
                        <div>
                            <ul className="field-box column">
                                <li className="field row">
                                    <h3 className="field-title">이름</h3>
                                    <div className="field-content">
                                        <input type="text" name="newBrandName" ref={brandForm} />
                                    </div>
                                </li>
                                <li className="field row">
                                    <h3 className="field-title">카테고리</h3>
                                    <div className="field-content">
                                        <Controller
                                            name="newBrandCategory"
                                            control={control}
                                            as={
                                                <Select>
                                                    {BRAND_CATEGORY.map((category, index) => (
                                                        <MenuItem key={index} value={category}>
                                                            {category}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            }
                                        />
                                    </div>
                                </li>
                            </ul>
                            <div className="btn-area">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => onChange("add")}
                                >
                                    적용
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ChangeBrandModal;

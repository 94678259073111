import React from "react";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { BANNER_POSITION_LIST } from "scheme/banner";
import { convertBannerPosition } from "lib/utils/banner";

import "./BannerFilter.scss";

const BannerFilter = ({ search, filter, setFilter }) => (
    <div id="banner-filter">
        <fieldset>
            <div className="field">
                <label htmlFor="banner-filter-name">배너명</label>
                <input
                    type="text"
                    id="banner-filter-name"
                    value={filter.name}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            search();
                        }
                    }}
                    onChange={setFilter}
                />
            </div>
            <div className="field">
                <label htmlFor="banner-filter-position">위치</label>
                <Select
                    id="banner-filter-position"
                    value={filter.position || "all"}
                    name="position"
                    onChange={setFilter}
                >
                    <MenuItem value="all">전체</MenuItem>
                    {BANNER_POSITION_LIST.map(item => (
                        <MenuItem value={item} key={item}>
                            {convertBannerPosition(item)}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </fieldset>
        <div className="button-box">
            <Button variant="contained" color="primary" onClick={search}>
                검색
            </Button>
        </div>
    </div>
);

export default BannerFilter;

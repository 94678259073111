import React from "react";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import { convertCouponTarget } from "lib/utils/coupon";

import "./EnterCouponInfo.scss";

const TARGET_LIST = ["all", "project", "order", "suggestion"];
const EnterCouponInfo = ({ createForm }) => (
    <div id="enter-coupon-info">
        <div className="field-items">
            <div className="field">
                <p className="field-title">사용 가능 서비스</p>
                <div className="field-content">
                    {TARGET_LIST.map(target => (
                        <label key={target}>
                            <input
                                type="radio"
                                name="targetService"
                                value={target}
                                ref={createForm}
                            />
                            {convertCouponTarget(target)}
                        </label>
                    ))}
                </div>
            </div>
            <div className="field">
                <p className="field-title">사용 기간</p>
                <div className="field-content">
                    <TextField
                        type="date"
                        defaultValue={moment().format("YYYY-MM-DD")}
                        inputProps={{
                            name: "startedAt",
                            ref: createForm
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                    <span className="with-space">~</span>
                    <TextField
                        type="date"
                        defaultValue={moment().format("YYYY-MM-DD")}
                        inputProps={{
                            name: "endAt",
                            ref: createForm
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </div>
            </div>
        </div>
        <div className="field">
            <p className="field-title">쿠폰명</p>
            <div className="field-content">
                <input ref={createForm} type="text" name="name" />
            </div>
        </div>
        <div className="field-items">
            <div className="field">
                <p className="field-title">할인율</p>
                <div className="field-content">
                    <input ref={createForm} type="number" name="discountRate" placeholder="0" />
                </div>
            </div>
            <div className="field">
                <p className="field-title">최소 사용 금액</p>
                <div className="field-content">
                    <input ref={createForm} type="number" name="minSpendPrice" placeholder="0" />
                </div>
            </div>
            <div className="field">
                <p className="field-title">최대 할인 금액</p>
                <div className="field-content">
                    <input ref={createForm} type="number" name="maxDiscountPrice" placeholder="0" />
                </div>
            </div>
        </div>
        <div className="field row">
            <p className="field-title">알림, 알림톡, 이메일 발송</p>
            <div className="field-content">
                <input ref={createForm} type="checkbox" name="isSendingNoti" defaultChecked />
            </div>
        </div>
    </div>
);

export default EnterCouponInfo;

import React from "react";
import Button from "@material-ui/core/Button";

import "./SuggestionFilter.scss";

const SuggestionFilter = ({ search, filter, setFilter }) => (
    <div id="suggestion-filter">
        <fieldset>
            <div className="field">
                <label htmlFor="suggestion-filter-id">제안 ID</label>
                <input
                    type="text"
                    id="suggestion-filter-id"
                    value={filter.id}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            search();
                        }
                    }}
                    onChange={e => {
                        const { value } = e.target;
                        setFilter(prev => ({ ...prev, id: value }));
                    }}
                />
            </div>
            <div className="field">
                <label htmlFor="suggestion-filter-from">비즈니스</label>
                <input
                    type="text"
                    id="suggestion-filter-from"
                    value={filter.from}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            search();
                        }
                    }}
                    onChange={e => {
                        const { value } = e.target;
                        setFilter(prev => ({ ...prev, from: value }));
                    }}
                />
            </div>
            <div className="field">
                <label htmlFor="suggestion-filter-to">리뷰어</label>
                <input
                    type="text"
                    id="suggestion-filter-to"
                    value={filter.to}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            search();
                        }
                    }}
                    onChange={e => {
                        const { value } = e.target;
                        setFilter(prev => ({ ...prev, to: value }));
                    }}
                />
            </div>
        </fieldset>
        <div className="button-box">
            <Button variant="contained" color="primary" onClick={search}>
                검색
            </Button>
        </div>
    </div>
);

export default SuggestionFilter;

import React, { useState, useEffect } from "react";
import SideMenu from "components/base/SideMenu";

const sideMenuList = [
    { title: "홈", more: false, link: "/main/kpi" },
    {
        title: "회원 관리",
        more: false,
        value: "user",
        link: "/user/reviewer"
    },
    {
        title: "프로젝트 관리",
        more: false,
        value: "project",
        link: "/manage/project"
    },
    {
        title: "서비스 관리",
        more: false,
        value: "talent",
        link: "/talent/list"
    },
    {
        title: "1:1 제안 관리",
        more: false,
        value: "suggestion",
        link: "/suggestions"
    },
    {
        title: "포인트 선물",
        more: false,
        value: "pointGift",
        link: "/pointGift"
    },
    {
        title: "결제 내역",
        more: false,
        value: "payment",
        link: "/payment/biz"
    },
    {
        title: "포인트 충전 내역",
        more: false,
        value: "charge",
        link: "/charge"
    },
    {
        title: "포인트 출금 내역",
        more: false,
        value: "withdraw-point",
        link: "/pointRequests"
    },
    {
        title: "쪽지 내역",
        more: false,
        value: "messenger",
        link: "/messenger"
    },
    {
        title: "배너 관리",
        more: false,
        value: "banner",
        link: "/banners"
    },
    {
        title: "콘텐츠픽 관리",
        more: false,
        value: "feed",
        link: "/feeds"
    },
    {
        title: "공지사항",
        more: false,
        value: "notice",
        link: "/notice/list"
    },
    {
        title: "FAQ",
        more: false,
        value: "faq",
        link: "/faq/list"
    },
    {
        title: "1:1 문의",
        more: false,
        value: "contact-us",
        link: "/contact-us"
    },
    {
        title: "전화 상담 예약",
        more: false,
        value: "callRequest",
        link: "/call-request"
    },
    {
        title: "1:1 문의 템플릿",
        more: false,
        value: "template",
        link: "/template/list"
    },
    {
        title: "후기 편지",
        more: false,
        value: "feedback",
        link: "/feedbacks"
    },
    {
        title: "리뷰어 인증",
        more: false,
        value: "reviewer-verified",
        link: "/reviewer-verified"
    },
    {
        title: "이메일 마케팅",
        more: false,
        value: "email-marketing",
        link: "/email-marketing"
    }
    // {
    //     title: "쿠폰 관리",
    //     more: false,
    //     value: "coupon",
    //     link: "/coupon/user"
    // },
    // {
    //     title: "구독 프로모션 관리",
    //     more: false,
    //     value: "promotion",
    //     link: "/promotion/subscription"
    // }
];

const subListInitState = {
    project: false,
    payment: false,
    banner: false
};

const SideMenuContainer = () => {
    const urlPathName = window.location.pathname;

    const [subListState, handleSubListState] = useState(subListInitState);
    const [targetSubListItem, setTargetSubListItem] = useState(null);

    const convertSubListState = pathName => {
        switch (pathName) {
            case "manage":
                return "project";
            case "payment":
                return "payment";
            case "brand":
                return "brand";
            case "message":
                return "message";
            default:
                return null;
        }
    };

    useEffect(() => {
        const mainPath = urlPathName.split("/")[1];

        handleSubListState({
            ...subListInitState,
            [convertSubListState(mainPath)]: true
        });
        setTargetSubListItem(urlPathName);
    }, [urlPathName]);

    return (
        <SideMenu
            menuList={sideMenuList}
            subListState={subListState}
            handleSubListState={handleSubListState}
            targetSubListItem={targetSubListItem}
        />
    );
};

export default SideMenuContainer;

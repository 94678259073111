import React from "react";
import { Switch, Route } from "react-router-dom";
import ManageNav from "components/common/ManageNav";
import SubsMainStatistics from "components/main/SubsMainStatistics";
import SubsStatisticsGraph from "components/main/SubsStatisticsGraph";
import WeeklyActiveReviewer from "components/main/WeeklyActiveReviewer";
import WeeklyRetentionReviewer from "components/main/WeeklyRetentionReviewer";
import MonthlyLoginReviewer from "components/main/MonthlyLoginReviewer";
import MonthlyTransactionAndFeeGraph from "components/main/MonthlyTransactionAndFeeGraph";
import DownloadStatisticData from "components/main/DownloadStatisticData";

const MainPage = () => (
    <>
        <ManageNav
            navList={[
                { url: "/main/kpi", name: "KPI" },
                { url: "/main/transaction-amount-and-fee", name: "월별 플랫폼 거래액 및 수수료" },
                { url: "/main/subs", name: "구독 지표" },
                { url: "/main/data", name: "서비스 통계 데이터" }
            ]}
        />
        <Switch>
            <Route exact path="/main/kpi">
                <WeeklyActiveReviewer />
                <WeeklyRetentionReviewer />
                <MonthlyLoginReviewer />
                <iframe
                    style={{
                        backgroundColor: " #FFFFFF",
                        border: "none",
                        borderRadius: "2px",
                        boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)"
                    }}
                    width="1200"
                    height="480"
                    src="https://charts.mongodb.com/charts-project-0-oxmya/embed/charts?id=64a7a8ea-57a0-4a67-8a8d-e62c104e02e3&maxDataAge=86400&theme=light&autoRefresh=true"
                ></iframe>
            </Route>
            <Route path="/main/transaction-amount-and-fee">
                <MonthlyTransactionAndFeeGraph />
            </Route>
            <Route path="/main/subs">
                <SubsMainStatistics />
                <SubsStatisticsGraph />
            </Route>
            <Route path="/main/data">
                <DownloadStatisticData />
            </Route>
        </Switch>
    </>
);

export default MainPage;

import { createAction, handleActions } from "redux-actions";
import { takeLeading } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as paymentAPI from "lib/api/payment";

const RESET_PAYMENT_INFO = "payment/RESET_PAYMENT_INFO";
const SELECT_CHARGE_INFO = "payment/SELECT_CHARGE_INFO";
const [
    GET_PAYMENT_LIST,
    GET_PAYMENT_LIST_SUCCESS,
    // eslint-disable-next-line
    GET_PAYMENT_LIST_FAILURE
] = createRequestActionTypes("payment/GET_PAYMENT_LIST");
const [
    GET_CHARGE_LIST,
    GET_CHARGE_LIST_SUCCESS,
    // eslint-disable-next-line
    GET_CHARGE_LIST_FAILURE
] = createRequestActionTypes("payment/GET_CHARGE_LIST");

export const getPaymentList = createAction(GET_PAYMENT_LIST, obj => obj);
export const getChargeList = createAction(GET_CHARGE_LIST, obj => obj);
export const selectChargeInfo = createAction(SELECT_CHARGE_INFO);
export const resetPaymentInfo = createAction(RESET_PAYMENT_INFO);

const getPaymentListSaga = createRequestSagaWithAlert(
    GET_PAYMENT_LIST,
    paymentAPI.getPaymentHistory,
    "결제 목록을 가져오는 데 실패하였습니다."
);

const getChargeListSaga = createRequestSagaWithAlert(
    GET_CHARGE_LIST,
    paymentAPI.getChargeList,
    "포인트 충전 목록을 가져오는 데 실패하였습니다."
);

export function* paymentSaga() {
    yield takeLeading(GET_PAYMENT_LIST, getPaymentListSaga);
    yield takeLeading(GET_CHARGE_LIST, getChargeListSaga);
}

const initialState = {
    paymentList: [],
    paymentCounts: 0,
    charge: {
        list: [],
        counts: 0
    },
    chargeDetail: null
};

const payment = handleActions(
    {
        [RESET_PAYMENT_INFO]: state => ({
            ...state,
            paymentList: [],
            paymentCounts: 0
        }),
        [SELECT_CHARGE_INFO]: (state, { payload }) => ({
            ...state,
            chargeDetail: payload
        }),
        [GET_PAYMENT_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            paymentList: payload.payment,
            paymentCounts: payload.count
        }),
        [GET_CHARGE_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            charge: {
                list: payload.payments,
                counts: payload.total
            }
        })
    },
    initialState
);

export default payment;

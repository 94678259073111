import React from "react";
import moment from "moment";
import { Color } from "theme";
import { convertReceiptType, numberWithCommas } from "lib/common";
import { convertPointsType } from "lib/utils/wallet";

import "./PaymentList.scss";

const PaymentList = ({ list, counts, totalPoints }) => (
    <div id="payment-list">
        <div className="total-points">
            <p>보유 포인트</p> <span>{numberWithCommas(totalPoints)}P</span>
        </div>
        <div className="history-list">
            <div className="history-items">
                <table>
                    <thead>
                        <tr>
                            <th className="project-date">날짜</th>
                            <th className="project-id">번호</th>
                            <th className="project-title">유형</th>
                            <th className="project-title">내용</th>
                            <th className="project-category">결제 방식</th>
                            <th className="project-status">결제 상태</th>
                            <th className="project-amount">
                                결제 금액
                                <span>(VAT포함)</span>
                            </th>
                            <th className="project-point">포인트</th>
                            <th className="project-receipt">지출증빙</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list &&
                            !!counts &&
                            list.map((val, index) => (
                                <tr key={index}>
                                    <th className="project-date-id mobile">
                                        <span>{moment(val.createdAt).format("YYYY-MM-DD")}</span>
                                        <span>{val.number || "-"}</span>
                                    </th>
                                    <th className="project-date web">
                                        <div>{moment(val.createdAt).format("YYYY-MM-DD")}</div>
                                    </th>
                                    <th className="project-id web">
                                        <div>{val.number || "-"}</div>
                                    </th>
                                    <th className="project-type">
                                        <div>{convertPointsType(val.type)}</div>
                                    </th>
                                    <th className="project-title">
                                        <div>
                                            {val.title || "-"}
                                            {val.isFeeFree && (
                                                <p className="event-text">*수수료 무료 이벤트</p>
                                            )}
                                        </div>
                                    </th>
                                    <th className="project-category">
                                        <p>결제 방식</p>
                                        <div>{val.category}</div>
                                    </th>
                                    <th>
                                        <p>결제 상태</p>
                                        <div>{val.status}</div>
                                    </th>
                                    <th className="project-amount">
                                        <p>결제 금액</p>
                                        <div>
                                            {val.amountReal
                                                ? `${numberWithCommas(val.amountReal)}원`
                                                : "-"}
                                        </div>
                                    </th>
                                    <th
                                        className="project-point"
                                        style={{
                                            color: val.usedPoints
                                                ? Color.red
                                                : val.points
                                                ? Color.mainColor
                                                : Color.gray5
                                        }}
                                    >
                                        <p>포인트</p>
                                        <div>
                                            {val.usedPoints
                                                ? `-${numberWithCommas(val.points)}P`
                                                : val.points
                                                ? `+${numberWithCommas(val.points)}P`
                                                : `0P`}
                                        </div>
                                    </th>
                                    <th className="project-receipt">
                                        <p>지출증빙</p>
                                        <div>{convertReceiptType(val.receiptType) || "-"}</div>
                                    </th>
                                </tr>
                            ))}
                        {(!list || !counts) && (
                            <tr>
                                <td colSpan="8">
                                    <div className="non-content">
                                        {counts === 0 && <p>조회/검색 결과가 없습니다.</p>}
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);

export default PaymentList;

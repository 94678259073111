import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import { createRequestActionTypes, createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as scoreRecordAPI from "lib/api/scoreRecord";

const [
    GET_REVIEWER_SCORE_RECORD_LIST,
    GET_REVIEWER_SCORE_RECORD_LIST_SUCCESS,
    // eslint-disable-next-line
    GET_REVIEWER_SCORE_RECORD_LIST_FAILURE
] = createRequestActionTypes("/scoreRecord/GET_REVIEWER_SCORE_RECORD_LIST");

export const getReviewerScoreRecordList = createAction(
    GET_REVIEWER_SCORE_RECORD_LIST,
    ({ reviewerId, projectId, type, skip }) => ({ reviewerId, projectId, type, skip })
);

const getReviewerScoreRecordListSaga = createRequestSagaWithAlert(
    GET_REVIEWER_SCORE_RECORD_LIST,
    scoreRecordAPI.getReviewerScoreRecordList,
    "활동 점수 목록을 가져오는 데 실패하였습니다.",
    true
);

export function* scoreRecordSaga() {
    yield takeLatest(GET_REVIEWER_SCORE_RECORD_LIST, getReviewerScoreRecordListSaga);
}

const initialState = {
    reviewerScoreRecordList: {
        list: [],
        counts: 0,
        grade: null,
        score: null
    }
};

const scoreRecord = handleActions(
    {
        [GET_REVIEWER_SCORE_RECORD_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            reviewerScoreRecordList: {
                list: payload.list,
                counts: payload.totalCounts,
                grade: payload.grade,
                score: payload.totalScore
            }
        })
    },
    initialState
);

export default scoreRecord;

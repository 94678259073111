import React, { useState } from "react";
import SubsStatisticsGraphFilter from "components/main/SubsStatisticsGraphFilter";
import SubsStatisticsGraphView from "components/main/SubsStatisticsGraphView";

const SubsStatisticsGraph = () => {
    const [graphDataTarget, changeGraphDataTarget] = useState("totalNumOfSubs");

    return (
        <>
            <SubsStatisticsGraphFilter
                graphDataTarget={graphDataTarget}
                changeGraphDataTarget={changeGraphDataTarget}
            />
            <SubsStatisticsGraphView graphDataTarget={graphDataTarget} />
        </>
    );
};

export default SubsStatisticsGraph;

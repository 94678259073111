import React from "react";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import { convertCouponTarget } from "lib/utils/coupon";
import { TARGET_LIST } from "scheme/promotion";

import "./EnterPromotionInfo.scss";

const EnterPromotionInfo = ({ createForm }) => (
    <div id="enter-promotion-info">
        <div className="field">
            <p className="field-title">적용 플랜</p>
            <div className="field-content">
                {TARGET_LIST.map(target => (
                    <label key={target}>
                        <input type="checkbox" name="targetPlan" value={target} ref={createForm} />
                        {convertCouponTarget(target)}
                    </label>
                ))}
            </div>
        </div>
        <div className="field">
            <p className="field-title">사용 기간</p>
            <div className="field-content">
                <TextField
                    type="date"
                    defaultValue={moment().format("YYYY-MM-DD")}
                    inputProps={{
                        name: "startedAt",
                        ref: createForm
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
                <span className="with-space">~</span>
                <TextField
                    type="date"
                    defaultValue={moment().format("YYYY-MM-DD")}
                    inputProps={{
                        name: "endAt",
                        ref: createForm
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </div>
        </div>
        <div className="field">
            <p className="field-title">간단 설명</p>
            <div className="field-content">
                <input ref={createForm} type="text" name="name" />
            </div>
        </div>
        <div className="field-items">
            <div className="field">
                <p className="field-title">할인율</p>
                <div className="field-content">
                    <input ref={createForm} type="number" name="discountRate" placeholder="0" />
                </div>
            </div>
        </div>
    </div>
);

export default EnterPromotionInfo;

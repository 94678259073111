import React from "react";
import cx from "classnames";
import { bizSubsMainStatistics } from "scheme/Scheme";

import "./SubsStatisticsGraphFilter.scss";

const SubsStatisticsGraphFilter = ({ graphDataTarget, changeGraphDataTarget }) => (
    <div className="subs-statistics-graph-filter">
        <ul className="statistics-filter-list">
            {Object.keys(bizSubsMainStatistics).map(statisticsItem => (
                <li>
                    <button
                        type="button"
                        className={cx("rs-button size-m", {
                            theme8: graphDataTarget !== statisticsItem,
                            theme1: graphDataTarget === statisticsItem
                        })}
                        onClick={() => changeGraphDataTarget(statisticsItem)}
                    >
                        {bizSubsMainStatistics[statisticsItem].label}
                    </button>
                </li>
            ))}
        </ul>
        <button type="button" className="month-filter rs-button theme3 size-m" disabled>
            월별
        </button>
    </div>
);

export default SubsStatisticsGraphFilter;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SuggestionModal from "components/suggestionList/SuggestionModal";
import {
    getSuggestionInfo,
    selectSuggestionId,
    cancelSuggestion,
    unsubmitSuggestion
} from "stores/modules/suggestion";

const SuggestionDetailContainer = () => {
    const dispatch = useDispatch();
    const { selectedSuggestionId, suggestionInfo } = useSelector(({ suggestion }) => suggestion);

    useEffect(() => {
        if (selectedSuggestionId) {
            dispatch(getSuggestionInfo(selectedSuggestionId));
        }
    }, [dispatch, selectedSuggestionId]);

    const onCloseModal = () => {
        dispatch(selectSuggestionId(null));
    };

    const onCancel = suggestionId => {
        if (!suggestionId) return;
        dispatch(cancelSuggestion(suggestionId));
    };

    const onUnsubmit = suggestionId => {
        if (!suggestionId) return;
        dispatch(unsubmitSuggestion(suggestionId));
    };

    return (
        <div>
            {selectedSuggestionId && suggestionInfo && !!Object.keys(suggestionInfo).length && (
                <SuggestionModal
                    data={suggestionInfo}
                    cancel={onCancel}
                    unsubmit={onUnsubmit}
                    closeModal={onCloseModal}
                />
            )}
        </div>
    );
};

export default SuggestionDetailContainer;

import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import cx from "classnames";
import { IconFont } from "reviewshare-common-component";
import { TableContainer, Table, TableHead, TableRow, TableBody, Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { selectPointGift } from "stores/modules/pointGift";
import { returnSubtractFee } from "lib/utils/price";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { numberWithCommas } from "lib/common";
import CustomTableCell from "components/common/CustomTableCell";

import "./PointGiftList.scss";

const tableHeaderList = [
    { label: "선물 ID", align: "center" },
    { label: "비즈니스", align: "center" },
    { label: "브랜드", align: "center" },
    { label: "리뷰어", align: "center" },
    { label: "선물 포인트", align: "center" },
    { label: "리뷰어가 받는 포인트", align: "center" },
    { label: "수수료", align: "center" },
    { label: "선물 날짜", align: "center" }
];

const limit = 30;
const PointGiftList = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { query } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;

    const list = useSelector(({ pointGift }) => pointGift.list);
    const counts = useSelector(({ pointGift }) => pointGift.counts);

    const onChangePagination = (e, num) => {
        const newQuery = createSearchQuery({ ...query, skip: (num - 1) * limit });
        history.replace({ path: location.pathname, search: newQuery });
    };

    const onSelectPointGift = info => {
        dispatch(selectPointGift(info));
    };

    return (
        <div id="point-gift-list">
            <div className="button-box">
                <p>전체: {numberWithCommas(counts)}건</p>
            </div>
            <TableContainer>
                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {tableHeaderList.map((headerItem, index) => (
                                <CustomTableCell
                                    align={headerItem.align}
                                    key={index}
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    {headerItem.label}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.length > 0 &&
                            list.map((item, index) => (
                                <TableRow
                                    key={index}
                                    hover
                                    className={cx("suggestion-list-row", {
                                        "deleted-item": item.isDeleted
                                    })}
                                >
                                    <CustomTableCell
                                        className="open-detail"
                                        align="center"
                                        onClick={() => onSelectPointGift(item)}
                                    >
                                        <IconFont icon="icon_project" />
                                        {item._id}
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {item.owner?.name}({item.owner?._id})
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {item.brand?.name}({item.brand?._id})
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {item.targetList
                                            ?.map(target => `${target?.nickname}(${target?._id})`)
                                            .join(" / ")}
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {numberWithCommas(item.pointsPerTarget)}P
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {numberWithCommas(
                                            returnSubtractFee({
                                                value: item.pointsPerTarget,
                                                rate: item.rateOfFee
                                            })
                                        )}
                                        P
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {item.rateOfFee}%
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {moment(item.createdAt).format("YYYY-MM-DD")}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center" style={{ margin: "20px 0" }}>
                <Pagination
                    count={Math.ceil(counts / limit)}
                    color="primary"
                    onChange={onChangePagination}
                />
            </Box>
        </div>
    );
};

export default PointGiftList;

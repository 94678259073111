import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { Editor } from "reviewshare-common-component";
import { useForm } from "react-hook-form";

import { callDirectApi } from "lib/common";
import AlertModal from "components/common/AlertModal";
import { updateTemplate as updateTemplateAPI } from "lib/api/template";

let templateDefaultValue = {
    title: ""
};

const TemplateDetailModalContainer = ({ modalStatus, onClose, templateId, templateObj }) => {
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: templateDefaultValue,
        shouldUnregister: false
    });

    const [alertModal, handleAlertModal] = useState(false);
    const [templateContent, setTemplateContent] = useState("");

    const updateTeplate = async data => {
        try {
            await callDirectApi(updateTemplateAPI, {
                templateId,
                templateContent,
                templateTitle: data.title
            });

            handleAlertModal(true);
        } catch (error) {
            console.log("Write template error.");
            console.log(error);
            window.alert("수정에 실패하였습니다." + error);
        }
    };

    useEffect(() => {
        if (templateObj) {
            setTemplateContent(templateObj.contents);
            templateDefaultValue.title = templateObj.title;
            setValue("title", templateObj.title);
        }
    }, [templateObj, setValue]);

    return (
        <Modal open={modalStatus} onClose={onClose}>
            <div>
                {alertModal && (
                    <AlertModal
                        handleModal={() => {
                            handleAlertModal(false);
                            onClose();
                        }}
                    >
                        <p>수정이 완료되었습니다.</p>
                    </AlertModal>
                )}
                <div className="common-modal-style">
                    <div className="modal-content">
                        <form>
                            <h2>템플릿명</h2>
                            <input
                                type="text"
                                ref={register}
                                name="title"
                                className="size-l"
                                placeholder="템플릿명을 입력해주세요."
                                style={{ marginBottom: "20px" }}
                            />
                            <div className="editor-area">
                                <Editor
                                    value={templateContent}
                                    onChange={contentValue => setTemplateContent(contentValue)}
                                />
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <Button
                            variant="contained"
                            color="primary"
                            className="submit-btn"
                            onClick={handleSubmit(updateTeplate)}
                        >
                            수정
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default TemplateDetailModalContainer;

export const convertUserFieldCategory = val => {
    switch (val) {
        case "cosmetic":
            return "코스메틱";
        case "fashion":
            return "패션";
        case "food":
            return "식품/건강";
        case "supplies":
            return "생활용품";
        case "pet":
            return "반려동물";
        case "baby":
            return "출산/육아";
        case "interior":
            return "인테리어/리빙";
        case "stationery":
            return "문구/케이스";
        case "digital":
            return "디지털/가전";
        case "outdoor":
            return "아웃도어";
        case "restaurant":
            return "맛집/카페";
        case "sports":
            return "휘트니스/운동";
        case "beauty":
            return "헤어/미용/관리";
        case "photograph":
            return "스튜디오/촬영";
        case "travel":
            return "여행/숙박";
        case "making":
            return "디자인/제작";
        case "etc":
            return "기타";
        default:
            return val;
    }
};

export const convertUserKeywordCategory = val => {
    switch (val) {
        case "feature":
            return "특징";
        case "ability":
            return "능력";
        case "character":
            return "성격/성향";
        case "contentsFeature":
            return "콘텐츠 특징";
        case "mood":
            return "무드/연출";
        case "detail":
            return "디테일";
        default:
            return val;
    }
};

export const convertTalentCategory = val => {
    switch (val) {
        case "cosmetic":
            return "코스메틱";
        case "fashion":
            return "패션";
        case "food":
            return "식품/건강";
        case "supplies":
            return "생활용품";
        case "pet":
            return "반려동물";
        case "baby":
            return "출산/육아";
        case "interior":
            return "인테리어/리빙";
        case "stationery":
            return "문구/케이스";
        case "digital":
            return "디지털/가전";
        case "outdoor":
            return "아웃도어";
        case "restaurant":
            return "맛집/카페";
        case "sports":
            return "휘트니스/운동";
        case "beauty":
            return "헤어/미용/관리";
        case "photograph":
            return "스튜디오/촬영";
        case "travel":
            return "여행/숙박";
        case "making":
            return "디자인/제작";
        case "etc":
            return "기타";
        default:
            return val;
    }
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubsMainStatisticsItem from "components/main/SubsMainStatisticsItem";
import { getStatistics } from "stores/modules/subscription";

import "./SubsMainStatistics.scss";

const SubsMainStatistics = () => {
    const dispatch = useDispatch();

    const mainStatistics = useSelector(({ subs }) => subs.statistics.main);

    useEffect(() => {
        dispatch(getStatistics());
    }, []);

    return (
        <ul className="subs-main-statistics">
            {Object.keys(mainStatistics).map(statisticsItem => (
                <SubsMainStatisticsItem
                    statisticsItem={{ name: statisticsItem, value: mainStatistics[statisticsItem] }}
                />
            ))}
        </ul>
    );
};

export default SubsMainStatistics;

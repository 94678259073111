import React from "react";
import { withRouter } from "react-router-dom";
import IconFont from "components/common/IconFont";
import cx from "classnames";

import "./SideMenu.scss";

const SideMenu = ({
    menuList,
    history,
    subListState,
    handleSubListState,
    targetSubListItem,
    location
}) => (
    <div className="side-menu">
        <ul>
            {menuList.map((list, index) => (
                <li
                    key={index}
                    className={cx("main-menu-list", { active: location.pathname === list.link })}
                >
                    <div
                        className="list-item"
                        tabIndex={0}
                        role="button"
                        onClick={() => {
                            if (list.link) {
                                history.push(list.link);
                            } else {
                                handleSubListState({
                                    ...subListState,
                                    [list.value]: !subListState[list.value]
                                });
                            }
                        }}
                    >
                        <h3>{list.title}</h3>
                        {list.more && (
                            <IconFont
                                icon={
                                    subListState[list.value] ? "icon_arrow_up" : "icon_arrow_down"
                                }
                            >
                                더보기 아이콘
                            </IconFont>
                        )}
                    </div>
                    {list.subList && subListState[list.value] && (
                        <ul className={list.value}>
                            {list.subList.map((subList, subIndex) => (
                                <li
                                    className={cx("sub-list-item", {
                                        active: targetSubListItem === subList.value
                                    })}
                                    key={`sub_${subIndex}`}
                                    onClick={() => history.push(subList.value)}
                                >
                                    {subList.label}
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            ))}
        </ul>
    </div>
);

export default withRouter(SideMenu);

import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import cx from "classnames";

import "./ManageNav.scss";

const ManageNav = ({ navList }) => {
    const { url } = useRouteMatch();

    return (
        <nav className="manage-nav">
            <ul>
                {navList.map((nav, index) => (
                    <li className={cx({ active: url === nav.url })} key={index}>
                        <Link to={nav.url}>{nav.name}</Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default ManageNav;

import React, { useState, useEffect } from "react";
import { callDirectApi } from "lib/common";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

import { createPost as createPostAPI } from "lib/api/post";
import PostEditor from "components/common/PostEditor";
import AlertModal from "components/common/AlertModal";

const FaqWriteContainer = () => {
    const history = useHistory();
    const [value, setValue] = useState("");
    const [faqTagTarget, setFaqTagTarget] = useState(null);
    const [alertModal, handleAlertModal] = useState(false);
    const { register, handleSubmit, watch } = useForm();
    const target = watch("target");

    const closeModal = () => {
        handleAlertModal(false);
        history.go(0);
    };

    const submitData = async data => {
        try {
            await callDirectApi(createPostAPI, {
                contents: value,
                title: data.title,
                type: "faq",
                target: data.target,
                faqTag: data.faqTag
            });
            handleAlertModal(true);
        } catch (error) {
            console.log("Write notice error.");
            console.log(error);
            window.alert("작성에 실패하였습니다." + error);
        }
    };

    useEffect(() => {
        if (target) {
            setFaqTagTarget(target);
        }
    }, [target]);

    return (
        <>
            {alertModal && (
                <AlertModal handleModal={closeModal}>
                    <p>작성이 완료되었습니다.</p>
                </AlertModal>
            )}
            <div style={{ backgroundColor: "white", padding: "20px" }}>
                <PostEditor
                    contentValue={value}
                    postForm={register}
                    postType="faq"
                    setValue={setValue}
                    faqTagTarget={faqTagTarget}
                />
                <div className="flex justify-center" style={{ marginTop: "20px" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        className="submit-btn"
                        onClick={handleSubmit(submitData)}
                    >
                        작성
                    </Button>
                </div>
            </div>
        </>
    );
};

export default FaqWriteContainer;

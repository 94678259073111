export const TYPE_LIST = [
    { label: "온라인 리뷰", value: "review" },
    { label: "사진,영상 콘텐츠", value: "content" },
    { label: "숏폼", value: "shortform" }
];

export const CATEGORY_LIST_BY_TYPE = {
    review: [
        { label: "배송형", value: "Delivery" },
        { label: "방문형", value: "Visit" },
        { label: "구매형", value: "Buy" },
        { label: "기자단", value: "Reporters" }
    ],
    content: [
        { label: "배송형", value: "Delivery" },
        { label: "방문형", value: "Visit" },
        { label: "구매형", value: "Buy" },
        { label: "자율형", value: "None" }
    ],
    shortform: [
        { label: "배송형", value: "Delivery" },
        { label: "방문형", value: "Visit" },
        { label: "자율형", value: "None" }
    ]
};

export const SHORTFORM_SNS_LIST = [
    { label: "틱톡", value: "Tiktok" },
    { label: "쇼츠", value: "Shorts" },
    { label: "릴스", value: "Reels" }
];

export const DEFAULT_SNS_LIST_BY_CATEGORY = {
    Delivery: [
        { label: "네이버 블로그", value: "NaverBlog" },
        { label: "인스타그램", value: "Instagram" },
        { label: "유튜브", value: "Youtube" },
        { label: "네이버 카페", value: "NaverCafe" },
        { label: "네이버 포스트", value: "NaverPost" }
    ],
    Visit: [
        { label: "네이버 블로그", value: "NaverBlog" },
        { label: "인스타그램", value: "Instagram" },
        { label: "유튜브", value: "Youtube" },
        { label: "네이버 카페", value: "NaverCafe" },
        { label: "네이버 포스트", value: "NaverPost" }
    ],
    Buy: [
        { label: "자사몰(사용X)", value: "SelfMarket" },
        { label: "쇼핑몰", value: "OnlineComm" },
        { label: "네이버 블로그", value: "NaverBlog" },
        { label: "인스타그램", value: "Instagram" },
        { label: "유튜브", value: "Youtube" },
        { label: "네이버 카페", value: "NaverCafe" },
        { label: "네이버 포스트", value: "NaverPost" }
    ],
    Reporters: [
        { label: "네이버 블로그", value: "NaverBlog" },
        { label: "인스타그램", value: "Instagram" },
        { label: "유튜브", value: "Youtube" },
        { label: "네이버 카페", value: "NaverCafe" },
        { label: "앱리뷰", value: "AppReview" }
    ],
    None: []
};

export const SNS_LIST_BY_TYPE_AND_CATEGORY = {
    review: DEFAULT_SNS_LIST_BY_CATEGORY,
    shortform: {
        Delivery: SHORTFORM_SNS_LIST,
        Visit: SHORTFORM_SNS_LIST,
        None: SHORTFORM_SNS_LIST
    }
};

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import cx from "classnames";
import { IconFont } from "reviewshare-common-component";
import { useSubsInfo, useUserDetail } from "lib/customHooks/useUser";
import { numberWithCommas } from "lib/common";
import { convertSubsStatus, convertUnSubs } from "lib/utils/subscription";
import { getBizSubsInfo } from "stores/modules/user";

import "./BizSubsInfo.scss";

const BizSubsInfo = () => {
    const dispatch = useDispatch();
    const subsInfo = useSubsInfo();
    const userId = useUserDetail("_id");

    const [moreHistory, setMoreHistory] = useState(true);

    useEffect(() => {
        dispatch(getBizSubsInfo({ bizId: userId }));
    }, []);

    const historyItem = item => (
        <div className="history-item">
            <div className="plan-info">
                <div className="field row">
                    <p className="field-title">플랜</p>
                    <div className="field-content">{item.plan}</div>
                </div>
                <div className="field row">
                    <p className="field-title">상태</p>
                    <div className="field-content">{convertSubsStatus(item.status)}</div>
                </div>
                <div className="field row">
                    <p className="field-title">이용 기간</p>
                    <div className="field-content">
                        {moment(item.startAt).format("YYYY-MM-DD")} ~{" "}
                        {moment(item.endAt).format("YYYY-MM-DD")}
                    </div>
                </div>
                {item.reasonForUnSubs && item.reasonForUnSubs.length > 0 && (
                    <div className="unsubs field">
                        <p className="field-title">해지 사유</p>
                        <div className="field-content">
                            <ul>
                                {item.reasonForUnSubs.map((unsubs, index) => (
                                    <li key={index}>{convertUnSubs(unsubs)}</li>
                                ))}
                                {item.detailReasonForUnSubs && (
                                    <li>{item.detailReasonForUnSubs}</li>
                                )}
                            </ul>
                        </div>
                    </div>
                )}
                <div className="brand field">
                    <p className="field-title">브랜드 목록</p>
                    <div className="field-content">
                        <ul>
                            {item.targetBrandIdList &&
                                item.targetBrandIdList.map((brand, index) => (
                                    <li key={index}>
                                        {brand._id} - [{brand.category}] {brand.name}
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="price-info">
                <div className="field row">
                    <p className="field-title">결제 방식</p>
                    <div className="field-content">
                        {item.paymentMethod === "yearly" ? "연간 결제" : "월간 결제"}
                    </div>
                </div>
                <div className="field row">
                    <p className="field-title">결제 금액(VAT 포함)</p>
                    <div className="field-content">
                        {numberWithCommas(item.priceInfo && item.priceInfo.price)}원
                    </div>
                </div>
                <div className="field row">
                    <p className="field-title">카드 정보</p>
                    <div className="field-content">
                        {item.paymentInfo && item.paymentInfo.cardNumber}
                    </div>
                </div>
                <div className="field row">
                    <p className="field-title">moid</p>
                    <div className="field-content">{item.paymentInfo && item.paymentInfo.moid}</div>
                </div>
                <div className="field row">
                    <p className="field-title">tid</p>
                    <div className="field-content">{item.paymentInfo && item.paymentInfo.tid}</div>
                </div>
            </div>
        </div>
    );

    return (
        <div id="biz-subs-info" className="modal-content">
            <div className="field current">
                <p className="field-title">현재 내역</p>
                <div className="field-content">
                    {["running", "readyForUnsubscribe"].indexOf(subsInfo.status) > -1
                        ? historyItem(subsInfo)
                        : "없음"}
                </div>
            </div>
            <div className={cx("field history", { active: moreHistory })}>
                <p className="field-title" onClick={() => setMoreHistory(!moreHistory)}>
                    이전 내역{" "}
                    <IconFont icon={moreHistory ? "icon_arrow_up" : "icon_arrow_down"}>
                        더보기
                    </IconFont>
                </p>
                <div className="field-content">
                    <ul>
                        {subsInfo.history
                            ? subsInfo.history.map((item, index) => (
                                  <li
                                      key={index}
                                      className={cx({
                                          isDeleted: item.isDeleted
                                      })}
                                  >
                                      {historyItem(item)}
                                  </li>
                              ))
                            : "없음"}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default BizSubsInfo;

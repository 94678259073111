import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";
import PaymentFilter from "components/paymentList/PaymentFilter";
import PaymentList from "components/paymentList/PaymentList";
import { resetWalletInfo, getMyWallet } from "stores/modules/wallet";
import { resetPaymentInfo, getPaymentList } from "stores/modules/payment";

const limit = 10;

const initialOptions = {
    userId: "",
    keyword: "",
    skip: 0,
    limit
};

const PaymentListContainer = () => {
    const dispatch = useDispatch();
    const { paymentList, paymentCounts } = useSelector(({ payment }) => payment);
    const { totalPoints } = useSelector(({ wallet }) => wallet);

    const [filter, setFilter] = useState(initialOptions);

    const onSearch = () => {
        if (!filter.userId) return;
        dispatch(getMyWallet(filter.userId));
        setFilter(prev => ({ ...prev, skip: 0 }));
        dispatch(getPaymentList({ ...filter, skip: 0 }));
    };

    const onChangePaginateion = (e, index) => {
        const _filter = { ...filter, skip: filter.limit * (index - 1) };
        setFilter(_filter);
        dispatch(getPaymentList(_filter));
    };

    useEffect(() => {
        onSearch();

        return () => {
            dispatch(resetPaymentInfo());
            dispatch(resetWalletInfo());
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div>
                <PaymentFilter filter={filter} setFilter={setFilter} search={onSearch} />
                <PaymentList list={paymentList} counts={paymentCounts} totalPoints={totalPoints} />
                <Box display="flex" justifyContent="center" style={{ margin: "20px 0" }}>
                    <Pagination
                        count={Math.ceil(paymentCounts / limit)}
                        color="primary"
                        onChange={onChangePaginateion}
                    />
                </Box>
            </div>
        </>
    );
};

export default PaymentListContainer;

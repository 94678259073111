export const convertOrderStatus = status => {
    switch (status) {
        case "request":
            return "신청";
        case "running":
            return "진행 중";
        case "submit":
            return "결과물 제출";
        case "complete":
            return "완료";
        case "cancel":
            return "어드민 취소";
        case "requestCancel":
            return "비즈니스 취소";
        case "reject":
            return "거절";
        default:
            return status;
    }
};

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { POINT_REQUEST_DENY_REASON } from "scheme/Scheme";
import { setBlockProjectRequest, setBlockPointRequest } from "stores/modules/user";

import "./LimitFunction.scss";

const blockFnList = [
    { label: "프로젝트 신청 제한", value: "blockProjectRequest" },
    {
        label: "출금 신청 제한",
        value: "blockPointRequest",
        reasonList: POINT_REQUEST_DENY_REASON
    }
];
const INIT_BLOCK_STATE = {
    blockProjectRequest: { status: false, option: false, defaultCheckedOption: true },
    blockPointRequest: { status: false, option: false, requiredReason: true }
};
const LimitFunction = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(({ user }) => user.userDetail);
    const [blockState, setBlockState] = useState(INIT_BLOCK_STATE);

    const savedBlockState = {
        blockProjectRequest: !!userInfo.blockProjectRequest,
        blockPointRequest: !!userInfo.reviewerStatus?.blockPointRequest
    };

    const onChangeBlockState = value => {
        const status = blockState[value].status ? "block" : "approve";
        const { option, requiredReason, reason } = blockState[value];
        if (status && option && requiredReason && !reason) return;

        if (value === "blockProjectRequest") {
            const confirm = window.confirm(
                status === "block"
                    ? "프로젝트 신청을 제한하시겠습니까?"
                    : "프로젝트 신청 제한을 해제하시겠습니까?"
            );
            if (confirm) {
                dispatch(
                    setBlockProjectRequest({
                        reviewerId: userInfo._id,
                        process: status === "block" ? "assign" : "left"
                    })
                );
            }
        }

        if (value === "blockPointRequest") {
            const confirm = window.confirm(
                status === "block"
                    ? "출금 신청을 제한하시겠습니까?"
                    : "출금 신청 제한을 해제하시겠습니까?"
            );
            if (confirm) {
                dispatch(
                    setBlockPointRequest({
                        reviewerId: userInfo._id,
                        blockStatus: status,
                        ...(status === "block" && {
                            denyObject: {
                                method: option ? "deny" : "keep",
                                reasonForDeny: option && reason
                            }
                        })
                    })
                );
            }
        }
    };

    useEffect(() => {
        setBlockState(prev => ({
            ...prev,
            blockProjectRequest: {
                ...prev.blockProjectRequest,
                status: !!userInfo.blockProjectRequest
            },
            blockPointRequest: {
                ...prev.blockPointRequest,
                status: !!userInfo.reviewerStatus?.blockPointRequest
            }
        }));
    }, [userInfo]);

    return (
        <>
            <div className="modal-content user-manage-limit-function">
                <div className="field-box">
                    {blockFnList.map((item, index) => (
                        <div className="field" key={index}>
                            <div className="limit-status">
                                <p className="field-title">{item.label}</p>
                                <Switch
                                    checked={blockState[item.value].status}
                                    onClick={() => {
                                        if (!item.disabled) {
                                            setBlockState(prev => ({
                                                ...prev,
                                                [item.value]: {
                                                    ...prev[item.value],
                                                    status: !prev[item.value].status,
                                                    option: false
                                                }
                                            }));
                                        }
                                    }}
                                />
                            </div>
                            <ul
                                className={cx("options", {
                                    disabled:
                                        savedBlockState[item.value] ||
                                        !blockState[item.value].status
                                })}
                            >
                                <li>
                                    <p>신청 리스트 모두 취소</p>
                                    {blockState[item.value].option && item.reasonList && (
                                        <div className="select-box">
                                            <Select
                                                value={blockState[item.value].reason}
                                                onChange={e => {
                                                    setBlockState(prev => ({
                                                        ...prev,
                                                        [item.value]: {
                                                            ...prev[item.value],
                                                            reason: e.target.value
                                                        }
                                                    }));
                                                }}
                                            >
                                                {item.reasonList.map((reason, reasonIndex) => (
                                                    <MenuItem
                                                        value={reason.value}
                                                        key={reasonIndex}
                                                    >
                                                        {reason.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    )}
                                    {!blockState[item.value].defaultCheckedOption ? (
                                        <Checkbox
                                            checked={blockState[item.value].option}
                                            disabled={
                                                savedBlockState[item.value] ||
                                                !blockState[item.value].status
                                            }
                                            onClick={() =>
                                                setBlockState(prev => ({
                                                    ...prev,
                                                    [item.value]: {
                                                        ...prev[item.value],
                                                        option: !prev[item.value].option,
                                                        reason: item.reasonList?.[0].value
                                                    }
                                                }))
                                            }
                                        />
                                    ) : (
                                        "(기본)"
                                    )}
                                </li>
                            </ul>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={
                                    item.disabled ||
                                    savedBlockState[item.value] === blockState[item.value].status
                                }
                                onClick={() => onChangeBlockState(item.value)}
                            >
                                적용
                            </Button>
                        </div>
                    ))}
                </div>
            </div>
            <div className="modal-footer user-manage-edit-actions">
                <div className="btn-area"></div>
            </div>
        </>
    );
};

export default LimitFunction;

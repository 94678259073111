import React from "react";
import { Switch, Route } from "react-router-dom";
import ManageNav from "components/common/ManageNav";
import TemplateListContainer from "containers/template/TemplateListContainer";
import TemplateWriteContainer from "containers/template/TemplateWriteContainer";

const TemplatePage = () => (
    <>
        <ManageNav
            navList={[
                { url: "/template/list", name: "템플릿" },
                { url: "/template/write", name: "템플릿 작성" }
            ]}
        />
        <Switch>
            <Route exact path="/template/list">
                <TemplateListContainer />
            </Route>
            <Route exact path="/template/write">
                <TemplateWriteContainer />
            </Route>
        </Switch>
    </>
);

export default TemplatePage;

import React from "react";
import SuggestionListContainer from "containers/suggestionList/SuggestionListContainer";
import SuggestionContainer from "containers/suggestionList/SuggestionContainer";

const SuggestionListPage = () => (
    <>
        <SuggestionListContainer />
        <SuggestionContainer />
    </>
);

export default SuggestionListPage;

import { convertCouponStatus, convertCouponTarget } from "lib/utils/coupon";
import { numberWithCommas, countArrayLength, convertDate } from "lib/common";

export const STATUS_LIST = ["ready", "used", "expired", "retrieve"];
export const TARGET_LIST = ["all", "project", "order", "suggestion"];
export const USER_FILTER_INPUT_ITEMS = [
    {
        label: "상태",
        value: "status",
        inputType: "select",
        array: STATUS_LIST,
        convertFn: convertCouponStatus
    },
    { label: "쿠폰 ID", value: "couponId", inputType: "text" },
    { label: "쿠폰명", value: "couponName", inputType: "text" },
    { label: "쿠폰 코드", value: "couponCode", inputType: "text" },
    {
        label: "사용 가능 서비스",
        value: "targetService",
        inputType: "select",
        array: TARGET_LIST,
        convertFn: convertCouponTarget
    },
    { label: "발급일", value: "startedAt", inputType: "date" },
    { label: "만료일", value: "endAt", inputType: "date" },
    { label: "비즈니스 ID", value: "bizId", inputType: "text" },
    { label: "비즈니스명", value: "bizName", inputType: "text" },
    { label: "휴대폰 번호", value: "bizPhoneNumber", inputType: "text" }
];

export const CODE_FILTER_INPUT_ITEMS = [
    { label: "쿠폰 ID", value: "id", inputType: "text" },
    { label: "쿠폰명", value: "name", inputType: "text" },
    { label: "쿠폰 코드", value: "code", inputType: "text" },
    {
        label: "사용 가능 서비스",
        value: "targetService",
        inputType: "select",
        array: TARGET_LIST,
        convertFn: convertCouponTarget
    },
    { label: "발급일", value: "startedAt", inputType: "date" },
    { label: "만료일", value: "endAt", inputType: "date" },
    { label: "비즈니스 ID", value: "bizId", inputType: "text" }
    // { label: "비즈니스명", value: "bizName", inputType: "text" }
];

export const USER_TABLE_ITEMS = [
    {
        label: "쿠폰 ID",
        align: "center",
        value: "id",
        key: "coupon._id"
    },
    {
        label: "상태",
        align: "left",
        value: "status",
        key: "status",
        convertFn: convertCouponStatus,
        openDetail: true
    },
    {
        label: "쿠폰명",
        align: "left",
        value: "name",
        key: "coupon.name",
        openDetail: true
    },
    { label: "쿠폰 코드", align: "left", value: "code", key: "coupon.code" },
    {
        label: "사용 가능 서비스",
        align: "center",
        value: "targetService",
        key: "coupon.targetService",
        convertFn: convertCouponTarget
    },
    {
        label: "사용한 서비스",
        align: "left",
        value: "serviceCollection",
        key: "serviceCollection",
        convertFn: convertCouponTarget
    },
    { label: "사용 기간", align: "center", value: "period" },
    {
        label: "사용 날짜",
        align: "center",
        value: "usedAt",
        key: "statusDate.used",
        convertFn: convertDate
    },
    { label: "비즈니스 ID", align: "center", value: "bizId", key: "owner._id" },
    { label: "비즈니스명", align: "center", value: "bizName", key: "owner.name" },
    { label: "휴대폰번호", align: "center", value: "bizPhoneNumber", key: "owner.phoneNumber" }
];

export const CODE_TABLE_ITEMS = [
    {
        label: "쿠폰 ID",
        align: "center",
        value: "id",
        key: "_id"
    },
    {
        label: "쿠폰명",
        align: "left",
        value: "name",
        key: "name",
        openDetail: true
    },
    { label: "쿠폰 코드", align: "left", value: "code", key: "code" },
    {
        label: "사용 가능 서비스",
        align: "center",
        value: "targetService",
        key: "targetService",
        convertFn: convertCouponTarget
    },
    { label: "할인율", align: "center", value: "discountRate", key: "discountRate", unit: "%" },
    {
        label: "최소 결제 금액",
        align: "center",
        value: "minSpendPrice",
        key: "minSpendPrice",
        convertFn: numberWithCommas
    },
    {
        label: "최대 할인 금액",
        align: "center",
        value: "maxDiscountPrice",
        key: "maxDiscountPrice",
        convertFn: numberWithCommas
    },
    { label: "사용 기간", align: "center", value: "period" },
    {
        label: "지정된 비즈니스",
        align: "center",
        value: "bizCount",
        key: "targetUserIdList",
        unit: "명",
        convertFn: countArrayLength
    }
];

import React, { useEffect, useMemo } from "react";
import { callDirectApi } from "lib/common";
import {
    getAdminTagList as getAdminTagListAPI,
    getAllTagList as getAllTagListAPI,
    addTagToUser as addTagToUserAPI
} from "lib/api/tag";
import useDirectApi from "lib/customHooks/useDirectApi";
import TagList from "components/tag/TagList";

import "./UserTagCtrl.scss";

const UserTagCtrl = ({ userId }) => {
    const {
        data: userTagData,
        fetch: getUserTagList,
        isFetching: isSearchingUserTagList
    } = useDirectApi({
        api: getAdminTagListAPI
    });
    const {
        data: allTagData,
        fetch: getAllTagList,
        isFetching: isSearchingAllTagList
    } = useDirectApi({
        api: getAllTagListAPI
    });

    const userTagList = useMemo(() => userTagData?.list || [], [userTagData]);
    const allTagList = useMemo(() => allTagData?.list || [], [allTagData]);

    const onHandleTag = async ({ isSet, tagIdList }) => {
        if (!tagIdList?.length) return;
        try {
            await callDirectApi(addTagToUserAPI, { userId, isSet, tagIdList }, true);
            getUserTagList({ userId });
        } catch (e) {}
    };

    useEffect(() => {
        if (userId) {
            getUserTagList({ userId });
            getAllTagList();
        }
    }, [userId]);

    return (
        <div className="user-tag-ctrl">
            <TagList
                type="user"
                tagList={userTagList}
                isSearching={isSearchingUserTagList}
                handleTag={obj => onHandleTag({ ...obj, isSet: false })}
            />
            <TagList
                type="all"
                tagList={allTagList}
                isSearching={isSearchingAllTagList}
                handleTag={obj => onHandleTag({ ...obj, isSet: true })}
                excludeIdList={userTagList.map(item => item._id)}
            />
        </div>
    );
};

export default UserTagCtrl;

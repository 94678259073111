import React from "react";
import cx from "classnames";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import CustomTableCell from "components/common/CustomTableCell";
import { convertScoreType } from "lib/common";

const tableHeaderList = [
    { label: "선택", align: "center" },
    { label: "사유", align: "center" },
    { label: "점수", align: "center" },
    { label: "프로젝트 번호", align: "center" },
    { label: "프로젝트명", align: "center" },
    { label: "상태", align: "center" },
    { label: "날짜", align: "center" }
];

const ReviewerScoreRecordList = ({
    scoreRecordList,
    onCheckedItem,
    selectedIdArray,
    isPossibleCancel,
    onCancelScoreRecord
}) => (
    <>
        <div
            className="div cancel-btn-area"
            style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}
        >
            <Button
                variant="contained"
                color="secondary"
                disabled={!isPossibleCancel}
                onClick={onCancelScoreRecord}
            >
                삭제
            </Button>
        </div>
        <TableContainer>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {tableHeaderList.map((headerItem, index) => (
                            <CustomTableCell
                                align={headerItem.align}
                                key={index}
                                style={{ whiteSpace: "nowrap" }}
                            >
                                {headerItem.label}
                            </CustomTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {scoreRecordList &&
                        scoreRecordList.length > 0 &&
                        scoreRecordList.map((scoreRecord, index) => (
                            <TableRow
                                key={index}
                                hover
                                classes={{
                                    root: cx({
                                        "deleted-item": scoreRecord.isDeleted,
                                        isPast: scoreRecord.isPastScore
                                    })
                                }}
                            >
                                <CustomTableCell align="center">
                                    <Checkbox
                                        color="primary"
                                        disabled={
                                            scoreRecord.type === "login" ||
                                            scoreRecord.type === "register" ||
                                            scoreRecord.isDeleted
                                        }
                                        checked={selectedIdArray.indexOf(scoreRecord._id) >= 0}
                                        onChange={() => {
                                            onCheckedItem(scoreRecord._id);
                                        }}
                                    />
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                    {scoreRecord.type === "etc"
                                        ? scoreRecord.reasonByAdmin
                                        : convertScoreType(scoreRecord.type)}
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                    {scoreRecord.value}
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                    {scoreRecord.campaignId ? (
                                        <a
                                            href={`${process.env.REACT_APP_MAIN_SERVICE_URL}/project/${scoreRecord.campaignId}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {scoreRecord.campaignId}
                                        </a>
                                    ) : (
                                        "-"
                                    )}
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                    {scoreRecord.campaignTitle ? (
                                        <a
                                            href={`${process.env.REACT_APP_MAIN_SERVICE_URL}/project/${scoreRecord.campaignId}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {scoreRecord.campaignTitle}
                                        </a>
                                    ) : (
                                        "-"
                                    )}
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                    {scoreRecord.isDeleted ? "삭제" : "-"}
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                    {moment(scoreRecord.createdAt).format("YYYY-MM-DD HH:mm")}
                                </CustomTableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            {!scoreRecordList || (scoreRecordList.length === 0 && <p>활동 내역이 없습니다.</p>)}
        </TableContainer>
    </>
);

export default ReviewerScoreRecordList;

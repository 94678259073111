import React from "react";
import { IconFont } from "reviewshare-common-component";
import isEqual from "lodash/isEqual";
import { Grid, Button, TextField, IconButton, Checkbox } from "@material-ui/core";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import moment from "moment";
import cx from "classnames";
import { openNewTab } from "lib/common";
import { ADDITION_LIST_SCHEME } from "scheme/Scheme";
import {
    TYPE_LIST,
    CATEGORY_LIST_BY_TYPE,
    SNS_LIST_BY_TYPE_AND_CATEGORY
} from "schema/ProjectSchema";

import "./DetailInfo.scss";

const commaText = "&#44;";
const DetailInfo = ({
    isRereviewing,
    projectDetail,
    otherDetail,
    projectImageList,
    removeProjectImage,
    uploadImage,
    changeMainImage,
    projectForm,
    formValues,
    addOption,
    removeOption,
    guidelineEditor,
    searchAddress,
    newProjectAddress,
    projectAddress
}) => {
    const category = formValues?.category || projectDetail.category;
    const sns = formValues?.sns || projectDetail.sns;
    const type = formValues?.type || projectDetail.type;
    const itemOptions = formValues?.itemOptions || projectDetail.itemOptions;

    return (
        <div
            className={cx("project-modal-detail-info", {
                "deleted-item": projectDetail.isDeleted
            })}
        >
            <div className="field row">
                <h3 className="field-title">리뷰쉐어 픽</h3>
                <div className="field-content">
                    <Checkbox color="primary" checked={projectDetail.isPick} disabled />
                </div>
            </div>
            <Grid container spacing={2}>
                <Grid item>
                    <h3 className="info-title">프로젝트 일정</h3>
                    <Grid container spacing={2} className="date-field-area">
                        <Grid item xs={6}>
                            <h4>리뷰어 모집기간</h4>
                            <div className="date-picker">
                                <TextField
                                    id="date"
                                    type="date"
                                    defaultValue={moment(projectDetail.requestStartAt).format(
                                        "YYYY-MM-DD"
                                    )}
                                    inputProps={{
                                        name: "requestStartAt",
                                        ref: projectForm
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <p>~</p>
                                <TextField
                                    id="date"
                                    type="date"
                                    defaultValue={moment(projectDetail.requestEndAt).format(
                                        "YYYY-MM-DD"
                                    )}
                                    inputProps={{
                                        name: "requestEndAt",
                                        ref: projectForm
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <h4>결과물 제출기간</h4>
                            <div className="date-picker">
                                <TextField
                                    id="date"
                                    type="date"
                                    defaultValue={moment(projectDetail.submitStartAt).format(
                                        "YYYY-MM-DD"
                                    )}
                                    inputProps={{
                                        name: "submitStartAt",
                                        ref: projectForm
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <p>~</p>
                                <TextField
                                    id="date"
                                    type="date"
                                    defaultValue={moment(projectDetail.submitEndAt).format(
                                        "YYYY-MM-DD"
                                    )}
                                    inputProps={{
                                        name: "submitEndAt",
                                        ref: projectForm
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    className={cx("project-title", {
                        changed: projectDetail.title !== otherDetail.title
                    })}
                >
                    <h3 className="info-title">프로젝트명</h3>
                    <input
                        type="text"
                        name="title"
                        defaultValue={projectDetail.title}
                        ref={projectForm}
                    />
                </Grid>

                {category !== "reporters" && (
                    <>
                        <Grid
                            item
                            xs={4}
                            className={cx("project-item", {
                                changed: projectDetail.itemName !== otherDetail.itemName
                            })}
                        >
                            <h3 className="info-title">제품명</h3>
                            <input
                                type="text"
                                name="itemName"
                                defaultValue={projectDetail.itemName}
                                ref={projectForm}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            className={cx("project-item", {
                                changed: projectDetail.itemPrice !== otherDetail.itemPrice
                            })}
                        >
                            <h3 className="info-title">제품 가격</h3>
                            <input
                                type="text"
                                name="itemPrice"
                                defaultValue={projectDetail.itemPrice}
                                ref={projectForm}
                            />
                        </Grid>
                    </>
                )}
                <Grid
                    item
                    xs={category !== "reporters" ? 4 : 12}
                    className={cx("project-points project-item", {
                        changed: projectDetail.points !== otherDetail.points
                    })}
                >
                    <h3 className="info-title">리워드</h3>
                    <input
                        type="text"
                        name="points"
                        defaultValue={projectDetail.points}
                        ref={projectForm}
                    />
                    {projectDetail.addition && projectDetail.addition.secondaryUse.status && (
                        <p className="red-font" style={{ marginTop: "4px" }}>
                            + 마케팅 활용 포인트 (10,000P)
                        </p>
                    )}
                </Grid>

                <Grid
                    item
                    xs={12}
                    className={cx("project-type project-item", {
                        changed: projectDetail.type !== otherDetail.type
                    })}
                >
                    <h3 className="info-title">유형</h3>
                    <ul>
                        {TYPE_LIST.map((item, index) => (
                            <li key={index}>
                                <label>
                                    <input
                                        type="radio"
                                        name={isRereviewing ? "re-type" : "type"}
                                        id={`project-type-${item.value}`}
                                        value={item.value}
                                        defaultChecked={projectDetail.type === item.value}
                                        ref={projectForm}
                                    />{" "}
                                    {item.label}
                                </label>
                            </li>
                        ))}
                    </ul>
                </Grid>

                <Grid
                    item
                    xs={12}
                    className={cx("project-category project-item", {
                        changed: projectDetail.category !== otherDetail.category
                    })}
                >
                    <h3 className="info-title">진행 방식</h3>
                    <ul>
                        {CATEGORY_LIST_BY_TYPE[type].map((item, index) => (
                            <li key={index}>
                                <label>
                                    <input
                                        type="radio"
                                        name={isRereviewing ? "re-category" : "category"}
                                        id={`project-category-${item.value}`}
                                        value={item.value}
                                        defaultChecked={projectDetail.category === item.value}
                                        ref={projectForm}
                                    />{" "}
                                    {item.label}
                                </label>
                            </li>
                        ))}
                    </ul>
                </Grid>

                <Grid
                    item
                    xs={12}
                    className={cx("project-sns project-item", {
                        changed: projectDetail.sns !== otherDetail.sns
                    })}
                >
                    <h3 className="info-title">SNS</h3>
                    <ul>
                        {SNS_LIST_BY_TYPE_AND_CATEGORY?.[type]?.[category]?.map((item, index) => (
                            <li key={index}>
                                <label>
                                    <input
                                        type="radio"
                                        name={isRereviewing ? "re-sns" : "sns"}
                                        id={`project-sns-${item.value}`}
                                        value={item.value}
                                        defaultChecked={
                                            projectDetail.sns === item.value ||
                                            projectDetail.otherSns === item.value
                                        }
                                        disabled={type === "content"}
                                        ref={projectForm}
                                    />{" "}
                                    {item.label}
                                </label>
                            </li>
                        )) || "없음"}
                    </ul>
                </Grid>

                <Grid item xs={12} className="project-sns">
                    <h3 className="info-title">부가서비스</h3>
                    <ul>
                        {ADDITION_LIST_SCHEME.map((item, index) => (
                            <li key={index} className="flex align-center">
                                <input
                                    type="checkbox"
                                    disabled={
                                        !(
                                            projectDetail.addition &&
                                            projectDetail.addition[item.value].status
                                        )
                                    }
                                    checked={
                                        projectDetail.addition &&
                                        projectDetail.addition[item.value].status
                                    }
                                />
                                {item.label}
                            </li>
                        ))}
                    </ul>
                </Grid>

                <Grid
                    item
                    xs={3}
                    className={cx("project-item image-item", {
                        changed: projectDetail.photos?.[0]?._id !== otherDetail.photos?.[0]?._id
                    })}
                >
                    <h3 className="info-title">대표 이미지</h3>
                    <div className="main-image">
                        {projectImageList[0] ? (
                            <>
                                <IconButton
                                    aria-label="사진 삭제 버튼"
                                    color="secondary"
                                    className="remove-icon"
                                    onClick={() => removeProjectImage(0)}
                                >
                                    <CancelIcon />
                                </IconButton>
                                <img
                                    src={
                                        projectImageList[0].secure_url ||
                                        projectImageList[0].imagePreviewUrl
                                    }
                                    alt="프로젝트 메인 이미지"
                                    onDoubleClick={() =>
                                        openNewTab(
                                            projectImageList[0].secure_url ||
                                                projectImageList[0].imagePreviewUrl
                                        )
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <input
                                    type="file"
                                    accept="image/*"
                                    name="subImage-0"
                                    id="subImage-0"
                                    style={{ display: "none" }}
                                    onChange={e => changeMainImage(e.target.files)}
                                />
                                <label htmlFor="subImage-0">
                                    <IconButton
                                        aria-label="사진 추가 버튼"
                                        color="primary"
                                        className="add-icon"
                                        component="span"
                                    >
                                        <AddBoxRoundedIcon fontSize="large" />
                                    </IconButton>
                                </label>
                            </>
                        )}
                    </div>
                </Grid>
                {[1, 2, 3].map((value, index) => (
                    <Grid
                        item
                        xs={3}
                        key={index}
                        className={cx("project-item image-item", {
                            changed:
                                projectDetail.photos?.[value]?._id !==
                                otherDetail.photos?.[value]?._id
                        })}
                    >
                        <h3 className="info-title">상세이미지{value}</h3>
                        <div className="sub-image">
                            {projectImageList[value] ? (
                                <>
                                    {removeProjectImage && (
                                        <IconButton
                                            aria-label="사진 삭제 버튼"
                                            color="secondary"
                                            className="remove-icon"
                                            onClick={() => removeProjectImage(value)}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    )}
                                    <img
                                        onDoubleClick={() =>
                                            openNewTab(
                                                projectImageList[value].secure_url ||
                                                    projectImageList[value].imagePreviewUrl
                                            )
                                        }
                                        src={
                                            projectImageList[value].secure_url ||
                                            projectImageList[value].imagePreviewUrl
                                        }
                                        alt="프로젝트 상세 이미지"
                                    />
                                </>
                            ) : (
                                <>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        name={`subImage-${value}`}
                                        id={`subImage-${value}`}
                                        style={{ display: "none" }}
                                        onChange={e => uploadImage(value, e.target.files)}
                                    />
                                    {uploadImage && (
                                        <label htmlFor={`subImage-${value}`}>
                                            <IconButton
                                                aria-label="사진 추가 버튼"
                                                color="primary"
                                                className="add-icon"
                                                component="span"
                                            >
                                                <AddBoxRoundedIcon fontSize="large" />
                                            </IconButton>
                                        </label>
                                    )}
                                </>
                            )}
                        </div>
                    </Grid>
                ))}

                <Grid item xs={12} className="project-options">
                    <Grid container spacing={2}>
                        {[0, 1, 2].map(optionIndex => (
                            <div key={optionIndex} className="item-option">
                                <Grid
                                    item
                                    xs={3}
                                    className={cx("project-item", {
                                        changed:
                                            projectDetail.itemOptions?.[optionIndex]?.option !==
                                            otherDetail.itemOptions?.[optionIndex]?.option
                                    })}
                                >
                                    <h4>옵션명</h4>
                                    <input
                                        type="text"
                                        name={`itemOptions[${optionIndex}].option`}
                                        ref={projectForm}
                                        defaultValue={itemOptions?.[optionIndex]?.option}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={9}
                                    className={cx("project-item", {
                                        changed:
                                            projectDetail.itemOptions?.[optionIndex]?.value !==
                                            otherDetail.itemOptions?.[optionIndex]?.value
                                    })}
                                >
                                    <h4>옵션값</h4>
                                    <input
                                        type="text"
                                        name={`newItemOption[${optionIndex}]`}
                                        ref={projectForm}
                                        onKeyDown={e => {
                                            if (e.keyCode === 13) {
                                                addOption(e, optionIndex);
                                            }
                                        }}
                                        placeholder="enter"
                                    />
                                    <input
                                        hidden
                                        type="text"
                                        name={`itemOptions[${optionIndex}].value`}
                                        ref={projectForm}
                                        defaultValue={itemOptions?.[optionIndex]?.value}
                                    />
                                    <div className="option-value-list">
                                        {itemOptions?.[optionIndex]?.value
                                            ? itemOptions[optionIndex].value
                                                  .split(",")
                                                  .map((value, valueIndex) => (
                                                      <Button
                                                          key={valueIndex}
                                                          className="option-value-item"
                                                          onClick={() =>
                                                              removeOption(
                                                                  `itemOptions[${optionIndex}].value`,
                                                                  valueIndex
                                                              )
                                                          }
                                                      >
                                                          <span>
                                                              {value.replace(
                                                                  new RegExp(commaText, "g"),
                                                                  ","
                                                              )}
                                                          </span>
                                                          <IconFont icon="icon_close">
                                                              제거
                                                          </IconFont>
                                                      </Button>
                                                  ))
                                            : null}
                                    </div>
                                </Grid>
                            </div>
                        ))}
                    </Grid>
                </Grid>

                {category === "Visit" && (
                    <Grid item xs={12} className="project-address-info">
                        <h3 className="info-title">방문 정보</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <h4>주소</h4>
                            </Grid>
                            <Grid item xs={9} className="search-address-area">
                                {newProjectAddress ? (
                                    <p className="address">{newProjectAddress.address_name}</p>
                                ) : (
                                    <p className="address">{projectAddress}</p>
                                )}
                                <Button variant="contained" onClick={searchAddress}>
                                    주소 검색
                                </Button>
                            </Grid>
                            <Grid item xs={3} />
                            <Grid item xs={9}>
                                <input
                                    type="text"
                                    name="addressDetail"
                                    ref={projectForm}
                                    defaultValue={projectDetail.addressDetail}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <h4>방문 가능 시간대</h4>
                            </Grid>
                            <Grid item xs={9}>
                                <input
                                    type="text"
                                    name="visitDesc"
                                    ref={projectForm}
                                    defaultValue={projectDetail.visitDesc}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <h4>방문 시 참고사항/예약 관련 안내</h4>
                            </Grid>
                            <Grid item xs={9}>
                                <textarea
                                    name="visitInfo"
                                    ref={projectForm}
                                    defaultValue={projectDetail.visitInfo}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {category === "Buy" && (
                    <>
                        <Grid item xs={12} className="project-category-buy-site-info">
                            <h3 className="info-title">구매 및 리뷰 작성 사이트 주소</h3>
                            <input
                                type="text"
                                name="buyInfo.siteUrl"
                                ref={projectForm}
                                defaultValue={
                                    projectDetail.buyInfo && projectDetail.buyInfo.siteUrl
                                }
                            />
                        </Grid>
                        <Grid item xs={12} className="project-category-buy-site-info">
                            <h3 className="info-title">어디서 무엇을 어떻게 구매해야하는지</h3>
                            <textarea
                                type="text"
                                name="buyInfo.desc"
                                ref={projectForm}
                                defaultValue={projectDetail.buyInfo && projectDetail.buyInfo.desc}
                            />
                        </Grid>
                    </>
                )}

                <Grid
                    item
                    xs={12}
                    className={cx("proejct-check-point project-item", {
                        changed: projectDetail.writeWarning !== otherDetail.writeWarning
                    })}
                >
                    <h3 className="info-title">리뷰어 확인 사항</h3>
                    <h4>리뷰 작성 시 주의사항</h4>
                    <textarea
                        name="writeWarning"
                        id="writeWarning"
                        ref={projectForm}
                        defaultValue={projectDetail.writeWarning}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    className={cx("project-item", {
                        changed: projectDetail.guideLine !== otherDetail.guideLine
                    })}
                >
                    <h3 className="info-title">가이드라인</h3>
                    {guidelineEditor}
                </Grid>

                <Grid
                    item
                    xs={12}
                    className={cx("project-keyword project-item", {
                        changed:
                            !isEqual(projectDetail.hashTags, otherDetail.hashTags) ||
                            projectDetail.keyword !== otherDetail.keyword
                    })}
                >
                    {sns === "Instagram" || type === "shortform" ? (
                        <>
                            <h3 className="info-title">검색 해시태그</h3>
                            <textarea
                                name="hashTags"
                                ref={projectForm}
                                id="projectHashTags"
                                defaultValue={
                                    projectDetail.hashTags
                                        ? projectDetail.hashTags.join(",")
                                        : projectDetail.keyword
                                }
                            />
                        </>
                    ) : (
                        <>
                            <h3 className="info-title">검색 키워드</h3>
                            <textarea
                                name="keyword"
                                id="projectKeyword"
                                ref={projectForm}
                                defaultValue={projectDetail.keyword}
                            />
                        </>
                    )}
                </Grid>

                <Grid item xs={12} className="project-onwer-info">
                    <h3 className="info-title">담당자 정보</h3>
                    <ul>
                        <li
                            className={cx("project-item", {
                                changed: projectDetail.phoneNumber !== otherDetail.phoneNumber
                            })}
                        >
                            <h4>담당자 연락처1</h4>
                            <input
                                type="text"
                                name="phoneNumber"
                                ref={projectForm}
                                defaultValue={projectDetail.phoneNumber}
                            />
                        </li>
                        <li
                            className={cx("project-item", {
                                changed:
                                    projectDetail.contact?.way !== otherDetail.contact?.way ||
                                    projectDetail.contact?.value !== otherDetail.contact?.value
                            })}
                        >
                            <h4>담당자 연락처2</h4>
                            <select
                                name="contact.way"
                                ref={projectForm}
                                id="phonenumber2"
                                defaultValue={projectDetail.contact && projectDetail.contact.way}
                            >
                                <option value="kakao">카카오톡</option>
                                <option value="company">회사</option>
                                <option value="email">이메일</option>
                            </select>
                            <input
                                name="contact.value"
                                ref={projectForm}
                                type="text"
                                defaultValue={projectDetail.contact && projectDetail.contact.value}
                            />
                        </li>
                        <li
                            className={cx("project-item", {
                                changed: projectDetail.contactTime !== otherDetail.contactTime
                            })}
                        >
                            <h4>응대 가능 시간</h4>
                            <input
                                type="text"
                                name="contactTime"
                                defaultValue={projectDetail.contactTime}
                                ref={projectForm}
                            />
                        </li>
                    </ul>
                </Grid>

                {projectDetail.historyList?.length > 0 && (
                    <Grid item xs={12}>
                        <h3 className="info-title">히스토리</h3>
                        <TreeView
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                        >
                            {projectDetail.historyList.map((history, index) => (
                                <TreeItem
                                    key={index}
                                    nodeId={`title${index}`}
                                    label={`${history.revision}버전, 변경 값:${
                                        history.diff
                                    }, 날짜:${
                                        history.revisionDate
                                            ? moment(history.revisionDate).format(
                                                  "YYYY-MM-DD HH:mm"
                                              )
                                            : ""
                                    }`}
                                >
                                    {Object.keys(history.data).map((value, i) => (
                                        <TreeItem
                                            key={i}
                                            nodeId={`item${i}`}
                                            label={`${value}:${history.data[value]}`}
                                        />
                                    ))}
                                    {/* <TreeItem label={JSON.stringify(history.data)} /> */}
                                </TreeItem>
                            ))}
                        </TreeView>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default DetailInfo;

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import ContactUsReply from "components/contactUs/ContactUsReply";
import { callDirectApi } from "lib/common";
import { getTemplateList as getTemplateListAPI } from "lib/api/template";
import { replyComment } from "stores/modules/comment";
import AlertModal from "components/common/AlertModal";

const LIMIT_FILE_SIZE = 10 * 1024 * 1024;

const ContactUsReplyContainer = ({ pageType }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { commentId } = useParams();
    const { register, handleSubmit, setValue: setReplyForm } = useForm();
    const { register: searchForm, handleSubmit: searchHandleSumit } = useForm();
    const adminName = useSelector(({ auth }) => auth.userInfo.user && auth.userInfo.user.username);

    const [contentValue, setValue] = useState("");
    const [alertModal, handleAlertModal] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [attachments, handleAttachments] = useState([]);

    const closeModal = () => {
        handleAlertModal(false);
        history.push(pageType === "requestCall" ? "/call-request" : "/contact-us");
    };

    const handleFile = ({ type, files, index }) => {
        if (type === "add") {
            // eslint-disable-next-line
            Object.keys(files).some(key => {
                if (files[key].size > LIMIT_FILE_SIZE) {
                    window.alert("첨부파일은 최대 10MB 이하만 가능합니다.");
                    return true;
                }
            });

            if (attachments.length + files.length > 3) {
                window.alert("첨부파일은 최대 3개입니다.");
                return;
            }

            handleAttachments(prev => [...prev, ...files]);
        }

        if (type === "remove") {
            handleAttachments(prev => [...prev.filter((file, filesIndex) => index !== filesIndex)]);
        }
    };

    const onSearchTemplate = async data => {
        try {
            const result = await callDirectApi(getTemplateListAPI, data.keyword, true);

            if (result.status === 200) {
                setTemplateList(result.data);
            }
        } catch (error) {
            console.log("Search template error.");
            console.log(error);
            window.alert("검색에 실패하였습니다.");
        }
    };

    const submitData = async data => {
        const formData = new FormData();

        formData.append("requestId", commentId);
        formData.append("contents", contentValue);
        formData.append("manager", data.manager);

        if (attachments.length > 0) {
            attachments.forEach(file => {
                formData.append("attachments", file);
            });
        }
        dispatch(
            replyComment({
                replyData: formData,
                successPath: pageType === "requestCall" ? "/call-request" : "/contact-us"
            })
        );
    };

    useEffect(() => {
        if (adminName) {
            let managerName;
            switch (adminName) {
                case "pine":
                    managerName = "Pine";
                    break;

                case "beige":
                    managerName = "Yerin";
                    break;

                case "sjkwon":
                    managerName = "Seongjun";
                    break;

                default:
                    managerName = "Tom";
                    break;
            }
            setReplyForm("manager", managerName);
        }
    }, [adminName]);

    return (
        <>
            {alertModal && (
                <AlertModal handleModal={closeModal}>
                    <p>작성이 완료되었습니다.</p>
                </AlertModal>
            )}
            <ContactUsReply
                templateList={templateList}
                searchForm={searchForm}
                submitsearch={searchHandleSumit(onSearchTemplate)}
                replyForm={register}
                contentValue={contentValue}
                setValue={setValue}
                onSubmit={handleSubmit(submitData)}
                handleFile={handleFile}
                attachments={attachments}
            />
        </>
    );
};

export default ContactUsReplyContainer;

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import cx from "classnames";
import result from "lodash/result";
import TableRow from "@material-ui/core/TableRow";
import CustomTableCell from "components/common/CustomTableCell";
import { IconFont, Tag } from "reviewshare-common-component";
import { useSearchQuery } from "lib/customHooks/useSearchQuery";
import { WITHDRAWAL_TABLE_ITEMS } from "scheme/pointRequest";
import { returnTagColor } from "lib/common";
import CommonTable from "components/common/CommonTable";
import WithdrawPointActions from "components/withdrawPoint/WithdrawPointActions";

import "./WithdrawPointList.scss";

const WithdrawPointList = () => {
    const { search } = useSearchQuery();
    const { register, watch, setValue } = useForm({ defaultValues: { idList: [] } });
    const selectedIdList = watch("idList");
    const selectedAll = watch("selectedAll");
    const { list, counts } = useSelector(({ pointRequest }) => pointRequest.withdrawal);

    const handleAllCheckboxStatus = () => {
        if (selectedAll) {
            const idList = list.map(item => String(item._id));
            setValue("idList", idList);
        } else {
            setValue("idList", []);
        }
    };
    console.log(selectedIdList);
    useEffect(() => {
        handleAllCheckboxStatus();
    }, [selectedAll]);

    useEffect(() => {
        setValue("selectedAll", false);
        setValue("idList", []);
    }, [search]);
    return (
        <div id="withdraw-point-list">
            <WithdrawPointActions idList={selectedIdList} counts={counts} />
            <CommonTable
                counts={counts}
                headerItems={WITHDRAWAL_TABLE_ITEMS}
                checkboxProps={{ ref: register }}
            >
                <input type="checkbox" name="idList" value={undefined} ref={register} hidden />
                {list?.length > 0 &&
                    list.map((payment, index) => (
                        <TableRow
                            key={index}
                            hover
                            className={cx("coupon-list-row", {
                                "deleted-item": payment.isDeleted
                            })}
                        >
                            <CustomTableCell>
                                <input
                                    type="checkbox"
                                    name="idList"
                                    value={payment._id}
                                    ref={register}
                                />
                            </CustomTableCell>

                            {WITHDRAWAL_TABLE_ITEMS.map(item => {
                                if (item.value === "status") {
                                    return (
                                        <CustomTableCell align={item.align} key={item.value}>
                                            <Tag color={returnTagColor(result(payment, item.key))}>
                                                {item.convertFn(result(payment, item.key))}
                                            </Tag>
                                        </CustomTableCell>
                                    );
                                }

                                if (item.value === "accountNumber") {
                                    return (
                                        <CustomTableCell align={item.align} key={item.value}>
                                            {payment.bankName} {payment.accountNumber}
                                        </CustomTableCell>
                                    );
                                }

                                if (item.value === "owner") {
                                    return (
                                        <CustomTableCell align={item.align} key={item.value}>
                                            {payment.user?.name}({payment.user?._id})
                                        </CustomTableCell>
                                    );
                                }

                                return (
                                    <CustomTableCell align={item.align} key={item.value}>
                                        {item.openDetail && <IconFont icon="icon_project" />}
                                        {item.convertFn
                                            ? item.convertFn(result(payment, item.key))
                                            : result(payment, item.key)}
                                        {item.unit}
                                    </CustomTableCell>
                                );
                            })}
                        </TableRow>
                    ))}
            </CommonTable>
        </div>
    );
};

export default WithdrawPointList;

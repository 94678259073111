import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import * as authAPI from "lib/api/auth";
import produce from "immer";
import createRequestSaga, { createRequestActionTypes } from "lib/createRequestSaga";

const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] = createRequestActionTypes("auth/LOGIN");

const [
    GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE,
    GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE_SUCCESS,
    GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE_FAILURE
] = createRequestActionTypes("auth/GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE");

const LOGOUT = "auth/LOGOUT";

export const login = createAction(LOGIN, (userObj) => userObj);

export const getUserInfoAsTokenInLocalStorage = createAction(
    GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE
);

export const logout = createAction(LOGOUT);

const loginSaga = createRequestSaga(LOGIN, authAPI.login);

const getUserInfoAsTokenInLocalStorageSaga = createRequestSaga(
    GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE,
    authAPI.getMyInfo
);

export function* authSaga() {
    yield takeLatest(LOGIN, loginSaga);
    yield takeLatest(GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE, getUserInfoAsTokenInLocalStorageSaga);
}

const initialState = {
    userInfo: {
        user: null,
        token: null
    },
    userId: null,
    userInfoError: null,
    naverProfile: null,
    authError: null
};

const auth = handleActions(
    {
        [LOGIN_SUCCESS]: (state, { payload: userInfo }) => ({
            ...state,
            userInfo,
            userId: userInfo.user._id,
            userInfoError: null
        }),
        [LOGIN_FAILURE]: (state, { payload: error }) => ({
            ...state,
            userInfoError: error.response
        }),
        [GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE_SUCCESS]: (state, { payload: auth }) =>
            produce(state, (draft) => {
                draft.userInfo.user = auth.user;
                draft.userId = auth.user._id;
            }),
        [GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE_FAILURE]: (state, { payload: error }) => ({
            ...state,
            authError: error
        }),
        [LOGOUT]: () => ({
            ...initialState
        })
    },
    initialState
);

export default auth;

import React, { useState } from "react";
import UploadImage from "components/common/UploadImage";
import CropImageModal from "components/common/CropImageModal";

import "./AddTalentImage.scss";

const MAX_SUB_COUNTS = 6;
const AddTalentImage = ({ inputItems, setInputItems }) => {
    const [cropImageModalStatus, setCropImageModalStatus] = useState(false);

    const onAddMainImage = value => {
        setInputItems(prev => ({ ...prev, mainPhoto: value }));
    };

    const onAddSubImage = ({ event, index }) => {
        const { files } = event.target;
        if (!files || !files.length) return;

        let subPhotoCounts = inputItems.subPhoto.filter(item => !!item).length;

        for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
            if (MAX_SUB_COUNTS <= subPhotoCounts) {
                if (!(files.length === 1 && index < MAX_SUB_COUNTS)) {
                    break;
                }
            }
            subPhotoCounts += 1;
            const file = files[fileIndex];
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                const newFile = {
                    file: new File([file], `${new Date().getTime()}_${file.name}`, {
                        type: file.type
                    }),
                    preview: reader.result
                };

                setInputItems(prev => {
                    const _subPhoto = [...prev.subPhoto];
                    _subPhoto[index + fileIndex] = newFile;

                    return { ...prev, subPhoto: _subPhoto };
                });
            });
            reader.readAsDataURL(file);
        }
    };

    const onRemoveImage = ({ type, index }) => {
        if (type === "main") {
            setInputItems(prev => ({ ...prev, mainPhoto: null }));
        } else if (type === "sub") {
            const _subPhoto = [...inputItems.subPhoto];
            _subPhoto.splice(index, 1);
            _subPhoto.push(null);
            setInputItems(prev => ({ ...prev, subPhoto: _subPhoto }));
        }
    };

    return (
        <>
            <div id="create-talent-add-image">
                <div className="field image">
                    <p className="field-title">대표 이미지</p>
                    <ul>
                        <li>
                            <UploadImage
                                image={
                                    inputItems.mainPhoto &&
                                    (inputItems.mainPhoto.preview ||
                                        inputItems.mainPhoto.secure_url)
                                }
                                remove={() => onRemoveImage({ type: "main" })}
                                onClick={() => setCropImageModalStatus(true)}
                            />
                        </li>
                    </ul>
                </div>
                <div className="field image">
                    <p className="field-title">상세 이미지</p>
                    <ul className="field-content">
                        {inputItems.subPhoto.map((item, index) => {
                            if (index > 0 && !inputItems.subPhoto[index - 1]) return null;

                            return (
                                <li key={index}>
                                    <UploadImage
                                        image={item && (item.preview || item.secure_url)}
                                        multiple
                                        add={event => onAddSubImage({ event, index })}
                                        remove={() => onRemoveImage({ type: "sub", index })}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            {cropImageModalStatus && (
                <CropImageModal
                    setValue={val => onAddMainImage(val)}
                    closeModal={() => setCropImageModalStatus(false)}
                />
            )}
        </>
    );
};
export default AddTalentImage;

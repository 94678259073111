export const convertCouponStatus = status => {
    switch (status) {
        case "ready":
            return "사용 전";
        case "used":
            return "사용 완료";
        case "expired":
            return "기간 만료";
        case "retrieve":
            return "회수 완료";
        default:
            return status;
    }
};

export const convertCouponTarget = target => {
    switch (target) {
        case "all":
            return "전체";
        case "project":
            return "프로젝트";
        case "suggestion":
            return "1:1 제안";
        case "order":
            return "거래";
        default:
            return target;
    }
};

import React, { useState, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button, NormalModal, AlertModal } from "reviewshare-common-component";

import "./CropImageModal.scss";

const initialValue = {
    aspect: 1 / 1,
    unit: "px",
    width: 50,
    height: 50,
    x: 0,
    y: 0
};

const CropImageModal = ({ closeModal, setValue }) => {
    const imageRef = useRef(null);
    const [alert, setAlert] = useState({ status: false, text: "" });
    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState("");
    const [cropValue, setCropValue] = useState(initialValue);
    const [isMaxHeight, setIsMaxHeight] = useState(false);

    const onSelectFile = e => {
        if (!e.target.files || !e.target.files.length) {
            return;
        }

        if (e.target.files[0].size > 1024 * 1024 * 10) {
            return setAlert({
                status: true,
                text: "파일 크기는 최대 10MB입니다."
            });
        }

        setCropValue(initialValue);

        if (e.target.files && e.target.files.length > 0) {
            let maxSize = 340;
            const files = e.target.files;
            const selectedFile = new File([files[0]], `${new Date().getTime()}_${files[0].name}`, {
                type: files[0].type
            });
            setFile(selectedFile);
            const image = document.getElementById("upload-hidden-image");
            let ratio = 1;

            image.addEventListener("load", () => {
                if (image.naturalWidth < maxSize) {
                    maxSize = image.naturalWidth;
                }

                if (image.naturalHeight > image.naturalWidth) {
                    // 세로 길이가 더 길 때
                    ratio = maxSize / image.naturalHeight;
                    setCropValue(prev => ({
                        ...prev,
                        width: Math.floor(image.naturalWidth * ratio),
                        height: Math.floor(image.naturalWidth * ratio)
                    }));
                    setIsMaxHeight(true);
                } else {
                    // 가로 길이가 더 길 때
                    ratio = maxSize / image.naturalWidth;
                    setCropValue(prev => ({
                        ...prev,
                        width: Math.floor(image.naturalHeight * ratio),
                        height: Math.floor(image.naturalHeight * ratio)
                    }));
                }
            });
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImageUrl(reader.result);
            });
            reader.readAsDataURL(selectedFile);
        }
    };

    const onGetCroppedImg = (image, crop) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        let scale = scaleX;
        if (scaleX > scaleY) {
            scale = scaleY;
        }

        canvas.width = scale * crop.width;
        canvas.height = scale * crop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
            image,
            crop.x * scale,
            crop.y * scale,
            scale * crop.width,
            scale * crop.height,
            0,
            0,
            scale * crop.width,
            scale * crop.height
        );
        return new Promise(resolve => {
            canvas.toBlob(blob => {
                const newFile = new File([blob], file.name);
                resolve(newFile);
            }, "image/jpeg");
        });
    };

    const onCropComplete = async () => {
        if (cropValue.width && cropValue.height) {
            await onGetCroppedImg(imageRef.current, cropValue).then(croppedImageUrl => {
                setValue({
                    file: croppedImageUrl,
                    preview: window.URL.createObjectURL(croppedImageUrl)
                });
            });
            closeModal();
        }
    };

    return (
        <>
            <NormalModal>
                <div id="common-crop-image-modal">
                    <input
                        type="file"
                        accept="image/*"
                        id="common-crop-image-input"
                        style={{ display: "none" }}
                        onChange={onSelectFile}
                    />
                    <canvas id="upload-project-image-canvas" style={{ display: "none" }} />
                    <p className="title">대표 이미지 등록</p>
                    <Button
                        className="theme3 size-m m-size-m"
                        style={{ width: "100%" }}
                        onClick={() => document.getElementById("common-crop-image-input").click()}
                    >
                        이미지 선택하기
                    </Button>
                    <div className={`crop-image-area ${isMaxHeight ? "max-height" : ""}`}>
                        <ReactCrop
                            id="react-cro"
                            src={imageUrl}
                            onImageLoaded={val => (imageRef.current = val)}
                            crop={cropValue}
                            onChange={val => setCropValue(val)}
                        />
                    </div>

                    <img
                        id="upload-hidden-image"
                        ref={imageRef}
                        alt="Crop"
                        src={imageUrl}
                        style={{ display: "none" }}
                    />

                    <div className="button-wrapper flex">
                        <Button className="theme3 size-m m-size-m" onClick={closeModal}>
                            취소
                        </Button>
                        <Button className="theme1 size-m m-size-m" onClick={onCropComplete}>
                            업로드
                        </Button>
                    </div>
                </div>
            </NormalModal>
            {alert.status && (
                <AlertModal handleModal={() => setAlert({ status: false, text: "" })}>
                    {alert.text}
                </AlertModal>
            )}
        </>
    );
};
export default CropImageModal;

import { useDispatch, useSelector } from "react-redux";
import { resetOrderDetail, getAllOrderList, getOrderDetail } from "stores/modules/order";

// state
export const useOrderState = value => useSelector(({ order }) => order[value]);
export const useAllOrder = value =>
    useSelector(({ order }) => (value ? order.all[value] : order.all));

// action
export const useOrderAction = () => {
    const dispatch = useDispatch();

    const onResetOrderDetail = () => {
        dispatch(resetOrderDetail());
    };

    const onGetAllOrderList = (options = {}) => {
        dispatch(getAllOrderList(options));
    };

    const onGetOrderDetail = orderId => {
        dispatch(getOrderDetail(orderId));
    };

    return {
        resetOrderDetail: onResetOrderDetail,
        getAllOrderList: onGetAllOrderList,
        getOrderDetail: onGetOrderDetail
    };
};

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import queryString from "query-string";
import { TableContainer, Table, TableHead, TableRow, TableBody } from "@material-ui/core";
import { IconFont } from "reviewshare-common-component";
import CustomTableCell from "components/common/CustomTableCell";
import ListWrapper from "components/common/ListWrapper";
import AlertModal from "components/common/AlertModal";

import { getProjectRequestList } from "stores/modules/projectRequest";
import ProjectRequestModalContainer from "containers/projectManage/ProjectRequestModalContainer";

const tableHeaderList = [
    { label: "신청 ID", align: "left", value: "_id" },
    { label: "상태", align: "left", value: "status" },
    { label: "리뷰어 ID", align: "center", value: "reviewerId" },
    { label: "이름", align: "center", value: "name" },
    { label: "닉네임", align: "center", value: "nickname" },
    { label: "휴대폰 번호", align: "left", value: "phoneNumber" },
    { label: "프로젝트 ID", align: "center", value: "projectId" },
    { label: "프로젝트명", align: "left", value: "projectTitle", type: "projectLink" },
    { label: "제출 url", align: "left", value: "submittedUrl", type: "link" },
    { label: "제출 파일 url", align: "left", value: "submittedFile", type: "link" },
    { label: "마감일", align: "left", value: "submitEndAt" },
    { label: "제출일", align: "left", value: "submittedAt" },
    { label: "선정일", align: "left", value: "choiseDay" },
    { label: "신청일", align: "left", value: "requestedAt" },
    { label: "진행채널", align: "left", value: "projectChannel" },
    { label: "메모", align: "left", value: "memo" },
    { label: "옵션", align: "left", value: "options" },
    { label: "주소", align: "left", value: "address" },
    { label: "요청사항", align: "left", value: "messageRequest" }
];
const PAGE_LIMIT = 10;
const convertStatus = status => {
    switch (status) {
        case "request":
            return "신청";
        case "preChosen":
            return "임시선정";
        case "chosen":
            return "선정";
        case "cancelByBizFault":
            return "선정취소";
        case "cancelByReviewerFault":
            return "프로젝트취소";
        case "applyForCancel":
            return "취소요청";
        case "unsubmitted":
            return "미제출";
        case "submitted":
            return "제출";

        default:
            return "-";
    }
};

const ProjectRequestListContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [isOpenAlertModal, handleAlertModal] = useState(false);
    const [modalStatus, handleModal] = useState({ isOpen: false, requestItem: null });

    const params = queryString.parse(location.search);
    const totalCount = useSelector(({ projectRequest }) => projectRequest.totalCount);
    const requestList = useSelector(({ projectRequest }) => projectRequest.list);
    const [currentPage, setCurrentPage] = useState(params.page || 1);

    const setPage = pageNum => {
        params.page = pageNum;
        const query = queryString.stringify(params);
        history.push(`${location.pathname}?${query}`);
    };

    const closeAlertModal = () => {
        handleAlertModal(false);
        handleModal({ isOpen: false, requestItem: null });
        dispatch(
            getProjectRequestList({
                ...params,
                skip: params.page ? (params.page - 1) * PAGE_LIMIT : undefined,
                limit: PAGE_LIMIT
            })
        );
    };

    useEffect(() => {
        setCurrentPage(params.page ? Number(params.page) : 1);
    }, [params.page]);

    return (
        <ListWrapper
            currentPage={currentPage}
            handlePage={(e, pageNum) => setPage(pageNum)}
            pageLimit={PAGE_LIMIT}
            totalCount={totalCount}
            // onRemove={onRemove}
        >
            <TableContainer>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {tableHeaderList.map((headerItem, index) => (
                                <CustomTableCell
                                    align={headerItem.align}
                                    key={index}
                                    style={{ width: headerItem.width }}
                                >
                                    {headerItem.label}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requestList &&
                            requestList.length > 0 &&
                            requestList.map((requestItem, index) => {
                                return (
                                    <TableRow key={index} hover>
                                        {tableHeaderList.map((tableItem, tableIndex) => {
                                            const hasOpenFn = tableItem.value === "status";

                                            return (
                                                <CustomTableCell
                                                    key={tableIndex}
                                                    align={tableHeaderList[tableIndex].align}
                                                    className={cx({
                                                        "open-detail": hasOpenFn
                                                    })}
                                                    onClick={() => {
                                                        if (hasOpenFn) {
                                                            handleModal({
                                                                isOpen: true,
                                                                requestItem
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {tableItem.type === "link" ? (
                                                        <a
                                                            href={requestItem[tableItem.value]}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {requestItem[tableItem.value]}
                                                        </a>
                                                    ) : tableItem.type === "projectLink" ? (
                                                        <a
                                                            href={`${process.env.REACT_APP_MAIN_SERVICE_URL}/project/${requestItem.projectId}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {requestItem[tableItem.value]}
                                                        </a>
                                                    ) : tableItem.value === "status" ? (
                                                        <>
                                                            <IconFont icon="icon_project" />
                                                            {convertStatus(
                                                                requestItem[tableItem.value]
                                                            )}
                                                            {requestItem?.securityDeposit?._id && (
                                                                <IconFont
                                                                    icon="icon_deposit"
                                                                    style={{
                                                                        marginLeft: "2px"
                                                                    }}
                                                                >
                                                                    보증금
                                                                </IconFont>
                                                            )}
                                                        </>
                                                    ) : [
                                                          "submitEndAt",
                                                          "requestedAt",
                                                          "submittedAt",
                                                          "choiseDay"
                                                      ].indexOf(tableItem.value) > -1 ? (
                                                        <>
                                                            {requestItem[tableItem.value]
                                                                ? moment(
                                                                      requestItem[tableItem.value]
                                                                  ).format("YYYY-MM-DD")
                                                                : "-"}
                                                        </>
                                                    ) : (
                                                        <span>{requestItem[tableItem.value]}</span>
                                                    )}
                                                </CustomTableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                {isOpenAlertModal && (
                    <AlertModal handleModal={closeAlertModal}>
                        <p>업데이트 성공</p>
                    </AlertModal>
                )}
                {modalStatus.isOpen && (
                    <ProjectRequestModalContainer
                        modalStatus={modalStatus.isOpen}
                        requestItem={modalStatus.requestItem}
                        openAlertModal={() => handleAlertModal(true)}
                        onClose={() => handleModal({ isOpen: false, requestItem: null })}
                    />
                )}
            </TableContainer>
        </ListWrapper>
    );
};

export default ProjectRequestListContainer;

import { useDispatch, useSelector } from "react-redux";
import {
    resetTalentDetail,
    getAllTalentList,
    getTalentDetail,
    updateTalentDetail,
    setPickTalent
} from "stores/modules/talent";

// state
export const useTalentState = value => useSelector(({ talent }) => talent[value]);
export const useAllTalent = value =>
    useSelector(({ talent }) => (value ? talent.all[value] : talent.all));

// action
export const useTalentAction = () => {
    const dispatch = useDispatch();

    const onResetTalentDetail = () => {
        dispatch(resetTalentDetail());
    };

    const onGetAllTalentList = (options = {}) => {
        dispatch(getAllTalentList(options));
    };

    const onGetTalentDetail = talentId => {
        dispatch(getTalentDetail(talentId));
    };

    const onUpdateTalentDetail = ({ talentId, obj }) => {
        dispatch(updateTalentDetail({ talentId, obj }));
    };

    const onSetPickTalent = ({ idList, process }) => {
        dispatch(setPickTalent({ talentIdList: idList, process }));
    };

    return {
        resetTalentDetail: onResetTalentDetail,
        getAllTalentList: onGetAllTalentList,
        getTalentDetail: onGetTalentDetail,
        updateTalentDetail: onUpdateTalentDetail,
        setPickTalent: onSetPickTalent
    };
};

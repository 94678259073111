import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { CautionList } from "reviewshare-common-component";
import { numberWithCommas } from "lib/common";

import "./MessageAlarmContent.scss";

import receive_gift_point from "assets/images/pages/messenger/receive_gift_point.jpg";
import receive_gift_point_m from "assets/images/pages/messenger/receive_gift_point_mobile.jpg";
import send_gift_point from "assets/images/pages/messenger/send_gift_point.jpg";
import send_gift_point_m from "assets/images/pages/messenger/send_gift_point_mobile.jpg";

const unknown = "알 수 없음";
const MessageAlarmContent = ({ data }) => {
    if (!data.pointGift) return null;

    const { type, pointGift } = data;

    return (
        <>
            {(() => {
                switch (data.contents.type) {
                    case "gift":
                        return (
                            <div className={cx("message-item-alarm-content", "gift")}>
                                <div
                                    className="top-illust-image web"
                                    style={{
                                        backgroundImage: `url(${
                                            type === "other" ? receive_gift_point : send_gift_point
                                        })`
                                    }}
                                />
                                <div
                                    className="top-illust-image mobile"
                                    style={{
                                        backgroundImage: `url(${
                                            type === "other"
                                                ? receive_gift_point_m
                                                : send_gift_point_m
                                        })`
                                    }}
                                />
                                <div className="alarm-detail">
                                    <div className="alarm-title">
                                        <h3 className="title-text">
                                            {type === "other"
                                                ? "포인트 선물 도착❤️"
                                                : "포인트 선물 완료❤️"}
                                        </h3>
                                        <h4 className="sub-text">
                                            {numberWithCommas(pointGift.pointsPerTarget)}P
                                        </h4>
                                    </div>
                                    {type === "other" ? (
                                        <div className="alarm-text">
                                            {pointGift.brand?.name || unknown} 담당자님이 포인트를
                                            선물했어요. 지금 확인해 보세요!
                                        </div>
                                    ) : (
                                        <ul className="detail-list">
                                            <li className="detail-item">
                                                <p>브랜드</p>
                                                <div>
                                                    <span className="ellipsis-text">
                                                        {pointGift.brand?.name || unknown}
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="detail-item">
                                                <p>받은 리뷰어</p>
                                                <div>
                                                    <span className="ellipsis-text">
                                                        {pointGift.targetList?.[0]?.nickname ||
                                                            unknown}
                                                    </span>
                                                    {pointGift.targetList?.length > 1 &&
                                                        ` 외 ${numberWithCommas(
                                                            pointGift.targetList.length - 1
                                                        )}명`}
                                                </div>
                                            </li>
                                        </ul>
                                    )}
                                    <CautionList
                                        title=""
                                        items={
                                            type === "other"
                                                ? [
                                                      `선물받은 포인트는 위 금액에서 ‘리뷰쉐어 수수료’ ${pointGift.rateOfFee}%를 차감하고 지급돼요.`,
                                                      "선물받은 포인트는 [마이페이지] - [보유 포인트]에서 확인 가능합니다."
                                                  ]
                                                : [
                                                      "선물한 포인트는 환불이 어려워요.",
                                                      "선물한 포인트는 [비즈니스 센터] - [결제/포인트 내역]에서 확인 가능합니다."
                                                  ]
                                        }
                                    />
                                    <div className="alarm-buttons">
                                        <Link className="rs-button theme6 size-m m-size-m">
                                            선물 내역 보기
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        );
                    default:
                        return null;
                }
            })()}
        </>
    );
};
export default MessageAlarmContent;

import client from "./client";

export const getCommentList = ({
    skip = 0,
    type,
    status,
    email,
    userId,
    name,
    contents,
    replyContents
}) =>
    client.get("/comments/admin", {
        params: { type, status, email, userId, name, contents, replyContents, skip }
    });

export const getComment = commentId => client.get(`/comments/${commentId}`);
export const removeComment = idList => client.delete("/comments/admin", { data: { idList } });

export const replyComment = replyData => client.post("/comments/reply", replyData);

import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import createRequestSaga, { createRequestActionTypes } from "lib/createRequestSaga";
import * as walletAPI from "lib/api/wallet";

const RESET_WALLET_INFO = "/wallet/RESET_WALLET_INFO";
const [GET_MY_WALLET, GET_MY_WALLET_SUCCESS, GET_MY_WALLET_FAILURE] = createRequestActionTypes(
    "wallet/GET_MY_WALLET"
);

export const resetWalletInfo = createAction(RESET_WALLET_INFO);
export const getMyWallet = createAction(GET_MY_WALLET, userId => userId);

const getMyWalletSaga = createRequestSaga(GET_MY_WALLET, walletAPI.findOneWallet);

export function* walletSaga() {
    yield takeLatest(GET_MY_WALLET, getMyWalletSaga);
}

const initialState = {
    totalPoints: null,
    readTotalPointsError: null
};

const wallet = handleActions(
    {
        [RESET_WALLET_INFO]: state => ({
            ...state,
            totalPoints: 0,
            readTotalPointsError: null
        }),
        [GET_MY_WALLET]: state => ({
            ...state,
            totalPoints: 0
        }),
        [GET_MY_WALLET_SUCCESS]: (state, { payload: userWallet }) => ({
            ...state,
            totalPoints: userWallet.wallet.total
        }),
        [GET_MY_WALLET_FAILURE]: (state, { payload: error }) => ({
            ...state,
            readTotalPointsError: error
        })
    },
    initialState
);

export default wallet;

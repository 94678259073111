import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AlertModal from "components/common/AlertModal";
import { Editor } from "reviewshare-common-component";
import { callDirectApi } from "lib/common";
import { useForm } from "react-hook-form";
import { createTemplate as createTemplateAPI } from "lib/api/template";

const TemplateWriteContainer = () => {
    const history = useHistory();
    const { register, handleSubmit } = useForm();
    const [contentValue, setValue] = useState("");
    const [alertModal, handleAlertModal] = useState(false);

    const closeModal = () => {
        handleAlertModal(false);
        history.go(0);
    };

    const submitData = async data => {
        try {
            await callDirectApi(createTemplateAPI, {
                contents: contentValue,
                title: data.title,
                type: "comment"
            });

            handleAlertModal(true);
        } catch (error) {
            console.log("Write template error.");
            console.log(error);
            window.alert("작성에 실패하였습니다." + error);
        }
    };

    return (
        <>
            {alertModal && (
                <AlertModal handleModal={closeModal}>
                    <p>작성이 완료되었습니다.</p>
                </AlertModal>
            )}
            <form
                onSubmit={handleSubmit(submitData)}
                style={{ backgroundColor: "white", padding: "20px" }}
            >
                <h3>템플릿명</h3>
                <input
                    type="text"
                    ref={register}
                    name="title"
                    style={{ width: "300px", marginTop: "10px", marginBottom: "20px" }}
                />
                <Editor value={contentValue} onChange={contentValue => setValue(contentValue)} />
                <div className="btn-area" style={{ marginTop: "20px" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        className="submit-btn"
                        type="submit"
                    >
                        작성
                    </Button>
                </div>
            </form>
        </>
    );
};

export default TemplateWriteContainer;

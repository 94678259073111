import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { Tag, IconFont } from "reviewshare-common-component";
import cx from "classnames";
import { returnTagColor } from "lib/common";
import { convertStatus, convertRereviewingStatus } from "lib/utils/project";
import {
    cancelProject as cancelProjectAPI,
    removeProject as removeProjectAPI,
    confirmProject as confirmProjectAPI,
    setRereviewingStatus as setRereviewingStatusAPI
} from "lib/api/project";
import useDirectApi from "lib/customHooks/useDirectApi";

import "./ModalWrapper.scss";

const ModalWrapper = ({ isOpen, closeModal, updateProject, children }) => {
    const projectId = useSelector(({ project }) => project.projectDetail._id);
    const projectIsDeleted = useSelector(({ project }) => project.projectDetail.isDeleted);
    const projectStatus = useSelector(({ project }) => project.projectDetail.status);
    const projectRereviewingStatus = useSelector(
        ({ project }) => project.projectDetail.rereviewingStatus
    );
    const projectPayment = useSelector(({ project }) => project.projectDetail.payment);
    const reReviewingProjectId = useSelector(({ project }) => project.reReviewingProjectDetail._id);

    const { data: confirmedStatus, fetch: fetchConfirmProject } = useDirectApi({
        api: confirmProjectAPI,
        showError: true
    });
    const { data: cancelledStatus, fetch: fetchCancelProject } = useDirectApi({
        api: cancelProjectAPI,
        showError: true
    });
    const { data: changedRereviewingStatus, fetch: fetchSetRereviewingStatus } = useDirectApi({
        api: setRereviewingStatusAPI,
        showError: true
    });

    const [rejectReason, setRejectReason] = useState("");

    const hasRequestModify = !!reReviewingProjectId;
    const isBeforePayment = projectPayment?.status === "ready";

    const onRemoveProject = () => {
        if (!projectId) return;

        const boolean = window.confirm("진짜 삭제하시겠습니까?");
        if (boolean) {
            try {
                removeProjectAPI(projectId);
            } catch (e) {
                console.log(e);
            }
        }
    };

    const handleConfirmOfProject = status => {
        if (status === "confirm") {
            fetchConfirmProject({
                projectId
            });
        }
        if (status === "cancel") {
            fetchCancelProject({
                projectId
            });
        }
    };

    const onConfirmModify = () => {
        fetchSetRereviewingStatus({ projectId, info: { process: "confirm" } });
    };

    const onRejectModify = () => {
        if (!rejectReason) return;
        fetchSetRereviewingStatus({
            projectId,
            info: { process: "deny", reasonForDeny: rejectReason }
        });
    };

    useEffect(() => {
        if (confirmedStatus || cancelledStatus || changedRereviewingStatus) {
            closeModal();
        }
    }, [confirmedStatus, cancelledStatus, changedRereviewingStatus]);

    return (
        <Modal open={isOpen} onClose={closeModal}>
            <div
                className={cx("project-detail-modal", {
                    "has-request-modify": hasRequestModify
                })}
            >
                <div className="modal-title">
                    <h2>프로젝트 ID {projectId}</h2>
                    <div className="tag-items">
                        <Tag
                            className="size-l"
                            color={returnTagColor(projectIsDeleted ? "gray-light" : projectStatus)}
                        >
                            {projectIsDeleted ? "삭제됨" : convertStatus(projectStatus)}
                        </Tag>
                        {isBeforePayment && (
                            <Tag className="size-l" color="gray-dark">
                                <IconFont icon="icon_caution_fill" /> 입금 전
                            </Tag>
                        )}
                        {projectRereviewingStatus && (
                            <Tag
                                className="size-l"
                                color={projectRereviewingStatus === "request" ? "orange" : "red"}
                            >
                                {convertRereviewingStatus(projectRereviewingStatus)}
                            </Tag>
                        )}
                    </div>
                </div>
                <div
                    className={cx("modal-content", {
                        disabled: hasRequestModify
                    })}
                >
                    {children}
                </div>

                <div className="modal-footer">
                    {hasRequestModify ? (
                        <>
                            <div className="reject-modify-reason">
                                <textarea
                                    placeholder="거절 사유를 입력해주세요"
                                    value={rejectReason}
                                    onChange={e => {
                                        const { value } = e.target;
                                        setRejectReason(value);
                                    }}
                                />
                            </div>

                            <Button variant="contained" color="primary" onClick={onConfirmModify}>
                                수정 요청 승인
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={!rejectReason}
                                onClick={onRejectModify}
                            >
                                수정 요청 거절
                            </Button>
                        </>
                    ) : (
                        <>
                            {projectStatus === "standby" ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleConfirmOfProject("confirm")}
                                    disabled={isBeforePayment}
                                >
                                    검수 승인
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={() => handleConfirmOfProject("cancel")}
                                >
                                    검수 취소
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={updateProject}
                                disabled={!!reReviewingProjectId}
                            >
                                수정
                            </Button>
                            <Button variant="contained" color="secondary" onClick={onRemoveProject}>
                                삭제
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ModalWrapper;

import client from "./client";

export const getSuggestionList = ({ id, from, to, skip, limit }) =>
    client.get(
        `/v2/suggestions/list/admin?skip=${skip || 0}&limit=${limit || 50}${id ? `&id=${id}` : ""}${
            from ? `&from=${from}` : ""
        }${to ? `&to=${to}` : ""}`
    );
export const getSuggestion = id => client.get(`/v2/suggestions/info/${id}/admin`);
export const cancel = id => client.put(`/v2/suggestions/cancel/${id}/admin`);
export const unsubmit = id => client.put(`/v2/suggestions/unsubmit/${id}/admin`);

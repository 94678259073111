export const convertSubsStatus = status => {
    switch (status) {
        case "none":
            return "없음";
        case "running":
            return "진행중";
        case "readyForUnsubscribe":
            return "해지 예정";
        case "interruption":
            return "결제 실패";
        case "complete":
            return "완료";
        case "fail":
            return "실패";
        case "readyForRefund":
            return "환불 예정";
        default:
            return status;
    }
};

export const convertUnSubs = reasonForUnSubs => {
    switch (reasonForUnSubs) {
        case "notEveryMonth":
            return "매 달 필요하진 않아요";
        case "burdensomePrice":
            return "가격이 부담스러워요";
        case "noReivewer":
            return "원하는 리뷰어가 없어요";
        case "changeBrand":
            return "구독 브랜드를 변경하고 싶어요";
        case "inconvenientService":
            return "서비스 이용이 불편해요";
        case "etc":
            return "기타";

        default:
            return reasonForUnSubs;
    }
};

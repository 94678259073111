import React from "react";
import cx from "classnames";
import moment from "moment";
import { TableContainer, Table, TableHead, TableRow, TableBody } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";
import CustomTableCell from "components/common/CustomTableCell";
import { IconFont } from "reviewshare-common-component";
import { convertSuggestionStatus, numberWithCommas } from "lib/common";

import "./SuggestionList.scss";

const SuggestionList = ({ list, counts, limit, openDetailModal, changePaginateion }) => {
    const tableHeaderList = [
        { label: "제안 ID", align: "center" },
        { label: "상태", align: "left" },
        { label: "비즈니스", align: "center" },
        { label: "리뷰어", align: "center" },
        { label: "포인트", align: "center" },
        { label: "제공 포인트 환불 여부", align: "center" },
        { label: "제안 날짜", align: "center" },
        { label: "제출 마감일", align: "center" }
    ];
    return (
        <div id="suggestion-list">
            <TableContainer>
                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {tableHeaderList.map((headerItem, index) => (
                                <CustomTableCell
                                    align={headerItem.align}
                                    key={index}
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    {headerItem.label}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.length > 0 &&
                            list.map((suggestion, index) => (
                                <TableRow
                                    key={index}
                                    hover
                                    className={cx("suggestion-list-row", {
                                        "deleted-item": suggestion.isDeleted
                                    })}
                                >
                                    <CustomTableCell align="center">
                                        {suggestion._id}
                                    </CustomTableCell>
                                    <CustomTableCell
                                        className="open-detail"
                                        onClick={() => openDetailModal(suggestion._id)}
                                    >
                                        <IconFont icon="icon_project" />
                                        {convertSuggestionStatus(suggestion.status)}
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {suggestion.from?.name}({suggestion.from?._id})
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {suggestion.to?.nickname}({suggestion.to?._id})
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {numberWithCommas(suggestion.points)}P
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {suggestion.isRefunded ? "O" : "X"}
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {moment(suggestion.createdAt).format("YYYY-MM-DD HH:mm")}
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {suggestion.submitEndAt
                                            ? moment(suggestion.submitEndAt).format(
                                                  "YYYY-MM-DD HH:mm"
                                              )
                                            : "-"}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center" style={{ margin: "20px 0" }}>
                <Pagination
                    count={Math.ceil(counts / limit)}
                    color="primary"
                    onChange={changePaginateion}
                />
            </Box>
        </div>
    );
};

export default SuggestionList;

import React from "react";
import cx from "classnames";
import { IconFont, Button } from "reviewshare-common-component";

import "./UploadImage.scss";

const UploadImage = ({ image, className, add, remove, multiple = false, onClick }) => (
    <div className={cx("common-upload-image-item", className, { empty: !image })}>
        {image && <img src={image} alt="서비스 이미지" />}
        <div className="button-items">
            {image ? (
                <>
                    <div className="edit-buttons">
                        {onClick ? (
                            <button type="button" className="edit-btn" onClick={onClick}>
                                수정
                            </button>
                        ) : (
                            <label className="edit-btn">
                                <input type="file" accept="image/*" hidden onChange={add} />
                                수정
                            </label>
                        )}
                        <span className="line" />
                        <button type="button" className="remove-btn" onClick={remove}>
                            삭제
                        </button>
                    </div>
                </>
            ) : (
                <>
                    {onClick ? (
                        <Button className="theme10 size-m m-size-s add-btn" onClick={onClick}>
                            <IconFont icon="icon_plus">추가하기</IconFont>
                        </Button>
                    ) : (
                        <label className="rs-button theme10 size-m m-size-s add-btn">
                            <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={add}
                                multiple={multiple}
                            />
                            <IconFont icon="icon_plus">추가하기</IconFont>
                        </label>
                    )}
                </>
            )}
        </div>
    </div>
);

export default UploadImage;

export const convertSns = sns => {
    switch (sns) {
        case "NaverBlog":
            return "네이버블로그";
        case "NaverPost":
            return "네이버포스트";
        case "Facebook":
            return "페이스북";
        case "Instagram":
            return "인스타그램";
        case "KakaoStory":
            return "카카오스토리";
        case "Youtube":
            return "유튜브";
        case "OtherSns":
            return "기타";
        case "SelfMarket":
            return "자사몰";
        case "OnlineComm":
            return "쇼핑몰";
        case "NaverCafe":
            return "네이버카페";
        case "AppReview":
            return "앱리뷰";
        case "Tiktok":
            return "틱톡";
        case "Reels":
            return "릴스";
        case "Shorts":
            return "쇼츠";
        case "NaverInfluencer":
            return "네이버 인플루언서";
        default:
            return sns;
    }
};

import React from "react";
import { numberWithCommas } from "lib/common";
import { bizSubsMainStatistics } from "scheme/Scheme";

import "./SubsMainStatisticsItem.scss";

const SubsMainStatisticsItem = ({ statisticsItem }) => (
    <li className="subs-main-statistics-item">
        <h3>{bizSubsMainStatistics[statisticsItem.name].label}</h3>
        <p className="desc">{bizSubsMainStatistics[statisticsItem.name].desc}</p>
        <p className="statistics-value">
            {numberWithCommas(Math.round(statisticsItem.value * 100) / 100)}
        </p>
    </li>
);
export default SubsMainStatisticsItem;

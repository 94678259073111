import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BannerModal from "components/bannerList/BannerModal";
import { getBannerInfo, selectBannerId, editBanner } from "stores/modules/banner";

const BannerDetailContainer = () => {
    const dispatch = useDispatch();
    const { selectedBannerId, bannerInfo } = useSelector(({ banner }) => banner);

    useEffect(() => {
        if (selectedBannerId) {
            dispatch(getBannerInfo(selectedBannerId));
        }
    }, [dispatch, selectedBannerId]);

    const onCloseModal = () => {
        dispatch(selectBannerId(null));
    };

    const onEditBanner = info => {
        if (!info._id || !info.name) return;

        const form = new FormData();
        form.append("id", info._id);
        form.append("photos", info.fileList[0]);
        form.append("photos", info.fileList[1]);
        form.append("editPhoto", [!!info.fileList[0], !!info.fileList[1]]);
        form.append("isShowing", info.isShowing);
        form.append("position", info.position);
        form.append("alt", info.alt);
        form.append("index", info.index);
        form.append("name", info.name);
        form.append("link", info.link);
        form.append("backgroundColor", info.backgroundColor);
        form.append("target", info.target);
        dispatch(editBanner(form));
    };

    return (
        <div>
            {selectedBannerId && bannerInfo && !!Object.keys(bannerInfo).length && (
                <BannerModal
                    data={bannerInfo}
                    closeModal={onCloseModal}
                    editBanner={onEditBanner}
                />
            )}
        </div>
    );
};

export default BannerDetailContainer;

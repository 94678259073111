import React from "react";
import { Switch, Route } from "react-router-dom";
import ManageNav from "components/common/ManageNav";
import TalentFilter from "components/talent/TalentFilter";
import TalentList from "components/talent/TalentList";
import TalentModal from "components/talent/TalentModal";
import OrderFilter from "components/order/OrderFilter";
import OrderList from "components/order/OrderList";
import OrderModal from "components/order/OrderModal";

const TalentListPage = () => (
    <>
        <ManageNav
            navList={[
                { url: "/talent/list", name: "서비스" },
                { url: "/talent/order", name: "거래" }
            ]}
        />
        <Switch>
            <Route exact path="/talent/list">
                <TalentFilter />
                <TalentList />
                <TalentModal />
            </Route>
            <Route path="/talent/order">
                <OrderFilter />
                <OrderList />
                <OrderModal />
            </Route>
        </Switch>
    </>
);

export default TalentListPage;

import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import produce from "immer";
import { createRequestActionTypes, createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as bannerAPI from "lib/api/banner";

const [
    GET_BANNER_LIST,
    GET_BANNER_LIST_SUCCESS,
    // eslint-disable-next-line
    GET_BANNER_LIST_FAILURE
] = createRequestActionTypes("/banner/GET_BANNER_LIST");

const SELECT_BANNER_ID = "/banner/SELECT_BANNER_ID";

const [
    GET_BANNER_INFO,
    GET_BANNER_INFO_SUCCESS,
    // eslint-disable-next-line
    GET_BANNER_INFO_FAILURE
] = createRequestActionTypes("/banner/GET_BANNER_INFO");

const [
    CREATE_BANNER,
    CREATE_BANNER_SUCCESS,
    // eslint-disable-next-line
    CREATE_BANNER_FAILURE
] = createRequestActionTypes("/banner/CREATE_BANNER");

const [
    EDIT_BANNER,
    EDIT_BANNER_SUCCESS,
    // eslint-disable-next-line
    EDIT_BANNER_FAILURE
] = createRequestActionTypes("/banner/EDIT_BANNER");

const [
    REMOVE_BANNER,
    REMOVE_BANNER_SUCCESS,
    // eslint-disable-next-line
    REMOVE_BANNER_FAILURE
] = createRequestActionTypes("/banner/REMOVE_BANNER");

export const getBannerList = createAction(GET_BANNER_LIST, obj => obj);
export const selectBannerId = createAction(SELECT_BANNER_ID, bannerId => bannerId);
export const getBannerInfo = createAction(GET_BANNER_INFO, bannerId => bannerId);
export const createBanner = createAction(CREATE_BANNER, obj => obj);
export const editBanner = createAction(EDIT_BANNER, obj => obj);
export const removeBanner = createAction(REMOVE_BANNER, bannerIds => bannerIds);

const getBannerListSaga = createRequestSagaWithAlert(
    GET_BANNER_LIST,
    bannerAPI.getBannerList,
    "배너 목록을 가져오는 데 실패하였습니다."
);

const getBannerInfoSaga = createRequestSagaWithAlert(
    GET_BANNER_INFO,
    bannerAPI.getBanner,
    "배너 정보을 가져오는 데 실패하였습니다."
);

const createBannerSaga = createRequestSagaWithAlert(
    CREATE_BANNER,
    bannerAPI.create,
    "배너 생성에 실패하였습니다.",
    true,
    "배너가 생성되었습니다."
);

const editBannerSaga = createRequestSagaWithAlert(
    EDIT_BANNER,
    bannerAPI.edit,
    "배너 수정에 실패하였습니다.",
    true,
    "배너가 수정되었습니다."
);

const removeBannerSaga = createRequestSagaWithAlert(
    REMOVE_BANNER,
    bannerAPI.remove,
    "배너 삭제에 실패하였습니다."
);

export function* bannerSaga() {
    yield takeLatest(GET_BANNER_LIST, getBannerListSaga);
    yield takeLatest(GET_BANNER_INFO, getBannerInfoSaga);
    yield takeLatest(CREATE_BANNER, createBannerSaga);
    yield takeLatest(EDIT_BANNER, editBannerSaga);
    yield takeLatest(REMOVE_BANNER, removeBannerSaga);
}

const initialState = {
    bannerList: [],
    bannerCounts: 0,
    selectedBannerId: null,
    bannerInfo: {}
};

const banner = handleActions(
    {
        [GET_BANNER_LIST]: state => ({
            ...state,
            bannerList: [],
            bannerCounts: 0
        }),
        [GET_BANNER_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            bannerList: payload.bannerList,
            bannerCounts: payload.counts
        }),
        [SELECT_BANNER_ID]: (state, { payload }) => ({
            ...state,
            selectedBannerId: payload,
            bannerInfo: {}
        }),
        [GET_BANNER_INFO]: state => ({
            ...state,
            bannerInfo: {}
        }),
        [GET_BANNER_INFO_SUCCESS]: (state, { payload }) => ({
            ...state,
            bannerInfo: payload.banner
        }),
        [CREATE_BANNER_SUCCESS]: state => ({
            ...state,
            selectedBannerId: "",
            bannerInfo: {}
        }),
        [EDIT_BANNER_SUCCESS]: state => ({
            ...state,
            selectedBannerId: null,
            bannerInfo: {}
        }),
        [REMOVE_BANNER_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.bannerList = state.bannerList.map(item => {
                    if (payload.ids.indexOf(item._id) > -1) {
                        return { ...item, isDeleted: true };
                    }
                    return item;
                });
            })
    },
    initialState
);

export default banner;

import React from "react";
import LoginContainer from "containers/login/LoginContainer";

const LoginPage = () => (
    <>
        <LoginContainer />
    </>
);

export default LoginPage;

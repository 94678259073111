import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { useSearchQuery, useCreateSearchQuery } from "lib/customHooks/useSearchQuery";
import { getMessageBoxList, resetMessenger } from "stores/modules/messenger";

import "./MessengerFilter.scss";

const MessengerFilter = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { query, search } = useSearchQuery();
    const createSearchQuery = useCreateSearchQuery;

    const [selectedOptions, setSelectedOptions] = useState({
        userId: query.userId || undefined,
        brandId: query.brandId || undefined
    });

    const onChangeOption = e => {
        const { name, value } = e.target;
        const filteredValue = value === "all" || !value ? undefined : value;
        setSelectedOptions(prev => ({ ...prev, [name]: filteredValue }));
    };

    const onSearch = () => {
        const newQuery = createSearchQuery(selectedOptions);
        history.replace({ path: "/messenger", search: newQuery });
    };

    useEffect(() => {
        if (query.userId) {
            dispatch(getMessageBoxList({ ...selectedOptions, skip: Number(query.skip) }));
        }
    }, [search]);

    useEffect(() => {
        return () => {
            dispatch(resetMessenger());
        };
    }, []);

    return (
        <div id="messenger-filter">
            <fieldset>
                <div className="field">
                    <label htmlFor="messenger-filter-user-id">유저 ID</label>
                    <input
                        type="text"
                        id="messenger-filter-user-id"
                        className="isRequired"
                        placeholder="필수"
                        name="userId"
                        value={selectedOptions.userId}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onSearch();
                            }
                        }}
                        onChange={onChangeOption}
                    />
                </div>
                <div className="field">
                    <label htmlFor="messenger-filter-brand-id">브랜드 ID</label>
                    <input
                        type="text"
                        id="messenger-filter-brand-id"
                        name="brandId"
                        placeholder="옵션"
                        value={selectedOptions.brandId}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onSearch();
                            }
                        }}
                        onChange={onChangeOption}
                    />
                </div>
            </fieldset>
            <div className="button-box">
                <Button variant="contained" color="primary" onClick={onSearch}>
                    검색
                </Button>
            </div>
        </div>
    );
};

export default MessengerFilter;
